import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/etapas.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { actions as dominiosAction } from "../../reducers/dominios.actions";
import { getPayload } from "../../selectors/rotas.selectors";

const listarEtapasApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/etapas",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarEtapaApi = ({ etapa }) => {
  return authenticatedRequest({
    url: "/administracao/etapa",
    isResourceService: true,
    method: "post",
    body: { etapa: etapa },
  });
};

const excluirEtapaApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/etapa/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarEtapasApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/etapa/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarEtapas = basicFlow({
  actionGenerator: actions.listarEtapas,
  api: listarEtapasApi,
});

const atualizarEtapa = basicFlow({
  actionGenerator: actions.atualizarEtapa,
  api: atualizarEtapaApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirEtapa = basicFlow({
  actionGenerator: actions.incluirEtapa,
  api: atualizarEtapaApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirEtapa = basicFlow({
  actionGenerator: actions.excluirEtapa,
  api: excluirEtapaApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarEtapa = basicFlow({
  actionGenerator: actions.buscarEtapa,
  api: buscarEtapasApi,
});

function* listaEtapasRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_ETAPAS, function* () {
    const produto = yield select(getPayload);
    yield put(
      actions.listarEtapas.request({
        pagina: pagina,
        produto: produto.id,
      })
    );
    yield put(dominiosAction.etapas.request({ produto: produto.id }));
  });
}

export const sagas = [
  listarEtapas.watcher(),
  listaEtapasRouteWatcher(),
  atualizarEtapa.watcher(),
  incluirEtapa.watcher(),
  buscarEtapa.watcher(),
  excluirEtapa.watcher(),
];
