import { actions, types } from "./exportSla.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  resultado: {
    qtd_consultas: null,
    file: null,
  },
  show_results: false,
  downloading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case routes.EXPORT_SLA:
      return {
        ...initialState,
      };
    case actions.processar.REQUEST:
    case actions.processar.SUCCESS:
    case actions.processar.FAILURE:
      return {
        ...state,
        downloading: action.type === actions.processar.REQUEST,
        error:
          action.type === actions.processar.FAILURE
            ? action.payload.error
            : null,
        show_results: action.type === actions.processar.SUCCESS,
        resultado:
          action.type === actions.processar.SUCCESS
            ? {
                qtd_consultas: action.payload.response.data.qtd_consultas,
                file: action.payload.response.data.file,
                momento_geracao: action.payload.response.data.momento_geracao,
              }
            : state.resultado,
      };
    case types.CLEAN_FILE:
      return { ...state, resultado: { ...state.resultado, file: null } };
    default:
      return state;
  }
};

export default reducer;
