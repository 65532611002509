import { actions, types } from "./localidades.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  localidade: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarLocalidades.REQUEST:
    case actions.listarLocalidades.SUCCESS:
    case actions.listarLocalidades.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarLocalidades.REQUEST,
        error:
          action.type === actions.listarLocalidades.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarLocalidades.SUCCESS
            ? action.payload.response.data.localidades
            : [],
        total:
          action.type === actions.listarLocalidades.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarLocalidades.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarLocalidade.REQUEST:
    case actions.atualizarLocalidade.SUCCESS:
    case actions.atualizarLocalidade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarLocalidade.REQUEST,
        localidade:
          action.type === actions.atualizarLocalidade.FAILURE
            ? action.payload.original.localidade
            : null,
        list:
          action.type === actions.atualizarLocalidade.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.localidade.id) {
                  return {
                    ...u,
                    nome: action.payload.original.localidade.nome ?? u.nome,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVA_LOCALIDADE:
      return {
        ...state,
        localidade: {
          nome: "",
          codigo: "",
        },
      };
    case types.EDITAR_LOCALIDADE:
      return {
        ...state,
        localidade: {
          ...action.payload.localidade,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        localidade: null,
      };
    case actions.incluirLocalidade.REQUEST:
    case actions.incluirLocalidade.SUCCESS:
    case actions.incluirLocalidade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirLocalidade.REQUEST,
        localidade:
          action.type === actions.incluirLocalidade.FAILURE
            ? action.payload.original.localidade
            : null,
        list:
          action.type === actions.incluirLocalidade.SUCCESS
            ? action.payload.response.data.localidades
            : state.list,
        total:
          action.type === actions.incluirLocalidade.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirLocalidade.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarLocalidade.REQUEST:
    case actions.buscarLocalidade.SUCCESS:
    case actions.buscarLocalidade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarLocalidade.REQUEST,
        error:
          action.type === actions.buscarLocalidade.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarLocalidade.SUCCESS
            ? action.payload.response.data.localidade
            : [],
        total:
          action.type === actions.buscarLocalidade.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarLocalidade.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarLocalidade.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirLocalidade.REQUEST:
    case actions.excluirLocalidade.SUCCESS:
    case actions.excluirLocalidade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirLocalidade.REQUEST,
        localidade: null,
        list:
          action.type === actions.excluirLocalidade.SUCCESS
            ? state.list.filter((u) => u.id !== action.payload.original.id)
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
