import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/perfis.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarPerfisApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/perfis",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarPerfilApi = ({ perfil }) => {
  return authenticatedRequest({
    url: "/administracao/perfil",
    isResourceService: true,
    method: "post",
    body: { perfil: perfil },
  });
};

const excluirPerfilApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/perfil/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarPerfisApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/perfil/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarPerfis = basicFlow({
  actionGenerator: actions.listarPerfis,
  api: listarPerfisApi,
});

const atualizarPerfil = basicFlow({
  actionGenerator: actions.atualizarPerfil,
  api: atualizarPerfilApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirPerfil = basicFlow({
  actionGenerator: actions.incluirPerfil,
  api: atualizarPerfilApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirPerfil = basicFlow({
  actionGenerator: actions.excluirPerfil,
  api: excluirPerfilApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarPerfil = basicFlow({
  actionGenerator: actions.buscarPerfil,
  api: buscarPerfisApi,
});

function* listaPerfisRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_PERFIS, function* () {
    yield put(
      actions.listarPerfis.request({
        pagina,
      })
    );
  });
}

export const sagas = [
  listarPerfis.watcher(),
  listaPerfisRouteWatcher(),
  atualizarPerfil.watcher(),
  incluirPerfil.watcher(),
  buscarPerfil.watcher(),
  excluirPerfil.watcher(),
];
