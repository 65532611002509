import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  LISTAR_PROPOSTAS: "@@proposta/LISTAR_PROPOSTAS",
  CONSULTAR_PROPOSTA: "@@proposta/CONSULTAR_PROPOSTA",
  ATUALIZAR_ANALISTA: "@@proposta/ATUALIZAR_ANALISTA",
  FECHAR_DETALHE: "@@/propostas/FECHAR_DETALHE",
  ABRIR_COCKPIT: "@@/propostas/ABRIR_COCKPIT",
  SELECIONAR_OPCAO_MENU: "@@/propostas/SELECIONAR_OPCAO_MENU",

  CONSULTAR_CHECKLIST: "@@/propostas/CONSULTAR_CHECKLIST",
  FILTRO_CHECKLIST: "@@checklist/ALTERAR_FILTRO",
  UPLOAD_ARTEFATO: "@@checklist/UPLOAD_ARTEFATO",
  ATUAR_ANALISE_DOCUMENTO: "@@checklist/ATUAR_ANALISE_DOCUMENTO",
  ATUALIZAR_MOTIVO_ANALISE: "@@checklist/ATUALIZAR_MOTIVO_ANALISE",
  ADICIONAR_DOCUMENTO: "@@checklist/ADICIONAR_DOCUMENTO",
  LIMPAR_PROPOSTA: "@@proposta/LIMPAR_PROPOSTA",

  CONSULTAR_CEP_CORREIOS: "@@/propostas/CONSULTAR_CEP_CORREIOS",

  CONSULTAR_PARTICIPANTE: "@@/propostas/CONSULTAR_PARTICIPANTE",
  ATUALIZAR_PARTICIPANTE: "@@/propostas/ATUALIZAR_PARTICIPANTE",
  ABRIR_NOVO_PARTICIPANTE: "@@/propostas/ABRIR_NOVO_PARTICIPANTE",
  EXCLUIR_PARTICIPANTE: "@@/propostas/EXCLUIR_PARTICIPANTE",
  CONSULTAR_IQ: "@@/propostas/CONSULTAR_IQ",
  ATUALIZAR_IQ: "@@/propostas/ATUALIZAR_IQ",
  ATUALIZAR_OBSERVACAO: "@@/propostas/ATUALIZAR_OBSERVACAO",
  GET_WORKFLOW: "@@/propostas/GET_WORKFLOW",
  ABRIR_ATIVIDADE: "@@/propostas/ABRIR_ATIVIDADE",
  FILTRO_ATIVIDADE: "@@propostas/FILTRO_ATIVIDADE",
  ATUALIZAR_SITUACAO: "@@propostas/ATUALIZAR_SITUACAO",
  OPEN_SEARCH: "@@search/OPEN",
  CLOSE_SEARCH: "@@search/CLOSE",
  ARROW_REFERENCE: "@@search/ARROW_REFERENCE",
  SET_SEARCH: "@@search/SET_SEARCH",
  ATUALIZAR_SITUACAO_CONSULTA: "@@/propostas/ATUALIZAR_SITUACAO_CONSULTA",
  ATUALIZAR_PRIORIDADE_CONSULTA: "@@/propostas/ATUALIZAR_PRIORIDADE_CONSULTA",
  SELECIONAR_FILTRO_SIMPLES: "@@/propostas/SELECIONAR_FILTRO_SIMPLES",
  SELECIONAR_FILTRO_EXCLUDENTE: "@@/propostas/SELECIONAR_FILTRO_EXCLUDENTE",
  LIMPAR_FILTRO_VALORES: "@@/propostas/LIMPAR_FILTRO_VALORES",
  HISTORICO_PROPOSTA: "@@/propostas/HISTORICO_PROPOSTA",
  EXPORTAR_HISTORICO_CONSULTAS: "@@propostas/EXPORTAR_HISTORICO_CONSULTAS",
  LIMPAR_HISTORICO_ATIVIDADES_FILE:
    "@@propostas/LIMPAR_HISTORICO_ATIVIDADES_FILE",
  LIMPAR_FORMULARIO: "@@propostas/LIMPAR_FORMULARIO",
  LISTAR_SITUACAO_ATIVIDADES: "@@propostas/LISTAR_SITUACAO_ATIVIDADES",
  ABRIR_MODAL_DOCUMENTOS: "@@propostas/ABRIR_MODAL_DOCUMENTOS",
  INCLUSAO_CORBAN_SOCIO: "@@propostas/INCLUSAO_CORBAN_SOCIO",
  VOLTAR_INCLUSAO_CORBAN: "@@propostas/VOLTAR_INCLUSAO_CORBAN",
  SELECIONAR_OPCAO_MENU_HISTORICO: "@@proposta/SELECIONAR_OPCAO_MENU_HISTORICO",
  HISTORICO_EMAILS: "@@proposta/HISTORICO_EMAILS",
  EXPORT_HISTORICO_EMAILS: "@@proposta/EXPORT_HISTORICO_EMAILS",
  HISTORICO_EMAILS_DETALHES: "@@proposta/HISTORICO_EMAILS_DETALHES",
};

export const actions = {
  listarSituacaoAtividades: createAsyncAction(types.LISTAR_SITUACAO_ATIVIDADES),
  listarPropostas: createAsyncAction(types.LISTAR_PROPOSTAS),
  consultarProposta: createAsyncAction(types.CONSULTAR_PROPOSTA),
  atualizarAnalista: createAsyncAction(types.ATUALIZAR_ANALISTA),
  fecharDetalhe: () => createSyncAction(types.FECHAR_DETALHE),
  selecionarOpcaoMenu: (values) =>
    createSyncAction(types.SELECIONAR_OPCAO_MENU, values),
  alterarFiltroChecklist: (filtro) =>
    createSyncAction(types.FILTRO_CHECKLIST, { filtro }),
  abrirCockpit: () => createSyncAction(types.ABRIR_COCKPIT),
  consultarChecklist: createAsyncAction(types.CONSULTAR_CHECKLIST),
  adicionarDocumento: createAsyncAction(types.ADICIONAR_DOCUMENTO),
  uploadArtefato: createAsyncAction(types.UPLOAD_ARTEFATO),
  atuarAnalisarDocumento: createAsyncAction(types.ATUAR_ANALISE_DOCUMENTO),
  atualizarMotivoAnalise: createAsyncAction(types.ATUALIZAR_MOTIVO_ANALISE),
  consultarParticipante: createAsyncAction(types.CONSULTAR_PARTICIPANTE),
  consultarCepCorreios: createAsyncAction(types.CONSULTAR_CEP_CORREIOS),
  atualizarParticipante: createAsyncAction(types.ATUALIZAR_PARTICIPANTE),
  abrirNovoParticipante: () => createSyncAction(types.ABRIR_NOVO_PARTICIPANTE),
  excluirParticipante: createAsyncAction(types.EXCLUIR_PARTICIPANTE),
  consultarIq: createAsyncAction(types.CONSULTAR_IQ),
  atualizarIq: createAsyncAction(types.ATUALIZAR_IQ),
  atualizarObservacao: createAsyncAction(types.ATUALIZAR_OBSERVACAO),
  getWorkflow: createAsyncAction(types.GET_WORKFLOW),
  abrirAtividade: createAsyncAction(types.ABRIR_ATIVIDADE),
  alterarFiltroAtividades: (filtro, valores) =>
    createSyncAction(types.FILTRO_ATIVIDADE, { filtro, valores }),
  atualizarSituacaoAtividade: createAsyncAction(types.ATUALIZAR_SITUACAO),
  openSearch: (anchor) => {
    return {
      type: types.OPEN_SEARCH,
      payload: {
        anchor,
      },
    };
  },
  closeSearch: () => {
    return {
      type: types.CLOSE_SEARCH,
    };
  },
  arrowReference: (arrow) => {
    return {
      type: types.ARROW_REFERENCE,
      payload: {
        arrow,
      },
    };
  },
  setPesquisa: (values) => createSyncAction(types.SET_SEARCH, values),
  atualizarSituacaoConsulta: createAsyncAction(
    types.ATUALIZAR_SITUACAO_CONSULTA
  ),
  atualizarPrioridadeConsulta: createAsyncAction(
    types.ATUALIZAR_PRIORIDADE_CONSULTA
  ),
  limparProposta: () => createSyncAction(types.LIMPAR_PROPOSTA),
  selecionarFiltroSimples: (values) =>
    createSyncAction(types.SELECIONAR_FILTRO_SIMPLES, values),
  selecionarFiltroExcludente: (values) =>
    createSyncAction(types.SELECIONAR_FILTRO_EXCLUDENTE, values),
  limparFiltroValores: () => createSyncAction(types.LIMPAR_FILTRO_VALORES),
  historicoProposta: createAsyncAction(types.HISTORICO_PROPOSTA),
  exportarHistoricoConsultas: createAsyncAction(
    types.EXPORTAR_HISTORICO_CONSULTAS
  ),
  cleanExportHistoricoAtividadesFile: createSyncAction(
    types.LIMPAR_HISTORICO_ATIVIDADES_FILE
  ),
  limparFormulario: () => createSyncAction(types.LIMPAR_FORMULARIO),
  abrirModalDocumentos: (values) =>
    createSyncAction(types.ABRIR_MODAL_DOCUMENTOS, values),
  incluirCorbanSocio: (values) => createSyncAction(types.INCLUSAO_CORBAN_SOCIO, values),
  voltarInclusaoCorban: () => createSyncAction(types.VOLTAR_INCLUSAO_CORBAN),
  selecionarOpcaoMenuHistorico: (values) =>
    createSyncAction(types.SELECIONAR_OPCAO_MENU_HISTORICO, values),
  historicoEmails: createAsyncAction(types.HISTORICO_EMAILS),
  exportHistoricoEmails: createAsyncAction(types.EXPORT_HISTORICO_EMAILS),
  historicoEmailsDetalhes: createAsyncAction(types.HISTORICO_EMAILS_DETALHES),
};
