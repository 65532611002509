import { useCallback } from "react";
import Header from "./Header";
import { getUsuario } from "../../selectors/usuario.selectors";
import { useSelector, useDispatch } from "react-redux";
import { actions as authActions } from "../../reducers/autenticacao.actions";
import {
  types as routes,
  actions as routeActions,
} from "../../reducers/rotas.actions";
import { actions as propostaActions } from "../../reducers/propostas.actions";

const HeaderConnected = () => {
  const dispatch = useDispatch();
  const { nome, perfil, permissoes, produtos } = useSelector(getUsuario);

  const handleClickMenu = useCallback(
    (route) => {
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );

  // const selecionarFiltro = useCallback(() => {
  //   dispatch(propostaActions.limparFiltroValores());
  // }, [dispatch]);

  const selecionarFiltroEMudarPagina = (route) => {
    //selecionarFiltro();
    setTimeout(function () {
      handleClickMenu(route);
    }, 50);
  };

  const menus = [
    {
      id: "novo-convenio",
      label: "Novo Convênio",
      route: routes.NOVO_PARTICIPANTE,
      perfil: ["BANCO"],
      produto: 1,
      action: handleClickMenu,
    },
    {
      id: "nova-corban",
      label: "Nova Corban",
      route: routes.NOVA_CORBAN,
      perfil: ["BANCO"],
      produto: 2,
      action: handleClickMenu,
    },
    {
      id: "novo-cadastro",
      label: "Novo Cadastro",
      route: routes.ESCOLHA_PRODUTO,
      perfil: ["BANCO"],
      produto: [],
      action: handleClickMenu,
    },
    {
      id: "painel-convenios",
      label: "Painel de Cadastros",
      route: routes.PROPOSTAS,
      action: selecionarFiltroEMudarPagina,
    },
    {
      id: "administracao",
      label: "Administração",
      perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
      menus: [
        // {
        //   id: "carga-consultas",
        //   label: "Carga de Consultas",
        //   perfil: ["ADMINISTRADOR"],
        //   action: handleClickMenu,
        //   route: routes.CARGA_CONSULTAS,
        // },
        // {
        //   id: "export-consultas",
        //   label: "Exportação de Consultas",
        //   perfil: ["ADMINISTRADOR", "BANCO GESTAO"],
        //   action: handleClickMenu,
        //   route: routes.EXPORT_CONSULTAS,
        // },
        {
          id: "cadastros",
          label: "Cadastros",
          perfil: ["ADMINISTRADOR", "BPO", "BANCO"],
          menus: [
            {
              id: "documentos",
              label: "Documento",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_DOCUMENTOS,
            },
            /*{
              id: "etapas",
              label: "Etapa",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_ETAPAS,
            },*/
            {
              id: "localidade",
              label: "Localidade",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_LOCALIDADES,
            },
            {
              id: "matrizChecklist",
              label: "Matriz Checklist",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_MCHECKLIST,
            },
            {
              id: "motivoCancelamento",
              label: "Motivo de Cancelamento",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_MOTIVO_CANCELAMENTO,
            },
            {
              id: "organizacao",
              label: "Organização",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_ORGANIZACAO,
            },
            {
              id: "perfil",
              label: "Perfil",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_PERFIS,
            },
            {
              id: "produtos",
              label: "Produto",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_PRODUTOS,
            },
            {
              id: "produtosConvenio",
              label: "Produto de Convênio",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_PRODUTOSCONVENIO,
            },
            {
              id: "relatoriosBI",
              label: "Relatórios BI",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_RELATORIO_BI,
            },
            {
              id: "templates",
              label: "Templates de E-mail",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_TEMPLATES,
            },
            {
              id: "tipoDeConvenio",
              label: "Tipo de Convênios",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACA0_TIPOS_DE_CONVENIOS,
            },
            {
              id: "usuarios",
              label: "Usuário",
              perfil: ["ADMINISTRADOR", "BPO", "BANCO"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_USUARIOS,
            },
          ],
        },
        {
          id: "export-sla",
          label: "Exportação de SLA",
          perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
          action: handleClickMenu,
          route: routes.EXPORT_SLA,
        },
        {
          id: "relatorios",
          label: "Relatórios",
          perfil: ["ADMINISTRADOR"],
          menus: [
            {
              id: "auditoria",
              label: "Auditoria Documentos",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_RELATORIO_AUDITORIA,
            },
          ],
        },
      ],
    },
  ];

  const handleClickLogout = useCallback(() => {
    dispatch(authActions.logout());
    // eslint-disable-next-line
  }, [dispatch]);

  const handleClickOpenSearch = useCallback(
    (e) => {
      dispatch(propostaActions.openSearch(e.currentTarget));
      // eslint-disable-next-line
    },
    [dispatch]
  );

  const restrictedMenus = restrictMenus(
    menus,
    [],
    perfil,
    permissoes,
    produtos
  );
  const showSearch = perfil !== "CLIENTE";
  return (
    <Header
      menus={restrictedMenus}
      username={nome}
      handleClickLogout={handleClickLogout}
      handleClickOpenSearch={handleClickOpenSearch}
      showSearch={showSearch}
    />
  );
};

const restrictMenus = (menus, current, perfil, permissoes, produtos) => {
  menus.forEach((menu) => {
    if (!menu.perfil) {
      if (!menu.menus) {
        if (!menu.produto) {
          current.push(menu);
        } else {
          if (produtos.length > 1) {
            if (menu.produto === []) current.push(menu);
          } else {
            if (
              produtos
                .map((i) => Number.parseInt(i.codigo))
                .indexOf(menu.produto) > -1
            )
              current.push(menu);
          }
        }
      } else {
        const restricted = {
          ...menu,
          menus: restrictMenus(menu.menus, [], perfil, permissoes, produtos),
        };
        current.push(restricted);
      }
    } else {
      const canAccess = menu.perfil.indexOf(perfil) > -1;
      if (canAccess) {
        if (!menu.menus) {
          if (!menu.produto) {
            current.push(menu);
          } else {
            if (produtos.length > 1) {
              if (Array.isArray(menu.produto)) current.push(menu);
            } else {
              if (
                produtos
                  .map((i) => Number.parseInt(i.codigo))
                  .indexOf(menu.produto) > -1
              )
                current.push(menu);
            }
          }
        } else {
          const restricted = {
            ...menu,
            menus: restrictMenus(menu.menus, [], perfil, permissoes),
          };
          current.push(restricted);
        }
      }
    }
  });
  return current;
};

export default HeaderConnected;
