import React, { useState } from "react";
import {
  CpfCnpjTextField,
  DateTextField,
  ControlledTextfield,
  ControlledSwitch,
} from "../../../../../components/inputs";

const CardDadosConjuge = ({ props, disabled }) => {
  const [isComprador, setIsComprador] = useState(
    props.initialValues?.isConjugeComprador ?? false
  );

  const handleChangeIsComprador = (e) => {
    setIsComprador(e.target.checked);
  };

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Cônjuge</span>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              formProps={props}
              label={"CPF do Cônjuge"}
              type={"cpf"}
              name={"cpfConjuge"}
              disabled={disabled}
              InputProps={{
                inputComponent: CpfCnpjTextField,
              }}
              margin={"none"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-9">
            <ControlledTextfield
              name={"nomeConjuge"}
              formProps={props}
              label="Nome do Cônjuge"
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              name={"dataNascimentoConjuge"}
              formProps={props}
              label={"Data de Nascimento"}
              InputProps={{
                inputComponent: DateTextField,
              }}
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-5">
            <ControlledTextfield
              name={"emailConjuge"}
              formProps={props}
              label={"E-Mail"}
              margin={"none"}
              disabled={disabled}
              uppercase={false}
            />
          </div>
          {props.initialValues.tipoParticipante === "C" && (
            <>
              <div className="col-12 col-md-6 col-lg-4">
                <ControlledSwitch
                  name="isConjugeComprador"
                  formProps={props}
                  label="Entrará na composição da renda?"
                  onChange={handleChangeIsComprador}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
              {isComprador && (
                <div className="col-12 col-md-6 col-lg-3">
                  <ControlledSwitch
                    name={"fgtsConjuge"}
                    formProps={props}
                    label={"Utilizará FGTS?"}
                    margin={"none"}
                    disabled={disabled}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardDadosConjuge;
