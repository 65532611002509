import React from "react";
import { PhoneTextField } from "../../../../../components/inputs";
import ControlledTextfield from "../../../../../components/inputs/ControlledTextfield";

const CardDadosContato = ({ props, disabled }) => {
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Contato</span>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              name={"email"}
              formProps={props}
              label={"E-Mail"}
              margin={"none"}
              disabled={disabled}
              uppercase={false}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              name={"telefone"}
              formProps={props}
              label={"Celular"}
              InputProps={{
                inputComponent: PhoneTextField,
              }}
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              name={"telefoneResidencial"}
              formProps={props}
              label={"Telefone"}
              InputProps={{
                inputComponent: PhoneTextField,
              }}
              margin={"none"}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDadosContato;
