import BasePage from "../../BasePage";
import React from "react";
import ExportConsultasForm from "./ExportConsultasForm";

const View = ({ showResults, resultado, ...otherProps }) => {
  return (
    <BasePage isLoggedIn={true}>
      <h2>Exportação de Consultas</h2>
      <div className="card mt-4">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <ExportConsultasForm resultado={resultado} {...otherProps} />
            </div>
            {showResults && (
              <div className="col-sm-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                <h4 className={"mt-3"}>Resultado</h4>
                <div>
                  Consultas exportadas: <b>{resultado.qtd_consultas}</b>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default View;
