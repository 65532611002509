import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";

const MotivoCancelamento = ({
  motivoCancelamento,
  index,
  handleEdit,
  handleOpenExlusaoMotivoCancelamento,
}) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row me-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-4 col-md-4 col-lg-4 mt-lg-0 user-table">
        <span>{motivoCancelamento.codigo}</span>
      </div>
      <div className="col-5 col-md-6 col-lg-7 mt-lg-0 user-table">
        <span>{motivoCancelamento.descricao}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton
          className="p-2"
          onClick={() => handleEdit(motivoCancelamento)}
        >
          <EditOutlined className="color-black" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() =>
            handleOpenExlusaoMotivoCancelamento(motivoCancelamento)
          }
        >
          <DeleteOutlined className="color-black" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default MotivoCancelamento;
