import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_TEMPLATES: "@@administracao/LISTAR_TEMPLATES",
  ATUALIZAR_TEMPLATE: "@@administracao/ATUALIZAR_TEMPLATE",
  NOVO_TEMPLATE: "@@administracao/NOVO_TEMPLATE",
  EDITAR_TEMPLATE: "@@administracao/EDITAR_TEMPLATE",
  VOLTAR_TEMPLATE: "@@administracao/VOLTAR_TEMPLATE",
  INCLUIR_TEMPLATE: "@@administracao/INCLUIR_TEMPLATE",
  PESQUISAR_TEMPLATE: "@@administracao/PESQUISAR_TEMPLATE",
  EXCLUIR_TEMPLATE: "@@administracao/EXCLUIR_TEMPLATE",
};

export const actions = {
  listarTemplates: createAsyncAction(types.LISTAR_TEMPLATES),
  atualizarTemplate: createAsyncAction(types.ATUALIZAR_TEMPLATE),
  novoTemplate: () => createSyncAction(types.NOVO_TEMPLATE),
  editarTemplate: (values) => createSyncAction(types.EDITAR_TEMPLATE, values),
  voltarListaTemplates: () => createSyncAction(types.VOLTAR_TEMPLATE),
  incluirTemplate: createAsyncAction(types.INCLUIR_TEMPLATE),
  buscarTemplate: createAsyncAction(types.PESQUISAR_TEMPLATE),
  excluirTemplate: createAsyncAction(types.EXCLUIR_TEMPLATE),
};
