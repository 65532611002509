import { actions, types } from "./emails.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  params: {
    proposta: null,
    atividade: null,
  },
  descriptions: {
    atividade: null,
  },
  templates: {
    loading: false,
    data: [],
  },
  template: {
    processing: false,
    data: null,
  },
  sending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case routes.ENVIO_EMAIL:
      return {
        ...initialState,
        params: {
          ...initialState.params,
          ...action.payload,
        },
        templates: {
          ...initialState.templates,
          loading: true,
        },
      };
    case actions.buscarTemplatesAtividade.REQUEST:
    case actions.buscarTemplatesAtividade.SUCCESS:
    case actions.buscarTemplatesAtividade.FAILURE:
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          atividade:
            action.type === actions.buscarTemplatesAtividade.SUCCESS
              ? action.payload.response.data.nome
              : null,
        },
        templates: {
          ...state.templates,
          loading: action.type === actions.buscarTemplatesAtividade.REQUEST,
          data:
            action.type === actions.buscarTemplatesAtividade.SUCCESS
              ? action.payload.response.data.templates
              : [],
        },
      };
    case actions.processarTemplate.REQUEST:
    case actions.processarTemplate.SUCCESS:
    case actions.processarTemplate.FAILURE:
      return {
        ...state,
        template: {
          ...state.templates,
          processing: action.type === actions.processarTemplate.REQUEST,
          data:
            action.type === actions.processarTemplate.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.enviarEmail.REQUEST:
    case actions.enviarEmail.SUCCESS:
    case actions.enviarEmail.FAILURE:
      return {
        ...state,
        sending: action.type === actions.enviarEmail.REQUEST,
      };
    case types.EMAIL_PARAMS:
      return {
        ...state,
        params: {
          proposta: action.payload.proposta,
          atividade: action.payload.atividade,
        },
      };
    default:
      return state;
  }
};

export default reducer;
