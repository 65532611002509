import React, { useCallback } from "react";
import ConfirmarAnaliseDialog from "./ConfirmarAnaliseDialog";
import { useSelector, useDispatch } from "react-redux";
import { getChecklist } from "../../../../selectors/documentos.selectors";
import { actions } from "../../../../reducers/propostas.actions";
import validators from "../../../../utils/validators";
import { useForm } from "react-hook-form";

const ConfirmarAnaliseDialogConnected = ({
  handleClose,
  dados: { open, ...otherData },
}) => {
  const dispatch = useDispatch();
  const { analysing } = useSelector(getChecklist);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.atuarAnalisarDocumento.request({
          ...otherData,
          ...values,
          handleClose,
          dataValidade:
            Object.keys(values).filter((a) => a === "dataValidade").length >
              0 && values.dataValidade !== null
              ? `${values.dataValidade.split("/")[2]}/${
                  values.dataValidade.split("/")[1]
                }/${values.dataValidade.split("/")[0]}`
              : null,
          dataEmissao:
            Object.keys(values).filter((a) => a === "dataEmissao").length > 0 &&
            values.dataEmissao !== null
              ? `${values.dataEmissao.split("/")[2]}/${
                  values.dataEmissao.split("/")[1]
                }/${values.dataEmissao.split("/")[0]}`
              : null,
        })
      );
    },
    [dispatch, otherData, handleClose]
  );

  const rules = {
    motivo: validators.string({
      required:
        otherData?.situacaoAnalise === "S" ||
        otherData?.situacaoAnalise === "D",
    }),
    dataValidade: validators.date({
      required: otherData?.situacaoAnalise === "A" && otherData?.temValidade,
    }),
    dataEmissao: validators.date({ valid: true }),
  };
  const initialValues = {
    motivo: "",
    dataValidade: "",
    dataEmissao: "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  return (
    <ConfirmarAnaliseDialog
      handleSubmit={handleSubmit}
      open={open}
      dados={otherData}
      formProps={formProps}
      handleClose={handleClose}
      submitting={analysing}
    />
  );
};

export default ConfirmarAnaliseDialogConnected;
