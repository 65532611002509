import { actions, types } from "./produtos.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  produto: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarProdutos.REQUEST:
    case actions.listarProdutos.SUCCESS:
    case actions.listarProdutos.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarProdutos.REQUEST,
        error:
          action.type === actions.listarProdutos.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarProdutos.SUCCESS
            ? action.payload.response.data.produtos
            : [],
        total:
          action.type === actions.listarProdutos.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarProdutos.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarProduto.REQUEST:
    case actions.atualizarProduto.SUCCESS:
    case actions.atualizarProduto.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarProduto.REQUEST,
        produto:
          action.type === actions.atualizarProduto.FAILURE
            ? action.payload.original.produto
            : null,
        list:
          action.type === actions.atualizarProduto.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.produto.id) {
                  return {
                    ...u,
                    codigo: action.payload.original.produto.codigo ?? u.codigo,
                    nome: action.payload.original.produto.nome ?? u.nome,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVA_PRODUTO:
      return {
        ...state,
        produto: {
          codigo: "",
          nome: "",
        },
      };
    case types.EDITAR_PRODUTO:
      return {
        ...state,
        produto: {
          ...action.payload.produto,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        produto: null,
      };
    case actions.incluirProduto.REQUEST:
    case actions.incluirProduto.SUCCESS:
    case actions.incluirProduto.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirProduto.REQUEST,
        produto:
          action.type === actions.incluirProduto.FAILURE
            ? action.payload.original.produto
            : null,
        list:
          action.type === actions.incluirProduto.SUCCESS
            ? action.payload.response.data.produtos
            : state.list,
        total:
          action.type === actions.incluirProduto.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirProduto.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarProduto.REQUEST:
    case actions.buscarProduto.SUCCESS:
    case actions.buscarProduto.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarProduto.REQUEST,
        error:
          action.type === actions.buscarProduto.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarProduto.SUCCESS
            ? action.payload.response.data.produtos
            : [],
        total:
          action.type === actions.buscarProduto.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarProduto.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarProduto.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirProduto.REQUEST:
    case actions.excluirProduto.SUCCESS:
    case actions.excluirProduto.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirProduto.REQUEST,
        produto: null,
        list:
          action.type === actions.excluirProduto.SUCCESS
            ? state.list.filter(
                (u) => u.id !== action.payload.original.idProduto
              )
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
