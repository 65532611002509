import React from "react";
import { TextField as MTextField } from "@material-ui/core";

const TextField = ({
  InputProps,
  variant = "filled",
  uppercase = true,
  margin,
  error,
  InputLabelProps,
  FormHelperTextProps,
  search = false,
  ...other
}) => (
  <MTextField
    InputLabelProps={{
      classes: {
        root: "label-input",
      },
      shrink: true,
      ...InputLabelProps,
    }}
    error={error}
    variant={variant}
    InputProps={{
      classes: {
        root: search ? "textfield-search" : "textfield-regular",
        input: uppercase ? "text-uppercase" : "",
        error: "textfield-undeline-error",
        underline: "textfield-undeline",
      },
      ...InputProps,
    }}
    FormHelperTextProps={{
      classes: {
        error: "label-error",
      },
      ...FormHelperTextProps,
    }}
    fullWidth
    margin={margin}
    {...other}
  />
);

export default TextField;
