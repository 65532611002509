import React, { useCallback, useState } from "react";
import { responsavelAtividade } from "../../../utils/parametros";
import FormAtividade from "./FormAtividade";
import FormDecisaoWorkflow from "./FormDecisaoWorkflow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Button } from "../../../components";
import FormPreReqAtividade from "./FormPreReqAtividade";

const FormAtividadePage = ({
  props,
  handleSubmit,
  atividade,
  etapa,
  perfis,
  tipoCondicaoCriacao,
  voltarParaLista,
  openAccordion,
  accordion,
  documentos,
  atividades,
  handleDeleteDecisao,
  templates,
  handleDeleteRegra,
  regras,
  setRegras,
}) => {
  const [decisoes, setDecisoes] = useState(atividade.decisoes ?? []);
  const [docs, setDocs] = useState([]);

  const handleClickNext = useCallback(
    (values) => {
      values = {
        ...values,
        decisoes: decisoes.map((d) => {
          return {
            ...d,
            atividades: d.atividades.map((a) => {
              return { atividade: a.id };
            }),
            documentos: d.documentos.map((doc) => {
              return {
                qualificacao: doc.qualificacao,
                tipoRequisito: doc.tipoRequisito,
                documento: doc.documento.id,
              };
            }),
          };
        }),
      };
      handleSubmit(values, atividade.id);
    },
    [handleSubmit, atividade, decisoes]
  );

  return (
    <>
      <form onSubmit={props.handleSubmit(handleClickNext)}>
        <div className="row">
          <Accordion
            className="card"
            onChange={openAccordion}
            expanded={accordion === "accordion01"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"Atividadeprincipal"}
              id={"accordion01"}
            >
              <span>ATIVIDADE</span>
            </AccordionSummary>
            <AccordionDetails>
              <FormAtividade
                etapa={etapa}
                perfis={perfis}
                atividade={atividade}
                props={props}
                tipoCondicaoCriacao={tipoCondicaoCriacao}
                tipoResponsavel={responsavelAtividade}
                voltarParaLista={voltarParaLista}
                templates={templates}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="row">
          <Accordion
            className="card"
            onChange={openAccordion}
            expanded={accordion === "accordion02"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"Atividadedecisaoworkflow"}
              id={"accordion02"}
            >
              <span>ATIVIDADE - DECISÃO WORKFLOW</span>
            </AccordionSummary>
            <AccordionDetails>
              <FormDecisaoWorkflow
                atividade={atividade}
                props={props}
                documentos={documentos}
                atividades={atividades}
                decisoes={decisoes}
                setDecisoes={setDecisoes}
                docs={docs}
                setDocs={setDocs}
                handleDeleteDecisao={handleDeleteDecisao}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="row">
          <Accordion
            className="card"
            onChange={openAccordion}
            expanded={accordion === "accordion03"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"PreReqAbertura"}
              id={"accordion03"}
            >
              <span>PRÉ-REQUISITO ABERTURA</span>
            </AccordionSummary>
            <AccordionDetails>
              <FormPreReqAtividade
                atividadeAtual={atividade.id}
                atividades={atividades}
                props={props}
                regras={regras}
                setRegras={setRegras}
                handleDeleteRegra={handleDeleteRegra}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="row spacer">
          <div className="offset-md-2 col-md-4 col-12">
            <Button
              label="Voltar"
              onClick={voltarParaLista}
              loading={atividade.loading}
            />
          </div>
          <div className="col-md-4 col-12">
            <Button
              label="CONFIRMAR"
              type={"submit"}
              loading={atividade.loading}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default FormAtividadePage;
