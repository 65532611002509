import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import validators from "../../../../../utils/validators";
import {
  getInterveniente,
  getLoadingAtualizacaoInterveniente,
  getLoadingInterveniente,
} from "../../../../../selectors/propostas.selectors";
import { actions } from "../../../../../reducers/propostas.actions";
import { getPerfil } from "../../../../../selectors/usuario.selectors";
import FormIntervenienteQuitante from "./FormIntervenienteQuitante";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../../reducers/rotas.actions";

const FormIntervenienteQuitanteConnected = () => {
  const dispatch = useDispatch();
  const perfil = useSelector(getPerfil);
  const interveniente = useSelector(getInterveniente);

  const rules = {
    nomeInterveniente: validators.string({ required: true }),
  };

  const initialValues = interveniente;

  const disabled = perfil !== "BPO" && perfil !== "BANCO";

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const loadingAtualizacao = useSelector(getLoadingAtualizacaoInterveniente);
  const loading = useSelector(getLoadingInterveniente);

  const handleSubmit = useCallback(
    (values, proposta) => {
      const request = {
        idProposta: proposta.idConsulta,
        iq: values.nomeInterveniente.toUpperCase(),
      };
      dispatch(actions.atualizarIq.request(request));
    },
    [dispatch]
  );
  const handleGoBack = useCallback(() => {
    dispatch(
      rotasActions.redirectTo(routes.PROPOSTA, {
        id: interveniente.idConsulta,
      })
    );
  }, [dispatch, interveniente]);

  return (
    <FormIntervenienteQuitante
      loading={loading}
      formProps={formProps}
      proposta={interveniente.idConsulta}
      handleSubmit={handleSubmit}
      loadingAtualizacao={loadingAtualizacao}
      disabled={disabled}
      handleGoBack={handleGoBack}
      isAtualizacao={initialValues?.nomeInterveniente?.length > 0}
    />
  );
};

export default FormIntervenienteQuitanteConnected;
