import CurrencyTextField from "./CurrencyTextField";

const CurrencyTextFieldLeadingZeros = (props) => (
  <CurrencyTextField
    {...props}
    fixedDecimalScale={true}
    allowLeadingZeros={true}
    isNumericString={true}
  />
);

export default CurrencyTextFieldLeadingZeros;
