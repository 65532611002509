import { createAsyncAction } from "../../utils/actionCreators";

export const types = {
  LISTAR_RELATORIO_AUDITORIA: "@@administracao/LISTAR_RELATORIO_AUDITORIA",
  LISTAR_RELATORIO_FILTROS: "@@administracao/LISTAR_RELATORIO_FILTROS",
};

export const actions = {
  listarRelatorioAuditoria: createAsyncAction(types.LISTAR_RELATORIO_AUDITORIA),
  listarRelatorioFiltros: createAsyncAction(types.LISTAR_RELATORIO_FILTROS),
};
