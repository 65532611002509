import { createSelector } from "reselect";

export const getLogin = (state) => state.autenticacao.login;
export const getEsqueciSenha = (state) => state.autenticacao["esqueci-senha"];
export const getTrocarSenha = (state) => state.autenticacao["trocar-senha"];
export const getValidarToken = (state) =>
  state.autenticacao["validar-token-senha"];

export const getLoginLoading = createSelector(
  getLogin,
  (state) => state.loading
);

export const getEsqueciSenhaLoading = createSelector(
  getEsqueciSenha,
  (state) => state.loading
);

export const getTrocarSenhaLoading = createSelector(
  getTrocarSenha,
  (state) => state.loading
);
export const getTokenValidacao = createSelector(
  getTrocarSenha,
  (state) => state.token
);

export const getValidarTokenLoading = createSelector(
  getValidarToken,
  (state) => state.loading
);
