import { createAsyncAction } from "../../utils/actionCreators";

export const types = {
  LISTAR_RELATORIOS: "@@administracao/LISTAR_RELATORIOS",
  ATUALIZAR_RELATORIO: "@@administracao/ATUALIZAR_RELATORIO",
  CRIAR_RELATORIO: "@@administracao/CRIAR_RELATORIO",
  EDITAR_RELATORIO: "@@administracao/EDITAR_RELATORIO",
  EXCLUIR_RELATORIO: "@@administracao/EXCLUIR_RELATORIO",
  MOSTRAR_RELATORIO: "@@administracao/MOSTRAR_RELATORIO",
};

export const actions = {
  listarRelatorios: createAsyncAction(types.LISTAR_RELATORIOS),
  atualizarRelatorio: createAsyncAction(types.ATUALIZAR_RELATORIO),
  criarRelatorio: createAsyncAction(types.CRIAR_RELATORIO),
  editarRelatorio: createAsyncAction(types.EDITAR_RELATORIO),
  excluirRelatorio: createAsyncAction(types.EXCLUIR_RELATORIO),
  mostrarRelatorio: createAsyncAction(types.MOSTRAR_RELATORIO),
};
