import React from "react";
import DetalhesProximaAtividadeDialog from "./DetalhesProximaAtividadeDialog";

const DetalhesProximaAtividadeDialogConnected = ({
  handleClose,
  open,
  detalhes,
  atividade,
}) => {
  return (
    <DetalhesProximaAtividadeDialog
      open={open}
      handleClose={handleClose}
      detalhes={detalhes}
      atividade={atividade}
    />
  );
};

export default DetalhesProximaAtividadeDialogConnected;
