import BasePage from "../../BasePage";
import CargaConsultasForm from "./CargaConsultasForm";
import React from "react";

const CargaConsultasPage = ({ showResults, resultado, ...otherProps }) => {
  return (
    <BasePage isLoggedIn={true}>
      <h2>Carga de Consultas</h2>
      <div className="card mt-4">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <CargaConsultasForm {...otherProps} />
            </div>
            {showResults && (
              <div className="col-sm-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                <h4 className={"mt-3"}>Resultado</h4>
                <div>
                  Consultas analisadas: <b>{resultado.total}</b>
                </div>
                <div>
                  Novas consultas: <b>{resultado.novos}</b>
                </div>
                <div>
                  Consultas atualizadas: <b>{resultado.atualizados}</b>
                </div>
                <div>
                  Concluídas antes do início: <b>{resultado.concluidasAntes}</b>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default CargaConsultasPage;
