import ProdutosPage from "./ProdutosPage";
import { useDispatch, useSelector } from "react-redux";
import { getProdutos } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/produtos.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";

const ProdutosPageConnected = () => {
  const dispatch = useDispatch();
  const produtos = useSelector(getProdutos);
  const loading = produtos?.loading;
  const total = produtos?.total;
  const pagina = produtos?.page;

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(actions.listarProdutos.request({ pagina: page }));
    },
    [dispatch]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarProduto({ produto: values }));
    },
    [dispatch]
  );

  const handleOpenAtividades = useCallback(
    (id) => {
      dispatch(
        routeActions.redirectTo(routes.ADMINISTRACAO_ATIVIDADES, { id: id.id })
      );
    },
    [dispatch]
  );

  const handleOpenEtapas = useCallback(
    (id) => {
      dispatch(
        routeActions.redirectTo(routes.ADMINISTRACAO_ETAPAS, { id: id.id })
      );
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaProduto());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirProduto.request({ idProduto: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "codigo",
        value: values.search,
      };
      dispatch(actions.buscarProduto.request({ search: request, pagina: 1 }));
    },
    [dispatch]
  );

  return (
    <ProdutosPage
      produtos={produtos.list}
      produto={produtos.produto}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
      handleOpenAtividades={handleOpenAtividades}
      handleOpenEtapas={handleOpenEtapas}
    />
  );
};

export default ProdutosPageConnected;
