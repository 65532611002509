import { createSelector } from "reselect";

export const getEnvioEmails = (state) => state.emails;

export const getEmailParams = createSelector(
  getEnvioEmails,
  (state) => state.params
);
export const getEmailTemplates = createSelector(
  getEnvioEmails,
  (state) => state.templates
);
export const getEmailTemplate = createSelector(
  getEnvioEmails,
  (state) => state.template
);
