import React from "react";
import {
  ControlledTextfield,
  CpfCnpjTextField,
  PhoneTextField,
} from "../../../../components/inputs";

const CardUsuarioPLD = ({ props, disabled }) => {
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Usuário PLD</span>
        <div className="row">
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"cpfPLD"}
              formProps={props}
              label={"CPF"}
              margin={"none"}
              type={"cpf"}
              disabled={disabled}
              InputProps={{ inputComponent: CpfCnpjTextField }}
            />
          </div>
          <div className="col-12 col-md-9">
            <ControlledTextfield
              name={"nomePLD"}
              formProps={props}
              label={"Nome"}
              margin={"none"}
              disabled={disabled}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"certificadoPLD"}
              formProps={props}
              label={"Certificado"}
              margin={"none"}
              disabled={disabled}
              inputProps={{ maxLength: 20 }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              name={"emailPLD"}
              formProps={props}
              label={"E-mail"}
              margin={"none"}
              disabled={disabled}
              uppercase={false}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"telefonePLD"}
              formProps={props}
              label={"Telefone"}
              margin={"none"}
              disabled={disabled}
              InputProps={{ inputComponent: PhoneTextField }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardUsuarioPLD;
