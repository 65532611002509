import React from "react";
import { FormControl, FormHelperText } from "@material-ui/core";
import Dropzone from "react-dropzone";

const FileInput = ({
  classes = {},
  files,
  name,
  value,
  margin = "none",
  disabled,
  helperText,
  onDrop,
  onFileDialogCancel,
  onDeleteFile,
  fullWidth = true,
  renderFile,
  children,
  ...otherValues
}) => (
  <FormControl
    fullWidth={fullWidth}
    disabled={disabled}
    margin={margin}
    className={classes.formControl}
  >
    <Dropzone
      {...otherValues}
      onFileDialogCancel={onFileDialogCancel}
      name={name}
      disabled={disabled}
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <div
            {...getRootProps()}
            className={classes.root + (disabled ? " " + classes.disabled : "")}
          >
            <input {...getInputProps({ className: classes.input })} />
            {children}
          </div>
        );
      }}
    </Dropzone>
    {!!helperText && (
      <FormHelperText
        variant={"filled"}
        classes={{ error: "label-error" }}
        error={!!otherValues.error}
      >
        {helperText}
      </FormHelperText>
    )}
    {!!renderFile &&
      files.length > 0 &&
      files.map((file, index) => renderFile(file, index, onDeleteFile))}
  </FormControl>
);

export default FileInput;
