import React from "react";
import { CloseSharp, DeleteForeverSharp } from "@material-ui/icons";
import { situacaoAtividade } from "../../../utils/parametros";
import { CardWithDeleteButton } from "../../../components";
import Chip from "@material-ui/core/Chip";

const CardDecisao = ({ decisao, handleExclusaoDecisao, editDecisao }) => {
  return (
    <CardWithDeleteButton
      style={{ minHeight: 150 }}
      canDelete={true}
      onClick={() => editDecisao(decisao)}
      className={"clickable"}
      renderDefault={() => (
        <>
          <div className="d-flex flex-row card-decisoes-title justify-content-between">
            <div
              className={
                "atividades-label-title participantes-label-title-comprador"
              }
            >
              {decisao.descricao}{" "}
            </div>
            <div className={"justify-content-end"}>
              <Chip
                //icon={icon}
                label={situacaoAtividade
                  .filter((s) => {
                    return s.id === decisao.padrao;
                  })[0]
                  ?.nome?.toUpperCase()}
                className={"MuiChip-root-color mt-3 mr-3"}
              />
            </div>
          </div>
          <div className="card-body d-flex flex-column">
            {!!decisao.atividades && decisao.atividades.length > 0 && (
              <div className="row">
                <div className="col-12 mt-1">
                  <div className="participantes-label participantes-label-medium">
                    Atividades
                  </div>
                </div>
                <div className="col-12 mt-1">
                  {decisao.atividades.map((at) => (
                    <Chip
                      //icon={icon}
                      label={at.nome}
                      //onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                      className={"mt-1 ml-1"}
                      variant="outlined"
                    />
                  ))}
                </div>
              </div>
            )}
            {!!decisao.documentos && decisao.documentos.length > 0 && (
              <div className="row spacer">
                <div className="col-12 mt-1">
                  <div className="participantes-label participantes-label-medium">
                    Documentos Obrigatórios para Decisão
                  </div>
                </div>
                <div className="col-12 mt-1">
                  {decisao.documentos.map((at) => (
                    <Chip
                      //icon={icon}
                      label={at.documento.nome}
                      //onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                      className={"mt-1 ml-1"}
                      variant="outlined"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      renderSecondary={({ handleClick }) => (
        <div className="card-body bg-red visible-1s pb-0">
          <CloseSharp
            className="position-absolute clickable close-icon"
            onClick={handleClick}
          />
          <div className="row align-items-center justify-content-center">
            <DeleteForeverSharp className="large-delete-icon" />
            <span className="color-white text-center">
              Confirma a exclusão da decisao{" "}
              <span className="delete-buyer-name">{decisao.descricao}</span>?
            </span>
          </div>
          <div className="row align-items-center justify-content-center">
            <div
              className="clickable confirm-delete-button spacer"
              onClick={() => handleExclusaoDecisao(decisao)}
            >
              SIM, PODE EXCLUIR
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default CardDecisao;
