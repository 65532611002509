import { useCallback } from "react";
import CargaConsultasPage from "./CargaConsultasPage";
import { useDispatch, useSelector } from "react-redux";
import { getCargaConsultas } from "selectors/administracao.selectors";
import { useForm } from "react-hook-form";
import validators from "utils/validators";
import { actions } from "reducers/administracao/cargaConsultas.actions";

const CargaConsultasPageConnected = () => {
  const dispatch = useDispatch();
  const carga = useSelector(getCargaConsultas);

  const rules = {
    files: validators.array({ required: true }),
  };
  const initialValues = {
    files: [],
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };
  const handleSubmit = useCallback(
    (values) => {
      dispatch(actions.processar.request(values));
    },
    [dispatch]
  );

  return (
    <CargaConsultasPage
      formProps={formProps}
      submitting={carga.submitting}
      showResults={carga["show-results"]}
      resultado={carga.resultado}
      handleSubmit={handleSubmit}
    />
  );
};

export default CargaConsultasPageConnected;
