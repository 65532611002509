import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_LOCALIDADES: "@@administracao/LISTAR_LOCALIDADES",
  ATUALIZAR_LOCALIDADE: "@@administracao/ATUALIZAR_LOCALIDADE",
  NOVA_LOCALIDADE: "@@administracao/NOVA_LOCALIDADE",
  EDITAR_LOCALIDADE: "@@administracao/EDITAR_LOCALIDADE",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_LOCALIDADE: "@@administracao/INCLUIR_LOCALIDADE",
  SEARCH_LOCALIDADE: "@@administracao/SEARCH_LOCALIDADE",
  EXCLUIR_LOCALIDADE: "@@administracao/EXCLUIR_LOCALIDADE",
};

export const actions = {
  listarLocalidades: createAsyncAction(types.LISTAR_LOCALIDADES),
  atualizarLocalidade: createAsyncAction(types.ATUALIZAR_LOCALIDADE),
  novaLocalidade: () => createSyncAction(types.NOVA_LOCALIDADE),
  editarLocalidade: (values) =>
    createSyncAction(types.EDITAR_LOCALIDADE, values),
  voltarListaLocalidade: () => createSyncAction(types.VOLTAR_LISTA),
  incluirLocalidade: createAsyncAction(types.INCLUIR_LOCALIDADE),
  buscarLocalidade: createAsyncAction(types.SEARCH_LOCALIDADE),
  excluirLocalidade: createAsyncAction(types.EXCLUIR_LOCALIDADE),
};
