import React from "react";
import FormPessoaFisica from "./FormPessoaFisica";
import BasePage from "../../../../BasePage";
import Loader from "../../../../../components/loaders";
import FormPessoaJuridica from "./FormPessoaJuridica";

const FormParticipante = ({ loading, isPf, ...props }) => {
  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {!loading && (
        <>
          <span className="page-title">Detalhes do Convênio</span>
          {isPf && <FormPessoaFisica {...props} />}
          {!isPf && <FormPessoaJuridica {...props} />}
        </>
      )}
    </BasePage>
  );
};

export default FormParticipante;
