import basicFlow from "../../sagas/asyncHandler";
import { actions } from "../../reducers/administracao/exportSla.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { actions as dominioActions } from "../../reducers/dominios.actions";
import { put } from "redux-saga/effects";

const processarApi = (form) => {
  return authenticatedRequest({
    url: "/administracao/exportar-sla",
    method: "post",
    body: form,
  });
};

function* listaAtividadesRouteWatcher() {
  yield routeWatcher(routes.EXPORT_SLA, function* () {
    yield put(dominioActions.atividades.request());
  });
}

const processar = basicFlow({
  actionGenerator: actions.processar,
  api: processarApi,
});

export const sagas = [processar.watcher(), listaAtividadesRouteWatcher()];
