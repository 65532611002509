import React, { useCallback, useState } from "react";
import Loader from "../../../components/loaders";
import Divider from "@material-ui/core/Divider";
import { Autorenew } from "@material-ui/icons";
import { VisibleWithoutProfile, VisibleWithProfile } from "../../../components";
import TrocarAnalistaDialog from "../analista/TrocarAnalistaDialog.connected";
import {
  divisao_administrativa,
  area_responsavel_kit,
  tipoConstituicao,
  situacaoCNPJ,
} from "../../../utils/parametros";
import formatters from "../../../utils/formatters";
const CardDadosProposta = ({
  loading,
  error,
  data,
  handleCloseAlterarPrioridade,
  handleOpenAlterarPrioridade,
  codigoProduto,
}) => {
  const [dadosTrocarAnalista, setDadosTrocarAnalista] = useState({
    open: false,
  });
  const handleCloseTrocarAnalista = useCallback(() => {
    setDadosTrocarAnalista({
      open: false,
    });
  }, []);
  // const handleOpenTrocarAnalista = useCallback(() => {
  //   setDadosTrocarAnalista({
  //     open: true,
  //     analista: data.usuarioBackoffice?.id,
  //     consulta: data.id,
  //   });
  // }, [data]);

  if (codigoProduto === "2") {
    //Corban
    return (
      <div className="card spacer">
        <div className="card-body d-flex flex-column">
          {!!loading && (
            <div className="flex d-flex justify-content-center align-items-center">
              <Loader type={"dots"} size={80} />
            </div>
          )}
          {!!error && (
            <div className="flex d-flex flex-column justify-content-center align-items-center">
              <span className="d-block colored-card-text mt-4 color-red">
                Não foi possível obter as informações
              </span>
            </div>
          )}
          {!loading && !error && (
            <>
              {!!loading && (
                <div className="col-12">
                  <Loader type={"dots"} color={"brand"} size={50} />
                </div>
              )}
              {!loading && (
                <div className="col-12 mt-0">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-xs-12">
                      <span className="label">Nome do Corban</span>
                      <div>
                        <span className="data-label">
                          {data?.cliente[0].nome}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Produto</span>
                      <div>
                        <span className="data-label">
                          {data?.Produto?.nome}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Situação do CNPJ</span>
                      <div>
                        <span className="data-label">
                          {situacaoCNPJ.find(
                            (item) =>
                              item.tipo ===
                              data?.cliente[0]?.PessoaDadoEmpresa?.situacaoCNPJ
                          )?.descricao ?? ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Divider />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Responsável Corban</span>
                      <div>
                        <span className="data-label">
                          {data.cliente[0]?.PessoaContato?.nome}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Faturamento Mensal</span>
                      <div>
                        <span className="data-label">
                          {`R$ ${formatters.numbers.currency(
                            data.cliente[0]?.PessoaDadoEmpresa?.valorFaturamento
                          )}`}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Forma Constituição</span>
                      <div>
                        <span className="data-label">
                          {tipoConstituicao.find(
                            (item) =>
                              item.codigo ===
                              data.cliente[0]?.PessoaDadoEmpresa
                                ?.tipoConstituicao
                          )?.descricao ?? ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Data da Constituição</span>
                      <div>
                        <span className="data-label">
                          {data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao
                            ? `${
                                data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao.split(
                                  "-"
                                )[2]
                              }/${
                                data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao.split(
                                  "-"
                                )[1]
                              }/${
                                data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao.split(
                                  "-"
                                )[0]
                              }`
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>

                  <VisibleWithoutProfile profiles={["CLIENTE"]}>
                    <div className="row">
                      <div className="col-12">
                        <Divider />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <span className="label">Prioridade</span>
                        <div className="d-flex flex-row align-items-center">
                          <span className="data-label text-transform-unset">
                            {data.prioridade
                              ? prioridadeDecode(data.prioridade)
                              : "(Nenhum)"}
                          </span>
                          <VisibleWithProfile profiles={["BPO", "BANCO"]}>
                            <Autorenew
                              className={"change-analyst-icon"}
                              onClick={() =>
                                handleOpenAlterarPrioridade({
                                  prioridade: data.prioridade,
                                  consultaID: data.id,
                                })
                              }
                            />
                          </VisibleWithProfile>
                        </div>
                      </div>
                    </div>
                  </VisibleWithoutProfile>
                </div>
              )}
            </>
          )}
        </div>
        <TrocarAnalistaDialog
          dados={dadosTrocarAnalista}
          handleClose={handleCloseTrocarAnalista}
        />
      </div>
    );
  } else {
    return (
      <div className="card spacer">
        <div className="card-body d-flex flex-column">
          {!!loading && (
            <div className="flex d-flex justify-content-center align-items-center">
              <Loader type={"dots"} size={80} />
            </div>
          )}
          {!!error && (
            <div className="flex d-flex flex-column justify-content-center align-items-center">
              <span className="d-block colored-card-text mt-4 color-red">
                Não foi possível obter as informações
              </span>
            </div>
          )}
          {!loading && !error && (
            <>
              {!!loading && (
                <div className="col-12">
                  <Loader type={"dots"} color={"brand"} size={50} />
                </div>
              )}
              {!loading && (
                <div className="col-12 mt-0">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-xs-12">
                      <span className="label">Nome do Convênio</span>
                      <div>
                        <span className="data-label">
                          {data?.cliente[0].nome}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Produto</span>
                      <div>
                        <span className="data-label">
                          {data?.Produto?.nome}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Data Início</span>
                      <div>
                        <span className="data-label">
                          {data?.dataConsulta
                            ? `${data?.dataConsulta?.split("-")[2]}/${
                                data?.dataConsulta?.split("-")[1]
                              }/${data?.dataConsulta?.split("-")[0]}`
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Divider />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Divisão Administrativa</span>
                      <div>
                        <span className="data-label">
                          {
                            divisao_administrativa.find(
                              (item) =>
                                item.codigo ===
                                data.ConsultaConvenio.tipoDivisaoAdministrativa
                            ).descricao
                          }
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Tipo de Convênio</span>
                      <div>
                        <span className="data-label">
                          {data?.ConsultaConvenio?.TipoConvenio?.nome}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Área Responsável</span>
                      <div>
                        <span className="data-label">
                          {
                            area_responsavel_kit.find(
                              (item) =>
                                item.codigo ===
                                data.ConsultaConvenio?.tipoAreaKitCredenciamento
                            ).descricao
                          }
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <span className="label">Qtde. Funcionários</span>
                      <div>
                        <span className="data-label">
                          {data?.ConsultaConvenio?.quantidadeFuncionarios}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <Divider />
                    </div>
                  </div>
                  <div className="row">
                    <VisibleWithoutProfile profiles={["CLIENTE"]}>
                      <div className="col-md-6 col-xs-12">
                        <span className="label">Prioridade</span>
                        <div className="d-flex flex-row align-items-center">
                          <span className="data-label text-transform-unset">
                            {data.prioridade
                              ? prioridadeDecode(data.prioridade)
                              : "(Nenhum)"}
                          </span>
                          <VisibleWithProfile profiles={["BPO", "BANCO"]}>
                            <Autorenew
                              className={"change-analyst-icon"}
                              onClick={() =>
                                handleOpenAlterarPrioridade({
                                  prioridade: data.prioridade,
                                  consultaID: data.id,
                                })
                              }
                            />
                          </VisibleWithProfile>
                        </div>
                      </div>
                    </VisibleWithoutProfile>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <TrocarAnalistaDialog
          dados={dadosTrocarAnalista}
          handleClose={handleCloseTrocarAnalista}
        />
      </div>
    );
  }
};

const prioridadeDecode = (prioridade) => {
  switch (prioridade) {
    case "B":
      return "Solicitação Diretoria";
    default:
      return "";
  }
};

export default CardDadosProposta;
