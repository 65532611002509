import { combineReducers } from "redux";
import { default as cargaConsultas } from "./cargaConsultas.reducer";
import { default as usuarios } from "./usuarios.reducer";
import { default as exportConsultas } from "./exportConsultas.reducer";
import { default as documentos } from "./documentos.reducer";
import { default as atividades } from "./atividades.reducer";
import { default as etapas } from "./etapas.reducer";
import { default as matrizChecklist } from "./matrizChecklist.reducer";
import { default as produtos } from "./produtos.reducer";
import { default as relatorios } from "./relatorioBI.reducer";
import { default as perfis } from "./perfis.reducer";
import { default as organizacao } from "./organizacao.reducer";
import { default as sla } from "./exportSla.reducer";
import { default as localidades } from "./localidades.reducer";
import { default as motivoCancelamento } from "./motivoCancelamento.reducer";
import { default as tiposDeConvenios } from "./tiposDeConvenios.reducer";
import { default as produtosConvenio } from "./produtosConvenio.reducer";
import { default as templatesEmail } from "./templatesEmail.reducer";
import { default as relatoriosAuditoria } from "./relatorios.reducer";

export default combineReducers({
  "carga-consultas": cargaConsultas,
  usuarios: usuarios,
  export_consultas: exportConsultas,
  "matriz-checklist": matrizChecklist,
  documentos: documentos,
  atividades: atividades,
  etapas: etapas,
  produtos: produtos,
  perfis: perfis,
  organizacao: organizacao,
  sla: sla,
  localidades: localidades,
  "motivos-cancelamento": motivoCancelamento,
  tiposDeConvenios: tiposDeConvenios,
  produtosConvenio: produtosConvenio,
  templates: templatesEmail,
  relatorios,
  relatoriosAuditoria,
});
