import React, { useCallback, useState } from "react";
import { ControlledAutocomplete } from "../../../components/inputs";
import { Button } from "../../../components";
import CardRegra from "./CardRegras";
import { toast } from "react-toastify";

const FormPreReqAtividade = ({ props, atividades, regras, setRegras }) => {
  const [novaRegra, setNovaRegra] = useState(false);
  const [editarRegra, setEditRegra] = useState({ isEdicao: false, id: "" });

  const limparCampos = useCallback(() => {
    props.setValue("regra.atividades", []);
  }, [props]);

  const adicionarRegra = useCallback((value) => {
    limparCampos();
    setNovaRegra(value);
    setEditRegra({
      ...editarRegra,
      isEdicao: false,
      idRegra: "",
    });
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const addRegra = useCallback(() => {
    let newId = 1;

    let isAtividades = props.getValues("regra.atividades");

    if (isAtividades?.length === 0) {
      toast.error("Favor incluir ao menos uma atividade");
    } else {
      // eslint-disable-next-line
      regras.map((el) => {
        if (el.idRegra >= newId) newId = el.idRegra + 1;
      }, 0);

      if (!editarRegra.isEdicao) {
        setRegras((prevRegras) => [
          ...prevRegras,
          {
            atividades: props.getValues("regra.atividades"),
            idRegra: newId,
          },
        ]);
      } else {
        setRegras((prevRegras) =>
          prevRegras.map((t) => {
            if (t.idRegra === editarRegra.idRegra) {
              return {
                ...t,
                atividades: props.getValues("regra.atividades"),
                idRegra: t.idRegra,
              };
            }
            return t;
          })
        );
      }

      setEditRegra({
        isEdicao: false,
        idRegra: "",
      });
      setNovaRegra(false);

      // eslint-disable-next-line
    }
  });

  const removeRegra = useCallback((regra) => {
    console.log(regra);

    setRegras((prevRegras) => [
      ...prevRegras.filter((i) => i.idRegra !== regra.idRegra),
    ]);
    limparCampos();
    setEditRegra({
      isEdicao: false,
    });
    setEditRegra({
      isEdicao: false,
      idRegra: "",
    });
    setNovaRegra(false);
    //eslint-disable-next-line
  }, []);

  const editRegra = useCallback(
    (d) => {
      setEditRegra({
        ...editarRegra,
        isEdicao: true,
        idRegra: d.idRegra,
      });
      setNovaRegra(false);
      props.initialValues.regra.atividades = d.atividades;
      props.setValue("regra.atividades", d.atividades);
    },
    // eslint-disable-next-line
    [props]
  );

  return (
    <>
      <div className={"row"}>
        {regras.map((d, index) => (
          <div className={"col-6"}>
            <CardRegra
              regra={d}
              handleExclusaoRegra={removeRegra}
              key={d.idRegra}
              editRegra={editRegra}
            />
          </div>
        ))}
      </div>
      {(novaRegra || editarRegra.isEdicao) && (
        <div className="col-12 col-md-12">
          <ControlledAutocomplete
            name={"regra.atividades"}
            domain={atividades.filter((i) => i.id !== props.initialValues.id)}
            autocompleteProps={{
              multiple: true,
            }}
            labelField={"nome"}
            formProps={props}
            label={"Atividades pré-requisito abertura"}
            margin={"none"}
          />
        </div>
      )}
      {novaRegra && (
        <div className="row spacer">
          <div className="offset-md-9 col-md-3 col-12">
            <Button label={"INCLUIR REGRA"} onClick={() => addRegra()} />
          </div>
        </div>
      )}
      {editarRegra.isEdicao && (
        <div className="row spacer">
          <div className="offset-md-6 col-md-3 col-12">
            <Button label="NOVA REGRA" onClick={() => adicionarRegra(true)} />
          </div>
          <div className="col-md-3 col-12">
            <Button label={"SALVAR REGRA"} onClick={() => addRegra()} />
          </div>
        </div>
      )}
      {!novaRegra && !editarRegra.isEdicao && (
        <div className="row spacer">
          <div className="offset-md-9 col-md-3 col-12">
            <Button label="NOVA REGRA" onClick={() => adicionarRegra(true)} />
          </div>
        </div>
      )}
    </>
  );
};

export default FormPreReqAtividade;
