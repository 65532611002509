import { actions } from "./relatorios.actions";

const initialState = {
  auditoria: {
    loading: false,
    error: null,
    loadingFile: false,
    usuarios: [],
    propostas: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarRelatorioAuditoria.REQUEST:
    case actions.listarRelatorioAuditoria.SUCCESS:
    case actions.listarRelatorioAuditoria.FAILURE:
      return {
        ...state,
        auditoria: {
          ...state.auditoria,
          loadingFile: action.type === actions.listarRelatorioAuditoria.REQUEST,
        },
      };
    case actions.listarRelatorioFiltros.REQUEST:
    case actions.listarRelatorioFiltros.SUCCESS:
    case actions.listarRelatorioFiltros.FAILURE:
      return {
        ...state,
        auditoria: {
          ...state.auditoria,
          loading: action.type === actions.listarRelatorioFiltros.REQUEST,
          error:
            action.type === actions.listarRelatorioFiltros.FAILURE
              ? action.payload.error
              : null,
          usuarios:
            action.type === actions.listarRelatorioFiltros.SUCCESS
              ? action.payload.response.data.usuarios
              : [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
