import basicFlow from "sagas/asyncHandler";
import { actions } from "reducers/administracao/exportConsultas.actions";
import { authenticatedRequest } from "utils/api";

const processarApi = (form) => {
  return authenticatedRequest({
    url: "/administracao/exportar-consultas",
    method: "post",
    body: form,
  });
};

const processar = basicFlow({
  actionGenerator: actions.processar,
  api: processarApi,
});

export const sagas = [processar.watcher()];
