import React, { useState } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  ZipCodeTextField,
} from "../../../../components/inputs";
import { MenuItem } from "../../../../components";

const CardEndereco = ({ props, title, uf, consultarCep, disabled }) => {

  const [isOutroPais, setIsOutroPais] = useState(
    props.initialValues.pais === "OUTRO"
  );

  const handleChangePais = (e) => {
    setIsOutroPais(
      e.target.value === 'OUTRO');
  };

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">{title || "Endereço"}</span>
        <div className="row">
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"cep"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"CEP"}
              onBlur={consultarCep}
              InputProps={{
                inputComponent: ZipCodeTextField,
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              formProps={props}
              margin={"none"}
              disabled={disabled}
              name={"logradouro"}
              label={"Logradouro"}
              inputProps={{ maxLength: 40 }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"numero"}
              formProps={props}
              disabled={disabled}
              label={"Número"}
              margin={"none"}
              inputProps={{ maxLength: 7 }}
            />
          </div>
          <div className={"col-12 col-md-4"}>
            <ControlledTextfield
              name={"complemento"}
              formProps={props}
              disabled={disabled}
              label={"Complemento"}
              margin={"none"}
              inputProps={{ maxLength: 30 }}
            />
          </div>
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"bairro"}
              formProps={props}
              disabled={disabled}
              label={"Bairro"}
              margin={"none"}
              inputProps={{ maxLength: 40 }}
            />
          </div>
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"municipio"}
              formProps={props}
              disabled={disabled}
              label={"Cidade"}
              margin={"none"}
              inputProps={{ maxLength: 60 }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledSelect
              name={"uf"}
              formProps={props}
              disabled={disabled}
              label={"UF"}
              margin={"none"}
            >
              {uf.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.codigo}
                  value={item.codigo}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
          <>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"pais"}
                formProps={props}
                disabled={disabled}
                label={"País"}
                margin={"none"}
                onChange={handleChangePais}
              >
                <MenuItem
                  className={"select-submenu-item"}
                  key={1}
                  value={"BRASIL"}
                >
                  BRASIL
                </MenuItem>
                <MenuItem
                  className={"select-submenu-item"}
                  key={2}
                  value={"OUTRO"}
                >
                  OUTRO
                </MenuItem>
              </ControlledSelect>
            </div>
            {isOutroPais &&
              <div className="col-12 col-md-6">
                <ControlledTextfield
                  name={"paisOutro"}
                  formProps={props}
                  disabled={disabled}
                  label={"Nome País"}
                  margin={"none"}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
            }
          </>
        </div>
      </div>
    </div>
  );
};

export default CardEndereco;
