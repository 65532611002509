import React, { useCallback } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  IntegerTextField,
  ControlledSwitch,
  ControlledAutocomplete,
} from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";
import { origemDocumento } from "../../../utils/parametros";

const FormDocumento = ({
  props,
  disabled,
  handleSubmit,
  documento,
  voltarParaLista,
  perfis,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, documento.id);
    },
    [handleSubmit, documento]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Documento</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-3">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Código"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-9">
              <ControlledTextfield
                name={"nome"}
                formProps={props}
                uppercase={true}
                disabled={disabled}
                label={"Documento"}
                margin={"none"}
                inputProps={{ maxLength: 200 }}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledSelect
                name={"origem"}
                formProps={props}
                label={"Origem"}
                margin={"none"}
              >
                {origemDocumento.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-3">
              <ControlledSwitch
                name={"interno"}
                formProps={props}
                label={"Documento Interno?"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledSwitch
                name={"permiteDispensa"}
                formProps={props}
                label={"Permite Dispensa?"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledSwitch
                name={"dataValidade"}
                formProps={props}
                label={"Consiste Data de Validade?"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12">
              <ControlledAutocomplete
                name="perfis"
                domain={perfis}
                autocompleteProps={{ multiple: true }}
                labelField="nome"
                formProps={props}
                label="Perfil para Notificação"
                margin={"none"}
              />
            </div>
            <div className="col-12">
              <ControlledAutocomplete
                name="analise"
                domain={perfis}
                autocompleteProps={{ multiple: true }}
                labelField="nome"
                formProps={props}
                label="Perfil de Análise Documental"
                margin={"none"}
              />
            </div>
            <div className="col-12 col-md-12">
              <ControlledTextfield
                name={"orientacao"}
                formProps={props}
                uppercase={false}
                disabled={disabled}
                label={"Orientação"}
                margin={"none"}
                inputProps={{ maxLength: 4000 }}
                rowsMax={16}
                rows={12}
                multiline={true}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={documento.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={documento.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormDocumento;
