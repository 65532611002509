import NotFoundImage from "../assets/not-found.png";
import { ReactComponent as Logo } from "../assets/logo-preto.svg";

const NotFoundPage = () => {
  return (
    <div className="min-vh-100 flex flex-column d-flex align-items-center justify-content-center">
      <Logo className={"logo-login"} />
      <img src={NotFoundImage} alt="Not found" className={"img-error"} />
    </div>
  );
};

export default NotFoundPage;
