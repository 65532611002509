import { useCallback } from "react";
import ArtefatosDocumentoPage from "./ArtefatosDocumentoPage";
import { useDispatch, useSelector } from "react-redux";
import { getArtefatos } from "../../../../selectors/artefatos.selectors";
import { actions } from "../../../../reducers/artefatos.actions";
import { getJwt } from "../../../../utils/localStorage";

const ArtefatosDocumentoConnected = () => {
  const dispatch = useDispatch();

  const {
    id,
    tipo,
    loading,
    list,
    selected,
    situacao,
    uploading,
    downloading,
    motivoIndexacao,
  } = useSelector(getArtefatos);

  const handleFileSelected = useCallback(
    (artefato) => {
      if (window.innerWidth > 768) {
        dispatch(actions.selecionarArtefato(artefato));
      } else {
        dispatch(actions.download.request(artefato));
      }
    },
    [dispatch]
  );
  const enhancedSelected = !!selected
    ? {
        ...selected,
        pdfPayload: {
          url: `${process.env.REACT_APP_API_BASE_URL}/artefato/${selected.artefato}`,
          httpHeaders: {
            Authorization: `Bearer ${getJwt()}`,
          },
        },
      }
    : null;
  const handleDownload = useCallback(() => {
    dispatch(actions.download.request(selected));
  }, [dispatch, selected]);

  const handleDelete = useCallback(
    (artefato) => {
      dispatch(actions.removerArtefato.request(artefato));
    },
    [dispatch]
  );

  const handleUpload = useCallback(
    (metadata, values) => {
      dispatch(
        actions.upload.request({
          id: metadata.name,
          motivo: metadata.motivo ?? "",
          callback: metadata.callback,
          files: values,
        })
      );
    },
    [dispatch]
  );

  return (
    <ArtefatosDocumentoPage
      loading={loading}
      list={list}
      id={id}
      tipo={tipo}
      uploading={uploading}
      situacaoDocumento={situacao}
      selected={enhancedSelected}
      handleDownload={handleDownload}
      handleDelete={handleDelete}
      handleFileSelected={handleFileSelected}
      handleUpload={handleUpload}
      downloading={downloading}
      motivoIndexacao={motivoIndexacao}
    />
  );
};

export default ArtefatosDocumentoConnected;
