import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_DOCUMENTOS: "@@administracao/LISTAR_DOCUMENTOS",
  LISTAR_ORIGEM: "@@administracao/LISTAR_ORIGEM",
  ATUALIZAR_DOCUMENTO: "@@administracao/ATUALIZAR_DOCUMENTO",
  NOVO_DOCUMENTO: "@@administracao/NOVO_DOCUMENTO",
  EDITAR_DOCUMENTO: "@@administracao/EDITAR_DOCUMENTO",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_DOCUMENTO: "@@administracao/INCLUIR_DOCUMENTO",
  SEARCH_DOCUMENTO: "@@administracao/SEARCH_DOCUMENTO",
  EXCLUIR_DOCUMENTO: "@@administracao/EXCLUIR_DOCUMENTO",
};

export const actions = {
  listarDocumentos: createAsyncAction(types.LISTAR_DOCUMENTOS),
  listarOrigem: createAsyncAction(types.LISTAR_ORIGEM),
  atualizarDocumento: createAsyncAction(types.ATUALIZAR_DOCUMENTO),
  novoDocumento: () => createSyncAction(types.NOVO_DOCUMENTO),
  editarDocumento: (values) => createSyncAction(types.EDITAR_DOCUMENTO, values),
  voltarListaDocumento: () => createSyncAction(types.VOLTAR_LISTA),
  incluirDocumento: createAsyncAction(types.INCLUIR_DOCUMENTO),
  buscarDocumento: createAsyncAction(types.SEARCH_DOCUMENTO),
  excluirDocumento: createAsyncAction(types.EXCLUIR_DOCUMENTO),
};
