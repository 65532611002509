import Loader from "../../../../components/loaders";
import { Button } from "../../../../components";
import Atividade from "./Atividade";

const View = ({
  loading,
  historico,
  handleDownload,
  loadingExportHistorico,
}) => {
  return (
    <>
      {!!loading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {!loading && (
        <>
          {historico.length === 0 && (
            <div className="flex d-flex flex-column justify-content-center align-items-center">
              <span className="d-block colored-card-text mt-4 color-secondary text-center">
                Nenhuma atividade encontrada.
              </span>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              <div className="" style={{ minHeight: 100 }}>
                <div className="card-body d-flex flex-column">
                  <div className={"row d-flex align-items-center"}>
                    <div className={"mt-0 ml-2"}>
                      <div className="card-title-checklist">Atividades</div>
                    </div>
                    <div className={"mt-0 ml-auto"}>
                      <Button
                        label="Download"
                        onClick={handleDownload}
                        loading={!!loadingExportHistorico}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className="col-12">
                      <div
                        className={
                          "row mt-0 d-flex align-items-center medium-row texto-dispensar tr-odd"
                        }
                      >
                        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0">
                          <span>Etapa</span>
                        </div>
                        <div className="col-sm-12 col-lg-4 mt-sm-2 mt-lg-0 ">
                          <span>Atividade</span>
                        </div>
                        {/*<div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 ">*/}
                        {/*  <span>Responsável</span>*/}
                        {/*</div>*/}
                        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                          <span>Situação</span>
                        </div>
                        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                          <span>Data/Hora</span>
                        </div>
                        {/*<div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 ">*/}
                        {/*  <span>Sla </span>*/}
                        {/*  <span>Acordado</span>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 ">*/}
                        {/*  <span>Sla </span>*/}
                        {/*  <span>Realizado</span>*/}
                        {/*</div>*/}
                        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0">
                          <div className="row d-flex align-items-center justify-content-center">
                            <span>Sla</span>
                          </div>
                          <div className="row d-flex align-items-center justify-content-center">
                            <span>Acordado/Realizado</span>
                          </div>
                        </div>
                      </div>
                      {historico.map((d, index) => (
                        <Atividade key={d.id} atividade={d} index={index} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default View;
