import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";

const Localidade = ({
  localidade,
  index,
  handleEdit,
  handleOpenExclusaoLocalidade,
}) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-3 mt-lg-0 user-table ">
        <span>{localidade.codigo}</span>
      </div>
      <div className="col-8 mt-lg-0 user-table ">
        <span>{localidade.nome}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(localidade)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() => handleOpenExclusaoLocalidade(localidade)}
        >
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Localidade;
