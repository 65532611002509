import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { Button } from "../../../../components";
import { ControlledTextfield } from "../../../../components/inputs";
import { CloseSharp } from "@material-ui/icons";

const VisualizarMotivoDialog = ({
  handleSubmit,
  disabled,
  submitting,
  dados,
  handleClose,
  formProps,
  open,
}) => {
  return (
    <Dialog classes={{ paper: "w100" }} maxWidth="sm" open={open}>
      <DialogContent>
        {!submitting && (
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
        )}
        {!!dados.isSubstituto && (
          <div className={"alerta-substituido"}>
            Referente a Documento entregue anteriormente
          </div>
        )}
        <form onSubmit={formProps.handleSubmit(handleSubmit)}>
          <div className="row mb-3">
            <div className="col-12">
              <ControlledTextfield
                formProps={formProps}
                multiline={true}
                disabled={disabled}
                rowsMax={8}
                rows={6}
                uppercase={false}
                name={"motivo"}
                margin={"none"}
                label={
                  dados?.situacao_analise === "S" || !!dados?.isSubstituto
                    ? "Motivo da Recusa"
                    : "Motivo da Dispensa"
                }
                inputProps={{ maxLength: 500 }}
              />
            </div>
          </div>
          {!disabled && (
            <div className={"row justify-content-center mb-3"}>
              <div className="col-6 mt-3">
                <Button
                  loading={submitting}
                  label={"ATUALIZAR"}
                  type={"submit"}
                />
              </div>
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default VisualizarMotivoDialog;
