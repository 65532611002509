import { actions, types } from "./artefatos.actions";
import { types as routes } from "./rotas.actions";
import { types as authTypes } from "./autenticacao.actions";

const initialState = {
  id: null,
  situacao: null,
  tipo: null,
  list: [],
  motivoIndexacao: null,
  loading: false,
  removing: [],
  uploading: false,
  downloading: false,
  selected: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case routes.ARTEFATOS_DOCUMENTO:
      return {
        ...state,
        id: action.payload.id,
      };
    case types.SELECIONAR_ARTEFATO:
      return {
        ...state,
        selected: action.payload.artefato,
      };
    case actions.listarArtefatos.REQUEST:
    case actions.listarArtefatos.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarArtefatos.REQUEST,
      };
    case actions.listarArtefatos.SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.response.data.list,
        tipo: action.payload.response.data.tipo,
        situacao: action.payload.response.data.situacao,
        motivoIndexacao: action.payload.response.data.motivoIndexacao,
      };
    case actions.removerArtefato.REQUEST:
    case actions.removerArtefato.SUCCESS:
    case actions.removerArtefato.FAILURE:
      return {
        ...state,
        removing:
          action.type === actions.removerArtefato.REQUEST
            ? [...state.removing, action.payload.id]
            : [
                ...state.removing.filter(
                  (i) => i !== action.payload.original.id
                ),
              ],
        list:
          action.type === actions.removerArtefato.SUCCESS
            ? [
                ...state.list.filter(
                  (i) => i.artefato !== action.payload.original.artefato
                ),
              ]
            : state.list,
        selected:
          action.type === actions.removerArtefato.SUCCESS &&
          state.selected?.artefato === action.payload.original.artefato
            ? null
            : state.selected,
      };
    case actions.upload.REQUEST:
    case actions.upload.SUCCESS:
    case actions.upload.FAILURE:
      return {
        ...state,
        uploading: action.type === actions.upload.REQUEST,
      };
    case actions.download.REQUEST:
    case actions.download.SUCCESS:
    case actions.download.FAILURE:
      return {
        ...state,
        downloading: action.type === actions.download.REQUEST,
      };
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
