import React, { useCallback, useState } from "react";
import { Button } from "../../../../components";
import CardDadosPessoaFisica from "./CardDadosPessoaFisica";
import CardDadosContato from "./CardDadosContato";
import ComfirmarDialog from "../ConfirmarDialog";

const FormPessoaFisica = ({
  loading,
  handleSubmit,
  formProps,
  loadingInclusao,
  disabled,
  validarCpfCnpjRepetido,
  isAtualizacao,
  handleGoBack,
  tipoSocio,
}) => {
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, { tipoPessoa: "F", tipoParticipante: tipoSocio });
      handleCloseDialog();
    },
    // eslint-disable-next-line
    [handleSubmit, handleCloseDialog, tipoSocio]
  );

  if (Object.keys(formProps.formState.errors).length > 0 && open) {
    setOpen(false);
  }

  return (
    <div>
      <form
        className="d-flex flex-column"
        onSubmit={formProps.handleSubmit(handleClickNext)}
      >
        <CardDadosPessoaFisica
          props={formProps}
          disabled={disabled}
          validarCpfCnpjRepetido={validarCpfCnpjRepetido}
          isAtualizacao={isAtualizacao}
        />
        <CardDadosContato props={formProps} disabled={disabled} isPf={true} />
        <div className="row spacer justify-content-center">
          <div className="col-12 col-md-4 col-lg-5">
            <Button
              disabled={loading}
              label="VOLTAR"
              loading={loadingInclusao}
              onClick={() => handleGoBack()}
            />
          </div>
          {!disabled && (
            <div className="col-12 col-md-4 col-lg-5">
              <Button
                disabled={loading}
                label={"CONFIRMAR"}
                loading={loadingInclusao}
                onClick={() => {
                  setOpen(true);
                  formProps.clearErrors();
                }}
              />
            </div>
          )}
        </div>
        <ComfirmarDialog handleClose={handleCloseDialog} open={open} />
      </form>
    </div>
  );
};

export default FormPessoaFisica;
