export default function Flex({
  className,
  direction,
  alignItems,
  justifyContent,
  ...otherProps
}) {
  const classes = ["d-flex"];

  switch (direction) {
    case "column":
      classes.push("flex-column");
      break;
    default:
      break;
  }

  switch (justifyContent) {
    case "center":
      classes.push("justify-content-center");
      break;
    default:
      break;
  }
  switch (alignItems) {
    case "center":
      classes.push("align-items-center");
      break;
    default:
      break;
  }

  if (!!className) {
    classes.push(className);
  }

  return <div className={classes.join(" ")} {...otherProps} />;
}
