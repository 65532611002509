import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_PERFIS_DOMINIO: "@@administracao/LISTAR_PERFIS_DOMINIO",
  ATUALIZAR_PERFIL: "@@administracao/ATUALIZAR_PERFIL",
  NOVA_PERFIL: "@@administracao/NOVA_PERFIL",
  EDITAR_PERFIL: "@@administracao/EDITAR_PERFIL",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_PERFIL: "@@administracao/INCLUIR_PERFIL",
  SEARCH_PERFIL: "@@administracao/SEARCH_PERFIL",
  EXCLUIR_PERFIL: "@@administracao/EXCLUIR_PERFIL",
};

export const actions = {
  listarPerfis: createAsyncAction(types.LISTAR_PERFIS_DOMINIO),
  atualizarPerfil: createAsyncAction(types.ATUALIZAR_PERFIL),
  novaPerfil: () => createSyncAction(types.NOVA_PERFIL),
  editarPerfil: (values) => createSyncAction(types.EDITAR_PERFIL, values),
  voltarListaPerfil: () => createSyncAction(types.VOLTAR_LISTA),
  incluirPerfil: createAsyncAction(types.INCLUIR_PERFIL),
  buscarPerfil: createAsyncAction(types.SEARCH_PERFIL),
  excluirPerfil: createAsyncAction(types.EXCLUIR_PERFIL),
};
