import { authenticatedRequest } from "../utils/api";
import basicFlow from "./asyncHandler";
import { actions } from "../reducers/emails/emails.actions";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { put, select } from "redux-saga/effects";
import { getEmailParams } from "../selectors/emails.selectors";
import { toast } from "react-toastify";

function* envioEmailsRouteWatcher() {
  yield routeWatcher(routes.ENVIO_EMAIL, function* ({ payload }) {
    yield put(actions.buscarTemplatesAtividade.request(payload));
  });
}

const buscarTemplatesAtividade = basicFlow({
  actionGenerator: actions.buscarTemplatesAtividade,
  api: ({ atividade }) => {
    return authenticatedRequest({
      url: `/envio-emails/templates/${atividade}`,
      isResourceService: true,
      method: "get",
    });
  },
});

const processarTemplate = basicFlow({
  actionGenerator: actions.processarTemplate,
  transform: function* ({ template }) {
    const { proposta, atividade } = yield select(getEmailParams);
    return {
      template,
      consulta: proposta,
      atividade: atividade,
    };
  },
  api: (body) => {
    return authenticatedRequest({
      url: `/envio-emails/processar-template`,
      isResourceService: true,
      method: "post",
      body,
    });
  },
  postSuccess: ({ response, original }) => {
    if (!!original.callback) {
      original.callback(response.data);
    }
  },
});

const enviarEmail = basicFlow({
  actionGenerator: actions.enviarEmail,
  transform: function* (payload) {
    const { proposta, atividade } = yield select(getEmailParams);
    const { assunto, conteudo, idConsultaEmailReferencia } = payload;
    return {
      assunto,
      conteudo,
      idConsultaEmailReferencia,
      consulta: proposta,
      atividade,
      destinatarios: payload.destinatarios.map((d) => d.value),
    };
  },
  api: (body) => {
    return authenticatedRequest({
      url: `/envio-emails/enviar`,
      isResourceService: true,
      method: "post",
      body,
    });
  },
  postSuccess: function* ({ values, original }) {
    const { consulta, destinatarios } = values;
    const { callback } = original;
    toast.success(
      destinatarios.length > 1
        ? "E-mails enviados com sucesso"
        : "E-mail enviado com sucesso"
    );
    yield put(
      routeActions.redirectTo(routes.PROPOSTA, {
        id: consulta,
      })
    );
    if (callback) {
      callback();
    }
  },
});

export const sagas = [
  buscarTemplatesAtividade.watcher(),
  processarTemplate.watcher(),
  enviarEmail.watcher(),
  envioEmailsRouteWatcher(),
];
