import React, { useState } from "react";
import CardDadosProposta from "./CardDadosProposta";
import MenuProposta from "./MenuProposta";
import BasePage from "../../BasePage";
import ListaDocumentos from "./documentos/ListaDocumentos.connected";
import ParticipantesPage from "./participantes/ParticipantesPage";
import ImoveisBlock from "./imoveis/ImoveisBlock.connected";
import CardObservacao from "./CardObservacao";
import ChevronList from "./ChevronList";
import ListaAtividades from "./atividades/ListaAtividades.connected";
import HistoricoPage from "./historico/HistoricoPage.connected";
import { Autorenew, InfoOutlined } from "@material-ui/icons";
import { VisibleWithoutProfile, VisibleWithProfile } from "../../../components";
import TrocarSituacaoConsulta from "../situacaoConsulta/TrocarSituacaoConsulta.connected";
import TrocarPrioridadeConsulta from "../prioridadeConsulta/TrocarPrioridadeConsulta.connected";
import { Popover } from "@material-ui/core";

const PropostaPage = ({
  detalheProposta,
  itens,
  menuSelecionado,
  loading,
  error,
  selecionarOpcaoMenu,
  abrirDetalhePessoa,
  novoCadastroPf,
  excluirParticipante,
  disabled,
  outros,
  formProps,
  handleSubmit,
  submitting,
  workflow,
  perfil,
  alterarSituacao,
  handleCloseAlterarSituacao,
  handleOpenAlterarSituacao,
  alterarPrioridade,
  handleCloseAlterarPrioridade,
  handleOpenAlterarPrioridade,
  handleOpcaoCanceladoSelected,
  showMotivoCancelamento,
  codigoProduto,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <BasePage isLoggedIn={true}>
        {!loading && !error && (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div>
              <span className="page-title">#{detalheProposta.codigo}</span>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <span className="page-title">
                  {situacaoDecode(detalheProposta.situacao)}
                </span>
                {!!detalheProposta?.MotivoCancelamento?.descricao && (
                  <VisibleWithProfile
                    profiles={[
                      "BPO",
                      "AGENCIA",
                      "BANCO GESTAO",
                      "PARCEIRO",
                      "ADMINISTRADOR",
                      "BANCO,",
                    ]}
                  >
                    <InfoOutlined
                      onClick={handleClick}
                      className={"change-analyst-icon"}
                    />
                    <Popover
                      id={detalheProposta.motivoCancelamento}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div className="p-2 bg-dark color-white orientacao">
                        {detalheProposta?.MotivoCancelamento?.descricao}
                      </div>
                    </Popover>
                  </VisibleWithProfile>
                )}
                <VisibleWithProfile profiles={["BPO", "BANCO"]}>
                  <Autorenew
                    className={"change-analyst-icon"}
                    onClick={() =>
                      handleOpenAlterarSituacao({
                        situacao: detalheProposta.situacao,
                        consultaID: detalheProposta.id,
                      })
                    }
                  />
                </VisibleWithProfile>
              </div>
            </div>
          </div>
        )}
        {!loading && !error && (
          <div className="row spacer">
            <div className="col-12">
              <MenuProposta
                itens={itens}
                menuSelecionado={menuSelecionado}
                selecionarOpcaoMenu={selecionarOpcaoMenu}
                proposta={!!detalheProposta ? detalheProposta.id : null}
                perfil={perfil}
                codigoProduto={codigoProduto}
              />
            </div>
          </div>
        )}
        {menuSelecionado === 0 && (
          <>
            <div className="row spacer">
              <div className="col-12">
                <CardDadosProposta
                  data={detalheProposta}
                  error={error}
                  loading={loading}
                  handleCloseAlterarPrioridade={handleCloseAlterarPrioridade}
                  handleOpenAlterarPrioridade={handleOpenAlterarPrioridade}
                  codigoProduto={codigoProduto}
                />
              </div>
            </div>
            <div className="row spacer">
              <div className="col-12">
                {!workflow?.loading && !!workflow?.timeline && (
                  <ChevronList timeline={workflow.timeline} />
                )}
              </div>
            </div>
            <VisibleWithoutProfile profiles={["CLIENTE"]}>
              <div className="row spacer">
                <div className="col-12">
                  <CardObservacao
                    formProps={formProps}
                    error={error}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    disabled={disabled}
                    submitting={submitting}
                    perfil={perfil}
                    data={detalheProposta?.observacoes}
                  />
                </div>
              </div>
            </VisibleWithoutProfile>
          </>
        )}
        {menuSelecionado === 1 && (
          <div className="row spacer">
            <div className="col-12">
              <ParticipantesPage
                proposta={detalheProposta?.id}
                buyers={detalheProposta?.cliente}
                socios={detalheProposta?.socio}
                outros={outros}
                error={false}
                loading={loading}
                perfil={perfil}
                abrirDetalhePessoa={abrirDetalhePessoa}
                novoCadastroPf={novoCadastroPf}
                excluirParticipante={excluirParticipante}
                disabled={disabled}
                detalheProposta={detalheProposta}
              />
            </div>
          </div>
        )}
        {menuSelecionado === 2 && (
          <div className="row">
            <div className="col-12">
              <ImoveisBlock />
            </div>
          </div>
        )}
        {menuSelecionado === 3 && (
          <div className="row spacer">
            <div className="col-12">
              <ListaDocumentos />
            </div>
          </div>
        )}
        {menuSelecionado === 4 && (
          <div className="row spacer">
            <div className="col-12">
              <ListaAtividades />
            </div>
          </div>
        )}
        {menuSelecionado === 5 && (
          <div className="row spacer">
            <div className="col-12">
              <HistoricoPage />
            </div>
          </div>
        )}
        <TrocarSituacaoConsulta
          alterarSituacao={alterarSituacao}
          handleCloseAlterarSituacao={handleCloseAlterarSituacao}
          handleOpenAlterarSituacao={handleOpenAlterarSituacao}
          showMotivoCancelamento={showMotivoCancelamento}
          handleOpcaoCanceladoSelected={handleOpcaoCanceladoSelected}
        />
        <TrocarPrioridadeConsulta
          alterarPrioridade={alterarPrioridade}
          handleCloseAlterarPrioridade={handleCloseAlterarPrioridade}
          handleOpenAlterarPrioridade={handleOpenAlterarPrioridade}
        />
      </BasePage>
    </>
  );
};

const situacaoDecode = (situacao) => {
  switch (situacao) {
    case "R":
      return "Em Prospecção";
    case "A":
      return "Em Andamento";
    case "C":
      return "Cancelado";
    case "E":
      return "Ativo";
    case "P":
      return "Suspenso";
    default:
      return "";
  }
};

export default PropostaPage;
