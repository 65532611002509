import React, { useCallback, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Loader from "../../components/loaders";
import { Button } from "../../components";
import TrocarAnalistaDialog from "./analista/TrocarAnalistaDialog.connected";
import {
  divisao_administrativa,
  area_responsavel_kit,
  tipoConstituicao,
  situacaoCNPJ,
} from "../../utils/parametros";
import formatters from "../../utils/formatters";

const DetalheProposta = ({ data, loading, abrirCockpit, codigoProduto }) => {
  const [dadosTrocarAnalista, setDadosTrocarAnalista] = useState({
    open: false,
  });
  const handleCloseTrocarAnalista = useCallback(() => {
    setDadosTrocarAnalista({
      open: false,
    });
  }, []);
  // const handleOpenTrocarAnalista = useCallback(() => {
  //   setDadosTrocarAnalista({
  //     open: true,
  //     analista: data.usuarioBackoffice?.id,
  //     consulta: data.id,
  //   });
  // }, [data]);

  if (!!loading) {
    return (
      <div className="col-12 d-flex justify-content-center">
        <Loader type={"dots"} color={"brand"} size={50} />
      </div>
    );
  }
  if (codigoProduto === "2") {
    return (
      <>
        <div className="col-12 mt-0">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xs-12">
              <span className="label">Nome do Corban</span>
              <div>
                <span className="data-label">
                  {data?.cliente ? data?.cliente[0]?.nome : null}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Produto</span>
              <div>
                <span className="data-label">{data?.Produto?.nome}</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Situação do CNPJ</span>
              <div>
                <span className="data-label">
                  {
                    situacaoCNPJ.find(
                      (item) =>
                        item.tipo ===
                        data?.cliente[0]?.PessoaDadoEmpresa?.situacaoCNPJ
                    )?.descricao
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Divider />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Responsável Corban</span>
              <div>
                <span className="data-label">
                  {data.cliente[0]?.PessoaContato?.nome}
                </span>
              </div>
            </div>
            <div className="col-lg-3  col-md-6 col-xs-12">
              <span className="label">Faturamento Mensal</span>
              <div>
                <span className="data-label">
                  R${" "}
                  {!!data?.cliente[0]?.PessoaDadoEmpresa?.valorFaturamento
                    ? formatters.numbers.currency(
                        data?.cliente[0]?.PessoaDadoEmpresa?.valorFaturamento
                      )
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Forma Constituição</span>
              <div>
                <span className="data-label">
                  {tipoConstituicao.find(
                    (item) =>
                      item.codigo ===
                      data?.cliente[0]?.PessoaDadoEmpresa?.tipoConstituicao
                  )?.descricao ?? ""}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Data da Constituição</span>
              <div>
                <span className="data-label">
                  {!!data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao
                    ? `${
                        data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao.split(
                          "-"
                        )[2]
                      }/${
                        data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao.split(
                          "-"
                        )[1]
                      }/${
                        data?.cliente[0]?.PessoaDadoEmpresa?.dataConstituicao.split(
                          "-"
                        )[0]
                      }`
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Divider />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mt-3">
              <Button
                label={"COCKPIT DO CORBAN"}
                onClick={() => abrirCockpit(data.id)}
              />
            </div>
          </div>
        </div>

        <TrocarAnalistaDialog
          dados={dadosTrocarAnalista}
          handleClose={handleCloseTrocarAnalista}
        />
      </>
    );
  } else {
    return (
      <>
        <div className="col-12 mt-0">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xs-12">
              <span className="label">Nome do Convênio</span>
              <div>
                <span className="data-label">
                  {data?.cliente ? data?.cliente[0]?.nome : null}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Produto</span>
              <div>
                <span className="data-label">{data?.Produto?.nome}</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Data Início</span>
              <div>
                <span className="data-label">
                  {data?.dataConsulta
                    ? `${data?.dataConsulta?.split("-")[2]}/${
                        data?.dataConsulta?.split("-")[1]
                      }/${data?.dataConsulta?.split("-")[0]}`
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Divider />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Divisão Administrativa</span>
              <div>
                <span className="data-label">
                  {divisao_administrativa.find(
                    (item) =>
                      item.codigo ===
                      data?.ConsultaConvenio?.tipoDivisaoAdministrativa
                  )?.descricao ?? ""}
                </span>
              </div>
            </div>
            <div className="col-lg-3  col-md-6 col-xs-12">
              <span className="label">Tipo de Convênio</span>
              <div>
                <span className="data-label">
                  {data?.ConsultaConvenio?.TipoConvenio?.nome}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Área Responsável</span>
              <div>
                <span className="data-label">
                  {area_responsavel_kit.find(
                    (item) =>
                      item.codigo ===
                      data?.ConsultaConvenio?.tipoAreaKitCredenciamento
                  )?.descricao ?? ""}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <span className="label">Qtde. Funcionários</span>
              <div>
                <span className="data-label">
                  {data?.ConsultaConvenio?.quantidadeFuncionarios}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Divider />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mt-3">
              <Button
                label={"COCKPIT DO CONVÊNIO"}
                onClick={() => abrirCockpit(data.id)}
              />
            </div>
          </div>
        </div>

        <TrocarAnalistaDialog
          dados={dadosTrocarAnalista}
          handleClose={handleCloseTrocarAnalista}
        />
      </>
    );
  }
};

export default DetalheProposta;
