import BasePage from "../../BasePage";
import Loader from "../../../components/loaders";
import React, { useCallback, useState } from "react";
import { Add, SearchOutlined } from "@material-ui/icons";
import TipoDeConvenio from "./TipoDeConvenio";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { ControlledTextfield } from "../../../components/inputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import FormTipoDeConvenio from "./FormTipoDeConvenio.connected";

const TiposDeConveniosPage = ({
  loading,
  tiposDeConvenios,
  tipoDeConvenio,
  total,
  pagina,
  handlePagination,
  handleNew,
  handleEdit,
  formProps,
  handleSearch,
  handleDelete,
}) => {
  const [
    dadosExclusaoTipoDeConvenio,
    setDadosExclusaoTipoDeConvenio,
  ] = useState({ open: false });

  const handleCloseExclusaoTipoDeConvenio = useCallback(() => {
    setDadosExclusaoTipoDeConvenio({
      open: false,
    });
  }, []);

  const handleOpenExclusaoTipoDeConvenio = useCallback((values) => {
    setDadosExclusaoTipoDeConvenio({
      open: true,
      tipoDeConvenio: values,
    });
  }, []);

  const handleClick = useCallback(
    (values) => {
      handleSearch(values);
    },
    [handleSearch]
  );

  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row spacer">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={80} />
          </div>
        </div>
      )}
      {!loading && !tipoDeConvenio && (
        <div className="row spacer">
          <div className="col-12">
            <div className="card" style={{ minHeight: 100 }}>
              <div className="card-body d-flex flex-column">
                <div className={"row d-flex align-items-center"}>
                  <div className={"col mt-0"}>
                    <div className="card-title color-brand">
                      Tipos de Convênios
                    </div>
                  </div>
                  <div className="d-none d-md-block mb-4">
                    <form onSubmit={formProps.handleSubmit(handleClick)}>
                      <ControlledTextfield
                        variant={"standard"}
                        search={true}
                        name={"search"}
                        formProps={formProps}
                        uppercase={false}
                        label={"Pesquisar"}
                        margin={"none"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="search"
                                edge="end"
                                type={"submit"}
                              >
                                <SearchOutlined />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </div>
                </div>
                <div className={"row spacer"}>
                  <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 color-brand">
                    <span>Tipo</span>
                  </div>
                  <div className="col-12 col-md-9 my-2 my-lg-0 color-brand">
                    <span>Nome</span>
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-12">
                    {tiposDeConvenios.map((d, index) => (
                      <TipoDeConvenio
                        key={d.id}
                        tipoDeConvenio={d}
                        index={index}
                        handleEdit={handleEdit}
                        handleOpenExclusaoTipoDeConvenio={
                          handleOpenExclusaoTipoDeConvenio
                        }
                      />
                    ))}
                  </div>
                </div>
                <div className="row spacer">
                  <div className="col-12 offset-md-9 col-md-3">
                    <div className="d-none d-md-block">
                      <div className="botao-adicionar mr-3" onClick={handleNew}>
                        <Add className="botao-adicionar-icon mr-1" />
                        <span className="botao-adicionar-text">ADICIONAR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && !tipoDeConvenio && total > 10 && (
        <>
          <div className="row spacer">
            <div className="col-12">
              <Pagination
                count={Math.ceil(total / 10)}
                page={pagina}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </div>
          </div>
        </>
      )}
      {!loading && !!tipoDeConvenio && (
        <div>
          <FormTipoDeConvenio />
        </div>
      )}
      <ConfirmarExclusaoDialog
        open={dadosExclusaoTipoDeConvenio.open}
        handleClose={handleCloseExclusaoTipoDeConvenio}
        excluirTipoDeConvenio={handleDelete}
        tipoDeConvenio={dadosExclusaoTipoDeConvenio.tipoDeConvenio}
      />
    </BasePage>
  );
};
export default TiposDeConveniosPage;
