import MatrizChecklistPage from "./MatrizChecklistPage";
import { useDispatch, useSelector } from "react-redux";
import { getMatrizChecklist } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/matrizChecklist.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import {
  getProdutos,
  getDocumentos,
} from "../../../selectors/dominios.selectors";

const MatrizChecklistPageConnected = () => {
  const dispatch = useDispatch();
  const matriz = useSelector(getMatrizChecklist);
  const loading = matriz?.loading;
  const total = matriz?.total;
  const pagina = matriz?.page;
  const registrosPagina = matriz["registros-pagina"];
  const filter = matriz.filter;
  const produtos = useSelector(getProdutos);
  const documentos = useSelector(getDocumentos);

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(actions.listarMatriz.request({ pagina: page, filter }));
    },
    [dispatch, filter]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarCondicaoChecklist({ condicao: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaCondicaoChecklist());
  }, [dispatch]);

  const rules = {
    documento: validators.string({ required: false }),
    produto: validators.string({ required: false }),
    participante: validators.string({ required: false }),
    qualificacao: validators.string({ required: false }),
  };
  const initialValues = {
    documento: filter?.documento,
    produto: filter?.produto,
    participante: filter?.participante,
    qualificacao: filter?.qualificacao,
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const excluirCondicao = useCallback(
    (values) => {
      dispatch(actions.excluirCondicaoChecklist.request({ condicao: values }));
    },
    [dispatch]
  );

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "codigo",
        value: values.search,
      };
      dispatch(actions.buscarMatriz.request({ search: request, pagina: 1 }));
    },
    [dispatch]
  );

  const handleFilterClick = useCallback(
    (value) => {
      dispatch(actions.alterarFiltro(value));
      dispatch(
        actions.listarMatriz.request({
          pagina: 1,
          "registros-pagina": registrosPagina,
          filter: value,
        })
      );
    },
    // eslint-disable-next-line
    [dispatch]
  );

  return (
    <MatrizChecklistPage
      matriz={matriz.list}
      condicao={matriz.condicao}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      registrosPagina={registrosPagina}
      excluirCondicao={excluirCondicao}
      filtro={filter}
      handleFilterClick={handleFilterClick}
      produtos={produtos}
      documentos={documentos.data}
    />
  );
};

export default MatrizChecklistPageConnected;
