import React from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  CurrencyTextFieldLeadingZeros,
  DateTextField,
  IntegerTextField,
  NumberTextField,
  PercentTextField,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";
import { meio_envio } from "../../../../../utils/parametros";

const CardInformacoesAdicionais = ({ props, disabled }) => {
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Informações Adicionais</span>
        <div className="row">
          <div className="col-12 col-md-6">
            <ControlledSelect
              name={"tipoEnvioKitCredenciamento"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Meio de Envio do Kit de Credenciamento"}
            >
              {meio_envio.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  value={item.codigo}
                  key={item.codigo}
                >
                  {item.descricao}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              name={"nomeGerente"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Gerente Comercial ou Regional"}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataCorteFolha"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Corte da Folha"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"funcao"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Código Função"}
              InputProps={{
                inputComponent: IntegerTextField,
              }}
              inputProps={{ maxLength: 10, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              name={"protocolo"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Protocolo"}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 15, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              name={"logoOrbitall"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Número do Logo Orbitall"}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 15, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              name={"logoAutorizador"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Número do Logo Autorizador"}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 15, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataAprovacaoOrbitall"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data Formulário Orbitall"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataAprovacaoIntelcav"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data Formulário Intelcav"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataAprovacaoInterog"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data Formulário Interog"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataCadastroEmpregador"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Cadastro do Empregador"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataLiberacaoEmpregador"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Liberação do Empregador"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataParametrizacaoAutorizador"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Parametrização - Autorizador"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataParametrizacaoApp"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Parametrização - App"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataParametrizacaoSite"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Parametrização - Site"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataParametrizacaoArquivoLogoD1"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Parametriz. - Arq. Logo D1"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"valorLimiteAprovado"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Limite Aprovado"}
              InputProps={{ inputComponent: CurrencyTextFieldLeadingZeros }}
              inputProps={{ fixedDecimalScale: false }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"prazoOperacao"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Prazo Operação"}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 5 }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"taxaOperacao"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Taxa"}
              InputProps={{ inputComponent: PercentTextField }}
              inputProps={{ maxLength: 7, fixedDecimalScale: false }}
            />
          </div>
          <div className="col-12 col-md-12">
            <ControlledTextfield
              name={"publicoAlvo"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Público Alvo"}
              inputProps={{ maxLength: 200 }}
            />
          </div>
          {/*<div className="col-12 col-md-4">*/}
          {/*  <ControlledTextfield*/}
          {/*    name={"cep"}*/}
          {/*    margin={"none"}*/}
          {/*    disabled={disabled}*/}
          {/*    formProps={props}*/}
          {/*    label={"Margem Utilizável"}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="col-12 col-md-12">
            <ControlledTextfield
              name={"politicaIdadeValor"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Política de Idade x Valor"}
              inputProps={{ maxLength: 200 }}
            />
          </div>
          <div className="col-12 col-md-12">
            <ControlledTextfield
              name={"classificacaoRiscos"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Classificação de Riscos"}
              inputProps={{ maxLength: 200 }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"dataVencimentoLimite"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Data de Vencimento Limite"}
              InputProps={{ inputComponent: DateTextField }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"valorRating"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Rating"}
              InputProps={{ inputComponent: NumberTextField }}
              inputProps={{
                maxLength: 5,
                fixedDecimalScale: false,
                allowEmptyFormatting: true,
              }}
            />
          </div>

          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"margemUtilizavel"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Margem Utilizável"}
              InputProps={{ inputComponent: PercentTextField }}
              inputProps={{ maxLength: 8, fixedDecimalScale: false }}
            />
          </div>
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"margemCartao"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Margem Cartão"}
              InputProps={{ inputComponent: PercentTextField }}
              inputProps={{ maxLength: 8, fixedDecimalScale: false }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"valorLimitePotencialSaque"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Limite Potencial de Saque"}
              InputProps={{ inputComponent: CurrencyTextFieldLeadingZeros }}
              inputProps={{ fixedDecimalScale: false }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"valorLimitePotencialCompra"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Limite Potencial de Compra"}
              InputProps={{ inputComponent: CurrencyTextFieldLeadingZeros }}
              inputProps={{ fixedDecimalScale: false }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"codigoContaCorrente"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Número de Conta Corrente"}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 10, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              name={"representante"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Representante do Órgão"}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              name={"cargoRepresentante"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Cargo do Representante"}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        </div>
        <div className={"row"}>
          <div className="col-12">
            <ControlledTextfield
              name={"observacao"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"Observação"}
              multiline={true}
              rowsMax={8}
              rows={6}
              inputProps={{ maxLength: 2000 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInformacoesAdicionais;
