import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_TIPOS_DE_CONVENIOS: "@@administracao/LISTAR_TIPOS_DE_CONVENIOS",
  ATUALIZAR_TIPO_DE_CONVENIO: "@@administracao/ATUALIZAR_TIPO_DE_CONVENIO",
  NOVO_TIPO_DE_CONVENIO: "@@administracao/NOVO_TIPO_DE_CONVENIO",
  EDITAR_TIPO_DE_CONVENIO: "@@administracao/EDITAR_TIPO_DE_CONVENIO",
  VOLTAR_LISTA: "@@administracao/VOLTAR_LISTA",
  INCLUIR_TIPO_DE_CONVENIO: "@@administracao/INCLUIR_TIPO_DE_CONVENIO",
  SEARCH_TIPO_DE_CONENIO: "@@administracao/SEARCH_TIPO_DE_CONVENIO",
  EXCLUIR_TIPO_DE_CONVENIO: "@@administracao/EXCLUIR_TIPO_DE_CONVENIO",
};

export const actions = {
  listarTiposDeConvenio: createAsyncAction(types.LISTAR_TIPOS_DE_CONVENIOS),
  atualizarTipoDeConvenio: createAsyncAction(types.ATUALIZAR_TIPO_DE_CONVENIO),
  novoTipoDeConvenio: () => createSyncAction(types.NOVO_TIPO_DE_CONVENIO),
  editarTipoDeConvenio: (values) =>
    createSyncAction(types.EDITAR_TIPO_DE_CONVENIO, values),
  voltarListaTipoDeConvenio: () => createSyncAction(types.VOLTAR_LISTA),
  incluirTipoDeConvenio: createAsyncAction(types.INCLUIR_TIPO_DE_CONVENIO),
  buscarTipoDeConvenio: createAsyncAction(types.SEARCH_TIPO_DE_CONENIO),
  excluirTipoDeConvenio: createAsyncAction(types.EXCLUIR_TIPO_DE_CONVENIO),
};
