import React from "react";
import BasePage from "../../../BasePage";
import Loader from "../../../../components/loaders";
import FormPessoaJuridica from "./FormPessoaJuridica";

const FormParticipante = ({ loading, ...props }) => {
  return (
    <BasePage isLoggedIn={true}>
      {(!!loading || !!props.empresasCorrespondentes.loading) && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {(!loading && !props.empresasCorrespondentes.loading)  && (
        <>
          <span className="page-title">Detalhes do Corban</span>
          <FormPessoaJuridica {...props} />
        </>
      )}
    </BasePage>
  );
};

export default FormParticipante;
