import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";

const DetalhesProximaAtividadeDialog = ({
  handleClose,
  detalhes,
  open,
  atividade,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Pré-Requisitos para Abertura</div>
        <div className={"mb-3"}>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <span className="label">Nome</span>
              <div className="mb-2">
                <span className="data-label mb-4">{atividade}</span>
              </div>
              <span className="label">Atividades Dependentes</span>
              {detalhes &&
                detalhes.map((item) => {
                  return (
                    <div className="mb-2" key={item.atividade + item.mensagem}>
                      <span className="data-label">{`Regra ${item.regra} - ${item.atividade} (${item.mensagem})`}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DetalhesProximaAtividadeDialog;
