import React from "react";
import NumberFormat from "react-number-format";

const PercentTextField = ({
  inputRef,
  decimalScale = 2,
  onChange,
  fixedDecimalScale = true,
  ...other
}) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values.value === "") {
          onChange(values.value);
        } else {
          onChange(values.floatValue);
        }
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      suffix=" %"
    />
  );
};

export default PercentTextField;
