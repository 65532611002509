import React from "react";
import { Tooltip } from "@material-ui/core";
import {
  responsavelAtividade,
  situacaoAtividade,
} from "../../../../utils/parametros";

const Atividade = ({ atividade, index }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip title={atividade.etapa}>
          <span>{atividade.etapa}</span>
        </Tooltip>
      </div>
      <div className="col-sm-12 col-lg-4 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip
          title={`(${
            responsavelAtividade.find(
              (item) => item.id === atividade.responsavel
            ).nome
          }) ${atividade.atividade}`}
        >
          <span>{`(${
            responsavelAtividade.find(
              (item) => item.id === atividade.responsavel
            ).nome
          }) ${atividade.atividade}`}</span>
        </Tooltip>
      </div>
      {/*<div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 resumir-texto">*/}
      {/*  <Tooltip*/}
      {/*    title={*/}
      {/*      responsavelAtividade.filter(*/}
      {/*        (s) => s.id === atividade.responsavel*/}
      {/*      )[0].nome*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <span>*/}
      {/*      {*/}
      {/*        responsavelAtividade.filter(*/}
      {/*          (s) => s.id === atividade.responsavel*/}
      {/*        )[0].nome*/}
      {/*      }*/}
      {/*    </span>*/}
      {/*  </Tooltip>*/}
      {/*</div>*/}
      <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip
          title={
            situacaoAtividade.find((item) => item.id === atividade.situacao)
              ?.nome
          }
        >
          <span>
            {
              situacaoAtividade.find((item) => item.id === atividade.situacao)
                ?.nome
            }
          </span>
        </Tooltip>
      </div>
      <div className={"col-sm-12 col-lg-2 mt-sm-2 mt-lg-0"}>
        <span>
          {new Date(atividade.dataHoraSituacao).toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>
      {/*<div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 resumir-texto">*/}
      {/*  <Tooltip title={atividade.sla_acordado}>*/}
      {/*    <span>{atividade.sla_acordado}</span>*/}
      {/*  </Tooltip>*/}
      {/*</div>*/}
      {/*<div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 resumir-texto">*/}
      {/*  <Tooltip title={atividade.sla_realizado}>*/}
      {/*    <span>{atividade.sla_realizado}</span>*/}
      {/*  </Tooltip>*/}
      {/*</div>*/}
      <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip
          title={`${atividade.sla_acordado ?? 0}/${
            atividade.sla_realizado ?? 0
          }`}
        >
          <div className="row d-flex align-items-center justify-content-center">
            <span>{`${atividade.sla_acordado ?? 0}/${
              atividade.sla_realizado ?? 0
            }`}</span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Atividade;
