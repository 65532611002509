import React from "react";
import Loader from "../../../components/loaders";
import { ControlledSelect } from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";
import BasePage from "../../BasePage";

const escolhaProduto = ({
                          loading,
                          formProps,
                          handleSubmit,
                          disabled,
                          submitting,
                          usuario,
                          handleGoBack
                        }) => (
  <BasePage isLoggedIn={true}>
    <div className="card" style={{ minHeight: 200 }}>
    <div className="card-body d-flex flex-column">
      {!!loading && (
        <div className="flex d-flex justify-content-center align-items-center">
          <Loader type={"dots"} size={80} />
        </div>
      )}
      {!loading && (
        <div className="col-12 mt-0">
          <form onSubmit={formProps.handleSubmit(handleSubmit)}>
            <span className="card-title color-brand">Selecione o produto</span>
            <div className="row">
              <div className="col-12">
                <ControlledSelect
                  name={"produto"}
                  formProps={formProps}
                  disabled={disabled}
                  label={"Produtos"}
                  margin={"none"}
                >
                  {usuario.produtos.map((item) => (
                    <MenuItem
                      className={"select-submenu-item"}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nome}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </div>
            </div>
            <div className={"row spacer justify-content-center mb-3"}>
              <div className="col-6 col-md-3">
                <Button
                  loading={submitting}
                  label={"CANCELAR"}
                  onClick={() => handleGoBack()}
                />
              </div>
              <div className="col-6 col-md-3">
                <Button
                  loading={submitting}
                  label={"CONFIRMAR"}
                  type={"submit"}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  </div>
  </BasePage>
);

export default escolhaProduto;
