import { createSelector } from "reselect";

const getDashboard = (state) => state.dashboard;

export const getProducaoDia = createSelector(
  getDashboard,
  (state) => state["producao-dia"]
);

export const getProducaoMes = createSelector(
  getDashboard,
  (state) => state["producao-mes"]
);

export const getProjecaoMes = createSelector(
  getDashboard,
  (state) => state["projecao-mes"]
);

export const getConsultasValor = createSelector(
  getDashboard,
  (state) => state["consultas-valor"]
);

export const getConsultasPorData = createSelector(
  getDashboard,
  (state) => state["consultas-por-data"]
);
