import { actions, types } from "./matrizChecklist.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  "registros-pagina": 10,
  condicao: null,
  search: "",
  filter: {
    documento: "",
    produto: "",
    qualificacao: "",
    participante: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarMatriz.REQUEST:
    case actions.listarMatriz.SUCCESS:
    case actions.listarMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarMatriz.REQUEST,
        error:
          action.type === actions.listarMatriz.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarMatriz.SUCCESS
            ? action.payload.response.data.matriz
            : [],
        total:
          action.type === actions.listarMatriz.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarMatriz.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
        filter:
          action.type === actions.listarMatriz.REQUEST
            ? action.payload.filter
            : state.filter,
      };
    case actions.atualizarMatriz.REQUEST:
    case actions.atualizarMatriz.SUCCESS:
    case actions.atualizarMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarMatriz.REQUEST,
        condicao:
          action.type === actions.atualizarMatriz.FAILURE
            ? {
                ...action.payload.original.condicao,
                Documento: { id: action.payload.original.condicao.documento },
                Produto: { id: action.payload.original.condicao.produto },
                Localidade: { id: action.payload.original.condicao.localidade },
                Organizacao: { id: action.payload.original.condicao.cartorio },
              }
            : null,
        list:
          action.type === actions.atualizarMatriz.SUCCESS
            ? action.payload.response.data.matriz
            : state.list,
        total:
          action.type === actions.atualizarMatriz.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.atualizarMatriz.SUCCESS
            ? action.payload.response.data.paginaAtual
            : state.page,
      };
    case types.NOVA_CONDICAO_CHECKLIST:
      return {
        ...state,
        condicao: {
          Documento: "",
          qualificacao: "",
          Produto: "",
          participante: "",
          condicao: "",
          Localidade: {
            id: "",
            nome: "",
          },
          Organizacao: {
            id: "",
            nome: "",
          },
          uf: "",
        },
      };
    case types.EDITAR_CONDICAO_CHECKLIST:
      return {
        ...state,
        condicao: {
          ...action.payload.condicao,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        condicao: null,
      };
    case actions.incluirCondicaoChecklist.REQUEST:
    case actions.incluirCondicaoChecklist.SUCCESS:
    case actions.incluirCondicaoChecklist.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirCondicaoChecklist.REQUEST,
        condicao:
          action.type === actions.incluirCondicaoChecklist.FAILURE
            ? {
                ...action.payload.original.condicao,
                Documento: { id: action.payload.original.condicao.documento },
                Produto: { id: action.payload.original.condicao.produto },
                Localidade: { id: action.payload.original.condicao.localidade },
                Organizacao: { id: action.payload.original.condicao.cartorio },
              }
            : null,
        list:
          action.type === actions.incluirCondicaoChecklist.SUCCESS
            ? action.payload.response.data.matriz
            : state.list,
        total:
          action.type === actions.atualizarMatriz.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.atualizarMatriz.SUCCESS
            ? action.payload.response.data.paginaAtual
            : state.page,
      };
    case actions.excluirCondicaoChecklist.REQUEST:
    case actions.excluirCondicaoChecklist.SUCCESS:
    case actions.excluirCondicaoChecklist.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirCondicaoChecklist.REQUEST,
        list:
          action.type === actions.excluirCondicaoChecklist.SUCCESS
            ? state.list.filter((u) => {
                return u.id !== action.payload.original.condicao.id;
              })
            : state.list,
        total:
          action.type === actions.excluirCondicaoChecklist.SUCCESS
            ? state.total - 1
            : state.total,
      };
    case actions.buscarMatriz.REQUEST:
    case actions.buscarMatriz.SUCCESS:
    case actions.buscarMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarMatriz.REQUEST,
        error:
          action.type === actions.buscarMatriz.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarMatriz.SUCCESS
            ? action.payload.response.data.usuarios
            : [],
        total:
          action.type === actions.buscarMatriz.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarMatriz.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarMatriz.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case types.ALTERAR_FILTRO:
      return {
        ...state,
        filter: action.payload.valores,
      };
    default:
      return state;
  }
};

export default reducer;
