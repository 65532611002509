// eslint-disable-next-line
export default {
  // Generic
  "generic.add": "Confirmar",
  "generic.cancel": "Cancelar",

  // BlockType
  "components.controls.blocktype.h1": "H1",
  "components.controls.blocktype.h2": "H2",
  "components.controls.blocktype.h3": "H3",
  "components.controls.blocktype.h4": "H4",
  "components.controls.blocktype.h5": "H5",
  "components.controls.blocktype.h6": "H6",
  "components.controls.blocktype.blockquote": "Citação",
  "components.controls.blocktype.code": "Código",
  "components.controls.blocktype.blocktype": "Block Type",
  "components.controls.blocktype.normal": "Normal",

  // Color Picker
  "components.controls.colorpicker.colorpicker": "Seletor de Cores",
  "components.controls.colorpicker.text": "Texto",
  "components.controls.colorpicker.background": "Fundo",

  // Embedded
  "components.controls.embedded.embedded": "Embedded",
  "components.controls.embedded.embeddedlink": "Embedded Link",
  "components.controls.embedded.enterlink": "Enter link",

  // Emoji
  "components.controls.emoji.emoji": "Emoji",

  // FontFamily
  "components.controls.fontfamily.fontfamily": "Fonte",

  // FontSize
  "components.controls.fontsize.fontsize": "Tamanho",

  // History
  "components.controls.history.history": "Histórico",
  "components.controls.history.undo": "Desfazer",
  "components.controls.history.redo": "Refazer",

  // Image
  "components.controls.image.image": "Imagem",
  "components.controls.image.fileUpload": "Upload",
  "components.controls.image.byURL": "URL",
  "components.controls.image.dropFileText": "Clique para fazer o upload",

  // Inline
  "components.controls.inline.bold": "Negrito",
  "components.controls.inline.italic": "Itálico",
  "components.controls.inline.underline": "Sublinhado",
  "components.controls.inline.strikethrough": "Tachado",
  "components.controls.inline.monospace": "Mono-espaçado",
  "components.controls.inline.superscript": "Sobrescrito",
  "components.controls.inline.subscript": "Subscrito",

  // Link
  "components.controls.link.linkTitle": "Texto do Link",
  "components.controls.link.linkTarget": "Endereço do Link",
  "components.controls.link.linkTargetOption": "Abrir link em nova janela",
  "components.controls.link.link": "Link",
  "components.controls.link.unlink": "Remover Link",

  // List
  "components.controls.list.list": "Lista",
  "components.controls.list.unordered": "Sem Ordem",
  "components.controls.list.ordered": "Numerada",
  "components.controls.list.indent": "Identar",
  "components.controls.list.outdent": "Voltar",

  // Remove
  "components.controls.remove.remove": "Remove",

  // TextAlign
  "components.controls.textalign.textalign": "Alinhamento do Texto",
  "components.controls.textalign.left": "Esquerda",
  "components.controls.textalign.center": "Centro",
  "components.controls.textalign.right": "Direita",
  "components.controls.textalign.justify": "Justificado",
};
