import React, { useCallback, useState } from "react";
import { Button } from "../../../../components";
import { ControlledTextfield, CpfCnpjTextField, PhoneTextField } from "../../../../components/inputs";
import ComfirmarDialog from "../ConfirmarDialog";

const FormSocioPj = ({
  loading,
  handleSubmit,
  formProps,
  loadingInclusao,
  disabled,
  handleGoBack,
  isAtualizacao,
  tipoSocio
}) => {

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, {tipoPessoa: "J", tipoParticipante: tipoSocio});
      handleCloseDialog();
    },
    // eslint-disable-next-line
    [handleSubmit, tipoSocio]
  );

  if (Object.keys(formProps.formState.errors).length > 0 && open) {
    setOpen(false);
  }

  return (
    <div>
      <form
        className="d-flex flex-column"
        onSubmit={formProps.handleSubmit(handleClickNext)}
      >

        <div className="card spacer">
          <div className="card-body">
            <span className="card-title color-brand">Dados de Identificação</span>
            <div className="row">
              <div className="col-12 col-md-4">
                <ControlledTextfield
                  formProps={formProps}
                  label={"CNPJ"}
                  type={"cnpj"}
                  name={"cpf"}
                  margin={"none"}
                  disabled={disabled}
                  InputProps={{
                    inputComponent: CpfCnpjTextField,
                  }}
                />
              </div>
              <div className={"col-12 col-md-8"}>
                <ControlledTextfield
                  formProps={formProps}
                  label={"Razão Social"}
                  name={"nome"}
                  margin={"none"}
                  disabled={disabled}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card spacer">
          <div className="card-body">
            <span className="card-title color-brand">Contato</span>
            <div className="row">
              <div className="col-12 col-md-4">
                <ControlledTextfield
                  name={"nomeContato"}
                  formProps={formProps}
                  label={"Nome do Contato"}
                  margin={"none"}
                  disabled={disabled}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
              <div className={"col-12 col-md-4"}>
                <ControlledTextfield
                  name={"email"}
                  formProps={formProps}
                  label={"E-Mail"}
                  margin={"none"}
                  disabled={disabled}
                  uppercase={false}
                />
              </div>
              <div className={"col-12 col-md-4"}>
                <ControlledTextfield
                  name={"telefone"}
                  formProps={formProps}
                  label={"Telefone"}
                  InputProps={{
                    inputComponent: PhoneTextField,
                  }}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row spacer justify-content-center">
          <div className="col-12 col-md-4 col-5">
            <Button
              disabled={loading}
              label="VOLTAR"
              loading={loadingInclusao}
              onClick={() => handleGoBack()}
            />
          </div>
            <div className="col-12 col-md-4 col-5">
              <Button
                disabled={loading}
                label={isAtualizacao ? "ATUALIZAR" : "CONFIRMAR"}
                loading={loadingInclusao}
                onClick={() => {
                  setOpen(true);
                  formProps.clearErrors();
                }}
              />
            </div>
        </div>
        <ComfirmarDialog handleClose={handleCloseDialog} open={open} />
      </form>
    </div>
  );
};

export default FormSocioPj;
