import BasePage from "../BasePage";
import TrocarSenhaForm from "./TrocarSenhaForm.connected";
import EsqueciSenhaForm from "./EsqueciSenhaForm.connected";
import Loader from "../../components/loaders";
import Logo from "assets/ib-logo.png";
import React from "react";

const TrocarSenhaPage = ({ loading, type, valid }) => {
  return (
    <BasePage>
      <div className="row justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <img src={Logo} alt="logo" className={"logo-login"} />
        </div>
        <div className="col-12 mw-480">
          <div className="card">
            <div className="card-body">
              {!!loading && (
                <div className="d-flex justify-content-center">
                  <Loader type={"dots"} color={"brand"} size={80} />
                </div>
              )}
              {!loading && (
                <>
                  <div className="card-title text-uppercase text-center">
                    {type === "P" ? "Primeiro acesso" : "Redefinição de senha"}
                  </div>
                  <div className="mini-separator mb-2" />
                </>
              )}
              {!loading && !!valid && <TrocarSenhaForm />}
              {!loading && !valid && (
                <>
                  <div className="banner-trocar-senha mt-3">
                    O link de redefinição de sua senha não é válido ou já foi
                    utilizado. Se necessário, solicite outro link abaixo.
                  </div>
                  <EsqueciSenhaForm internal={true} type={type} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default TrocarSenhaPage;
