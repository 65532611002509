import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";

const Documento = ({
  documento,
  index,
  handleEdit,
  handleOpenExclusaoDocumento,
}) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 user-table ">
        <span>{documento.codigo}</span>
      </div>
      <div className="col-7 col-md-7 my-2 my-lg-0 user-table">
        <span>{documento.nome}</span>
      </div>
      <div className="col-2 col-md-2 my-2 my-lg-0 user-table">
        <span>
          {documento.origem === "P"
            ? "PARTICIPANTE"
            : documento.origem === "I"
            ? "IMOVEL"
            : "OUTROS"}
        </span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(documento)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() => handleOpenExclusaoDocumento(documento)}
        >
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Documento;
