import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { actions } from "../../../reducers/administracao/templatesEmail.actions";
import { useForm, useFieldArray } from "react-hook-form";
import TemplateEmailForm from "./TemplateEmailForm";
import { getTemplate } from "../../../selectors/administracao.selectors";
import validators from "../../../utils/validators";
import { draftToHtml, htmlToDraft } from "../../../utils/draft";
import { toast } from "react-toastify";

const TemplateEmailFormConnected = () => {
  const dispatch = useDispatch();
  const template = useSelector(getTemplate);

  const initialValues = {
    nome: "",
    assunto: "",
    destinatarios: [],
    padrao: "",
    ...template,
    conteudo: htmlToDraft(template?.conteudo ?? ""),
  };

  const rules = {
    nome: validators.string({ required: true }),
    assunto: validators.string({ required: true }),
    padrao: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const reset = formProps.reset;

  const arrayDestinatarios = useFieldArray({
    control: formProps.control,
    name: "destinatarios",
  });

  const handleSubmit = useCallback(
    (values) => {
      if (values.padrao === "MN" && values.destinatarios.filter((i) => (i.tipo === "PE")).length > 0
      ) {
        toast.error("Não é permitido incluir templates manuais para o destinatário: Perfil");
      } else {
        const newValues = {
          ...values,
          conteudo: draftToHtml(values.conteudo),
        };
        if (!!template?.id) {
          dispatch(
            actions.atualizarTemplate.request({ ...newValues, id: template.id })
          );
        } else {
          dispatch(actions.incluirTemplate.request(newValues));
        }
      }
    },
    [dispatch, template]
  );

  const handleBack = useCallback(() => {
    dispatch(actions.voltarListaTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (!!reset) {
      reset({
        ...template,
        conteudo: htmlToDraft(template?.conteudo ?? ""),
      });
    }
  }, [reset, template]);

  return (
    <TemplateEmailForm
      formProps={formProps}
      arrayDestinatarios={arrayDestinatarios}
      handleSubmit={formProps.handleSubmit(handleSubmit)}
      handleBack={handleBack}
    />
  );
};

export default TemplateEmailFormConnected;
