import { call, put, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../utils/api";
import { toast } from "react-toastify";
import { actions as authActions } from "../reducers/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";

export const apiWrapper = (apiPromise) => {
  return apiPromise.then(handleApiErrors).catch((error) => {
    throw error;
  });
};

export const genericErrorHandler = function* ({ error }) {
  if (error.response?.status === 401) {
    toast.info("Sua sessão expirou.\nFaça o login novamente.");
    yield put(authActions.logout());
  } else if (error.response?.status === 403) {
    toast.error("Acesso negado.");
    yield put(routeActions.redirectTo(routes.PROPOSTAS));
  } else {
    const message =
      error.response?.data?.error?.message || error.message || error.toString();
    toast.error(message);
  }
};

const basicFlow = ({
  actionGenerator,
  transform = (p) => p,
  api,
  preSuccess = () => {},
  postSuccess = () => {},
  preFailure = () => {},
  postFailure = genericErrorHandler,
}) => {
  return {
    handler: function* (action) {
      let values, response;
      try {
        values = yield transform(action.payload);
        response = yield call(apiWrapper, api(values));
        yield preSuccess({
          original: action.payload,
          values,
          response,
        });
        yield put(
          actionGenerator.success({
            original: action.payload,
            values,
            response,
          })
        );
        yield postSuccess({
          original: action.payload,
          values,
          response,
        });
      } catch (error) {
        const abort = yield preFailure({
          original: action.payload,
          values,
          response,
          error,
        });
        if (abort === true) return;
        yield put(
          actionGenerator.failure({
            original: action.payload,
            values,
            response,
            error,
          })
        );
        yield postFailure({
          original: action.payload,
          values,
          response,
          error,
        });
      }
    },
    watcher: function* () {
      yield takeEvery(actionGenerator.REQUEST, this.handler);
    },
  };
};

export default basicFlow;
