import basicFlow from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { put } from "redux-saga/effects";
import { actions } from "../../reducers/administracao/tiposDeConvenios.actions";
import { toast } from "react-toastify";
import { types as routes } from "../../reducers/rotas.actions";

const listarTiposDeConveniosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/tipos-de-convenios/lista",
    isResourceService: true,
    method: "post",
    body: values,
  });
};
const atualizarTipoDeConvenioApi = ({ tipoDeConvenio }) => {
  return authenticatedRequest({
    url: "/administracao/tipos-de-convenios/",
    isResourceService: true,
    method: "put",
    body: { tipoDeConvenio: tipoDeConvenio },
  });
};

const CriarTipoDeConvenioApi = ({ tipoDeConvenio }) => {
  return authenticatedRequest({
    url: "/administracao/tipos-de-convenios/",
    isResourceService: true,
    method: "post",
    body: { tipoDeConvenio: tipoDeConvenio },
  });
};
const excluirTipoDeConvenioApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/tipos-de-convenios/",
    isResourceService: true,
    method: "delete",
    body: values,
  });
};
const buscarTiposDeConveniosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/tipos-de-convenios/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};
const listarTiposDeConvenio = basicFlow({
  actionGenerator: actions.listarTiposDeConvenio,
  api: listarTiposDeConveniosApi,
});

const atualizarTipoDeConvenio = basicFlow({
  actionGenerator: actions.atualizarTipoDeConvenio,
  api: atualizarTipoDeConvenioApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirTipoDeConvenio = basicFlow({
  actionGenerator: actions.incluirTipoDeConvenio,
  api: CriarTipoDeConvenioApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirTipoDeConvenio = basicFlow({
  actionGenerator: actions.excluirTipoDeConvenio,
  api: excluirTipoDeConvenioApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscaTipoDeConvenio = basicFlow({
  actionGenerator: actions.buscarTipoDeConvenio,
  api: buscarTiposDeConveniosApi,
});

function* listaTiposDeConveniosRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACA0_TIPOS_DE_CONVENIOS, function* () {
    yield put(
      actions.listarTiposDeConvenio.request({
        pagina,
      })
    );
  });
}
export const sagas = [
  listarTiposDeConvenio.watcher(),
  listaTiposDeConveniosRouteWatcher(),
  atualizarTipoDeConvenio.watcher(),
  incluirTipoDeConvenio.watcher(),
  buscaTipoDeConvenio.watcher(),
  excluirTipoDeConvenio.watcher(),
];
