import React, { useCallback } from "react";
import {
  ControlledAutocomplete,
  ControlledSelect,
} from "../../../components/inputs";
import { qualificacao } from "../../../utils/parametros";
import { Button, MenuItem } from "../../../components";
import { IconButton, Tooltip } from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";

const CardDocumentosPreReq = ({ props, documentos, docs, setDocs }) => {
  const addDoc = useCallback(() => {
    let errors = false;

    props.clearErrors();

    if (props.getValues("decisao.documento.documento") === "") {
      props.setError("decisao.documento.documento", {
        message: "Campo Obrigatório",
      });
      errors = true;
    }
    if (props.getValues("decisao.documento.tipoParticipante") === "") {
      props.setError("decisao.documento.tipoParticipante", {
        message: "Campo Obrigatório",
      });
      errors = true;
    }
    if (props.getValues("decisao.documento.tipoPreReq") === "") {
      props.setError("decisao.documento.tipoPreReq", {
        message: "Campo Obrigatório",
      });
      errors = true;
    }

    if (!errors) {
      setDocs((prevDoc) => [
        ...prevDoc,
        {
          documento: props.getValues("decisao.documento.documento"),
          qualificacao: props.getValues("decisao.documento.tipoParticipante"),
          tipoRequisito: props.getValues("decisao.documento.tipoPreReq"),
        },
      ]);
      props.setValue("decisao.documento.documento", "");
      props.setValue("decisao.documento.tipoParticipante", "");
      props.setValue("decisao.documento.tipoPreReq", "");
    }
    // eslint-disable-next-line
  }, [props]);

  const removeDoc = useCallback((doc) => {
    setDocs((prevDocs) => [
      ...prevDocs.filter((i) => i.documento.id !== doc.documento.id),
    ]);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="card" style={{ minHeight: 100 }}>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div className="col-12 card-title-checklist mt-0">
            Pré-Req Encerramento - Documentos
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-5">
            <ControlledAutocomplete
              name={"decisao.documento.documento"}
              domain={documentos}
              autocompleteProps={{
                multiple: false,
              }}
              labelField={"nome"}
              formProps={props}
              label={"Documento Pré Requisitos"}
              margin={"none"}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledSelect
              name={"decisao.documento.tipoParticipante"}
              formProps={props}
              label={"Tipo Participante"}
              margin={"none"}
            >
              {qualificacao.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.id}
                  value={item.id}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
          <div className="col-12 col-md-3">
            <ControlledSelect
              name={"decisao.documento.tipoPreReq"}
              formProps={props}
              label={"Tipo pré-req"}
              margin={"none"}
            >
              <MenuItem className={"select-submenu-item"} key={1} value={"I"}>
                INDEXADO
              </MenuItem>
              <MenuItem className={"select-submenu-item"} key={2} value={"A"}>
                ANALISADO
              </MenuItem>
            </ControlledSelect>
          </div>
          <div className="offset-md-9 col-md-3 col-12">
            <Button label="ADICIONAR PRÉ-REQ" onClick={() => addDoc()} />
          </div>
        </div>
        {docs.length > 0 && (
          <div className={"row spacer"}>
            <div className="col-2 col-md-4 mt-sm-2 mt-lg-0 user-table ">
              <span>
                <b>Documento</b>
              </span>
            </div>
            <div className="col-5 col-md-4 my-2 my-lg-0 user-table">
              <span>
                <b>Tipo Participante</b>
              </span>
            </div>
            <div className="col-5 col-md-4 my-2 my-lg-0 user-table">
              <span>
                <b>Tipo Pré-Req</b>
              </span>
            </div>
            <div className="col-12">
              {docs.map((d, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
                      : "row mt-0 d-flex align-items-center medium-row tr-odd"
                  }
                >
                  <div className="col-2 col-md-4 mt-sm-2 mt-lg-0 user-table ">
                    <span>{d.documento.nome}</span>
                  </div>
                  <div className="col-5 col-md-4 my-2 my-lg-0 user-table">
                    <span>
                      {
                        qualificacao.filter((q) => q.id === d.qualificacao)[0]
                          .nome
                      }
                    </span>
                  </div>
                  <div className="col-5 col-md-3 my-2 my-lg-0 user-table">
                    <span>
                      {d.tipoRequisito === "I" ? "INDEXADO" : "ANALISADO"}
                    </span>
                  </div>
                  <Tooltip title="Excluir">
                    <IconButton className="p-2" onClick={() => removeDoc(d)}>
                      <DeleteOutlined className={"color-black"} />
                    </IconButton>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardDocumentosPreReq;
