import ListaAtividades from "./ListaAtividades";
import { useDispatch, useSelector } from "react-redux";
import { getUsuario } from "../../../../selectors/usuario.selectors";
import {
  getDetalheProposta,
  getWorkflow,
} from "../../../../selectors/propostas.selectors";
import { useCallback } from "react";
import { actions } from "reducers/propostas.actions";
import validators from "../../../../utils/validators";
import { useForm } from "react-hook-form";
import {
  actions as routeActions,
  types as routes,
} from "../../../../reducers/rotas.actions";
import { getEtapas } from "../../../../selectors/dominios.selectors";

const ListaAtividadesConnected = () => {
  const dispatch = useDispatch();
  const { perfil, idPerfil, excepcional } = useSelector(getUsuario);
  let { data: dominioEtapas } = useSelector(getEtapas);

  const handleFilterClick = useCallback(
    (value, el) => {
      dispatch(actions.alterarFiltroAtividades(el, value.target.value));
    },
    [dispatch]
  );

  const detalheProposta = useSelector(getDetalheProposta);
  const workflow = useSelector(getWorkflow);
  const filter = workflow.filter;

  const handleOpenEnvioEmail = useCallback(
    (atividade) => {
      dispatch(
        routeActions.redirectTo(routes.ENVIO_EMAIL, {
          proposta: detalheProposta?.id,
          atividade,
        })
      );
    },
    [dispatch, detalheProposta]
  );

  const rules = {
    etapa: validators.string({ required: false }),
    responsavel: validators.string({ required: false }),
    situacao: validators.string({ required: false }),
  };
  const initialValues = {
    etapa: filter?.etapa,
    responsavel: filter?.responsavel,
    situacao: filter?.situacao,
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const filteredProximasAtividades = getFilteredProximaActivities(
    workflow.proximasAtividades,
    filter
  );

  const filteredActivities = getFilteredActivities(workflow.atividades, filter);

  const filteredEtapas = getFilteredEtapas(
    filteredActivities,
    workflow.timeline
  );

  const atualizarStatus = useCallback(
    (evento, atividade, situacao, decisao, situacaoAtual, comentario) => {
      const body = {
        consulta: detalheProposta.id,
        atividade: atividade.id,
        situacao: situacao,
        decisao,
        situacaoAtual,
        comentario
      };
      dispatch(actions.atualizarSituacaoAtividade.request(body));
    },
    [dispatch, detalheProposta]
  );

  return (
    <ListaAtividades
      loading={workflow.loading}
      proposta={detalheProposta?.id}
      produtoId={detalheProposta?.Produto.id}
      filtro={filter}
      atividades={filteredActivities}
      handleFilterClick={handleFilterClick}
      perfil={perfil}
      idPerfil={idPerfil}
      etapas={filteredEtapas}
      formProps={formProps}
      dominioEtapa={dominioEtapas}
      atualizarSituacao={atualizarStatus}
      loadingAtividade={workflow.loadingAtividade}
      proximasAtividades={filteredProximasAtividades}
      excepcional={excepcional}
      handleOpenEnvioEmail={handleOpenEnvioEmail}
    />
  );
};

const getFilteredProximaActivities = (list, filter) => {
  let listaRetorno = [];

  if (!filter) {
    return list;
  } else {
    if (filter.etapa.length > 0) {
      filter.etapa.forEach((e) => {
        listaRetorno = listaRetorno.concat(
          list.filter((atividade) => atividade.etapa === parseInt(e))
        );
      });
    } else {
      listaRetorno = list;
    }
    if (filter.responsavel.length > 0) {
      let ls = [];
      filter.responsavel.forEach((r) => {
        ls = ls.concat(
          listaRetorno.filter((atividade) => atividade.responsavel === r)
        );
      });
      listaRetorno = ls;
    }
    return listaRetorno;
  }
};

const getFilteredActivities = (list, filter) => {
  let listaRetorno = [];

  if (!filter) {
    return list;
  } else {
    if (filter.etapa.length > 0) {
      filter.etapa.forEach((e) => {
        listaRetorno = listaRetorno.concat(
          list.filter((atividade) => atividade.Atividade.etapa === parseInt(e))
        );
      });
    } else {
      listaRetorno = list;
    }
    if (filter.responsavel.length > 0) {
      let ls = [];
      filter.responsavel.forEach((r) => {
        ls = ls.concat(
          listaRetorno.filter(
            (atividade) => atividade.Atividade.responsavel === r
          )
        );
      });
      listaRetorno = ls;
    }
    if (filter.situacao.length > 0) {
      let ls = [];
      filter.situacao.forEach((s) => {
        ls = ls.concat(
          listaRetorno.filter((atividade) => atividade.situacao === s)
        );
      });
      listaRetorno = ls;
    }
    return listaRetorno;
  }
};

const getFilteredEtapas = (list, etapas) => {
  let listaEtapasFiltradas = [];
  if (!!list) {
    list.forEach((l) => {
      listaEtapasFiltradas.push(l.Atividade.etapa);
    });
    etapas = etapas.filter((etapa) => {
      for (let i = 0; i < listaEtapasFiltradas.length; i++) {
        if (listaEtapasFiltradas[i] === etapa.id) return true;
      }
      return false;
    });
  }

  return etapas;
};

export default ListaAtividadesConnected;
