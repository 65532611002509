import basicFlow from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions, types } from "../reducers/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { unauthenticatedRequest } from "../utils/api";
import {
  storeJwt,
  storeRefreshToken,
  storeZendeskToken,
  saveState,
  cleanJwt,
  cleanRefreshToken,
  cleanZendeskToken,
  cleanState,
} from "../utils/localStorage";
import { toast } from "react-toastify";
import { getTokenValidacao } from "../selectors/autenticacao.selectors";
import { routeWatcher } from "./rotas.saga";

const loginApi = (values) => {
  return unauthenticatedRequest({
    url: "/auth/login",
    method: "post",
    body: values,
  });
};
const login = basicFlow({
  actionGenerator: actions.login,
  api: loginApi,
  postSuccess: function* ({ response }) {
    if (!!response.data.jwt) {
      yield saveState({ usuario: response.data.usuario });
      yield storeJwt(response.data.jwt);
      yield storeRefreshToken(response.data.refreshToken);
      yield storeZendeskToken(response.data.zendeskToken);
      yield put(routeActions.redirectTo(routes.PROPOSTAS));
    }
    if (!!response.data.token) {
      toast.info("Sua senha expirou!\nPor favor escolha uma nova senha.");
      yield put(
        routeActions.redirectTo(routes.TROCAR_SENHA, {
          token: response.data.token,
        })
      );
    }
  },
});

const esqueciSenhaApi = (values) => {
  return unauthenticatedRequest({
    url: "/auth/esqueci-senha",
    method: "post",
    body: values,
  });
};
const esqueciSenha = basicFlow({
  actionGenerator: actions.esqueciSenha,
  api: esqueciSenhaApi,
  postSuccess: () => {
    toast.info(
      "Enviamos um e-mail para você com as instruções para a troca de senha."
    );
  },
});

const validarTokenSenhaApi = ({ token }) => {
  return unauthenticatedRequest({
    url: `/auth/validar-token/${token}`,
    method: "get",
  });
};
const validarTokenSenha = basicFlow({
  actionGenerator: actions.validarTokenSenha,
  transform: function* () {
    const token = yield select(getTokenValidacao);
    return { token };
  },
  api: validarTokenSenhaApi,
});

function* trocarSenhaRouteWatcher() {
  yield routeWatcher(routes.TROCAR_SENHA, function* () {
    yield put(actions.validarTokenSenha.request());
  });
}

const trocarSenhaApi = (values) => {
  return unauthenticatedRequest({
    url: `/auth/trocar-senha`,
    method: "post",
    body: values,
  });
};
const trocarSenha = basicFlow({
  actionGenerator: actions.trocarSenha,
  transform: function* (payload) {
    const token = yield select(getTokenValidacao);
    return { token, password: payload.password };
  },
  api: trocarSenhaApi,
  postSuccess: function* ({ response }) {
    if (!!response.data.jwt) {
      yield saveState({ usuario: response.data.usuario });
      yield storeJwt(response.data.jwt);
      yield storeRefreshToken(response.data.refreshToken);
      yield storeZendeskToken(response.data.zendeskToken);
      yield put(routeActions.redirectTo(routes.PROPOSTAS));
    }
  },
});

function* logoutWatcher() {
  yield routeWatcher(types.LOGOUT, function* () {
    yield cleanState();
    yield cleanJwt();
    yield cleanRefreshToken();
    yield cleanZendeskToken();
    yield put(routeActions.redirectTo(routes.LOGIN));
  });
}

export const sagas = [
  login.watcher(),
  esqueciSenha.watcher(),
  validarTokenSenha.watcher(),
  trocarSenha.watcher(),
  trocarSenhaRouteWatcher(),
  logoutWatcher(),
];
