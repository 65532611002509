import React, { useCallback } from "react";
import {
  ControlledTextfield,
  IntegerTextField,
} from "../../../components/inputs";
import { Button } from "../../../components";

const FormMotivoCancelamento = ({
  props,
  disabled,
  handleSubmit,
  motivoCancelamento,
  voltar,
  loading,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, motivoCancelamento.id);
    },
    [handleSubmit, motivoCancelamento]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-branch">Motivo Cancelamento</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Código"}
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"descricao"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                uppercase={true}
                label={"Descrição"}
                inputProps={{ maxLength: 40 }}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button label={"Voltar"} onClick={voltar} loading={loading} />
            </div>

            <div className="col-md-4 col-12">
              <Button label={"Confirmar"} type={"submit"} loading={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormMotivoCancelamento;
