import { actions } from "./imovel.actions";
import { types as authTypes } from "./autenticacao.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  proposta: null,
  id: null,
  error: false,
  loading: false,
  submitting: false,
  data: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case routes.IMOVEL:
      return {
        ...state,
        id: action.payload.id,
        proposta: action.payload.proposta,
        loading: true,
      };
    case actions.carregarImovel.REQUEST:
    case actions.carregarImovel.SUCCESS:
    case actions.carregarImovel.FAILURE:
      return {
        ...state,
        loading: action.type === actions.carregarImovel.REQUEST,
        error: action.type === actions.carregarImovel.FAILURE,
        data:
          action.type === actions.carregarImovel.SUCCESS
            ? action.payload.response.data
            : null,
      };
    case actions.gravarImovel.REQUEST:
    case actions.gravarImovel.SUCCESS:
    case actions.gravarImovel.FAILURE:
      return {
        ...state,
        submitting: action.type === actions.gravarImovel.REQUEST,
      };
    default:
      return state;
  }
};

export default reducer;
