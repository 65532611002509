import { actions, types } from "./propostas.actions";
import { types as authTypes } from "./autenticacao.actions";
import { types as routes } from "./rotas.actions";
import formatters from "../utils/formatters";

const initialState = {
  loading: false,
  changing: false,
  error: null,
  list: [],
  situacaoAtividade: {
    data: [],
    loading: false,
  },
  proposta: {
    error: false,
    loading: true,
    detalhe: null,
    "menu-selecionado": 0,
    workflow: {
      filter: {
        etapa: [],
        responsavel: [],
        situacao: [],
      },
    },
    situacaoProposta: {
      loading: false,
    },
    historico: {
      loading: false,
      list: [],
    },
    export_historico: {
      loading: false,
      momento_geracao: null,
      file: null,
    },
  },
  search: {},
  page: 1,
  expanded: null,
  pesquisa: null,
  filtroSimplesProposta: [],
  filtroExcludenteProposta: null,
  documentos: {
    data: [],
    open: false,
  },
  filtroValores: {
    analistaBack: [],
    resp: [],
    priorizadas: [],
    fgts: false,
    iq: false,
    headerText: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case routes.PROPOSTAS:
      return {
        ...state,
        expanded: null,
      };
    case actions.listarPropostas.REQUEST:
    case actions.listarPropostas.SUCCESS:
    case actions.listarPropostas.FAILURE:
      return {
        ...state,
        expanded: null,
        loading: action.type === actions.listarPropostas.REQUEST,
        proposta: {
          ...initialState.proposta,
        },
        error:
          action.type === actions.listarPropostas.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarPropostas.SUCCESS
            ? action.payload.response.data.consultas
            : [],
        total:
          action.type === actions.listarPropostas.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarPropostas.REQUEST
            ? action.payload.pagina
            : action.payload.original.pagina,
        pesquisa:
          action.type === actions.listarPropostas.SUCCESS
            ? action.payload.response.data.pesquisa
            : null,
        filtroValores:
          action.type === actions.listarPropostas.REQUEST
            ? action.payload.filtro
            : action.payload.original.filtro,
      };
    case actions.consultarProposta.REQUEST:
    case actions.consultarProposta.SUCCESS:
    case actions.consultarProposta.FAILURE:
      return {
        ...state,
        expanded:
          action.type === actions.consultarProposta.REQUEST
            ? action.payload
            : action.payload.original,
        proposta: {
          ...state.proposta,
          "menu-selecionado": 0,
          loading: action.type === actions.consultarProposta.REQUEST,
          error:
            action.type === actions.consultarProposta.FAILURE
              ? action.payload.error
              : null,
          detalhe:
            action.type === actions.consultarProposta.SUCCESS
              ? action.payload.response.data[0]
              : null,
        },
      };
    case types.FECHAR_DETALHE:
      return {
        ...state,
        expanded: null,
      };
    case types.ABRIR_COCKPIT:
      return {
        ...state,
      };
    case types.SELECIONAR_OPCAO_MENU:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          "menu-selecionado": !!action.payload ? action.payload : 0,
          checklist: {
            ...state.proposta.checklist,
            filter: "todos",
          },
          workflow: {
            ...state.proposta.workflow,
            filter: {
              etapa: [],
              responsavel: [],
              situacao: [],
            },
          },
        },
      };
    case actions.consultarChecklist.REQUEST:
    case actions.consultarChecklist.SUCCESS:
    case actions.consultarChecklist.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            loading: action.type === actions.consultarChecklist.REQUEST,
            uploading:
              action.type === actions.consultarChecklist.REQUEST
                ? []
                : state.proposta.checklist?.uploading,
            error:
              action.type === actions.consultarChecklist.FAILURE
                ? action.payload.error
                : null,
            list:
              action.type === actions.consultarChecklist.SUCCESS
                ? action.payload.response.data
                : [],
          },
        },
      };
    case types.FILTRO_CHECKLIST:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            filter: action.payload.filtro,
          },
        },
      };
    case types.FILTRO_ATIVIDADE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            filter: {
              ...state.proposta.workflow.filter,
              etapa:
                action.payload.filtro === "etapa"
                  ? action.payload.valores
                  : state.proposta.workflow.filter?.etapa,
              responsavel:
                action.payload.filtro === "responsavel"
                  ? action.payload.valores
                  : state.proposta.workflow.filter?.responsavel,
              situacao:
                action.payload.filtro === "situacao"
                  ? action.payload.valores
                  : state.proposta.workflow.filter?.situacao,
            },
          },
        },
      };
    case actions.uploadArtefato.REQUEST:
    case actions.uploadArtefato.SUCCESS:
    case actions.uploadArtefato.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            list:
              action.type === actions.uploadArtefato.SUCCESS
                ? state.proposta.checklist.list.map((item) => {
                    return {
                      ...item,
                      documentos: item.documentos.map((doc) => {
                        if (doc.id === action.payload.original.id) {
                          return {
                            ...doc,
                            situacao_documento: "I",
                            imagens:
                              doc.imagens +
                              action.payload.original.files.length,
                            dataHoraIndexacao: new Date(),
                            usuarioIndexacao: action.payload.original.usuario,
                            motivoIndexacao: action.payload.original.motivo,
                          };
                        } else {
                          return doc;
                        }
                      }),
                      documentos_conjuge: item.documentos_conjuge?.map(
                        (doc) => {
                          if (doc.id === action.payload.original.id) {
                            return {
                              ...doc,
                              situacao_documento: "I",
                              imagens:
                                doc.imagens +
                                action.payload.original.files.length,
                              dataHoraIndexacao: new Date(),
                              usuarioIndexacao: action.payload.original.usuario,
                            };
                          } else {
                            return doc;
                          }
                        }
                      ),
                    };
                  })
                : state.proposta.checklist.list,
            uploading:
              action.type === actions.uploadArtefato.REQUEST
                ? [...state.proposta.checklist.uploading, action.payload.id]
                : [
                    ...state.proposta.checklist.uploading.filter(
                      (i) => i !== action.payload.original.id
                    ),
                  ],
          },
        },
      };

    case actions.atuarAnalisarDocumento.REQUEST:
    case actions.atuarAnalisarDocumento.SUCCESS:
    case actions.atuarAnalisarDocumento.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            analysing: action.type === actions.atuarAnalisarDocumento.REQUEST,
            list: state.proposta.checklist.list.map((checklist) => {
              return {
                ...checklist,
                documentos:
                  action.type === actions.atuarAnalisarDocumento.SUCCESS
                    ? checklist.documentos.map((documento) => {
                        if (
                          documento.id === action.payload.original.checklist
                        ) {
                          return {
                            ...documento,
                            situacao_analise:
                              action.payload.original.situacaoAnalise,
                            situacao_documento:
                              action.payload.original.situacaoDocumento,
                          };
                        }
                        return documento;
                      })
                    : checklist.documentos,
              };
            }),
          },
        },
      };
    case actions.atualizarMotivoAnalise.REQUEST:
    case actions.atualizarMotivoAnalise.SUCCESS:
    case actions.atualizarMotivoAnalise.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            analysing: action.type === actions.atualizarMotivoAnalise.REQUEST,
            list: state.proposta.checklist.list.map((checklist) => {
              return {
                ...checklist,
                documentos:
                  action.type === actions.atualizarMotivoAnalise.SUCCESS
                    ? checklist.documentos.map((documento) => {
                        if (documento.id === action.payload.original.id) {
                          return {
                            ...documento,
                            motivoRecusa: action.payload.original.motivo,
                          };
                        }
                        return documento;
                      })
                    : checklist.documentos,
                documentos_conjuge:
                  action.type === actions.atualizarMotivoAnalise.SUCCESS
                    ? checklist.documentos_conjuge?.map((documento) => {
                        if (documento.id === action.payload.original.id) {
                          return {
                            ...documento,
                            motivoRecusa: action.payload.original.motivo,
                          };
                        }
                        return documento;
                      })
                    : checklist.documentos_conjuge,
              };
            }),
          },
        },
      };
    case actions.consultarParticipante.REQUEST:
    case actions.consultarParticipante.SUCCESS:
    case actions.consultarParticipante.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            participante: {
              loading: action.type === actions.consultarParticipante.REQUEST,
              dados:
                action.type === actions.consultarParticipante.SUCCESS
                  ? {
                      ...action.payload.response.data.Consultum,
                      ...action.payload.response.data.ConsultaConvenio,
                      reset_form: false,
                      dataConsulta:
                        action.payload.response.data?.Consultum
                          ?.dataConsulta !== null
                          ? `${
                              action.payload.response.data.Consultum[
                                "dataConsulta"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.Consultum[
                                "dataConsulta"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.Consultum[
                                "dataConsulta"
                              ].split("-")[0]
                            }`
                          : null,
                      dataCorteFolha:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataCorteFolha !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataCorteFolha !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataCorteFolha"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataCorteFolha"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataCorteFolha"
                              ].split("-")[0]
                            }`
                          : null,
                      dataAprovacaoOrbitall:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataAprovacaoOrbitall !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataAprovacaoOrbitall !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoOrbitall"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoOrbitall"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoOrbitall"
                              ].split("-")[0]
                            }`
                          : null,
                      dataAprovacaoIntelcav:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataAprovacaoIntelcav !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataAprovacaoIntelcav !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoIntelcav"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoIntelcav"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoIntelcav"
                              ].split("-")[0]
                            }`
                          : null,
                      dataAprovacaoInterog:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataAprovacaoInterog !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataAprovacaoInterog !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoInterog"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoInterog"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataAprovacaoInterog"
                              ].split("-")[0]
                            }`
                          : null,
                      dataCadastroEmpregador:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataCadastroEmpregador !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataCadastroEmpregador !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataCadastroEmpregador"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataCadastroEmpregador"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataCadastroEmpregador"
                              ].split("-")[0]
                            }`
                          : null,
                      dataLiberacaoEmpregador:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataLiberacaoEmpregador !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataLiberacaoEmpregador !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataLiberacaoEmpregador"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataLiberacaoEmpregador"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataLiberacaoEmpregador"
                              ].split("-")[0]
                            }`
                          : null,
                      dataParametrizacaoAutorizador:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoAutorizador !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoAutorizador !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoAutorizador"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoAutorizador"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoAutorizador"
                              ].split("-")[0]
                            }`
                          : null,
                      dataParametrizacaoApp:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoApp !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoApp !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoApp"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoApp"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoApp"
                              ].split("-")[0]
                            }`
                          : null,
                      dataParametrizacaoSite:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoSite !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoSite !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoSite"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoSite"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoSite"
                              ].split("-")[0]
                            }`
                          : null,
                      dataParametrizacaoArquivoLogoD1:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoArquivoLogoD1 !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataParametrizacaoArquivoLogoD1 !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoArquivoLogoD1"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoArquivoLogoD1"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataParametrizacaoArquivoLogoD1"
                              ].split("-")[0]
                            }`
                          : null,
                      dataVencimentoLimite:
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataVencimentoLimite !== null &&
                        action.payload.response.data?.ConsultaConvenio
                          ?.dataVencimentoLimite !== undefined
                          ? `${
                              action.payload.response.data.ConsultaConvenio[
                                "dataVencimentoLimite"
                              ].split("-")[2]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataVencimentoLimite"
                              ].split("-")[1]
                            }/${
                              action.payload.response.data.ConsultaConvenio[
                                "dataVencimentoLimite"
                              ].split("-")[0]
                            }`
                          : null,
                      valorLimiteAprovado:
                        action.payload.response.data?.ConsultaConvenio
                          ?.valorLimiteAprovado,

                      taxaOperacao:
                        action.payload.response.data?.ConsultaConvenio
                          ?.taxaOperacao,
                      valorRating:
                        action.payload.response.data?.ConsultaConvenio
                          ?.valorRating,
                      margemUtilizavel:
                        action.payload.response.data?.ConsultaConvenio
                          ?.margemUtilizavel,
                      margemCartao:
                        action.payload.response.data?.ConsultaConvenio
                          ?.margemCartao,
                      valorLimitePotencialSaque:
                        action.payload.response.data?.ConsultaConvenio
                          ?.valorLimitePotencialSaque,
                      valorLimitePotencialCompra:
                        action.payload.response.data?.ConsultaConvenio
                          ?.valorLimitePotencialCompra,
                      cpf: formatters.strings.cpfCnpj(
                        action.payload.response.data["cpf_cnpj"] ?? "",
                        action.payload.response.data.tipoPessoa
                      ),
                      dataNascimento: action.payload.response.data
                        .PessoaDadoPessoal?.dataNascimento
                        ? action.payload.response.data.PessoaDadoPessoal[
                            "dataNascimento"
                          ].split("-")[2] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal[
                            "dataNascimento"
                          ].split("-")[1] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal[
                            "dataNascimento"
                          ].split("-")[0]
                        : "",
                      nome: action.payload.response.data.nome ?? "",
                      tipoPessoa: action.payload.response.data.tipoPessoa ?? "",
                      tipoParticipante:
                        action.payload.response.data.tipoParticipante ?? "",
                      genero: action.payload.response.data?.genero ?? "",
                      fgts:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.fgts === "S",
                      colaborador:
                        action.payload.response.data?.colaborador === "S",
                      estadoCivil:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.estadoCivil,
                      uniaoEstavel:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.uniaoEstavel === "S",
                      cep:
                        action.payload.response.data?.PessoaEndereco?.cep
                          ?.length === 7
                          ? "0" +
                            action.payload.response.data.PessoaEndereco.cep
                          : action.payload.response.data?.PessoaEndereco?.cep ??
                            "",
                      logradouro:
                        action.payload.response.data?.PessoaEndereco
                          ?.logradouro ?? "",
                      complemento:
                        action.payload.response.data?.PessoaEndereco
                          ?.complemento ?? "",
                      numero:
                        action.payload.response.data?.PessoaEndereco?.numero ??
                        "",
                      bairro:
                        action.payload.response.data?.PessoaEndereco?.bairro ??
                        "",
                      municipio:
                        action.payload.response.data?.PessoaEndereco
                          ?.municipio ?? "",
                      uf:
                        action.payload.response.data?.PessoaEndereco?.uf ?? "",
                      pais: !action.payload.response.data?.PessoaEndereco?.pais
                        ? ""
                        : action.payload.response.data.PessoaEndereco.pais ===
                          "BRASIL"
                        ? "BRASIL"
                        : "OUTRO",
                      paisOutro: !action.payload.response.data?.PessoaEndereco
                        ?.pais
                        ? ""
                        : action.payload.response.data.PessoaEndereco.pais !==
                          "BRASIL"
                        ? action.payload.response.data.PessoaEndereco.pais
                        : "",
                      email:
                        action.payload.response.data?.PessoaContato?.email ??
                        "",
                      telefone: action.payload.response.data?.PessoaContato
                        ?.areaCelular
                        ? "(" +
                          action.payload.response.data?.PessoaContato?.areaCelular.toString() +
                          ") " +
                          action.payload.response.data?.PessoaContato
                            ?.telefoneCelular
                        : "",
                      telefoneResidencial: action.payload.response.data
                        ?.PessoaContato?.areaResidencial
                        ? "(" +
                          action.payload.response.data?.PessoaContato?.areaResidencial.toString() +
                          ") " +
                          action.payload.response.data?.PessoaContato
                            ?.telefoneResidencial
                        : "",
                      nomeConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nomeConjuge ?? "",
                      cpfConjuge:
                        action.payload.response.data?.PessoaDadoPessoal?.[
                          "cpf_conjuge"
                        ] ?? "",
                      dataCasamento: action.payload.response.data
                        ?.PessoaDadoPessoal?.dataCasamento
                        ? action.payload.response.data.PessoaDadoPessoal.dataCasamento.split(
                            "-"
                          )[2] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataCasamento.split(
                            "-"
                          )[1] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataCasamento.split(
                            "-"
                          )[0]
                        : "",
                      regimeCasamento:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.regimeCasamento ?? "",
                      dataNascimentoConjuge: action.payload.response.data
                        .PessoaDadoPessoal?.dataNascimentoConjuge
                        ? action.payload.response.data.PessoaDadoPessoal.dataNascimentoConjuge.split(
                            "-"
                          )[2] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataNascimentoConjuge.split(
                            "-"
                          )[1] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataNascimentoConjuge.split(
                            "-"
                          )[0]
                        : "",
                      emailConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.emailConjuge ?? "",
                      idPessoa: action.payload.response.data?.id ?? "",
                      idConsulta: action.payload.response.data?.consulta ?? "",
                      codigoPessoa: action.payload.response.data?.codigo ?? "",
                      isConjugeComprador:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.isConjugeComprador === "S",
                      nomeMae:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nomeMae,
                      fgtsConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.fgtsConjuge === "S",
                      localidade:
                        action.payload.response.data?.PessoaEndereco
                          ?.localidade ?? "",
                      tipoEmpresa:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.tipoEmpresa,
                      nomeContato:
                        action.payload.response.data?.PessoaContato?.nome,
                      nomeFantasia:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.nomeFantasia,
                      dataConstituicao: action.payload.response.data
                        .PessoaDadoEmpresa?.dataConstituicao
                        ? action.payload.response.data.PessoaDadoEmpresa?.dataConstituicao.split(
                            "-"
                          )[2] +
                          "/" +
                          action.payload.response.data.PessoaDadoEmpresa?.dataConstituicao.split(
                            "-"
                          )[1] +
                          "/" +
                          action.payload.response.data.PessoaDadoEmpresa?.dataConstituicao.split(
                            "-"
                          )[0]
                        : "",
                      tipoCorrespondente:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.tipoEmpresa,
                      tipoConstituicao:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.tipoConstituicao,
                      valorFaturamento: action.payload.response.data
                        ?.PessoaDadoEmpresa?.valorFaturamento
                        ? Number.parseFloat(
                            action.payload.response.data?.PessoaDadoEmpresa
                              ?.valorFaturamento
                          )
                        : null,
                      matriz:
                        action.payload.response.data?.PessoaDadoEmpresa?.matriz,
                      codigoFuncao:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.codigoFuncao,
                      cnae:
                        action.payload.response.data?.PessoaDadoEmpresa?.cnae,
                      site:
                        action.payload.response.data?.PessoaDadoEmpresa?.site,
                      situacaoCnpj:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.situacaoCnpj,
                      banco:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.banco === 0
                          ? null
                          : action.payload.response.data?.PessoaDadoEmpresa
                              ?.banco,
                      nomeBanco:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.nomeBanco,
                      nomeGerente:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.nomeGerente ??
                        action.payload.response.data.ConsultaConvenio
                          ?.nomeGerente,
                      agenciaBancaria:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.agenciaBancaria === 0
                          ? null
                          : action.payload.response.data?.PessoaDadoEmpresa
                              ?.agenciaBancaria,
                      conta:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.conta === 0
                          ? null
                          : action.payload.response.data?.PessoaDadoEmpresa
                              ?.conta,
                      digito:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.digito === 0
                          ? null
                          : action.payload.response.data?.PessoaDadoEmpresa
                              ?.digito,
                      nomeMaster:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.nomeMaster,
                      cpfMaster:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.cpfMaster,
                      emailMaster:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.emailMaster,
                      telefoneMaster:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.areaTelefoneMaster &&
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.telefoneMaster
                          ? `(${action.payload.response.data?.PessoaDadoEmpresa?.areaTelefoneMaster}) ${action.payload.response.data?.PessoaDadoEmpresa?.telefoneMaster}`
                          : null,
                      certificadoMaster:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.certificadoMaster,
                      loginMaster:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.loginMaster,
                      nomePLD:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.nomePLD,
                      cpfPLD:
                        action.payload.response.data?.PessoaDadoEmpresa?.cpfPLD,
                      emailPLD:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.emailPLD,
                      certificadoPLD:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.certificadoPLD,
                      telefonePLD:
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.areaTelefonePLD &&
                        action.payload.response.data?.PessoaDadoEmpresa
                          ?.telefonePLD
                          ? `(${action.payload.response.data?.PessoaDadoEmpresa?.areaTelefonePLD}) ${action.payload.response.data?.PessoaDadoEmpresa?.telefonePLD}`
                          : null,
                    }
                  : null,
            },
          },
        },
      };
    case actions.consultarCepCorreios.REQUEST:
    case actions.consultarCepCorreios.SUCCESS:
    case actions.consultarCepCorreios.FAILURE:
      return {
        ...state,
        cep: {
          ...state.cep,
          loading: action.type === actions.consultarCepCorreios.REQUEST,
          error: action.type === actions.consultarCepCorreios.FAILURE,
        },
      };
    case actions.atualizarParticipante.REQUEST:
    case actions.atualizarParticipante.SUCCESS:
    case actions.atualizarParticipante.FAILURE:
      return {
        ...state,
        proposta: {
          ...state?.proposta,
          detalhe: {
            ...state?.proposta?.detalhe,
            participante: {
              ...state?.proposta?.detalhe?.participante,
              confirmacao: {
                loading: action.type === actions.atualizarParticipante.REQUEST,
                error: action.type === actions.atualizarParticipante.FAILURE,
              },
            },
          },
        },
      };
    case types.ABRIR_NOVO_PARTICIPANTE:
      return {
        ...state,
        proposta: {
          ...state?.proposta,
          detalhe: {
            ...state?.proposta?.detalhe,
            participante: {
              ...state?.proposta?.detalhe?.participante,
              dados: {
                ...state?.proposta?.detalhe?.participante?.dados,
                reset_form: true,
                cpf: "",
                dataNascimento: "",
                nome: "",
                tipoPessoa: "J",
                tipoParticipante: "C",
                fgts: "",
                estadoCivil: "",
                uniaoEstavel: false,
                cep: "",
                logradouro: "",
                complemento: "",
                numero: "",
                bairro: "",
                municipio: "",
                uf: "",
                email: "",
                telefone: "",
                nomeConjuge: "",
                cpfConjuge: "",
                dataCasamento: "",
                dataNascimentoConjuge: "",
                generoConjuge: "",
                idPessoa: "",
                idConsulta: "",
                codigoPessoa: "",
                isConjugeCompradora: false,
                nomeMae: "",
                fgtsConjuge: false,
                regimeCasamento: "",
                localidade: "",
                tipoDivisaoAdministrativa: "",
                idTipoConvenio: "",
                tipoAreaKitCredenciamento: "",
                quantidadeFuncionarios: "",
                tipoEnvioKitCredenciamento: "",
                nomeGerente: "",
                dataCorteFolha: "",
                funcao: "",
                protocolo: "",
                logoOrbitall: "",
                logoAutorizador: "",
                dataAprovacaoOrbitall: "",
                dataAprovacaoIntelcav: "",
                dataAprovacaoInterog: "",
                dataCadastroEmpregador: "",
                dataLiberacaoEmpregador: "",
                dataParametrizacaoAutorizador: "",
                dataParametrizacaoApp: "",
                dataParametrizacaoSite: "",
                dataParametrizacaoArquivoLogoD1: "",
                valorLimiteAprovado: "",
                prazoOperacao: "",
                taxaOperacao: "",
                publicoAlvo: "",
                politicaIdadeValor: "",
                dataVencimentoLimite: "",
                valorRating: "",
                classificacaoRiscos: "",
                margemUtilizavel: "",
                margemCartao: "",
                valorLimitePotencialSaque: "",
                valorLimitePotencialCompra: "",
                codigoContaCorrente: "",
                representante: "",
                cargoRepresentante: "",
                observacao: "",
                produto: "",
                dataConsulta: "",
                produtoConvenio: "",
              },
            },
          },
        },
      };
    case types.ABRIR_MODAL_DOCUMENTOS:
      return {
        ...state,
        documentos: {
          ...state.documentos,
          data: action.payload.data,
          open: action.payload.open,
        },
      };
    case actions.excluirParticipante.REQUEST:
    case actions.excluirParticipante.SUCCESS:
    case actions.excluirParticipante.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          loading: true,
          detalhe: {
            ...state.proposta.detalhe,
            cliente: {
              ...state.proposta.detalhe.cliente,
              loadingExclusao:
                action.type === actions.excluirParticipante.REQUEST,
            },
          },
        },
      };
    case actions.adicionarDocumento.REQUEST:
    case actions.adicionarDocumento.SUCCESS:
    case actions.adicionarDocumento.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            adding: action.type === actions.adicionarDocumento.REQUEST,
          },
        },
      };
    case actions.consultarIq.REQUEST:
    case actions.consultarIq.SUCCESS:
    case actions.consultarIq.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            id:
              action.type === actions.consultarIq.REQUEST
                ? action.payload
                : action.payload.original,
            interveniente: {
              idConsulta:
                action.type === actions.consultarIq.REQUEST
                  ? action.payload
                  : action.payload.original,
              nomeInterveniente:
                action.type === actions.consultarIq.SUCCESS
                  ? action.payload.response.data[0].interveniente
                  : "",
              loading: action.type === actions.consultarIq.REQUEST,
              loadingAtualizacao: false,
            },
          },
        },
      };
    case actions.atualizarIq.REQUEST:
    case actions.atualizarIq.SUCCESS:
    case actions.atualizarIq.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          loading:
            action.type === actions.atualizarIq.REQUEST ||
            !state.proposta.detalhe.codigo,
          detalhe: {
            ...state.proposta.detalhe,
            interveniente: {
              ...state.proposta.detalhe.interveniente,
              loadingAtualizacao: action.type === actions.atualizarIq.REQUEST,
              nomeInterveniente:
                action.type === actions.atualizarIq.REQUEST
                  ? action.payload.iq
                  : action.payload.original.iq,
            },
          },
        },
      };
    case actions.atualizarObservacao.REQUEST:
    case actions.atualizarObservacao.SUCCESS:
    case actions.atualizarObservacao.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            observacao:
              action.type === actions.atualizarObservacao.REQUEST ||
              action.type === actions.atualizarObservacao.FAILURE
                ? action.payload.observacao
                : null,
            observacaoInterna:
              action.type === actions.atualizarObservacao.REQUEST
                ? action.payload.observacaoInterna
                : action.payload.original.observacaoInterna,
            submittingObservacao:
              action.type === actions.atualizarObservacao.REQUEST,
            observacoes:
              action.type === actions.atualizarObservacao.SUCCESS
                ? action.payload.response.data.observacoes
                : state.proposta.detalhe?.observacoes,
          },
        },
      };
    case actions.getWorkflow.REQUEST:
    case actions.getWorkflow.SUCCESS:
    case actions.getWorkflow.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            loading: action.type === actions.getWorkflow.REQUEST,
            timeline:
              action.type === actions.getWorkflow.SUCCESS
                ? action.payload.response.data.timeline
                : "",
            atividades:
              action.type === actions.getWorkflow.SUCCESS
                ? action.payload.response.data.atividades
                : "",
            proximasAtividades:
              action.type === actions.getWorkflow.SUCCESS
                ? action.payload.response.data.proximasAtividades
                : "",
          },
        },
      };
    case actions.abrirAtividade.REQUEST:
    case actions.abrirAtividade.SUCCESS:
    case actions.abrirAtividade.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            loading: action.type === actions.abrirAtividade.REQUEST,
            timeline:
              action.type === actions.abrirAtividade.SUCCESS
                ? action.payload.response.data.timeline
                : state.proposta.workflow.timeline,
            atividades:
              action.type === actions.abrirAtividade.SUCCESS
                ? action.payload.response.data.atividades
                : state.proposta.workflow.atividades,
            filter: {
              etapa: [],
              responsavel: [],
              situacao: [],
              loading: action.type === actions.abrirAtividade.REQUEST,
            },
            proximasAtividades:
              action.type === actions.abrirAtividade.SUCCESS
                ? state.proposta.workflow.proximasAtividades.filter(
                    (p) => p.id !== action.payload.original.atividade
                  )
                : state.proposta.workflow.proximasAtividades,
          },
          situacaoProposta: {
            loading: action.type === actions.abrirAtividade.REQUEST,
          },
          detalhe: {
            ...state.proposta.detalhe,
            situacao:
              actions.abrirAtividade.SUCCESS === action.type
                ? !!action.payload.response?.data?.situacaoConsulta
                  ? action.payload.response?.data?.situacaoConsulta
                  : state.proposta.detalhe.situacao
                : state.proposta.detalhe.situacao,
          },
        },
      };
    case actions.atualizarSituacaoAtividade.REQUEST:
    case actions.atualizarSituacaoAtividade.SUCCESS:
    case actions.atualizarSituacaoAtividade.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            loadingAtividade:
              action.type === actions.atualizarSituacaoAtividade.REQUEST
                ? action.payload.atividade
                : "",
            timeline:
              action.type === actions.atualizarSituacaoAtividade.SUCCESS
                ? action.payload.response.data.timeline
                : state.proposta.workflow.timeline,
            atividades:
              action.type === actions.atualizarSituacaoAtividade.SUCCESS
                ? action.payload.response.data.atividades
                : state.proposta.workflow.atividades,
            filter: {
              etapa: [],
              responsavel: [],
              situacao: [],
              loading:
                action.type === actions.atualizarSituacaoAtividade.REQUEST,
            },
          },
          situacaoProposta: {
            loading: action.type === actions.atualizarSituacaoAtividade.REQUEST,
          },
          detalhe: {
            ...state.proposta.detalhe,
            situacao:
              actions.atualizarSituacaoAtividade.SUCCESS === action.type
                ? !!action.payload.response?.data?.situacaoConsulta
                  ? action.payload.response?.data?.situacaoConsulta
                  : state.proposta.detalhe.situacao
                : state.proposta.detalhe.situacao,
          },
        },
      };
    case types.OPEN_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          open: true,
          anchor: action.payload.anchor,
        },
      };
    case types.ARROW_REFERENCE:
      return {
        ...state,
        search: {
          ...state.search,
          arrow: action.payload.arrow,
        },
      };
    case types.CLOSE_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          open: false,
          anchor: undefined,
        },
      };
    case types.SET_SEARCH:
      return {
        ...state,
        page: 1,
        pesquisa: action.payload.pesquisa,
      };
    case actions.atualizarAnalista.REQUEST:
    case actions.atualizarAnalista.SUCCESS:
    case actions.atualizarAnalista.FAILURE:
      return {
        ...state,
        changing: action.type === actions.atualizarAnalista.REQUEST,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            usuarioBackoffice:
              action.type === actions.atualizarAnalista.SUCCESS
                ? action.payload.response.data
                : state.proposta.detalhe.usuarioBackoffice,
          },
        },
      };
    case actions.atualizarSituacaoConsulta.REQUEST:
    case actions.atualizarSituacaoConsulta.SUCCESS:
    case actions.atualizarSituacaoConsulta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          situacaoProposta: {
            loading: actions.atualizarSituacaoConsulta.REQUEST === action.type,
          },
          detalhe: {
            ...state.proposta.detalhe,
            situacao:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.situacao
                : state.proposta.detalhe.situacao,
            diasNaEsteira:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.diasNaEsteira
                : state.proposta.detalhe.diasNaEsteira,
            motivoCancelamento:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.motivoCancelamento
                : state.proposta.detalhe.motivoCancelamento,
            MotivoCancelamento: {
              ...state.proposta.detalhe.MotivoCancelamento,
              descricao:
                actions.atualizarSituacaoConsulta.SUCCESS === action.type
                  ? action.payload.response?.data?.descricaoMotivoCancelamento
                  : state.proposta.detalhe?.MotivoCancelamento?.descricao,
            },
          },
        },
      };
    case actions.atualizarPrioridadeConsulta.REQUEST:
    case actions.atualizarPrioridadeConsulta.SUCCESS:
    case actions.atualizarPrioridadeConsulta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          prioridadeProposta: {
            loading:
              actions.atualizarPrioridadeConsulta.REQUEST === action.type,
          },
          detalhe: {
            ...state.proposta.detalhe,
            prioridade:
              actions.atualizarPrioridadeConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.prioridade
                : state.proposta.detalhe.prioridade,
          },
        },
      };
    case types.LIMPAR_PROPOSTA:
      return {
        ...state,
        proposta: null,
      };
    case types.SELECIONAR_FILTRO_SIMPLES:
      return {
        ...state,
        filtroSimplesProposta: action.payload,
      };
    case types.SELECIONAR_FILTRO_EXCLUDENTE:
      return {
        ...state,
        filtroExcludenteProposta: action.payload,
      };
    case types.LIMPAR_FILTRO_VALORES:
      return {
        ...state,
        filtroSimplesProposta: [],
        filtroExcludenteProposta: null,
        filtroValores: {
          analistaBack: [],
          resp: [],
          priorizadas: [],
          fgts: false,
          iq: false,
        },
      };
    case actions.historicoProposta.REQUEST:
    case actions.historicoProposta.SUCCESS:
    case actions.historicoProposta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            loading: actions.historicoProposta.REQUEST === action.type,
            list:
              actions.historicoProposta.SUCCESS === action.type
                ? action.payload.response.data
                : [],
          },
        },
      };
    case actions.exportarHistoricoConsultas.REQUEST:
    case actions.exportarHistoricoConsultas.SUCCESS:
    case actions.exportarHistoricoConsultas.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          export_historico: {
            loading: actions.exportarHistoricoConsultas.REQUEST === action.type,
            file:
              actions.exportarHistoricoConsultas.SUCCESS === action.type
                ? action.payload.response.data.file
                : null,
            momento_geracao:
              actions.exportarHistoricoConsultas.SUCCESS === action.type
                ? action.payload.response.data.momento_geracao
                : null,
          },
        },
      };
    case actions.listarSituacaoAtividades.REQUEST:
    case actions.listarSituacaoAtividades.SUCCESS:
    case actions.listarSituacaoAtividades.FAILURE:
      return {
        ...state,
        situacaoAtividade: {
          ...state.situacaoAtividade,
          loading: actions.listarSituacaoAtividades.REQUEST === action.type,
          data:
            actions.listarSituacaoAtividades.SUCCESS === action.type
              ? action.payload.response.data
              : [],
        },
      };
    case types.LIMPAR_HISTORICO_ATIVIDADES_FILE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          export_historico: {
            ...state.proposta.export_historico,
            file: null,
            momento_geracao: null,
          },
        },
      };
    case types.LIMPAR_FORMULARIO:
      return {
        ...state,
        proposta: {
          detalhe: {
            participante: {
              dados: {
                ...state?.detalhe?.participante?.dados,
                reset_form: true,
              },
            },
          },
        },
      };
    case types.INCLUSAO_CORBAN_SOCIO:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            corban: action.payload,
            participante: "",
          },
        },
      };
    case types.VOLTAR_INCLUSAO_CORBAN:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            isTelaSocio: false,
            participante: {
              dados: {
                reset_form: false,
                cpf: formatters.strings.cpfCnpj(
                  state.proposta.detalhe.corban.DadosPessoa["cpf_cnpj"],
                  "J"
                ),
                nome: state.proposta.detalhe.corban.DadosPessoa.nome ?? "",
                tipoPessoa:
                  state.proposta.detalhe.corban.DadosPessoa.tipoPessoa ?? "",
                tipoParticipante:
                  state.proposta.detalhe.corban.DadosPessoa.tipoParticipante ??
                  "",
                cep:
                  state.proposta.detalhe.corban?.PessoaEndereco?.cep?.length ===
                  7
                    ? "0" + state.proposta.detalhe.corban.PessoaEndereco.cep
                    : state.proposta.detalhe.corban?.PessoaEndereco?.cep ?? "",
                logradouro:
                  state.proposta.detalhe.corban?.PessoaEndereco?.logradouro ??
                  "",
                complemento:
                  state.proposta.detalhe.corban?.PessoaEndereco?.complemento ??
                  "",
                numero:
                  state.proposta.detalhe.corban?.PessoaEndereco?.numero ?? "",
                bairro:
                  state.proposta.detalhe.corban?.PessoaEndereco?.bairro ?? "",
                municipio:
                  state.proposta.detalhe.corban?.PessoaEndereco?.municipio ??
                  "",
                uf: state.proposta.detalhe.corban?.PessoaEndereco?.uf ?? "",
                pais: !state.proposta.detalhe.corban?.PessoaEndereco?.pais
                  ? ""
                  : state.proposta.detalhe.corban.PessoaEndereco.pais ===
                    "BRASIL"
                  ? "BRASIL"
                  : "OUTRO",
                paisOutro: !state.proposta.detalhe.corban?.PessoaEndereco?.pais
                  ? ""
                  : state.proposta.detalhe.corban.PessoaEndereco.pais !==
                    "BRASIL"
                  ? state.proposta.detalhe.corban.PessoaEndereco.pais
                  : "",
                email:
                  state.proposta.detalhe.corban?.PessoaContato?.email ?? "",
                telefone: state.proposta.detalhe.corban?.PessoaContato
                  ?.areaCelular
                  ? "(" +
                    state.proposta.detalhe.corban?.PessoaContato?.areaCelular.toString() +
                    ") " +
                    state.proposta.detalhe.corban?.PessoaContato
                      ?.telefoneCelular
                  : "",
                localidade:
                  state.proposta.detalhe.corban?.PessoaEndereco?.localidade ??
                  "",
                nomeContato: state.proposta.detalhe.corban?.PessoaContato?.nome,
                nomeFantasia:
                  state.proposta.detalhe.corban?.PessoaDadoEmpresa
                    ?.nomeFantasia,
                dataConstituicao:
                  state.proposta.detalhe.corban.PessoaDadoEmpresa
                    ?.dataConstituicao ?? "",
                tipoCorrespondente:
                  state.proposta.detalhe.corban?.PessoaDadoEmpresa?.tipoEmpresa,
                tipoConstituicao:
                  state.proposta.detalhe.corban?.PessoaDadoEmpresa
                    ?.tipoConstituicao,
                valorFaturamento:
                  state.proposta.detalhe.corban?.PessoaDadoEmpresa
                    ?.valorFaturamento,
                matriz:
                  state.proposta.detalhe.corban?.PessoaDadoEmpresa?.matriz,
                codigoFuncao:
                  state.proposta.detalhe.corban?.PessoaDadoEmpresa
                    ?.codigoFuncao,
                cnae: state.proposta.detalhe.corban?.PessoaDadoEmpresa?.cnae,
                site: state.proposta.detalhe.corban?.PessoaDadoEmpresa?.site,
                situacaoCnpj:
                  state.proposta.detalhe.corban?.PessoaDadoEmpresa
                    ?.situacaoCnpj,
              },
            },
          },
        },
      };
    case types.SELECIONAR_OPCAO_MENU_HISTORICO:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            "menu-selecionado": !!action.payload
              ? action.payload
              : "atividades",
          },
        },
      };
    case actions.historicoEmails.REQUEST:
    case actions.historicoEmails.SUCCESS:
    case actions.historicoEmails.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            emails: {
              loading: actions.historicoEmails.REQUEST === action.type,
              data:
                actions.historicoEmails.SUCCESS === action.type
                  ? action.payload.response.data
                  : [],
            },
          },
        },
      };
    case actions.exportHistoricoEmails.REQUEST:
    case actions.exportHistoricoEmails.SUCCESS:
    case actions.exportHistoricoEmails.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          export_historico: {
            loading: actions.exportHistoricoEmails.REQUEST === action.type,
            file:
              actions.exportHistoricoEmails.SUCCESS === action.type
                ? action.payload.response.data.file
                : null,
            momento_geracao:
              actions.exportHistoricoEmails.SUCCESS === action.type
                ? action.payload.response.data.momento_geracao
                : null,
          },
        },
      };
    case actions.historicoEmailsDetalhes.REQUEST:
    case actions.historicoEmailsDetalhes.SUCCESS:
    case actions.historicoEmailsDetalhes.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            detalhes_email: {
              loading: actions.historicoEmailsDetalhes.REQUEST === action.type,
              data:
                actions.historicoEmailsDetalhes.SUCCESS === action.type
                  ? action.payload.response.data
                  : {},
            },
            emails: {
              ...state.proposta.historico.emails,
              data:
                actions.historicoEmailsDetalhes.SUCCESS === action.type
                  ? state.proposta.historico.emails.data.map((email) => {
                      if (email.id === action.payload.response.data.id) {
                        return {
                          ...email,
                          lido: action.payload.response.data.lido,
                        };
                      }
                      return email;
                    })
                  : state.proposta.historico.emails.data,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
