import { actions, types } from "./perfis.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  perfil: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarPerfis.REQUEST:
    case actions.listarPerfis.SUCCESS:
    case actions.listarPerfis.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarPerfis.REQUEST,
        error:
          action.type === actions.listarPerfis.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarPerfis.SUCCESS
            ? action.payload.response.data.perfis
            : [],
        total:
          action.type === actions.listarPerfis.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarPerfis.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarPerfil.REQUEST:
    case actions.atualizarPerfil.SUCCESS:
    case actions.atualizarPerfil.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarPerfil.REQUEST,
        perfil:
          action.type === actions.atualizarPerfil.FAILURE
            ? action.payload.original.perfil
            : null,
        list:
          action.type === actions.atualizarPerfil.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.perfil.id) {
                  return {
                    ...u,
                    tipo: action.payload.original.perfil.tipo ?? u.tipo,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVA_PERFIL:
      return {
        ...state,
        perfil: {
          tipo: "",
        },
      };
    case types.EDITAR_PERFIL:
      return {
        ...state,
        perfil: {
          ...action.payload.perfil,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        perfil: null,
      };
    case actions.incluirPerfil.REQUEST:
    case actions.incluirPerfil.SUCCESS:
    case actions.incluirPerfil.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirPerfil.REQUEST,
        perfil:
          action.type === actions.incluirPerfil.FAILURE
            ? action.payload.original.perfil
            : null,
        list:
          action.type === actions.incluirPerfil.SUCCESS
            ? action.payload.response.data.perfis
            : state.list,
        total:
          action.type === actions.incluirPerfil.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirPerfil.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarPerfil.REQUEST:
    case actions.buscarPerfil.SUCCESS:
    case actions.buscarPerfil.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarPerfil.REQUEST,
        error:
          action.type === actions.buscarPerfil.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarPerfil.SUCCESS
            ? action.payload.response.data.perfis
            : [],
        total:
          action.type === actions.buscarPerfil.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarPerfil.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarPerfil.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirPerfil.REQUEST:
    case actions.excluirPerfil.SUCCESS:
    case actions.excluirPerfil.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirPerfil.REQUEST,
        perfil: null,
        list:
          action.type === actions.excluirPerfil.SUCCESS
            ? state.list.filter(
                (u) => u.id !== action.payload.original.idPerfil
              )
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
