import { useSelector } from "react-redux";
import { getPerfil } from "../../selectors/usuario.selectors";

const VisibleWithoutProfile = ({ profiles = [], children }) => {
  const perfil = useSelector(getPerfil);

  if (profiles.indexOf(perfil) === -1) {
    return children;
  }
  return null;
};

export default VisibleWithoutProfile;
