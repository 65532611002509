import React, { useCallback, useState } from "react";
import CardEndereco from "./CardEndereco";
import { Button } from "../../../../components";
import CardDadosPessoaJuridica from "./CardDadosPessoaJuridica";
import CardDadosContato from "./CardDadosContato";
import ComfirmarDialog from "../ConfirmarDialog";
import CardDadosBancarios from "./CardDadosBancarios";
import CardUsuarioMaster from "./CardUsuarioMaster";
import CardUsuarioPLD from "./CardUsuarioPLD";

const FormPessoaJuridica = ({
  loading,
  handleSubmit,
  formProps,
  uf,
  consultarCep,
  loadingInclusao,
  disabled,
  handleGoBack,
  isAtualizacao,
  produtos,
  empresasCorrespondentes,
  pathname,
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [tipoPessoa, setTipoPessoa] = useState("");

  const handleTipoPessoa = useCallback((value) => {
    setTipoPessoa(value);
  }, []);

  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, tipoPessoa, "S");
      handleCloseDialog();
    },
    // eslint-disable-next-line
    [handleSubmit, tipoPessoa]
  );

  return (
    <div>
      <form
        className="d-flex flex-column"
        onSubmit={formProps.handleSubmit(handleClickNext)}
      >
        <CardDadosPessoaJuridica
          props={formProps}
          disabled={disabled}
          produtos={produtos}
          empresasCorrespondentes={empresasCorrespondentes.data}
        />
        <CardEndereco
          props={formProps}
          title={"Endereço"}
          uf={uf}
          consultarCep={consultarCep}
          disabled={disabled}
          pathname={pathname}
        />
        <CardDadosContato props={formProps} disabled={disabled} />
        <CardUsuarioMaster props={formProps} disabled={disabled} />
        <CardUsuarioPLD props={formProps} disabled={disabled} />
        <CardDadosBancarios props={formProps} disabled={disabled} />

        <div className="row spacer justify-content-center">
          <div className="col-12 col-md-4 col-5">
            <Button
              disabled={loading}
              label="VOLTAR"
              loading={loadingInclusao}
              onClick={() => handleGoBack()}
            />
          </div>
          {!!formProps.initialValues.idConsulta && !disabled && (
            <div className="col-12 col-md-4 col-5">
              <Button
                disabled={loading}
                label={isAtualizacao ? "ATUALIZAR" : "CONFIRMAR"}
                loading={loadingInclusao}
                onClick={() => {
                  setOpen(true);
                  formProps.clearErrors();
                }}
              />
            </div>
          )}
          {!formProps.initialValues.idConsulta && (
            <div className="col-12 col-md-4 col-5">
              <Button
                disabled={loading}
                label="INCLUIR SÓCIO PJ"
                loading={loadingInclusao}
                type={"submit"}
                onClick={() => handleTipoPessoa("J")}
              />
            </div>
          )}
          {!formProps.initialValues.idConsulta && (
            <div className="col-12 col-md-4 col-5">
              <Button
                disabled={loading}
                label="INCLUIR SÓCIO PF"
                loading={loadingInclusao}
                type={"submit"}
                onClick={() => handleTipoPessoa("F")}
              />
            </div>
          )}
        </div>
        <ComfirmarDialog handleClose={handleCloseDialog} open={open} />
      </form>
    </div>
  );
};

export default FormPessoaJuridica;
