import { createSyncAction } from "../utils/actionCreators";
import { NOT_FOUND } from "redux-first-router";

export const types = {
  NOT_FOUND: NOT_FOUND,
  OPEN_WINDOW: "@@route/OPEN_WINDOW",
  POLITICA_PRIVACIDADE: "@@route/politica-privacidade",
  LOGIN: "@@route/login",
  ESQUECI_SENHA: "@@route/esqueci-minha-senha",
  TROCAR_SENHA: "@@route/trocar-senha",
  PROPOSTAS: "@@route/propostas",
  ARTEFATOS_DOCUMENTO: "@@route/artefatos-documento",
  CARGA_CONSULTAS: "@@route/carga-consultas",
  CADASTRO_DOCUMENTOS: "@@route/cadastro-documentos",
  CADASTRO_PRODUTOS: "@@route/cadastro-produtos",
  PROPOSTA: "@@route/proposta",
  PARTICIPANTE: "@@route/proposta/participante",
  IMOVEL: "@@route/proposta/imovel",
  ENVIO_EMAIL: "@@route/proposta/proposta/envio-email/atividade",
  NOVO_PARTICIPANTE: "@@route/proposta/novo-participante",
  INTERVENIENTE_QUITANTE: "@@route/proposta/interveniente",
  ADMINISTRACAO_USUARIOS: "@@route/administracao/usuarios",
  ADMINISTRACAO_ORGANIZACAO: "@@route/administracao/organizacao",
  EXPORT_CONSULTAS: "@@route/exportar-consultas",
  ADMINISTRACAO_DOCUMENTOS: "@@route/administracao/documentos",
  ADMINISTRACAO_ATIVIDADES: "@@route/administracao/atividades",
  ADMINISTRACAO_ETAPAS: "@@route/administracao/etapas",
  ADMINISTRACAO_MCHECKLIST: "@@route/administracao/mchecklist",
  ADMINISTRACAO_PRODUTOS: "@@route/administracao/produtos",
  ADMINISTRACAO_PERFIS: "@@route/administracao/perfis",
  ADMINISTRACAO_RELATORIO_BI: "@@route/administracao/relatorios",
  EXPORT_SLA: "@@route/exportar-sla",
  DASHBOARD: "@@routes/dashboard",
  ADMINISTRACA0_TIPOS_DE_CONVENIOS: "@@route/administracao/tipos-de-convenios",
  ADMINISTRACAO_LOCALIDADES: "@@route/administracao/localidades",
  ADMINISTRACAO_MOTIVO_CANCELAMENTO:
    "@@route/administracao/motivo-cancelamento",
  ADMINISTRACAO_PRODUTOSCONVENIO: "@@route/administracao/produtosConvenio",
  ESCOLHA_PRODUTO: "@@route/proposta/selecaoProduto",
  NOVA_CORBAN: "@@route/nova-corban",
  PARTICIPANTE_CORBAN: "@@route/proposta/corban/participante",
  INCLUIR_SOCIO_CORBAN: "@@route/proposta/corban/novo-participante",
  NOVO_SOCIO_CORBAN: "@@route/nova-corban/socio",
  PARTICIPANTE_SOCIO_CORBAN: "@@route/proposta/corban/socio",
  ADMINISTRACAO_TEMPLATES: "@@route/administracao/templates",
  ADMINISTRACAO_RELATORIO_AUDITORIA:
    "@@route/administracao/relatorio-auditoria",
};
export const actions = {
  redirectTo: (route, params = {}) => createSyncAction(route, params),
  openNewWindowWith: (route, params = {}) =>
    createSyncAction(types.OPEN_WINDOW, {
      route,
      params,
    }),
  rejectTo: (route, params = {}) =>
    createSyncAction(route, params, {
      meta: {
        location: {
          kind: "redirect",
        },
      },
    }),
};
