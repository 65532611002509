import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import { ControlledSelect } from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";

const TrocarSituacaoConsulta = ({
  handleSubmit,
  formProps,
  dominioData,
  submitting,
  handleClose,
  handleOpen,
  open,
  motivoCancelameto,
  showMotivoCancelamento,
  handleOpcaoCanceladoSelected,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      onEnter={handleOpen}
      classes={{ paper: "w100" }}
      maxWidth="sm"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Situação da Consulta</div>
        <form
          className="d-flex flex-column"
          onSubmit={formProps.handleSubmit(handleSubmit)}
        >
          <div className="row">
            <div className="col-12">
              <ControlledSelect
                name={"situacao"}
                margin={"none"}
                formProps={formProps}
                label={"Situação da Consulta"}
              >
                {dominioData.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.key}
                    value={item.key}
                    onClick={() => handleOpcaoCanceladoSelected(item.key)}
                  >
                    {item.desc}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
          </div>

          {showMotivoCancelamento && (
            <div className="row">
              <div className="col-12">
                <ControlledSelect
                  name={"motivoCancelamento"}
                  margin={"none"}
                  formProps={formProps}
                  label={"Motivo de Cancelamento"}
                  loading={motivoCancelameto?.loading}
                >
                  {motivoCancelameto?.data
                    ?.sort((a, b) => (a.descricao > b.descricao ? 1 : -1))
                    ?.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.descricao}
                      </MenuItem>
                    ))}
                </ControlledSelect>
              </div>
            </div>
          )}
          <div className={"row justify-content-center mb-3 mt-2"}>
            <div className="col-6 mt-3">
              <Button
                loading={submitting}
                label={"CONFIRMAR"}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TrocarSituacaoConsulta;
