import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/relatorioBI.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarRelatoriosApi = () => {
  return authenticatedRequest({
    url: "/administracao/relatorio-bi",
    isResourceService: true,
    method: "get",
  });
};

const atualizarRelatorioApi = (relatorio) => {
  return authenticatedRequest({
    url: `/administracao/relatorio-bi/${relatorio.id}`,
    isResourceService: true,
    method: "put",
    body: { ...relatorio },
  });
};

const criarRelatorioApi = (relatorio) => {
  return authenticatedRequest({
    url: "/administracao/relatorio-bi",
    isResourceService: true,
    method: "post",
    body: { ...relatorio },
  });
};

const excluirRelatorioApi = (values) => {
  return authenticatedRequest({
    url: `/administracao/relatorio-bi/delete/${values}`,
    isResourceService: true,
    method: "delete",
  });
};

const mostrarRelatorioApi = (values) => {
  return authenticatedRequest({
    url: `/administracao/relatorio-bi/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const listarRelatorio = basicFlow({
  actionGenerator: actions.listarRelatorios,
  api: listarRelatoriosApi,
});

const mostrarRelatorio = basicFlow({
  actionGenerator: actions.mostrarRelatorio,
  api: mostrarRelatorioApi,
});

const atualizarRelatorio = basicFlow({
  actionGenerator: actions.atualizarRelatorio,
  api: atualizarRelatorioApi,
  postSuccess: function* () {
    yield put(actions.listarRelatorios.request());
    yield toast.success("Atualização realizada com sucesso");
  },
});

const criarRelatorio = basicFlow({
  actionGenerator: actions.criarRelatorio,
  api: criarRelatorioApi,
  postSuccess: function* () {
    yield put(actions.listarRelatorios.request());
    yield toast.success("Inclusão realizada com sucesso");
  },
});

const excluirRelatorio = basicFlow({
  actionGenerator: actions.excluirRelatorio,
  api: excluirRelatorioApi,
  postSuccess: function* () {
    yield put(actions.listarRelatorios.request());
    yield toast.success("Exclusão realizada com sucesso");
  },
});

function* listaRelatorioRouteWatcher() {
  yield routeWatcher(routes.ADMINISTRACAO_RELATORIO_BI, function* () {
    yield put(actions.listarRelatorios.request());
  });
}

export const sagas = [
  listaRelatorioRouteWatcher(),
  listarRelatorio.watcher(),
  atualizarRelatorio.watcher(),
  criarRelatorio.watcher(),
  mostrarRelatorio.watcher(),
  excluirRelatorio.watcher(),
];
