import React, { useEffect, useState } from "react";
import { IconButton, Popover, Tooltip } from "@material-ui/core";
import {
  ListAltOutlined,
  DoneSharp,
  InfoOutlined,
  MailOutlined,
} from "@material-ui/icons";
import { responsavelAtividade } from "../../../../utils/parametros";
import { Button, MenuItem } from "../../../../components";
import Menu from "@material-ui/core/Menu";
import ComentarioDialog from "./ComentarioDialog";

const Atividade = ({
  atividade,
  index,
  perfil,
  handleOpenDetalhesDocumento,
  atualizarSituacao,
  loading,
  idPerfil,
  handleOpenEnvioEmail,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPerfil, setIsPerfil] = useState(false);
  const [openComentarioPopUp, setOpenComentarioPopUp] = useState(false);
  const [dadosEncerramento, setDadosEncerramento] = useState({});

  const handleCloseComentarioPopUp = () => {
    setOpenComentarioPopUp(false);
  };

  const handleOpenComentarioPopUp = (
    evento,
    atividade,
    situacao,
    decisao,
    situacaoAtual
  ) => {
    setDadosEncerramento({
      evento: evento,
      atividade: atividade,
      situacao: situacao,
      decisao: decisao,
      situacaoAtual,
    });
    setOpenComentarioPopUp(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const existe = atividade?.Atividade?.Perfils.filter((item) => {
      return item.id === idPerfil;
    });

    if (existe?.length > 0) {
      setIsPerfil(true);
    }
  }, [atividade, idPerfil]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitComentarioPopUp = (comentario) => {
    atualizarSituacao(
      dadosEncerramento.evento,
      dadosEncerramento.atividade,
      dadosEncerramento.situacao,
      dadosEncerramento.decisao,
      dadosEncerramento.situacaoAtual,
      comentario
    );
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-sm-12 col-lg-4 mt-sm-2 mt-lg-0">
        <div className={"d-flex align-items-center"}>
          <div className="resumir-texto">
            <Tooltip title={atividade.Atividade.nome}>
              <span>{atividade.Atividade.nome}</span>
            </Tooltip>
          </div>
          {!!atividade.Atividade.orientacao && (
            <>
              <InfoOutlined
                className="ml-2 info-documento"
                onClick={handleClick}
              />
              <Popover
                id={atividade.Atividade.id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className="p-2 bg-dark color-white orientacao">
                  {atividade.Atividade.orientacao}
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip
          title={
            responsavelAtividade.filter(
              (s) => s.id === atividade.Atividade.responsavel
            )[0].nome
          }
        >
          <span>
            {
              responsavelAtividade.filter(
                (s) => s.id === atividade.Atividade.responsavel
              )[0].nome
            }
          </span>
        </Tooltip>
      </div>
      <div
        className={
          Date.parse(atividade.dataSla) < Date.now()
            ? "col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 sla-vencido"
            : "col-sm-12 col-lg-2 mt-sm-2 mt-lg-0"
        }
      >
        <span>
          {!!atividade.dataSla
            ? new Date(atividade.dataSla).toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              })
            : ""}{" "}
          {"(D+" + atividade.Atividade.sla + ")"}
        </span>
      </div>
      <div className="col my-2 my-lg-0">
        <div className="d-flex">
          <BotaoAtividade
            key={atividade.atividade}
            atividade={atividade}
            atualizarSituacao={atualizarSituacao}
            perfil={perfil}
            loading={loading}
            isPerfil={isPerfil}
            handleOpenComentarioPopUp={handleOpenComentarioPopUp}
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-auto my-2 my-lg-0 w-92 d-flex justify-content-end">
        {(perfil === "BPO" || perfil === "BANCO") &&
          !!atividade.Atividade.templates &&
          atividade.Atividade.templates.length > 0 && (
            <Tooltip title="Envio de E-mails">
              <IconButton
                className="p-2"
                onClick={() => handleOpenEnvioEmail(atividade.atividade)}
              >
                <MailOutlined className={"color-secondary icon-indexado"} />
              </IconButton>
            </Tooltip>
          )}
        <Tooltip title="Detalhes da Atividade">
          <IconButton
            className="p-2"
            onClick={() => handleOpenDetalhesDocumento(atividade)}
          >
            <ListAltOutlined className={"color-secondary icon-indexado"} />
          </IconButton>
        </Tooltip>
      </div>
      {openComentarioPopUp && (
        <ComentarioDialog
          open={openComentarioPopUp}
          handleClose={handleCloseComentarioPopUp}
          handleSubmit={handleSubmitComentarioPopUp}
          hasData={atividade.Atividade.isProducao === "S"}
        />
      )}
    </div>
  );
};

const BotaoAtividade = ({
  atividade,
  atualizarSituacao,
  isPerfil,
  loading,
  handleOpenComentarioPopUp,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (isPerfil) {
      if (atividade.situacao !== "C" && atividade.situacao !== "S") {
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={!isPerfil}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        loading={loading}
        key={atividade.atividade}
        className={
          atividade.situacao === "C" || atividade.situacao === "S" || !isPerfil
            ? "btn-atualiza-atividade-concluido"
            : "btn-atualiza-atividade"
        }
        label={atividade?.decisao ?? "Pendente"}
        endIcon={
          atividade.situacao === "C" || atividade.situacao === "S" ? (
            <DoneSharp className={"color-concluido"} />
          ) : (
            ""
          )
        }
      />
      <Menu
        disabled={!isPerfil}
        id="simple-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        key={"menu" + atividade.atividade}
      >
        {atividade.Atividade.AtividadeDecisaos.sort((a, b) => {
          if (a.descricao > b.descricao) {
            return 1;
          }
          if (a.descricao < b.descricao) {
            return -1;
          }
          return 0;
        }).map((s) => (
          <MenuItem
            onClick={(evento) => {
              if (
                atividade.Atividade.isComentarioObrigatorio === "S" &&
                s.padrao === "C"
              ) {
                handleOpenComentarioPopUp(
                  evento,
                  atividade,
                  s.padrao,
                  s,
                  atividade.situacao
                );
              } else if (
                atividade.Atividade.isComentarioObrigatorio === "S" &&
                s.padrao === "S"
              ) {
                handleOpenComentarioPopUp(
                  evento,
                  atividade,
                  s.padrao,
                  s,
                  atividade.situacao
                );
              } else {
                atualizarSituacao(
                  evento,
                  atividade,
                  s.padrao,
                  s,
                  atividade.situacao
                );
                handleClose();
              }
            }}
            key={atividade.atividade + " " + s.padrao}
          >
            {s.descricao}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Atividade;
