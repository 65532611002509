import React, { useState, useRef } from "react";
import { DeleteForeverSharp } from "@material-ui/icons";

const CardWithDeleteButton = ({
  renderDeleteButton,
  renderSecondary,
  renderDefault,
  canDelete = true,
  className,
  ...otherProps
}) => {
  const buttonRef = useRef(null);
  const [defaultVisible, setDefaultVisible] = useState(true);
  const [buttonClass, setButtonClass] = useState("");

  const _handleInitialClick = () => {
    if (buttonRef.current) {
      setButtonClass("delete-button-larger");
    }
    setDefaultVisible(false);
  };
  const _handleSecondaryClick = () => {
    setDefaultVisible(true);
    setButtonClass("");
  };

  if (!renderDeleteButton) {
    renderDeleteButton = ({ handleClick }) => (
      <div
        className={"delete-button " + buttonClass}
        onClick={handleClick}
        ref={buttonRef}
      >
        <DeleteForeverSharp className="position-absolute clickable delete-icon" />
      </div>
    );
  }

  return (
    <div {...otherProps} className={"card restrict " + className}>
      {defaultVisible && renderDefault()}
      {!defaultVisible &&
        renderSecondary({ handleClick: _handleSecondaryClick })}
      {!!canDelete && renderDeleteButton({ handleClick: _handleInitialClick })}
    </div>
  );
};
export default CardWithDeleteButton;
