import ImoveisBlock from "./ImoveisBlock";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  getDetalheProposta,
  getPropostaLoading,
} from "../../../../selectors/propostas.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../../reducers/rotas.actions";

const ImoveisBlockConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getPropostaLoading);
  const detalhe = useSelector(getDetalheProposta);
  const { imovel, id } = loading ? {} : detalhe;

  const handleOnClick = useCallback(
    (proposta, id) => {
      dispatch(routeActions.redirectTo(routes.IMOVEL, { proposta, id }));
    },
    [dispatch]
  );

  return (
    <ImoveisBlock
      loading={loading}
      proposta={id}
      imoveis={imovel}
      handleOnClick={handleOnClick}
    />
  );
};

export default ImoveisBlockConnected;
