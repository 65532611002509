import { useDispatch, useSelector } from "react-redux";
import { getMatrizChecklist } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/matrizChecklist.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormCondicao from "./FormCondicao";
import {
  getDocumentos,
  getProdutos,
  getLocalidades,
  getCartorios,
} from "../../../selectors/dominios.selectors";
import { tipoPessoa, qualificacao } from "../../../utils/parametros";

const FormCondicaoConnected = () => {
  const dispatch = useDispatch();
  const matriz = useSelector(getMatrizChecklist);
  const { data: dominioDocumentos } = useSelector(getDocumentos);
  const { data: dominioProdutos } = useSelector(getProdutos);
  const { data: dominioLocalidades } = useSelector(getLocalidades);
  const { data: dominioCartorios } = useSelector(getCartorios);
  const condicao = matriz.condicao;

  const initialValues = {
    ...condicao,
    Localidade: {
      id: condicao.Localidade?.id ?? "",
      nome: condicao.Localidade?.nome ?? "",
    },
    Organizacao: {
      id: condicao.Organizacao?.id ?? "",
      nome: condicao.Organizacao?.nome ?? "",
    },
    uf: condicao?.uf,
  };

  const rules = {
    "Documento.id": validators.string({ required: true }),
    qualificacao: validators.string({ required: true }),
    "Produto.id": validators.string({ required: true }),
    condicao: validators.string({ required: true }),
    participante: validators.string({
      custom: {
        requiredIfOther: (value) => {
          const { qualificacao } = formProps.getValues();

          if (qualificacao !== "IM") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        documento: values.Documento.id,
        condicao: values.condicao,
        produto: values.Produto.id,
        qualificacao: values.qualificacao,
        participante: values.participante,
        localidade: values.Localidade.id,
        uf: values.uf,
        cartorio: values.Organizacao.id,
        id: i,
      };

      if (!i) {
        dispatch(
          actions.incluirCondicaoChecklist.request({
            condicao: request,
            "registros-pagina": matriz["registros-pagina"],
          })
        );
      } else {
        dispatch(
          actions.atualizarMatriz.request({
            condicao: request,
            "registros-pagina": matriz["registros-pagina"],
          })
        );
      }
    },
    // eslint-disable-next-line
    [dispatch, condicao, matriz]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaMatriz());
  }, [dispatch]);

  return (
    <FormCondicao
      condicao={condicao}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
      qualificacao={qualificacao}
      tipoPessoa={tipoPessoa}
      dominioDocumentos={dominioDocumentos}
      dominioProdutos={dominioProdutos}
      dominioLocalidades={dominioLocalidades}
      dominioCartorios={dominioCartorios}
    />
  );
};

export default FormCondicaoConnected;
