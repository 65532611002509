import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/localidades.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarLocalidadesApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/localidades",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarLocalidadeApi = ({ localidade }) => {
  return authenticatedRequest({
    url: "/administracao/localidade",
    isResourceService: true,
    method: "post",
    body: { localidade: localidade },
  });
};

const excluirLocalidadeApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/localidade/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarLocalidadeApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/localidade/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarLocalidades = basicFlow({
  actionGenerator: actions.listarLocalidades,
  api: listarLocalidadesApi,
});

const atualizarLocalidade = basicFlow({
  actionGenerator: actions.atualizarLocalidade,
  api: atualizarLocalidadeApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirLocalidade = basicFlow({
  actionGenerator: actions.incluirLocalidade,
  api: atualizarLocalidadeApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirLocalidade = basicFlow({
  actionGenerator: actions.excluirLocalidade,
  api: excluirLocalidadeApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarLocalidade = basicFlow({
  actionGenerator: actions.buscarLocalidade,
  api: buscarLocalidadeApi,
});

function* listaLocalidadesRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_LOCALIDADES, function* () {
    yield put(
      actions.listarLocalidades.request({
        pagina,
      })
    );
  });
}

export const sagas = [
  listarLocalidades.watcher(),
  listaLocalidadesRouteWatcher(),
  atualizarLocalidade.watcher(),
  incluirLocalidade.watcher(),
  buscarLocalidade.watcher(),
  excluirLocalidade.watcher(),
];
