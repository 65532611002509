import ListaDocumentos from "./ListaDocumentos";
import { useDispatch, useSelector } from "react-redux";
import {
  getChecklistList,
  getFilter,
  getLoading,
  getUploading,
} from "../../../../selectors/documentos.selectors";
import { getUsuario } from "../../../../selectors/usuario.selectors";
import { getDetalheProposta } from "../../../../selectors/propostas.selectors";
import { useCallback } from "react";
import { actions } from "reducers/propostas.actions";
import {
  actions as routeActions,
  types as routes,
} from "reducers/rotas.actions";

const ListaDocumentosConnected = () => {
  const dispatch = useDispatch();
  const { nome, perfil, idPerfil, produtos } = useSelector(getUsuario);

  const handleFilterClick = useCallback(
    (valor) => {
      dispatch(actions.alterarFiltroChecklist(valor));
    },
    [dispatch]
  );

  const handleUpload = useCallback(
    (metadata, values) => {
      dispatch(
        actions.uploadArtefato.request({
          id: metadata.name,
          files: values,
          motivo: metadata.motivo ?? "",
          callback: metadata.callback,
          usuario: nome,
        })
      );
    },
    [dispatch, nome]
  );

  const handleOpenImagens = useCallback(
    (id) => {
      dispatch(
        routeActions.openNewWindowWith(routes.ARTEFATOS_DOCUMENTO, { id })
      );
    },
    [dispatch]
  );

  const checklist = useSelector(getChecklistList);
  const loading = useSelector(getLoading);
  const filter = useSelector(getFilter);
  const uploading = useSelector(getUploading);
  const detalheProposta = useSelector(getDetalheProposta);

  const filteredChecklist = getFilteredChecklist(checklist, filter);
  const codigoProduto = produtos.find(
    (item) => item.id === detalheProposta?.Produto?.id
  )?.codigo;
  return (
    <ListaDocumentos
      loading={loading}
      proposta={detalheProposta?.id}
      produto={codigoProduto}
      filtro={filter}
      checklist={filteredChecklist}
      handleFilterClick={handleFilterClick}
      handleOpenImagens={handleOpenImagens}
      handleUpload={handleUpload}
      uploading={uploading}
      perfil={perfil}
      idPerfil={idPerfil}
    />
  );
};

const getFilteredChecklist = (list, filter) => {
  switch (filter) {
    case "aprovados":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "A"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "A"
          ),
        };
      });

    case "recusados":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "S"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "S"
          ),
        };
      });

    case "pendentes":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) => documento.situacao_documento === "I"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) => documento.situacao_documento === "I"
          ),
        };
      });

    case "falta":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) => documento.situacao_documento === "F"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) => documento.situacao_documento === "F"
          ),
        };
      });

    case "iniciais":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) => documento.condicao === "PAD1"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) => documento.condicao === "PAD1"
          ),
        };
      });

    case "todos":
    default:
      return list;
  }
};

export default ListaDocumentosConnected;
