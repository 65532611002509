import PerfisPage from "./PerfisPage";
import { useDispatch, useSelector } from "react-redux";
import { getPerfis } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/perfis.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const PerfisPageConnected = () => {
  const dispatch = useDispatch();
  const perfis = useSelector(getPerfis);
  const loading = perfis?.loading;
  const total = perfis?.total;
  const pagina = perfis?.page;

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(actions.listarPerfis.request({ pagina: page }));
    },
    [dispatch]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarPerfil({ perfil: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaPerfil());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirPerfil.request({ idPerfil: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "tipo",
        value: values.search,
      };
      dispatch(actions.buscarPerfil.request({ search: request, pagina: 1 }));
    },
    [dispatch]
  );

  return (
    <PerfisPage
      perfis={perfis.list}
      perfil={perfis.perfil}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default PerfisPageConnected;
