import React, { useCallback, useEffect } from "react";
import DestinatarioDialog from "./DestinatarioDialog";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";

const DestinatarioDialogConnected = ({ handleClose, data }) => {
  const form = useForm();

  const rules = {
    tipo: validators.string({ required: true }),
    outrosDestinatarios: validators.email({
      required: false,
      custom: {
        requiredIfOther: (value) => {
          const tipo = form.getValues("tipo");

          if (tipo === "OU" && !value) {
            return "Campo obrigatório";
          }
          return true;
        },
      },
    }),
  };
  const initialValues = {
    tipo: "",
    outrosDestinatarios: "",
    ...data,
  };
  const formProps = {
    ...form,
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      if (data.index !== undefined) {
        if (data.handleEdit(values, data.index)) {
          handleClose();
        }
      } else {
        if (data.handleAdd(values)) {
          handleClose();
        }
      }
    },
    [data, handleClose]
  );

  const { reset } = formProps;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <DestinatarioDialog
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      open={data.open}
      data={initialValues}
      formProps={formProps}
    />
  );
};

export default DestinatarioDialogConnected;
