import basicFlow, { genericErrorHandler } from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions } from "../reducers/propostas.actions";
import { actions as dominiosActions } from "../reducers/dominios.actions";
import { authenticatedRequest, unauthenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import {
  getDetalheProposta,
  getFiltroValores,
  getPaginaAtual,
} from "../selectors/propostas.selectors";
import { getPayload } from "../selectors/rotas.selectors";
import { removeNonDigitsFromString } from "../utils/basic";
import { toast } from "react-toastify";
import { getPerfil } from "../selectors/usuario.selectors";

const listaPropostasApi = (values) => {
  return authenticatedRequest({
    url: "/listaConsultas",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarSituacaoAtividadeApi = (values) => {
  return authenticatedRequest({
    url: "/atividade-decisao",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const consultarPropostaApi = (values) => {
  return authenticatedRequest({
    url: "/detalhesConsulta/" + values,
    isResourceService: true,
    method: "get",
  });
};

const consultarParticipanteApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/pessoa/" + values,
    isResourceService: true,
    method: "get",
  });
};

const consultaCepCorreiosApi = ({ value }) => {
  return unauthenticatedRequest({
    url: `/ws/${removeNonDigitsFromString(value)}/json/`,
    method: "get",
    base: "https://viacep.com.br",
  });
};

const incluirAtualizarPessoaApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/pessoa",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const excluirPessoaApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/pessoa",
    isResourceService: true,
    method: "delete",
    body: values,
  });
};

const consultaIntervenienteApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/iq/" + values,
    isResourceService: true,
    method: "get",
  });
};

const atualizaIntervenienteApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/iq",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizaObservacaoApi = (values) => {
  return authenticatedRequest({
    url: "/detalhesConsulta/observacao",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const getWorkflowPropostasApi = (values) => {
  return authenticatedRequest({
    url: "/workflow-consulta/" + values,
    isResourceService: true,
    method: "get",
  });
};

const abrirAtividadeApi = (values) => {
  return authenticatedRequest({
    url: "/abrir-atividade",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarSituacaoAtividadeApi = (values) => {
  return authenticatedRequest({
    url: "/atualizar-atividade",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarSituacaoConsultaApi = (values) => {
  return authenticatedRequest({
    url: "/consulta/atualizar-consulta",
    isResourceService: true,
    method: "post",
    body: {
      situacao: values.situacao,
      consultaID: values.consultaID,
      motivoCancelamento: values.motivoCancelamento,
    },
  });
};

const atualizarPrioridadeConsultaApi = (values) => {
  return authenticatedRequest({
    url: "/consulta/atualizar-prioridade-consulta",
    isResourceService: true,
    method: "post",
    body: { prioridade: values.prioridade, consultaID: values.consultaID },
  });
};

const historicoPropostaApi = (values) => {
  return authenticatedRequest({
    url: "/historico-atividades/" + values,
    isResourceService: true,
    method: "get",
  });
};

const exportarHistoricoAtividadesApi = (values) => {
  return authenticatedRequest({
    url: `/exportar-historico-atividades/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const abrirAtividade = basicFlow({
  actionGenerator: actions.abrirAtividade,
  api: abrirAtividadeApi,
  postSuccess: function* ({ original }) {
    toast.success("Atividade adicionada com sucesso.");
    yield put(actions.getWorkflow.request(original.consulta));
    original.handleClose();
  },
});

const atualizarStatusAtividade = basicFlow({
  actionGenerator: actions.atualizarSituacaoAtividade,
  api: atualizarSituacaoAtividadeApi,
  postSuccess: function* ({ original }) {
    yield put(actions.getWorkflow.request(original.consulta));
    toast.success("Situação da fase atualizada com sucesso");
  },
  postFailure: function* ({ error, original, values }) {
    if (!!error.response.data.error.context) {
      yield put(
        actions.abrirModalDocumentos({
          data: error.response.data.error.context.documentos,
          open: true,
        })
      );
    } else {
      yield genericErrorHandler({ error });
    }
  },
});

const listarSituacaoAtividade = basicFlow({
  actionGenerator: actions.listarSituacaoAtividades,
  api: listarSituacaoAtividadeApi,
});

const consultaInterveniente = basicFlow({
  actionGenerator: actions.consultarIq,
  transform: function* (payload) {
    let consulta = payload;
    if (!consulta) {
      const detalhe = yield select(getDetalheProposta);
      if (!detalhe) {
        const routePayload = yield select(getPayload);
        consulta = routePayload?.id;
      } else {
        consulta = detalhe.id;
      }
    }
    return consulta;
  },
  api: consultaIntervenienteApi,
});

const atualizarInterveniente = basicFlow({
  actionGenerator: actions.atualizarIq,
  api: atualizaIntervenienteApi,
  postSuccess: function* ({ original }) {
    toast.success("Informações salvas com sucesso.");
    yield put(
      routeActions.redirectTo(routes.PROPOSTA, { id: original.idProposta })
    );
  },
});

const listaPropostas = basicFlow({
  actionGenerator: actions.listarPropostas,
  api: listaPropostasApi,
});

const consultarPropostas = basicFlow({
  actionGenerator: actions.consultarProposta,
  api: consultarPropostaApi,
  preSuccess: function ({ response }) {
    response.data[0].interveniente = {
      nomeInterveniente: response.data[0].interveniente,
      idConsulta: response.data[0].id,
    };
  },
});

const consultarParticipante = basicFlow({
  actionGenerator: actions.consultarParticipante,
  api: consultarParticipanteApi,
});

const historicoProposta = basicFlow({
  actionGenerator: actions.historicoProposta,
  api: historicoPropostaApi,
});

const consultarCep = basicFlow({
  actionGenerator: actions.consultarCepCorreios,
  api: consultaCepCorreiosApi,
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
});

const atualizarSituacaoConsulta = basicFlow({
  actionGenerator: actions.atualizarSituacaoConsulta,
  api: atualizarSituacaoConsultaApi,
  postSuccess: ({ original: { handleCloseAlterarSituacao } }) => {
    toast.success("Situação atualizada com sucesso");
    handleCloseAlterarSituacao();
  },
});

const atualizarPrioridadeConsulta = basicFlow({
  actionGenerator: actions.atualizarPrioridadeConsulta,
  api: atualizarPrioridadeConsultaApi,
  postSuccess: ({ original: { handleCloseAlterarPrioridade } }) => {
    toast.success("Prioridade atualizada com sucesso");
    handleCloseAlterarPrioridade();
  },
});

function* listaPropostasRouteWatcher(values) {
  yield routeWatcher(routes.PROPOSTAS, function* (values) {
    const pagina = yield select(getPaginaAtual);
    const filtroValores = yield select(getFiltroValores);
    const perfil = yield select(getPerfil);
    if (perfil !== "CLIENTE" && perfil !== "AGENCIA") {
      yield put(dominiosActions.analistas.request());
    }
    yield put(dominiosActions.motivos_cancelamento.request());
    yield put(
      actions.listarPropostas.request({
        pagina,
        pesquisa: values?.payload,
        filtro: { ...filtroValores },
      })
    );
  });
}

function* intervenienteRouteWatcher() {
  yield routeWatcher(routes.INTERVENIENTE_QUITANTE, function* () {
    const proposta = yield select(getPayload);
    const detalhe = yield select(getDetalheProposta);
    if (!detalhe) yield put(actions.consultarIq.request(proposta.id));
  });
}

function* propostaRouteWatcher() {
  yield routeWatcher(routes.PROPOSTA, function* () {
    const proposta = yield select(getPayload);

    yield put(actions.consultarProposta.request(proposta.id));
    yield put(actions.listarSituacaoAtividades.request({ id: proposta.id }));

    yield put(actions.getWorkflow.request(proposta.id));
  });
}

function* participanteRouteWatcher() {
  yield routeWatcher(routes.PARTICIPANTE, function* () {
    const proposta = yield select(getPayload);

    yield put(dominiosActions.produtosConvenio.request());
    yield put(dominiosActions.tipo_convenio.request());
    if (!!proposta) {
      yield put(actions.consultarParticipante.request(proposta.idParticipante));
    }
  });
}

function* participanteCorbanRouteWatcher() {
  yield routeWatcher(routes.PARTICIPANTE_CORBAN, function* () {
    const proposta = yield select(getPayload);
    yield put(dominiosActions.empresasCorrespondentes.request());
    if (!!proposta) {
      yield put(actions.consultarParticipante.request(proposta.idParticipante));
    }
  });
}

function* participanteSocioCorbanRouteWatcher() {
  yield routeWatcher(routes.PARTICIPANTE_SOCIO_CORBAN, function* () {
    const proposta = yield select(getPayload);
    if (!!proposta) {
      yield put(actions.consultarParticipante.request(proposta.idParticipante));
    }
  });
}

function* novoParticipanteRouteWatcher() {
  yield routeWatcher(routes.NOVO_PARTICIPANTE, function* () {
    yield put(dominiosActions.produtosConvenio.request());
    yield put(dominiosActions.tipo_convenio.request());
    yield put(dominiosActions.empresasCorrespondentes.request());
    yield put(actions.abrirNovoParticipante());

    // const proposta = yield select(getPayload);
    // const dadosProposta = yield select(getDetalheProposta);
    // if (!dadosProposta) {
    //   yield put(actions.consultarProposta.request(proposta.id));
    //   yield put(routeActions.redirectTo(routes.PROPOSTA, { id: proposta.id }));
    // }
  });
}

function* novoCadastroRouteWatcher() {
  yield routeWatcher(routes.ESCOLHA_PRODUTO, function* () {
    yield put(dominiosActions.produtos.request());
    yield put(dominiosActions.empresasCorrespondentes.request());
  });
}

function* novaCorbanRouteWatcher() {
  yield routeWatcher(routes.NOVA_CORBAN, function* () {
    yield put(dominiosActions.produtos.request());
    yield put(dominiosActions.empresasCorrespondentes.request());
  });
}

const incluirAtualizarPessoa = basicFlow({
  actionGenerator: actions.atualizarParticipante,
  api: incluirAtualizarPessoaApi,
  postSuccess: function* ({ original, response }) {
    toast.success("Informações salvas com sucesso");
    yield put(
      routeActions.redirectTo(routes.PROPOSTA, {
        id:
          original.id_consulta !== ""
            ? original.id_consulta
            : response.data.id_consulta,
      })
    );
  },
});

const excluirPessoa = basicFlow({
  actionGenerator: actions.excluirParticipante,
  api: excluirPessoaApi,
  postSuccess: function* ({ original }) {
    toast.success("Participante excluído com sucesso");
    yield put(actions.consultarProposta.request(original.idProposta));
  },
});

const atualizarObservacao = basicFlow({
  actionGenerator: actions.atualizarObservacao,
  api: atualizaObservacaoApi,
  postSuccess: function ({ original }) {
    if (original?.callback) original.callback();
    if (!original?.hideToast) {
      toast.success("Observação armazenada com sucesso");
    }
  },
});

const getWorkflow = basicFlow({
  actionGenerator: actions.getWorkflow,
  api: getWorkflowPropostasApi,
  postSuccess: function* () {
    const detalheProposta = yield select(getDetalheProposta);
    if (!!detalheProposta)
      yield put(
        dominiosActions.etapas.request({
          produto: detalheProposta?.Produto?.id,
        })
      );
  },
});

const atualizarAnalista = basicFlow({
  actionGenerator: actions.atualizarAnalista,
  api: (values) => {
    return authenticatedRequest({
      url: "/analista",
      isResourceService: true,
      method: "post",
      body: values,
    });
  },
  postSuccess: ({ original: { handleClose } }) => {
    toast.success("Analista atualizado com sucesso");
    handleClose();
  },
});

const exportarHistoricoAtividade = basicFlow({
  actionGenerator: actions.exportarHistoricoConsultas,
  api: exportarHistoricoAtividadesApi,
});

const historicoEmailsApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/historico-emails/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const historicoEmails = basicFlow({
  actionGenerator: actions.historicoEmails,
  api: historicoEmailsApi,
});

const exportHistoricoEmailsApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/exportar-historico-emails/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const exportarHistoricoEmails = basicFlow({
  actionGenerator: actions.exportHistoricoEmails,
  api: exportHistoricoEmailsApi,
});

const historicoEmailsDetalhesApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/email-historico-conteudo/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const historicoEmailsDetalhes = basicFlow({
  actionGenerator: actions.historicoEmailsDetalhes,
  api: historicoEmailsDetalhesApi,
  postSuccess: function* ({ original, response }) {
    yield original.callback(response.data);
  },
});

export const sagas = [
  listaPropostas.watcher(),
  listaPropostasRouteWatcher(),
  consultarPropostas.watcher(),
  propostaRouteWatcher(),
  participanteRouteWatcher(),
  consultarParticipante.watcher(),
  consultarCep.watcher(),
  incluirAtualizarPessoa.watcher(),
  excluirPessoa.watcher(),
  novoParticipanteRouteWatcher(),
  consultaInterveniente.watcher(),
  atualizarInterveniente.watcher(),
  intervenienteRouteWatcher(),
  atualizarObservacao.watcher(),
  getWorkflow.watcher(),
  abrirAtividade.watcher(),
  atualizarStatusAtividade.watcher(),
  atualizarAnalista.watcher(),
  atualizarSituacaoConsulta.watcher(),
  atualizarPrioridadeConsulta.watcher(),
  historicoProposta.watcher(),
  exportarHistoricoAtividade.watcher(),
  listarSituacaoAtividade.watcher(),
  novoCadastroRouteWatcher(),
  participanteCorbanRouteWatcher(),
  novaCorbanRouteWatcher(),
  participanteSocioCorbanRouteWatcher(),
  historicoEmails.watcher(),
  exportarHistoricoEmails.watcher(),
  historicoEmailsDetalhes.watcher(),
];
