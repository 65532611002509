import formatters from "../../utils/formatters";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  AttachMoneyOutlined,
  HomeWorkOutlined,
} from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import Loader from "../../components/loaders";
import { DatePicker } from "../../components/inputs";

const CardProducaoMes = ({
  producaoMes,
  getProducao,
  getListaConsultasMes,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = useCallback(
    (value) => {
      const data = new Date(value);
      if (
        data.getMonth() !== new Date().getMonth() ||
        data.getFullYear() !== new Date().getFullYear()
      ) {
        data.setDate(1);
        data.setMonth(data.getMonth() + 1);
        data.setDate(0);
      }
      setSelectedDate(data);
      getProducao(new Date(data.getFullYear(), data.getMonth(), 1), data);
    },
    [getProducao]
  );

  return (
    <div className="card" style={{ minHeight: 100 }}>
      <div className="card-body d-flex flex-column">
        {!!producaoMes.loading && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Loader type={"dots"} color={"brand"} size={80} />
            </div>
          </div>
        )}
        {!producaoMes.loading && (
          <>
            <div
              className={"row d-flex align-items-center justify-content-center"}
            >
              <div className="card-title-dashboard color-brand">
                Produção de{" "}
                <DatePicker
                  disableFuture
                  variant={"outline"}
                  margin="none"
                  fullWidth={false}
                  views={["month", "year"]}
                  format="MMM/yyyy"
                  value={selectedDate}
                  className={"card-date-picker"}
                  onChange={handleDateChange}
                  InputProps={{
                    disabled: true,
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-3">
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <AttachMoneyOutlined
                    style={{ fontSize: 56, color: "grey" }}
                  />
                </div>
                <div
                  className={
                    "d-flex align-items-center justify-content-center mt-2"
                  }
                >
                  <div className="colored-card-data">
                    {producaoMes.data.valor > 0
                      ? producaoMes.data.valor / 1000000 > 1
                        ? formatters.numbers.nFixed(
                            producaoMes.data.valor / 1000000,
                            2
                          ) + "M"
                        : formatters.numbers.simple(
                            producaoMes.data.valor / 1000
                          ) + "K"
                      : 0}
                  </div>
                </div>
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <span className="colored-card-text">Reais</span>
                </div>
                <div className="mt-3 justify-content-center">
                  <div
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    {(producaoMes.data.percDiffValor === 0 ||
                      !producaoMes.data.percDiffValor) && (
                      <span className="colored-card-data">-</span>
                    )}
                    {!(producaoMes.data.percDiffValor === 0) &&
                      !!producaoMes.data.percDiffValor && (
                        <>
                          {producaoMes.data.percDiffValor > 0 && (
                            <ArrowUpwardOutlined className="producao-alta" />
                          )}
                          {producaoMes.data.percDiffValor < 0 && (
                            <ArrowDownwardOutlined className="producao-baixa" />
                          )}
                          <span
                            className={
                              producaoMes.data.percDiffValor < 0
                                ? "producao-baixa colored-card-text ml-2 d-inline-block"
                                : "colored-card-text ml-2 d-inline-block producao-alta"
                            }
                          >
                            {formatters.numbers.currency(
                              producaoMes.data.percDiffValor
                            )}{" "}
                            %
                          </span>
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-6 mt-3">
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <HomeWorkOutlined style={{ fontSize: 56, color: "grey" }} />
                </div>
                <div
                  className={
                    "d-flex align-items-center justify-content-center mt-2"
                  }
                >
                  <div
                    className="colored-card-data clickable"
                    onClick={getListaConsultasMes}
                  >
                    {producaoMes.data.quantidade}
                  </div>
                </div>
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <span className="colored-card-text">
                    {producaoMes.data.quantidade === 1
                      ? "Contrato"
                      : "Contratos"}
                  </span>
                </div>
                <div className="mt-3">
                  <div
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    {(producaoMes.data.percDiffQuantidade === 0 ||
                      !producaoMes.data.percDiffQuantidade) && (
                      <span className="colored-card-data">-</span>
                    )}
                    {producaoMes.data.percDiffQuantidade !== 0 &&
                      !!producaoMes.data.percDiffQuantidade && (
                        <>
                          {producaoMes.data.percDiffQuantidade > 0 && (
                            <ArrowUpwardOutlined className="producao-alta" />
                          )}
                          {producaoMes.data.percDiffQuantidade < 0 && (
                            <ArrowDownwardOutlined className="producao-baixa" />
                          )}
                          <span
                            className={
                              producaoMes.data.percDiffQuantidade < 0
                                ? "producao-baixa colored-card-text ml-2 d-inline-block"
                                : "colored-card-text ml-2 d-inline-block producao-alta"
                            }
                          >
                            {formatters.numbers.currency(
                              producaoMes.data.percDiffQuantidade
                            )}{" "}
                            %
                          </span>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CardProducaoMes;
