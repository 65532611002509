import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import formatters from "../../../../utils/formatters";

const DetalhesDocumentoDialog = ({ handleClose, detalhes, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Detalhes do Documento</div>
        <div className={"mb-3"}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Data/hora da criação</span>
              <div>
                <span className="data-label">
                  {formatters.dates.withHours(
                    new Date(detalhes.dataHoraCriacao)
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Motivo da criação</span>
              <div>
                <span className="data-label">
                  {detalhes.tipoCriacao === "A"
                    ? "AUTOMÁTICA"
                    : detalhes.tipoCriacao === "M"
                    ? "MANUAL"
                    : "SUBSTITUIÇÃO"}
                </span>
              </div>
            </div>
          </div>
          {!!detalhes.dataHoraIndexacao && (
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <span className="label">Data/hora da indexação</span>
                <div>
                  <span className="data-label">
                    {formatters.dates.withHours(
                      new Date(detalhes.dataHoraIndexacao)
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Indexado por</span>
                <div>
                  <span className="data-label">
                    {detalhes.usuarioIndexacao}
                  </span>
                </div>
              </div>
            </div>
          )}
          {!!detalhes.dataHoraAnalise && (
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <span className="label">Data/hora da análise</span>
                <div>
                  <span className="data-label">
                    {formatters.dates.withHours(
                      new Date(detalhes.dataHoraAnalise)
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Analisado por</span>
                <div>
                  <span className="data-label">{detalhes.usuarioAnalise}</span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Resultado da análise</span>
                <div>
                  <span className="data-label">
                    {detalhes.situacao_analise === "A"
                      ? "APROVADO"
                      : detalhes.situacao_analise === "S"
                      ? "RECUSADO"
                      : detalhes.situacao_analise === "D"
                      ? "DISPENSADO"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          )}
          {!!detalhes.motivoIndexacao && (
            <div className="row">
              <div className="col-12">
                <span className="label">Justificativa da Indexação</span>
                <div>
                  <span className="data-label">{detalhes.motivoIndexacao}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DetalhesDocumentoDialog;
