import React from "react";
import { CircularProgress } from "@material-ui/core";
import Dots from "./Dots";

const MainLoader = ({ type = "discs", ...otherProps }) => {
  return (
    <>
      {type === "circle" ? (
        <CircularProgress {...otherProps} />
      ) : type === "dots" ? (
        <Dots {...otherProps} />
      ) : null}
    </>
  );
};

export default MainLoader;
