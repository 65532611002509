import BasePage from "../../BasePage";
import Loader from "../../../components/loaders";
import React, { useCallback, useState } from "react";
import { Add, SearchOutlined } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import TemplateEmailForm from "./TemplateEmailForm.connected";
import { ControlledTextfield } from "../../../components/inputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Template from "./Template";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";

const TemplateEmailPage = ({
  loading,
  templates,
  template,
  total,
  pagina,
  handlePagination,
  handleEdit,
  handleNew,
  formProps,
  handleSearch,
  handleDelete,
}) => {
  const [dadosExclusao, setDadosExclusao] = useState({
    open: false,
  });

  const handleCloseExclusao = useCallback(() => {
    setDadosExclusao({
      open: false,
    });
  }, []);

  const handleOpenExclusao = useCallback((values) => {
    setDadosExclusao({
      open: true,
      template: values,
    });
  }, []);

  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row spacer">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={80} />
          </div>
        </div>
      )}
      {!loading && !template && (
        <div className="row spacer">
          <div className="col-12">
            <div className="card" style={{ minHeight: 100 }}>
              <div className="card-body d-flex flex-column">
                <div className={"row align-items-center"}>
                  <div className={"col mt-0"}>
                    <div className="card-title color-brand">
                      Templates de E-mail
                    </div>
                  </div>
                  <div className="d-none d-md-block mb-4">
                    <form onSubmit={formProps.handleSubmit(handleSearch)}>
                      <ControlledTextfield
                        variant={"standard"}
                        search={true}
                        name={"search"}
                        formProps={formProps}
                        uppercase={false}
                        label={"Pesquisar"}
                        margin={"none"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="search"
                                edge="end"
                                type={"submit"}
                              >
                                <SearchOutlined />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </div>
                </div>
                <div className={"row spacer"}>
                  <div className="col-4 my-2 my-lg-0 color-brand">
                    <span>NOME</span>
                  </div>
                  <div className="col-7 my-2 my-lg-0 color-brand">
                    <span>ASSUNTO</span>
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-12">
                    {templates.map((t, index) => (
                      <Template
                        key={t.id}
                        data={t}
                        index={index}
                        handleEdit={handleEdit}
                        handleOpenExclusao={handleOpenExclusao}
                      />
                    ))}
                  </div>
                </div>
                <div className="row spacer">
                  <div className="col-12 offset-md-9 col-md-3">
                    <div className="d-none d-md-block">
                      <div className="botao-adicionar" onClick={handleNew}>
                        <Add className="botao-adicionar-icon mr-1" />
                        <span className="botao-adicionar-text">ADICIONAR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && !template && total > 10 && (
        <>
          <div className="row spacer">
            <div className="col-12">
              <Pagination
                count={Math.ceil(total / 10)}
                page={pagina}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </div>
          </div>
        </>
      )}
      {!loading && !!template && (
        <div>
          <TemplateEmailForm />
        </div>
      )}
      <ConfirmarExclusaoDialog
        open={dadosExclusao.open}
        handleClose={handleCloseExclusao}
        excluir={handleDelete}
        template={dadosExclusao.template}
      />
    </BasePage>
  );
};

export default TemplateEmailPage;
