import OrganizacaoPage from "./OrganizacaoPage";
import { useDispatch, useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useCallback } from "react";
import { getOrganizacoes } from "../../../selectors/administracao.selectors";
import { actions } from "../../../reducers/administracao/organizacao.actions";
import { useForm } from "react-hook-form";

const tipoOrganizacao = [
  {
    tipo: "A",
    desc: "AGÊNCIA",
  },
  {
    tipo: "P",
    desc: "PARCEIRO",
  },
  {
    tipo: "D",
    desc: "DIRETORIA REGIONAL",
  },
  {
    tipo: "G",
    desc: "GERÊNCIA REGIONAL",
  },
  {
    tipo: "C",
    desc: "CENIM",
  },
  {
    tipo: "T",
    desc: "CENTRALIZADORA",
  },
  {
    tipo: "R",
    desc: "CARTÓRIO",
  },
];

const OrganizacaoPageConnected = () => {
  const dispatch = useDispatch();
  const organizacoes = useSelector(getOrganizacoes);
  const loading = organizacoes?.loading;
  const total = organizacoes?.total;
  const pagina = organizacoes?.pagina;
  const search = organizacoes?.search;

  const initialValues = {
    search: search,
  };
  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };
  const handlePagination = useCallback(
    (e, page) => {
      dispatch(
        actions.listarOrganizacoes.request({ pagina: page, search: search })
      );
    },
    [dispatch, search]
  );
  const handleSearch = useCallback(
    (values) => {
      dispatch(
        actions.listarOrganizacoes.request({ search: values.search, pagina: 1 })
      );
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaOrganizacao());
  }, [dispatch]);

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarOrganizacao(values));
    },
    [dispatch]
  );
  const handleDelete = useCallback(
    (values) => {
      dispatch(actions.removerOrganizacao.request(values));
    },
    [dispatch]
  );
  return (
    <OrganizacaoPage
      organizacoes={organizacoes.lista}
      organizacao={organizacoes.organizacao}
      loading={loading}
      total={total}
      pagina={pagina}
      tipoOrganizacao={tipoOrganizacao}
      handlePagination={handlePagination}
      handleSearch={handleSearch}
      formProps={formProps}
      handleNew={handleNew}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );
};

export default OrganizacaoPageConnected;
