import BasePage from "../../../../BasePage";
import ImovelForm from "./ImovelForm";
import React from "react";
import Loader from "components/loaders";

const ImovelPage = ({ loading, error, ...otherProps }) => {
  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {!loading && !!error && (
        <div className="flex d-flex flex-column justify-content-center align-items-center">
          <span className="d-block colored-card-text mt-4 color-red">
            Não foi possível obter as informações
          </span>
        </div>
      )}
      {!loading && !error && (
        <>
          <span className="page-title">Detalhes do Imóvel</span>
          <ImovelForm {...otherProps} />
        </>
      )}
    </BasePage>
  );
};

export default ImovelPage;
