import React, { useCallback } from "react";
import { ControlledTextfield } from "../../../components/inputs";
import { Button } from "../../../components";

const FormLocalidade = ({
  props,
  disabled,
  handleSubmit,
  localidade,
  voltarParaLista,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, localidade.id);
    },
    [handleSubmit, localidade]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Localidade</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-6">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                uppercase={true}
                label={"Código"}
                inputProps={{ maxLength: 10 }}
              />
            </div>
            <div className="col-6">
              <ControlledTextfield
                name={"nome"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                uppercase={true}
                label={"Nome"}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={localidade.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={localidade.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormLocalidade;
