import AtividadesPage from "./AtividadesPage";
import { useDispatch, useSelector } from "react-redux";
import { getAtividades } from "../../../selectors/administracao.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../../reducers/administracao/atividades.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const AtividadesPageConnected = () => {
  const dispatch = useDispatch();
  const [pesquisa, setPesquisa] = useState();
  const atividades = useSelector(getAtividades);
  const loading = atividades?.loading;
  const total = atividades?.total;
  const pagina = atividades?.page;

  const handlePagination = useCallback(
    (e, page) => {
      if (!!pesquisa) {
        dispatch(
          actions.buscarAtividade.request({
            search: pesquisa,
            pagina: page,
            produto: {
              id: atividades.produto,
            },
          })
        );
      } else {
        dispatch(
          actions.listarAtividades.request({
            pagina: page,
            produto: {
              id: atividades.produto,
            },
          })
        );
      }
    },
    [dispatch, pesquisa, atividades]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarAtividade({ atividade: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaAtividade());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirAtividade.request({ idAtividade: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "codigo",
        value: values.search,
      };
      setPesquisa(request);
      dispatch(
        actions.buscarAtividade.request({
          search: request,
          pagina: 1,
          produto: atividades.produto,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch, setPesquisa]
  );

  return (
    <AtividadesPage
      atividades={atividades.list}
      atividade={atividades.atividade}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default AtividadesPageConnected;
