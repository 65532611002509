export const getAdministracao = (state) => state.administracao;
export const getCargaConsultas = (state) =>
  state.administracao["carga-consultas"];
export const getExportConsultas = (state) =>
  state.administracao.export_consultas;
export const getUsuarios = (state) => state.administracao.usuarios;
export const getUsuario = (state) => state.administracao.usuarios.usuario;
export const getMatrizChecklist = (state) =>
  state.administracao["matriz-checklist"];
export const getDocumentos = (state) => state.administracao.documentos;
export const getDocumento = (state) => state.administracao.documentos.documento;
export const getAtividades = (state) => state.administracao.atividades;
export const getAtividade = (state) => state.administracao.atividades.atividade;
export const getEtapas = (state) => state.administracao.etapas;
export const getEtapa = (state) => state.administracao.etapas.etapa;
export const getProdutos = (state) => state.administracao.produtos;
export const getProduto = (state) => state.administracao.produtos.produto;
export const getRelatorios = (state) => state.administracao.relatorios;
export const getRelatorio = (state) => state.administracao.relatorios.relatorio;
export const getTiposDeConvenios = (state) =>
  state.administracao.tiposDeConvenios;
export const getTipoDeConvenio = (state) =>
  state.administracao.tiposDeConvenios.tipoDeConvenio;
export const getPerfis = (state) => state.administracao.perfis;
export const getPerfil = (state) => state.administracao.perfis.perfil;
export const getLocalidades = (state) => state.administracao.localidades;
export const getOrganizacoes = (state) => state.administracao.organizacao;
export const getOrganizacao = (state) =>
  state.administracao.organizacao.organizacao;
export const getExportSla = (state) => state.administracao.sla;

export const getTemplates = (state) => state.administracao.templates;
export const getTemplate = (state) => state.administracao.templates.template;

export const getDominioOrganizacoes = (state) => {
  return {
    gerencias: state.administracao.organizacao.gerencias,
    diretorias: state.administracao.organizacao.diretorias,
    centralizadoras: state.administracao.organizacao.centralizadoras,
    cenins: state.administracao.organizacao.cenins,
    matriz_parceiros: state.administracao.organizacao.matriz_parceiros,
  };
};

export const getMotivosCancelamento = (state) =>
  state.administracao["motivos-cancelamento"];

export const getProdutosConvenio = (state) =>
  state.administracao.produtosConvenio;
export const getProdutoConvenio = (state) =>
  state.administracao.produtosConvenio.produto;

export const getRelatorioAuditoria = (state) =>
  state.administracao.relatoriosAuditoria.auditoria;
