import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  PROCESSAR: "@@export-consultas/PROCESSAR",
  CLEAN_FILE: "@@export-consultas/CLEAN_FILE",
};

export const actions = {
  processar: createAsyncAction(types.PROCESSAR),
  cleanFile: createSyncAction(types.CLEAN_FILE),
};
