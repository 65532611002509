import FormParticipante from "./FormParticipante";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import validators from "../../../../../utils/validators";
import {
  estadoCivil,
  uf,
  regimeComunhao,
} from "../../../../../utils/parametros";
import {
  getLoadingInclusaoParticipante,
  getLocationPayload,
  getParticipante,
  getPropostaLoading,
} from "../../../../../selectors/propostas.selectors";
import {
  actions as propostaActions,
  actions,
} from "../../../../../reducers/propostas.actions";
import { getPerfil } from "../../../../../selectors/usuario.selectors";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../../reducers/rotas.actions";

import {
  getProdutosConvenio,
  getTipoConvenio,
} from "../../../../../selectors/dominios.selectors";

const FormParticipanteConnected = () => {
  const dispatch = useDispatch();
  const participante = useSelector(getParticipante);
  const perfil = useSelector(getPerfil);
  const loadingDetalhe = useSelector(getPropostaLoading);

  const isAtualizacao = !!useSelector(getLocationPayload).idParticipante;
  const produtos = useSelector(getProdutosConvenio);
  const tipoConvenio = useSelector(getTipoConvenio);

  const initialValues = {
    ...participante?.dados,
  };

  const rules = {
    dataNascimento: validators.date({ required: true }),
    cep: validators.string({ required: true }),
    logradouro: validators.string({ required: true }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    cpf: validators.cpfCnpj({
      required: true,
      participantes: initialValues?.participantes,
      validarRepetido: false,
    }),
    nome: validators.string({ required: true }),
    email: validators.email({ required: false }),
    dataNascimentoConjuge: validators.date({ required: false }),
    telefone: validators.phone({ required: false }),
    telefoneResidencial: validators.phone({ required: false }),
    uf: validators.string({ required: true }),
    dataCasamento: validators.date({ required: false }),
    emailConjuge: validators.email({ required: false }),
    dataConsulta: validators.date({ required: true }),
    dataCorteFolha: validators.date({ required: false, valid: true }),
    dataAprovacaoOrbitall: validators.date({ required: false, valid: true }),
    dataAprovacaoIntelcav: validators.date({ required: false, valid: true }),
    dataAprovacaoInterog: validators.date({ required: false, valid: true }),
    dataCadastroEmpregador: validators.date({ required: false, valid: true }),
    dataLiberacaoEmpregador: validators.date({ required: false, valid: true }),
    dataParametrizacaoAutorizador: validators.date({
      required: false,
      valid: true,
    }),
    dataParametrizacaoApp: validators.date({ required: false, valid: true }),
    dataParametrizacaoSite: validators.date({ required: false, valid: true }),
    dataParametrizacaoArquivoLogoD1: validators.date({
      required: false,
      valid: true,
    }),
    dataVencimentoLimite: validators.date({ required: false, valid: true }),
    produto: validators.number({ required: true }),
    tipoDivisaoAdministrativa: validators.string({ required: true }),
    idTipoConvenio: validators.number({ required: true }),
    tipoAreaKitCredenciamento: validators.string({ required: true }),
    quantidadeFuncionarios: validators.number({ required: true }),
    numero: validators.string({ required: true }),
  };

  const disabled = perfil !== "BANCO";

  // eslint-disable-next-line
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    if (initialValues.reset_form) {
      formProps.reset(initialValues);
      dispatch(actions.limparFormulario());
    }
    // eslint-disable-next-line
  }, [initialValues.reset_form]);

  const loadingInclusao = useSelector(getLoadingInclusaoParticipante);

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        produto: 1,
        id_pessoa: i.idPessoa ?? "",
        id_consulta: i.idConsulta ?? "",
        DadosPessoa: {
          produtoConvenio: values.produtoConvenio,
          tipoParticipante: i.tipoParticipante,
          tipoPessoa: i.tipoPessoa,
          nome: values.nome.toUpperCase(),
          cpf_cnpj: values.cpf
            ?.replaceAll(".", "")
            .replace("-", "")
            .replace("/", ""),
          codigo: i.codigoPessoa,
          colaborador: values.colaborador ? "S" : "N",
          dataConsulta: values.dataConsulta !== "" ? values.dataConsulta : null,
        },
        PessoaEndereco: {
          pessoa: i.idPessoa ?? "",
          tipoEndereco: "R",
          cep: values.cep?.replace("-", ""),
          logradouro: values.logradouro.toUpperCase(),
          numero: values.numero ?? "",
          complemento: values.complemento.toUpperCase() ?? "",
          bairro: values.bairro.toUpperCase(),
          municipio: values.municipio?.toUpperCase(),
          uf: values.uf,
        },
        PessoaDadoPessoal: {
          pessoa: i.idPessoa ?? "",
          dataNascimento: values.dataNascimento,
          estadoCivil: values.estadoCivil,
          nomeMae: values.nomeMae?.toUpperCase() ?? "",
          fgts: values.fgts ? "S" : "N",
          uniaoEstavel: values.uniaoEstavel ? "S" : "N",
          conjuge: !!values.nomeConjuge ? "S" : "N",
          cpf_conjuge:
            values.cpfConjuge?.replaceAll(".", "").replace("-", "") ?? "",
          dataCasamento: values.dataCasamento ?? "",
          nomeConjuge: values.nomeConjuge?.toUpperCase() ?? "",
          dataNascimentoConjuge: values.dataNascimentoConjuge ?? "",
          isConjugeComprador: values.isConjugeComprador ? "S" : "N",
          fgtsConjuge: values.fgtsConjuge ? "S" : "N",
          tipoEmpresa: values.tipoEmpresa ?? "",
          regimeCasamento: values.regimeCasamento?.toUpperCase() ?? "",
          emailConjuge: values.emailConjuge ?? "",
          //novos campos aqui
          tipoDivisaoAdministrativa: values.tipoDivisaoAdministrativa ?? "",
          idTipoConvenio: values.idTipoConvenio ?? "",
          tipoEnvioKitCredenciamento: values.tipoEnvioKitCredenciamento ?? "",
          nomeGerente: values.nomeGerente
            ? values.nomeGerente.toUpperCase()
            : "",
          funcao: values.funcao ?? null,
          protocolo: values.protocolo ? values.protocolo : null,
          logoOrbitall: values.logoOrbitall ? values.logoOrbitall : null,
          logoAutorizador: values.logoAutorizador ?? null,
          dataCorteFolha:
            values.dataCorteFolha && values.dataCorteFolha !== ""
              ? new Date(
                  `${values.dataCorteFolha.split("/")[2]}-${
                    values.dataCorteFolha.split("/")[1]
                  }-${values.dataCorteFolha.split("/")[0]}`
                )
              : null,
          dataAprovacaoOrbitall:
            values.dataAprovacaoOrbitall && values.dataAprovacaoOrbitall !== ""
              ? new Date(
                  `${values.dataAprovacaoOrbitall.split("/")[2]}-${
                    values.dataAprovacaoOrbitall.split("/")[1]
                  }-${values.dataAprovacaoOrbitall.split("/")[0]}`
                )
              : null,
          dataAprovacaoIntelcav:
            values.dataAprovacaoIntelcav && values.dataAprovacaoIntelcav !== ""
              ? new Date(
                  `${values.dataAprovacaoIntelcav.split("/")[2]}-${
                    values.dataAprovacaoIntelcav.split("/")[1]
                  }-${values.dataAprovacaoIntelcav.split("/")[0]}`
                )
              : null,
          dataAprovacaoInterog:
            values.dataAprovacaoInterog && values.dataAprovacaoInterog !== ""
              ? new Date(
                  `${values.dataAprovacaoInterog.split("/")[2]}-${
                    values.dataAprovacaoInterog.split("/")[1]
                  }-${values.dataAprovacaoInterog.split("/")[0]}`
                )
              : null,
          dataCadastroEmpregador:
            values.dataCadastroEmpregador &&
            values.dataCadastroEmpregador !== ""
              ? new Date(
                  `${values.dataCadastroEmpregador.split("/")[2]}-${
                    values.dataCadastroEmpregador.split("/")[1]
                  }-${values.dataCadastroEmpregador.split("/")[0]}`
                )
              : null,
          dataLiberacaoEmpregador:
            values.dataLiberacaoEmpregador &&
            values.dataLiberacaoEmpregador !== ""
              ? new Date(
                  `${values.dataLiberacaoEmpregador.split("/")[2]}-${
                    values.dataLiberacaoEmpregador.split("/")[1]
                  }-${values.dataLiberacaoEmpregador.split("/")[0]}`
                )
              : null,
          dataParametrizacaoAutorizador:
            values.dataParametrizacaoAutorizador &&
            values.dataParametrizacaoAutorizador !== ""
              ? new Date(
                  `${values.dataParametrizacaoAutorizador.split("/")[2]}-${
                    values.dataParametrizacaoAutorizador.split("/")[1]
                  }-${values.dataParametrizacaoAutorizador.split("/")[0]}`
                )
              : null,
          dataParametrizacaoApp:
            values.dataParametrizacaoApp && values.dataParametrizacaoApp !== ""
              ? new Date(
                  `${values.dataParametrizacaoApp.split("/")[2]}-${
                    values.dataParametrizacaoApp.split("/")[1]
                  }-${values.dataParametrizacaoApp.split("/")[0]}`
                )
              : null,
          dataParametrizacaoSite:
            values.dataParametrizacaoSite &&
            values.dataParametrizacaoSite !== ""
              ? new Date(
                  `${values.dataParametrizacaoSite.split("/")[2]}-${
                    values.dataParametrizacaoSite.split("/")[1]
                  }-${values.dataParametrizacaoSite.split("/")[0]}`
                )
              : null,
          dataParametrizacaoArquivoLogoD1:
            values.dataParametrizacaoArquivoLogoD1 &&
            values.dataParametrizacaoArquivoLogoD1 !== ""
              ? new Date(
                  `${values.dataParametrizacaoArquivoLogoD1.split("/")[2]}-${
                    values.dataParametrizacaoArquivoLogoD1.split("/")[1]
                  }-${values.dataParametrizacaoArquivoLogoD1.split("/")[0]}`
                )
              : null,
          dataVencimentoLimite:
            values.dataVencimentoLimite && values.dataVencimentoLimite !== ""
              ? new Date(
                  `${values.dataVencimentoLimite.split("/")[2]}-${
                    values.dataVencimentoLimite.split("/")[1]
                  }-${values.dataVencimentoLimite.split("/")[0]}`
                )
              : null,
          valorLimiteAprovado: values.valorLimiteAprovado
            ? values.valorLimiteAprovado
            : "",
          prazoOperacao: values.prazoOperacao ?? "",
          taxaOperacao: values.taxaOperacao ?? "",
          publicoAlvo: values.publicoAlvo?.toUpperCase() ?? "",
          politicaIdadeValor: values.politicaIdadeValor?.toUpperCase() ?? "",

          valorRating: values.valorRating ?? "",
          classificacaoRiscos: values.classificacaoRiscos
            ? values.classificacaoRiscos?.toUpperCase()
            : "",
          margemUtilizavel: values.margemUtilizavel ?? "",
          margemCartao: values.margemCartao ?? "",
          valorLimitePotencialSaque: values.valorLimitePotencialSaque ?? "",
          valorLimitePotencialCompra: values.valorLimitePotencialCompra ?? "",
          codigoContaCorrente: values.codigoContaCorrente ?? "",
          representante: values.representante
            ? values.representante?.toUpperCase()
            : "",
          cargoRepresentante: values.cargoRepresentante
            ? values.cargoRepresentante.toUpperCase()
            : "",
          observacao: values.observacao ? values.observacao?.toUpperCase() : "",
          quantidadeFuncionarios: values.quantidadeFuncionarios ?? "",
          tipoAreaKitCredenciamento: values.tipoAreaKitCredenciamento ?? "",
        },
        PessoaContato: {
          pessoa: i.idPessoa ?? "",
          email: values.email ?? "",
          areaCelular: values.telefone?.charAt(1) + values.telefone?.charAt(2),
          telefoneCelular: values.telefone?.slice(5).replace("-", ""),
          areaResidencial:
            values.telefoneResidencial?.charAt(1) +
            values.telefoneResidencial?.charAt(2),
          telefoneResidencial: values.telefoneResidencial
            ?.slice(5)
            .replace("-", ""),
        },
      };
      dispatch(actions.atualizarParticipante.request(request));
    },
    [dispatch]
  );

  const handleGoBack = useCallback(() => {
    if (
      formProps.initialValues?.idConsulta !== undefined &&
      formProps.initialValues?.idConsulta !== ""
    ) {
      dispatch(
        rotasActions.redirectTo(routes.PROPOSTA, {
          id: formProps.initialValues.idConsulta,
        })
      );
    } else {
      dispatch(rotasActions.redirectTo(routes.PROPOSTAS));
    }
  }, [dispatch, formProps]);

  const successCallback = useCallback(
    ({ data }, value) => {
      formProps.setValue("logradouro", data?.logradouro || "");
      formProps.setValue("bairro", data?.bairro || "");
      formProps.setValue("uf", data?.uf || "");
      formProps.setValue("municipio", data?.localidade || "");
      formProps.setValue("localidade", data?.ibge || "");
    },
    [formProps]
  );

  const consultarCep = useCallback(
    (value) => {
      if (!!value && value.length === 9) {
        dispatch(
          propostaActions.consultarCepCorreios.request({
            value,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );

  const validarCpfCpnjRepetido = useCallback(
    (value) => {
      // initialValues.participantes.comprador.forEach((c) => {
      //   if (c.cpfCnpj === value) return true;
      // });
      // initialValues.participantes.vendedor.forEach((c) => {
      //   if (c.cpfCnpj === value) return true;
      // });
      // return false;
    },
    // eslint-disable-next-line
    [dispatch]
  );

  return (
    <FormParticipante
      isPf={participante?.dados?.tipoPessoa === "F"}
      loading={participante?.loading}
      formProps={formProps}
      estadoCivil={estadoCivil}
      uf={uf.sort()}
      participante={participante?.dados}
      consultarCep={consultarCep}
      handleSubmit={handleSubmit}
      loadingInclusao={loadingInclusao}
      regimeComunhao={regimeComunhao}
      disabled={disabled}
      loadingDetalhe={loadingDetalhe}
      validarCpfCnpjRepetido={validarCpfCpnjRepetido}
      isAtualizacao={isAtualizacao}
      handleGoBack={handleGoBack}
      produtos={produtos}
      tipoConvenio={tipoConvenio}
    />
  );
};

export default FormParticipanteConnected;
