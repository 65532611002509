import Email from "./Email";
import { Button } from "../../../../components";
import Loader from "../../../../components/loaders";
import EnvioEmailPage from "../email/EnvioEmailPage";

const BaseView = ({
  loading,
  historico,
  handleDownload,
  loadingExportHistorico,
  handleAbrirDetalhesEmail,
}) => {
  return (
    <>
      {!!loading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {!loading && (
        <>
          {historico.length === 0 && (
            <div className="flex d-flex flex-column justify-content-center align-items-center">
              <span className="d-block colored-card-text mt-4 color-secondary text-center">
                Nenhum email encontrado.
              </span>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              <div className="" style={{ minHeight: 100 }}>
                <div className="card-body d-flex flex-column">
                  <div className={"row d-flex align-items-center"}>
                    <div className={"mt-0 ml-2"}>
                      <div className="card-title-checklist">E-mails</div>
                    </div>
                    <div className={"mt-0 ml-auto"}>
                      <Button
                        label="Download"
                        onClick={handleDownload}
                        loading={!!loadingExportHistorico}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className="col-12">
                      <div
                        className={
                          "row mt-0 d-flex align-items-center medium-row texto-dispensar tr-odd"
                        }
                      >
                        <div className="col-sm-12 col-lg-3 mt-sm-2 mt-lg-0">
                          <span>Atividade</span>
                        </div>
                        <div className="col-sm-12 col-lg-4 mt-sm-2 mt-lg-0 ">
                          <span>Assunto</span>
                        </div>
                        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                          <span>Usuário</span>
                        </div>
                        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                          <span>Data/Hora Envio</span>
                        </div>
                        <div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 text-center">
                          <span>Detalhes</span>
                        </div>
                      </div>
                      {historico.map((d, index) => (
                        <Email
                          key={d.id}
                          email={d}
                          index={index}
                          handleAbrirDetalhesEmail={handleAbrirDetalhesEmail}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const View = ({
  abrirDetalhes,
  detalhesEmail,
  historicoEmailDetalhesLoading,
  handleSubmit,
  habilitarResponder,
  handleResponder,
  canRespond,
  sending,
  ...otherProps
}) => {
  return (
    <>
      {!abrirDetalhes && <BaseView {...otherProps} />}
      {abrirDetalhes && historicoEmailDetalhesLoading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {abrirDetalhes && !historicoEmailDetalhesLoading && (
        <EnvioEmailPage
          formProps={detalhesEmail.formProps}
          handleGoBack={detalhesEmail.handleEmailGoBack}
          loading={historicoEmailDetalhesLoading}
          handleChangeTemplate={""}
          submitting={sending}
          historico={true}
          title="E-mail"
          hasValue={habilitarResponder}
          handleRespond={handleResponder}
          handleSubmit={handleSubmit}
          canRespond={canRespond}
        />
      )}
    </>
  );
};

export default View;
