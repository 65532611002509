import React, { useState } from "react";
import {
  ControlledSelect,
  ControlledSwitch,
  ControlledTextfield,
  CpfCnpjTextField,
  PhoneTextField,
  ZipCodeTextField,
} from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";

const FormOrganizacao = ({
  organizacao,
  formProps,
  voltarParaLista,
  tipoOrganizacao,
  dominios,
  handleZipCodeBlur,
  uf,
  handleSubmit,
  saving,
}) => {
  const [tipo, setTipo] = useState(organizacao.tipo);
  const [isMatriz, setIsMatriz] = useState(organizacao.isMatriz === "S");

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Organização</span>
        <form onSubmit={formProps.handleSubmit(handleSubmit)}>
          <div className="row">
            <div className="col-12 col-md-2">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                uppercase={false}
                formProps={formProps}
                label={"Código"}
                inputProps={{ maxLength: 10 }}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"nome"}
                formProps={formProps}
                uppercase={true}
                label={"Nome"}
                margin={"none"}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <div className="col-12 col-md-4">
              <ControlledSelect
                name={"tipo"}
                formProps={formProps}
                label={"Tipo"}
                margin={"none"}
                onChange={(e) => setTipo(e.target.value)}
              >
                {tipoOrganizacao.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.tipo}
                    value={item.tipo}
                  >
                    {item.desc}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
          </div>
          {"A" === tipo && (
            <div className="row">
              <div className="col-12 col-md-5">
                <ControlledSelect
                  name={"organizacaoMatriz"}
                  formProps={formProps}
                  label={"Gerência"}
                  margin={"none"}
                  onChange={() => true}
                >
                  {dominios.gerencias.map((item) => (
                    <MenuItem
                      className={"select-submenu-item"}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nome}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </div>
              <div className="col-12 col-md-4">
                <ControlledSelect
                  name={"cenim"}
                  formProps={formProps}
                  label={"Cenim"}
                  margin={"none"}
                  onChange={() => true}
                >
                  {dominios.cenins.map((item) => (
                    <MenuItem
                      className={"select-submenu-item"}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nome}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </div>
              <div className="col-12 col-md-3">
                <ControlledSelect
                  name={"centralizadora"}
                  formProps={formProps}
                  label={"Centralizadora"}
                  margin={"none"}
                  onChange={() => true}
                >
                  {dominios.centralizadoras.map((item) => (
                    <MenuItem
                      className={"select-submenu-item"}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nome}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </div>
              <div className="col-12 col-md-12">
                <ControlledTextfield
                  name={"email"}
                  formProps={formProps}
                  uppercase={false}
                  label={"E-Mail"}
                  margin={"none"}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
            </div>
          )}
          {tipo === "G" && (
            <div className="row">
              <div className="col-12 col-md-3">
                <ControlledSelect
                  name={"organizacaoMatriz"}
                  formProps={formProps}
                  label={"Diretoria"}
                  margin={"none"}
                >
                  {dominios.diretorias.map((item) => (
                    <MenuItem
                      className={"select-submenu-item"}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nome}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </div>
            </div>
          )}
          {tipo === "P" && (
            <>
              <div className="row">
                <div className="col-12 col-md-2">
                  <ControlledTextfield
                    name={"organizacao_agencia"}
                    margin={"none"}
                    uppercase={false}
                    formProps={formProps}
                    label={"Agência"}
                    inputProps={{ maxLength: 10 }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <ControlledTextfield
                    formProps={formProps}
                    label={"CPF/CNPJ"}
                    type={"both"}
                    name={"cpf_cnpj"}
                    margin={"none"}
                    InputProps={{
                      inputComponent: CpfCnpjTextField,
                    }}
                  />
                </div>
                <div className="col-12 col-md-7">
                  <ControlledTextfield
                    name={"nome_fantasia"}
                    margin={"none"}
                    uppercase={true}
                    formProps={formProps}
                    label={"Nome Fantasia"}
                    inputProps={{ maxLength: 100 }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-2">
                  <ControlledTextfield
                    name={"cep"}
                    margin={"none"}
                    formProps={formProps}
                    label={"CEP"}
                    onBlur={handleZipCodeBlur}
                    InputProps={{
                      inputComponent: ZipCodeTextField,
                    }}
                  />
                </div>
                <div className="col-12 col-md-8">
                  <ControlledTextfield
                    formProps={formProps}
                    margin={"none"}
                    name={"logradouro"}
                    label={"Logradouro"}
                    uppercase={true}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <ControlledTextfield
                    name={"numero_logradouro"}
                    formProps={formProps}
                    label={"Número"}
                    margin={"none"}
                    uppercase={true}
                    inputProps={{ maxLength: 7 }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <ControlledTextfield
                    name={"complemento"}
                    formProps={formProps}
                    uppercase={true}
                    label={"Complemento"}
                    margin={"none"}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <ControlledTextfield
                    name={"bairro"}
                    formProps={formProps}
                    label={"Bairro"}
                    uppercase={true}
                    margin={"none"}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <ControlledTextfield
                    name={"municipio"}
                    formProps={formProps}
                    uppercase={true}
                    label={"Município"}
                    margin={"none"}
                    inputProps={{ maxLength: 60 }}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <ControlledSelect
                    name={"uf"}
                    formProps={formProps}
                    label={"UF"}
                    margin={"none"}
                  >
                    {uf.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.codigo}
                        value={item.codigo}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-9">
                  <ControlledTextfield
                    name={"email"}
                    formProps={formProps}
                    uppercase={false}
                    label={"E-Mail"}
                    margin={"none"}
                    inputProps={{ maxLength: 100 }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <ControlledTextfield
                    name={"telefone"}
                    formProps={formProps}
                    label={"Telefone"}
                    InputProps={{
                      inputComponent: PhoneTextField,
                    }}
                    margin={"none"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <ControlledTextfield
                    name={"responsavel"}
                    formProps={formProps}
                    label={"Nome do Responsável"}
                    uppercase={true}
                    margin={"none"}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <ControlledTextfield
                    name={"consultor_comercial"}
                    formProps={formProps}
                    uppercase={true}
                    label={"Nome do Consultor Comercial"}
                    margin={"none"}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-2">
                  <ControlledSwitch
                    name={"isMatriz"}
                    formProps={formProps}
                    onChange={() => setIsMatriz(!isMatriz)}
                    label={"É Matriz?"}
                    margin={"none"}
                  />
                </div>
                {!isMatriz && dominios.matriz_parceiros.length > 0 && (
                  <div className="col-12 col-md-10">
                    <ControlledSelect
                      name={"organizacaoMatriz"}
                      formProps={formProps}
                      label={"Matriz"}
                      margin={"none"}
                    >
                      {dominios.matriz_parceiros.map((item) => (
                        <MenuItem
                          className={"select-submenu-item"}
                          key={item.id}
                          value={item.id}
                        >
                          {item.nome}
                        </MenuItem>
                      ))}
                    </ControlledSelect>
                  </div>
                )}
              </div>
            </>
          )}
          {tipo === "R" && (
            <>
              <div className="row">
                <div className="col-12 col-md-2">
                  <ControlledTextfield
                    name={"cep"}
                    margin={"none"}
                    formProps={formProps}
                    label={"CEP"}
                    onBlur={handleZipCodeBlur}
                    InputProps={{
                      inputComponent: ZipCodeTextField,
                    }}
                  />
                </div>
                <div className="col-12 col-md-8">
                  <ControlledTextfield
                    formProps={formProps}
                    margin={"none"}
                    name={"logradouro"}
                    label={"Logradouro"}
                    uppercase={true}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <ControlledTextfield
                    name={"numero_logradouro"}
                    formProps={formProps}
                    label={"Número"}
                    margin={"none"}
                    uppercase={true}
                    inputProps={{ maxLength: 7 }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <ControlledTextfield
                    name={"complemento"}
                    formProps={formProps}
                    uppercase={true}
                    label={"Complemento"}
                    margin={"none"}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <ControlledTextfield
                    name={"bairro"}
                    formProps={formProps}
                    label={"Bairro"}
                    uppercase={true}
                    margin={"none"}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <ControlledTextfield
                    name={"municipio"}
                    formProps={formProps}
                    uppercase={true}
                    label={"Município"}
                    margin={"none"}
                    inputProps={{ maxLength: 60 }}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <ControlledSelect
                    name={"uf"}
                    formProps={formProps}
                    label={"UF"}
                    margin={"none"}
                  >
                    {uf.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.codigo}
                        value={item.codigo}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-9">
                  <ControlledTextfield
                    name={"email"}
                    formProps={formProps}
                    uppercase={false}
                    label={"E-Mail"}
                    margin={"none"}
                    inputProps={{ maxLength: 100 }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <ControlledTextfield
                    name={"telefone"}
                    formProps={formProps}
                    label={"Telefone"}
                    InputProps={{
                      inputComponent: PhoneTextField,
                    }}
                    margin={"none"}
                  />
                </div>
              </div>
            </>
          )}
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={saving}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button label="CONFIRMAR" type={"submit"} loading={saving} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormOrganizacao;
