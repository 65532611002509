import { useSelector } from "react-redux";
import { getPerfil } from "../../selectors/usuario.selectors";

const VisibleWithProfile = ({ profiles = [], children }) => {
  const perfil = useSelector(getPerfil);

  if (profiles.indexOf(perfil) === -1) {
    return null;
  }
  return children;
};

export default VisibleWithProfile;
