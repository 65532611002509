import React, { useCallback } from "react";
import { ControlledTextfield } from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";
import ControlledSelect from "../../../components/inputs/ControlledSelect";

const FormPerfil = ({
  props,
  disabled,
  handleSubmit,
  perfil,
  voltarParaLista,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, perfil.id);
    },
    [handleSubmit, perfil]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Perfil</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"nome"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                uppercase={true}
                label={"Nome"}
                inputProps={{ maxLength: 40 }}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"tipo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                uppercase={true}
                label={"Tipo"}
                inputProps={{ maxLength: 20 }}
              >
                <MenuItem
                  className={"select-submenu-item"}
                  key={7}
                  value={"ADMINISTRADOR"}
                >
                  ADMINISTRADOR
                </MenuItem>
                <MenuItem
                  className={"select-submenu-item"}
                  key={2}
                  value={"BANCO"}
                >
                  BANCO
                </MenuItem>
                <MenuItem
                  className={"select-submenu-item"}
                  key={4}
                  value={"BPO"}
                >
                  BPO
                </MenuItem>
                <MenuItem
                  className={"select-submenu-item"}
                  key={4}
                  value={"CLIENTE"}
                >
                  CLIENTE
                </MenuItem>
              </ControlledSelect>
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={perfil.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={perfil.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormPerfil;
