import { useCallback } from "react";
import LoginPage from "./LoginPage";
import validators from "utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "reducers/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "reducers/rotas.actions";
import { getLoginLoading } from "selectors/autenticacao.selectors";
import { useForm } from "react-hook-form";

const LoginPageConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoginLoading);

  const rules = {
    user: validators.string({ required: true }),
    password: validators.string({ required: true }),
  };
  const initialValues = {
    user: "",
    password: "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleClickForgotPassword = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.ESQUECI_SENHA));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(actions.login.request(values));
    },
    [dispatch]
  );

  return (
    <LoginPage
      loading={loading}
      formProps={formProps}
      handleClickForgotPassword={handleClickForgotPassword}
      handleSubmit={handleSubmit}
    />
  );
};

export default LoginPageConnected;
