const JWT_TOKEN_NAME = "id_token";
const REFRESH_TOKEN_NAME = "refresh_token";
const ZENDESK_TOKEN_NAME = "zendesk_token";
const STATE_NAME = "state";

const data = {};
const localStorageAvailable = () => {
  try {
    const mod = "a";
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};

export const storeRefreshToken = (token) => save(REFRESH_TOKEN_NAME, token);
export const getRefreshToken = () => load(REFRESH_TOKEN_NAME);
export const cleanRefreshToken = () => clean(REFRESH_TOKEN_NAME);

export const storeJwt = (jwtToken) => save(JWT_TOKEN_NAME, jwtToken);
export const getJwt = () => load(JWT_TOKEN_NAME);
export const cleanJwt = () => clean(JWT_TOKEN_NAME);

export const storeZendeskToken = (token) => save(ZENDESK_TOKEN_NAME, token);
export const getZendeskToken = () => load(ZENDESK_TOKEN_NAME);
export const cleanZendeskToken = () => clean(ZENDESK_TOKEN_NAME);

export const loadState = () => {
  try {
    const serializedState = load(STATE_NAME);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    save(STATE_NAME, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
export const cleanState = () => clean(STATE_NAME);

export const clean = (key) => {
  if (localStorageAvailable()) {
    localStorage.removeItem(key);
  } else {
    delete data[key];
  }
};
export const load = (key) => {
  if (localStorageAvailable()) {
    return localStorage.getItem(key);
  } else {
    return data[key];
  }
};
export const save = (key, value) => {
  if (localStorageAvailable()) {
    localStorage.setItem(key, value);
  } else {
    data[key] = value;
  }
};
