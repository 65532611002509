import { authenticatedRequest } from "../utils/api";
import basicFlow from "./asyncHandler";
import { actions } from "../reducers/dominios.actions";

const documentosApi = (payload) => {
  return authenticatedRequest({
    url: `/dominio/documentos`,
    isResourceService: true,
    method: "get",
    queryParams: {
      tipo: payload,
    },
  });
};
const etapasApi = (payload) => {
  return authenticatedRequest({
    url: `/dominio/etapas`,
    isResourceService: true,
    method: "get",
    queryParams:
      payload,
  });
};
const atividadesApi = (values) => {
  return authenticatedRequest({
    url: `/dominio/atividades`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};
const produtosConvenioApi = () => {
  return authenticatedRequest({
    url: `/dominio/produtos-convenio`,
    isResourceService: true,
    method: "get",
  });
};
const produtosApi = () => {
  return authenticatedRequest({
    url: `/dominio/produtos`,
    isResourceService: true,
    method: "get",
  });
};
const localidadesApi = () => {
  return authenticatedRequest({
    url: `/dominio/localidades`,
    isResourceService: true,
    method: "get",
  });
};

const cartoriosApi = () => {
  return authenticatedRequest({
    url: `/dominio/cartorios`,
    isResourceService: true,
    method: "get",
  });
};

const motivoCancelamentoApi = () => {
  return authenticatedRequest({
    url: `/dominio/motivo_cancelamento`,
    isResourceService: true,
    method: "get",
  });
};

const documentos = basicFlow({
  actionGenerator: actions.documentos,
  api: documentosApi,
});

const etapas = basicFlow({
  actionGenerator: actions.etapas,
  api: etapasApi,
});

const atividades = basicFlow({
  actionGenerator: actions.atividades,
  api: atividadesApi,
});

const localidades = basicFlow({
  actionGenerator: actions.localidades,
  api: localidadesApi,
});

const analistas = basicFlow({
  actionGenerator: actions.analistas,
  api: () => {
    return authenticatedRequest({
      url: `/analistas`,
      isResourceService: true,
      method: "get",
    });
  },
});

const produtosConvenio = basicFlow({
  actionGenerator: actions.produtosConvenio,
  api: produtosConvenioApi,
});

const produtos = basicFlow({
  actionGenerator: actions.produtos,
  api: produtosApi,
});

const cartorios = basicFlow({
  actionGenerator: actions.cartorio,
  api: cartoriosApi,
});

const motivoCancelamento = basicFlow({
  actionGenerator: actions.motivos_cancelamento,
  api: motivoCancelamentoApi,
});

const tipoConvenioApi = () => {
  return authenticatedRequest({
    url: "/dominio/tipo_convenio",
    method: "get",
    isResourceService: true,
  });
};

const tipoConvenio = basicFlow({
  actionGenerator: actions.tipo_convenio,
  api: tipoConvenioApi,
});

const perfis = basicFlow({
  actionGenerator: actions.perfis,
  api: () => {
    return authenticatedRequest({
      url: "/dominio/perfil",
      method: "get",
      isResourceService: true,
    });
  },
});

const atividadesExcepcionaisApi = ({ id }) => {
  return authenticatedRequest({
    url: `/dominio/etapasExcepcionais/${id}`,
    method: "get",
    isResourceService: true,
  });
};

const atividadesExcepcionais = basicFlow({
  actionGenerator: actions.atividadesExcepcionais,
  api: atividadesExcepcionaisApi,
});

const empresasCorrespondentesApi = () => {
  return authenticatedRequest({
    url: `/dominio/empresas-correspondentes`,
    method: "get",
    isResourceService: true,
  });
};

const empresasCorrespondentes = basicFlow({
  actionGenerator: actions.empresasCorrespondentes,
  api: empresasCorrespondentesApi,
});

const templates = basicFlow({
  actionGenerator: actions.templates,
  api: () => {
    return authenticatedRequest({
      url: `/dominio/templates`,
      isResourceService: true,
      method: "get",
    });
  },
});

export const sagas = [
  documentos.watcher(),
  etapas.watcher(),
  atividades.watcher(),
  analistas.watcher(),
  produtos.watcher(),
  localidades.watcher(),
  cartorios.watcher(),
  motivoCancelamento.watcher(),
  tipoConvenio.watcher(),
  perfis.watcher(),
  atividadesExcepcionais.watcher(),
  produtosConvenio.watcher(),
  empresasCorrespondentes.watcher(),
  templates.watcher(),
];
