import React from "react";

const Link = ({ children, onClick, className }) => {
  return (
    <span
      className={className}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </span>
  );
};

export default Link;
