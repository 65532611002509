export default function Column({
  base,
  sm,
  md,
  lg,
  xl,
  className,
  ...otherProps
}) {
  const classes = [];

  switch (base) {
    case "auto":
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      classes.push("col-" + base);
      break;
    default:
      classes.push("col");
      break;
  }

  switch (sm) {
    case "auto":
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      classes.push("col-sm-" + sm);
      break;
    default:
      break;
  }

  switch (md) {
    case "auto":
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      classes.push("col-md-" + md);
      break;
    default:
      break;
  }

  switch (lg) {
    case "auto":
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      classes.push("col-lg-" + lg);
      break;
    default:
      break;
  }

  switch (xl) {
    case "auto":
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      classes.push("col-xl-" + xl);
      break;
    default:
      break;
  }

  if (!!className) {
    classes.push(className);
  }

  return <div className={classes.join(" ")} {...otherProps} />;
}
