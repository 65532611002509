import React, { useCallback, useState } from "react";
import { ControlledTextfield } from "../../../../../components/inputs";
import { Button } from "../../../../../components";
import BasePage from "../../../../BasePage";
import ConfirmarDialog from "../../ConfirmarDialog";

const FormIntervenienteQuitante = ({
  loading,
  formProps,
  handleSubmit,
  loadingAtualizacao,
  disabled,
  proposta,
  handleGoBack,
  isAtualizacao,
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, formProps.initialValues);
      handleCloseDialog();
    },
    // eslint-disable-next-line
    [handleSubmit, handleCloseDialog]
  );
  if (Object.keys(formProps.errors).length > 0 && open) {
    setOpen(false);
  }

  return (
    <BasePage isLoggedIn={true}>
      {!loading && (
        <>
          <span className="page-title">Interveniente Quitante</span>
          <div>
            <form
              id={proposta}
              className="d-flex flex-column"
              onSubmit={formProps.handleSubmit(handleClickNext)}
            >
              <div className="card spacer">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 mt-0 col-md-6 offset-md-3">
                      <ControlledTextfield
                        formProps={formProps}
                        label={"Nome Interveniente Quitante"}
                        name={"nomeInterveniente"}
                        margin={"none"}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="row spacer justify-content-center">
                    <div className="col-12 col-md-4 col-5">
                      <Button
                        disabled={loading || loadingAtualizacao}
                        label="VOLTAR"
                        loading={loadingAtualizacao}
                        onClick={() => handleGoBack()}
                      />
                    </div>
                    {!disabled && (
                      <div className="col-12 col-md-4 col-5">
                        <Button
                          disabled={loading || loadingAtualizacao}
                          label={isAtualizacao ? "ATUALIZAR" : "CONFIRMAR"}
                          loading={loadingAtualizacao}
                          onClick={() => {
                            setOpen(true);
                            formProps.clearErrors();
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <ConfirmarDialog open={open} handleClose={handleCloseDialog} />
            </form>
          </div>
        </>
      )}
    </BasePage>
  );
};

export default FormIntervenienteQuitante;
