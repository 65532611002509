import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../reducers/propostas.actions";
import {
  getDetalheProposta,
  getDocumentos,
} from "../../../../selectors/propostas.selectors";
import DocumentosDialog from "./DocumentosDialog";

const groupBy = (data) => {
  if (!data) return [];
  const newArray = data.reduce((total, item, index) => {
    let arrayItem = total.find((i) => i.participante === item.participante);

    if (!arrayItem) {
      arrayItem = {
        id: index,
        participante: item.participante,
        documentos: [],
      };
      total.push(arrayItem);
    }

    arrayItem.documentos.push(item);
    return total;
  }, []);
  newArray.sort((a, b) => a.participante.localeCompare(b.participante));
  return newArray;
};

const DocumentosDialogConnected = ({ subtitulo }) => {
  const { open, data } = useSelector(getDocumentos);
  const { cliente } = useSelector(getDetalheProposta);
  const dispatch = useDispatch();

  const handleClose = (e) => {
    dispatch(actions.abrirModalDocumentos({ open: false, data: [] }));
  };

  return (
    <DocumentosDialog
      subtitulo={subtitulo}
      open={open}
      handleClose={handleClose}
      detalhes={groupBy(data)}
      cliente={cliente}
    />
  );
};

export default DocumentosDialogConnected;
