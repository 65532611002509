import React from "react";
import BasePage from "../../../BasePage";
import Loader from "../../../../components/loaders";
import FormSocioPj from "./FormSocioPj";
import FormPessoaFisica from "./FormSocioPf";

const FormSocio = ({ loading, isPf, tipoPessoa, tipoSocio, ...props }) => {
  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {!loading && (
        <>
          <span className="page-title">Detalhes do {tipoSocio === "S" ? "Sócio" : "Representante Legal"}</span>
          {tipoPessoa === "J" && <FormSocioPj {...props} tipoSocio={tipoSocio}/>}
          {tipoPessoa === "F" && <FormPessoaFisica {...props} tipoSocio={tipoSocio}/>}
        </>
      )}
    </BasePage>
  );
};

export default FormSocio;
