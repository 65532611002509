import React from "react";
import NumberFormat from "react-number-format";

const IntegerTextField = ({
  inputRef,
  thousandSeparator = ".",
  onChange,
  ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange(values.value);
    }}
    thousandSeparator={thousandSeparator}
    decimalSeparator={","}
    decimalScale={0}
  />
);

export default IntegerTextField;
