import { useDispatch, useSelector } from "react-redux";
import {
  getUsuarios,
  getUsuario,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/usuarios.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import FormUsuario from "./FormUsuario";
import { getPerfil } from "../../../selectors/usuario.selectors";
import { getProdutos } from "../../../selectors/dominios.selectors";

const UsuariosPageConnected = () => {
  const dispatch = useDispatch();
  const usuarios = useSelector(getUsuarios);
  const usuario = useSelector(getUsuario);
  const perfil = useSelector(getPerfil);
  const produtos = useSelector(getProdutos);

  const initialValues = {
    ...usuario,
    perfil: usuario.perfil?.id ?? "",
    "codigo-organizacao": usuario.Organizacao?.codigo ?? "",
    "nome-organizacao": usuario.Organizacao?.nome ?? "",
    atividadeExcepcional: usuario.atividadeExcepcional === "S",
    produto: usuario.produto ?? [],
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    email: validators.email({ required: true }),
    perfil: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    "codigo-organizacao": validators.number({ required: true }),
  };
  // eslint-disable-next-line
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const successCallback = useCallback(
    ({ data }) => {
      if (data.length > 0) {
        formProps.setValue("nome-organizacao", data[0].nome);
      } else {
        formProps.setValue("nome-organizacao", "");
      }
    },
    [formProps]
  );

  const getOrganizacao = useCallback(
    (values) => {
      if (!!values) {
        dispatch(actions.getOrganizacao.request({ values, successCallback }));
      } else {
        formProps.setValue("nome-organizacao", "");
      }
    },
    // eslint-disable-next-line
    [dispatch, successCallback]
  );

  const handleSubmit = useCallback(
    (values, i) => {
      if (
        usuarios.perfis.filter((a) => a.id === values.perfil)[0].tipo ===
          "CLIENTE" &&
        !i
      ) {
        toast.error("Não é permitido incluir novos usuários para clientes");
      } else if (
        usuarios.usuario.perfil?.tipo !== "CLIENTE" &&
        usuarios.perfis.filter((a) => a.id === values.perfil)[0].tipo ===
          "CLIENTE"
      ) {
        toast.error("Não é permitido alterar o perfil para cliente");
      } else if (
        !usuarios.usuario.Organizacao?.id &&
        usuarios.perfis.filter((a) => a.id === values.perfil)[0].tipo ===
          "AGENCIA"
      ) {
        toast.error(
          "Agência não cadastrada na base. Favor informar um código de agência válido"
        );
      } else if (
        !usuarios.usuario.Organizacao?.id &&
        usuarios.perfis.filter((a) => a.id === values.perfil)[0].tipo ===
          "PARCEIRO"
      ) {
        toast.error(
          "Parceiro não cadastrado na base. Favor informar um código de parceiro válido"
        );
      } else {
        const request = {
          codigo: values.codigo,
          nome: values.nome.toUpperCase(),
          email: values.email,
          "id-perfil": values.perfil,
          perfil: usuarios.perfis.filter((u) => u.id === values.perfil)[0],
          id: i,
          "id-organizacao": usuarios.usuario.Organizacao?.id
            ? usuarios.usuario.Organizacao?.id
            : null,
          situacao: values.situacao,
          Organizacao: usuarios.usuario.Organizacao,
          produtos: values.produto,
          atividadeExcepcional:
            values.atividadeExcepcional === true ? "S" : "N",
        };
        if (!!i) {
          if (perfil === "BPO") {
            dispatch(actions.reenviarEmail.request({ usuario: request }));
          } else {
            dispatch(actions.atualizarUsuario.request({ usuario: request }));
          }
        } else {
          dispatch(actions.incluirUsuario.request({ usuario: request }));
        }
      }
    },
    // eslint-disable-next-line
    [dispatch, usuarios, toast, perfil]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaUsuario());
    // eslint-disable-next-line
  }, [dispatch]);

  const tipoOrganizacao = [
    { id: "A", descricao: "AGÊNCIA" },
    { id: "D", descricao: "DIRETORIA REGIONAL" },
    { id: "G", descricao: "GERENCIA REGIONAL" },
  ];

  const listaSituacoes = [
    { id: "A", descricao: "ATIVO" },
    { id: "I", descricao: "INATIVO" },
    { id: "B", descricao: "BLOQUEADO" },
    { id: "T", descricao: "BLOQUEADO POR SENHA" },
  ];

  return (
    <FormUsuario
      perfil={usuarios.perfis}
      usuario={usuario}
      props={formProps}
      handleSubmit={handleSubmit}
      tipoOrganizacao={tipoOrganizacao}
      getOrganizacao={getOrganizacao}
      organizacao={usuarios.organizacao}
      listaSituacoes={listaSituacoes}
      voltarParaLista={voltarParaLista}
      perfilUsuario={perfil}
      produtos={produtos.data}
    />
  );
};

export default UsuariosPageConnected;
