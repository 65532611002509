import React from "react";
import DetalhesDocumentoDialog from "./DetalhesDocumentoDialog";

const DetalhesDocumentoDialogConnected = ({
  handleClose,
  dados: { open, ...otherData },
}) => {
  return (
    <DetalhesDocumentoDialog
      open={open}
      handleClose={handleClose}
      detalhes={otherData}
    />
  );
};

export default DetalhesDocumentoDialogConnected;
