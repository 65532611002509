import React from "react";
import { LinearProgress } from "@material-ui/core";

const InputLoader = ({ className, color = "brand" }) => {
  return (
    <LinearProgress
      className={"input-loader " + className}
      classes={{
        bar: `bg-${color}`,
      }}
    />
  );
};

export default InputLoader;
