import React, { PureComponent } from "react";
import { MenuItem } from "@material-ui/core";

export default class SelectItem extends PureComponent {
  render() {
    return (
      <MenuItem {...this.props} classes={{ root: "menu-item-input-select" }}>
        {this.props.children}
      </MenuItem>
    );
  }
}
