import BasePage from "../BasePage";
import React from "react";
import CardProducaoDia from "./CardProducaoDia";
import CardProducaoMes from "./CardProducaoMes";
import CardProjecaoMes from "./CardProjecaoMes";
import ConsultasAcimaValorPage from "./ConsultasAcimaValorPage";
import ConsultasCardProducaoPage from "./ConsultasCardProducaoPage";

const DashboardPage = ({
  producaoDia,
  producaoMes,
  projecao,
  consultas,
  handlePagination,
  goToCockpit,
  getProducao,
  getListaConsultasMes,
  getListaConsultasDia,
  consultasPorData,
}) => {
  return (
    <BasePage isLoggedIn={true}>
      <h2>Dashboard</h2>
      <div className="row spacer">
        <div className="col-12 col-md-4">
          <CardProducaoDia
            producaoDia={producaoDia}
            getProducao={getProducao}
            getListaConsultasDia={getListaConsultasDia}
          />
        </div>
        <div className="col-12 col-md-4">
          <CardProducaoMes
            producaoMes={producaoMes}
            getProducao={getProducao}
            getListaConsultasMes={getListaConsultasMes}
          />
        </div>
        <div className="col-12 col-md-4">
          <CardProjecaoMes projecao={projecao} />
        </div>
      </div>
      {!!consultasPorData.visible && (
        <div className="row spacer">
          <div className="col-12">
            <ConsultasCardProducaoPage
              consultas={consultasPorData}
              handlePagination={handlePagination}
              goToCockpit={goToCockpit}
            />
          </div>
        </div>
      )}
      <div className="row spacer">
        <div className="col-12">
          <ConsultasAcimaValorPage
            consultas={consultas}
            handlePagination={handlePagination}
            goToCockpit={goToCockpit}
          />
        </div>
      </div>
    </BasePage>
  );
};

export default DashboardPage;
