import { createSelector } from "reselect";

export const getDominios = (state) => state.dominios;

export const getDocumentos = createSelector(
  getDominios,
  (state) => state.documentos
);

export const getEtapas = createSelector(getDominios, (state) => state.etapas);
export const getAtividades = createSelector(
  getDominios,
  (state) => state.atividades
);

export const getAnalistas = createSelector(
  getDominios,
  (state) => state.analistas
);

export const getProdutosConvenio = createSelector(
  getDominios,
  (state) => state.produtosConvenio
);

export const getProdutos = createSelector(
  getDominios,
  (state) => state.produtos
);

export const getLocalidades = createSelector(
  getDominios,
  (state) => state.localidades
);

export const getCartorios = createSelector(
  getDominios,
  (state) => state.cartorios
);

export const getMotivosCancelamento = createSelector(
  getDominios,
  (state) => state.motivosCancelamento
);

export const getTipoConvenio = createSelector(
  getDominios,
  (state) => state.tipo_convenio
);

export const getAtividadesExcepcionais = createSelector(
  getDominios,
  (state) => state.atividades_excepcionais
);

export const getEmpresasCorrespondentes = createSelector(
  getDominios,
  (state) => state.empresasCorrespondentes
);

export const getPerfis = createSelector(getDominios, (state) => state.perfis);

export const getTemplates = createSelector(
  getDominios,
  (state) => state.templates
);
