import React from "react";
import CardImovel from "./CardImovel";
import Loader from "components/loaders";

const ImovelBlock = ({ proposta, imoveis, loading, handleOnClick }) => (
  <>
    {!!loading && (
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loader type={"dots"} color={"brand"} size={50} />
        </div>
      </div>
    )}
    {!loading && (
      <div className="row">
        {!!imoveis &&
          imoveis.map((imovel) => (
            <div key={imovel.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
              <CardImovel
                proposta={proposta}
                imovel={imovel}
                handleOnClick={handleOnClick}
              />
            </div>
          ))}
      </div>
    )}
  </>
);

export default ImovelBlock;
