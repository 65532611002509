import React, { useState } from "react";
import {
  ControlledTextfield,
  ControlledSelect,
  CpfCnpjTextField,
  DateTextField,
  CurrencyTextField,
  ControlledAutocomplete,
} from "../../../../components/inputs";
import { MenuItem } from "../../../../components";
import { tipoConstituicao } from "../../../../utils/parametros";

const CardDadosPessoaJuridica = ({
  props,
  disabled,
  empresasCorrespondentes,
}) => {
  const [isSubstabelecida, setIsSubstabelecida] = useState(
    !props.initialValues?.tipoCorrespondente
      ? false
      : props.initialValues?.tipoCorrespondente === "S"
  );

  const handleChangeTipoCorrespondente = (e) => {
    setIsSubstabelecida(e.target.value === "S");
  };

  return (
    <>
      <div className="card spacer">
        <div className="card-body">
          <span className="card-title color-brand">Dados do Corban</span>
          <div className="row">
            <div className="col-12 col-md-3">
              <ControlledTextfield
                formProps={props}
                label={"CNPJ"}
                type={"cnpj"}
                name={"cpf"}
                margin={"none"}
                disabled={disabled}
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                }}
              />
            </div>
            <div className={"col-12 col-md-6"}>
              <ControlledTextfield
                formProps={props}
                label={"Razão Social"}
                name={"nome"}
                margin={"none"}
                disabled={disabled}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <>
              <div className="col-12 col-md-3">
                <ControlledSelect
                  formProps={props}
                  label={"Tipo Empresa"}
                  name={"tipoConstituicao"}
                  margin={"none"}
                  disabled={disabled}
                >
                  {tipoConstituicao.map((item) => (
                    <MenuItem
                      className="select-submenu-item"
                      key={item.codigo}
                      value={item.codigo}
                    >
                      {item.descricao}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </div>
              <div className="col-12 col-md-3">
                <ControlledTextfield
                  formProps={props}
                  label={"Data de Constituição"}
                  name={"dataConstituicao"}
                  margin={"none"}
                  disabled={disabled}
                  InputProps={{ inputComponent: DateTextField }}
                />
              </div>
              <div className="col-12 col-md-6">
                <ControlledTextfield
                  formProps={props}
                  label={"Nome Fantasia"}
                  name={"nomeFantasia"}
                  margin={"none"}
                  disabled={disabled}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
              <div className="col-12 col-md-3">
                <ControlledSelect
                  formProps={props}
                  label={"Situação CNPJ"}
                  name={"situacaoCnpj"}
                  margin={"none"}
                  disabled={disabled}
                >
                  <MenuItem className="select-submenu-item" key="R" value="R">
                    REGULAR
                  </MenuItem>
                  <MenuItem className="select-submenu-item" key="P" value="P">
                    PENDENTE
                  </MenuItem>
                  <MenuItem className="select-submenu-item" key="I" value="I">
                    INAPTO
                  </MenuItem>
                </ControlledSelect>
              </div>
              <div className="col-12 col-md-6">
                <ControlledTextfield
                  formProps={props}
                  label={"Faturamento Mensal"}
                  name={"valorFaturamento"}
                  margin={"none"}
                  disabled={disabled}
                  InputProps={{ inputComponent: CurrencyTextField }}
                />
              </div>
              <div className="col-12 col-md-6">
                <ControlledTextfield
                  formProps={props}
                  label={"Site Empresa"}
                  name={"site"}
                  margin={"none"}
                  disabled={disabled}
                  uppercase={false}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
              <div className="col-12 col-md-6">
                <ControlledTextfield
                  formProps={props}
                  label={"CNAE"}
                  name={"cnae"}
                  margin={"none"}
                  disabled={disabled}
                  inputProps={{ maxLength: 2000 }}
                />
              </div>
              <div className="col-12 col-md-6">
                <ControlledTextfield
                  formProps={props}
                  label={"Código Função"}
                  name={"codigoFuncao"}
                  margin={"none"}
                  disabled={disabled}
                  inputProps={{ maxLength: 10 }}
                />
              </div>
              <div className="col-12 col-md-6">
                <ControlledSelect
                  formProps={props}
                  label={"Tipo Correspondente"}
                  name={"tipoCorrespondente"}
                  margin={"none"}
                  disabled={disabled}
                  onChange={handleChangeTipoCorrespondente}
                >
                  <MenuItem className="select-submenu-item" key="C" value="C">
                    CORRESPONDENTE
                  </MenuItem>
                  {empresasCorrespondentes.length > 0 && (
                    <MenuItem className="select-submenu-item" key="S" value="S">
                      SUBSTABELECIDA
                    </MenuItem>
                  )}
                </ControlledSelect>
              </div>
              {isSubstabelecida && (
                <div className="col-12 col-md-6">
                  <ControlledAutocomplete
                    name={"matriz"}
                    formProps={props}
                    label={"Substabelecida à"}
                    labelField={"nome"}
                    domain={empresasCorrespondentes.map((e) => {
                      return {
                        id: e.pessoa,
                        nome: e.ConsultaPessoa.nome,
                      };
                    })}
                    margin={"none"}
                    disabled={disabled}
                    autocompleteProps={{
                      noOptionsText: "Nenhum resultado encontrado",
                    }}
                  />
                </div>
              )}
              <div className="col-12 col-md-12">
                <ControlledTextfield
                  formProps={props}
                  label={"Nome do Gerente"}
                  name={"nomeGerente"}
                  margin={"none"}
                  disabled={disabled}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDadosPessoaJuridica;
