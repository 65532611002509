import { actions, types } from "./tiposDeConvenios.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  tipoDeConvenio: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarTiposDeConvenio.REQUEST:
    case actions.listarTiposDeConvenio.SUCCESS:
    case actions.listarTiposDeConvenio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarTiposDeConvenio.REQUEST,
        error:
          action.type === actions.listarTiposDeConvenio.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarTiposDeConvenio.SUCCESS
            ? action.payload.response.data.tiposDeConvenios
            : [],
        total:
          action.type === actions.listarTiposDeConvenio.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarTiposDeConvenio.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarTipoDeConvenio.REQUEST:
    case actions.atualizarTipoDeConvenio.SUCCESS:
    case actions.atualizarTipoDeConvenio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarTipoDeConvenio.REQUEST,
        tipoDeConvenio:
          action.type === actions.atualizarTipoDeConvenio.FAILURE
            ? action.payload.original.tipoDeConvenio
            : null,
        list:
          action.type === actions.atualizarTipoDeConvenio.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.tipoDeConvenio.id) {
                  return {
                    ...u,
                    tipoConvenio:
                      action.payload.original.tipoDeConvenio.tipoConvenio ??
                      u.tipoConvenio,
                    nome: action.payload.original.tipoDeConvenio.nome ?? u.nome,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVO_TIPO_DE_CONVENIO:
      return {
        ...state,
        tipoDeConvenio: {
          tipoConvenio: "",
          nome: "",
        },
      };
    case types.EDITAR_TIPO_DE_CONVENIO:
      return {
        ...state,
        tipoDeConvenio: {
          ...action.payload.tipoDeConvenio,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        tipoDeConvenio: null,
      };
    case actions.incluirTipoDeConvenio.REQUEST:
    case actions.incluirTipoDeConvenio.SUCCESS:
    case actions.incluirTipoDeConvenio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirTipoDeConvenio.REQUEST,
        tipoDeConvenio:
          action.type === actions.incluirTipoDeConvenio.FAILURE
            ? action.payload.original.tipoDeConvenio
            : null,
        list:
          action.type === actions.incluirTipoDeConvenio.SUCCESS
            ? action.payload.response.data.tiposDeConvenios
            : state.list,
        total:
          action.type === actions.incluirTipoDeConvenio.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirTipoDeConvenio.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarTipoDeConvenio.REQUEST:
    case actions.buscarTipoDeConvenio.SUCCESS:
    case actions.buscarTipoDeConvenio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarTipoDeConvenio.REQUEST,
        error:
          action.type === actions.buscarTipoDeConvenio.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarTipoDeConvenio.SUCCESS
            ? action.payload.response.data.tiposDeConvenios
            : [],
        total:
          action.type === actions.buscarTipoDeConvenio.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarTipoDeConvenio.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarTipoDeConvenio.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirTipoDeConvenio.REQUEST:
    case actions.excluirTipoDeConvenio.SUCCESS:
    case actions.excluirTipoDeConvenio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirTipoDeConvenio.REQUEST,
        error:
          action.type === actions.excluirTipoDeConvenio.FAILURE
            ? action.payload.error.response.data.error.message
            : null,
        list:
          action.type === actions.excluirTipoDeConvenio.SUCCESS
            ? state.list.filter(
                (u) => u.id !== action.payload.original.idTipoDeConvenio
              )
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
