import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_ORGANIZACOES: "@@administracao/LISTAR_ORGANIZACOES",
  EDITAR_ORGANIZACAO: "@@administracao/EDITAR_ORGANIZACAO",
  VOLTAR_LISTA_ORGANIZACAO: "@@administracao/VOLTAR_LISTA_ORGANIZACAO",
  SALVAR_ORGANIZACAO: "@@administracao/SALVAR_ORGANIZACAO",
  REMOVER_ORGANIZACAO: "@@administracao/REMOVER_ORGANIZACAO",
  NOVA_ORGANIZACAO: "@@administracao/NOVA_ORGANIZACAO",
  ORGANIZACAO_DOMINIOS: "@@administracao/ORGANIZACAO_DOMINIOS",
};

export const actions = {
  listarOrganizacoes: createAsyncAction(types.LISTAR_ORGANIZACOES),
  novaOrganizacao: () => createSyncAction(types.NOVA_ORGANIZACAO),
  editarOrganizacao: (values) =>
    createSyncAction(types.EDITAR_ORGANIZACAO, values),
  voltarListaOrganizacao: () =>
    createSyncAction(types.VOLTAR_LISTA_ORGANIZACAO),
  salvarOrganizacao: createAsyncAction(types.SALVAR_ORGANIZACAO),
  organizacaoDominios: createAsyncAction(types.ORGANIZACAO_DOMINIOS),
  removerOrganizacao: createAsyncAction(types.REMOVER_ORGANIZACAO),
};
