import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/motivoCancelamento.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarMotivosCancelamentoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/motivos_cancelamento",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarMotivoCancelamentoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/motivo_cancelamento",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const excluirMotivoCancelamentoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/motivo_cancelamento/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarMotivoCancelamentoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/motivo_cancelamento/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarMotivosCancelamento = basicFlow({
  actionGenerator: actions.listarMotivosCancelamento,
  api: listarMotivosCancelamentoApi,
});

const atualizarMotivoCancelamento = basicFlow({
  actionGenerator: actions.atualizarMotivoCancelamento,
  api: atualizarMotivoCancelamentoApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirMotivoCancelamento = basicFlow({
  actionGenerator: actions.incluirMotivoCancelamento,
  api: atualizarMotivoCancelamentoApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirMotivoCancelamento = basicFlow({
  actionGenerator: actions.excluirMotivoCancelamento,
  api: excluirMotivoCancelamentoApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarMotivoCancelamento = basicFlow({
  actionGenerator: actions.buscarMotivoCancelamento,
  api: buscarMotivoCancelamentoApi,
});

function* listaMotivoCancelamentoRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_MOTIVO_CANCELAMENTO, function* () {
    yield put(actions.listarMotivosCancelamento.request({ pagina }));
  });
}

export const sagas = [
  listaMotivoCancelamentoRouteWatcher(),
  listarMotivosCancelamento.watcher(),
  atualizarMotivoCancelamento.watcher(),
  incluirMotivoCancelamento.watcher(),
  buscarMotivoCancelamento.watcher(),
  excluirMotivoCancelamento.watcher(),
];
