import React, { useCallback } from "react";
import VisualizarMotivoDialog from "./VisualizarMotivoDialog";
import { useSelector, useDispatch } from "react-redux";
import { getChecklist } from "../../../../selectors/documentos.selectors";
import { actions } from "../../../../reducers/propostas.actions";
import validators from "../../../../utils/validators";
import { useForm } from "react-hook-form";

const VisualizarMotivoDialogConnected = ({
  handleClose,
  perfil,
  dados: { open, ...otherData },
}) => {
  const dispatch = useDispatch();
  const { analysing } = useSelector(getChecklist);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.atualizarMotivoAnalise.request({
          id: otherData.id,
          ...values,
          handleClose,
        })
      );
    },
    [dispatch, otherData, handleClose]
  );

  const rules = {
    motivo: validators.string({ required: true }),
  };
  const initialValues = {
    motivo: otherData.isSubstituto
      ? otherData.motivoRecusaSubstituido
      : otherData.motivoRecusa,
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  formProps.setValue("motivo", otherData.isSubstituto ? otherData.motivoRecusaSubstituido : otherData.motivoRecusa);

  const disabled =
    (perfil !== "BPO" && perfil !== "BANCO") || otherData.isSubstituto;

  return (
    <VisualizarMotivoDialog
      handleSubmit={handleSubmit}
      open={open}
      disabled={disabled}
      dados={otherData}
      formProps={formProps}
      handleClose={handleClose}
      submitting={analysing}
    />
  );
};

export default VisualizarMotivoDialogConnected;
