import basicFlow, { genericErrorHandler } from "../asyncHandler";
import { actions } from "../../reducers/administracao/relatorios.actions";
import { authenticatedRequest } from "../../utils/api";
import { saveAs } from "file-saver";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import format from "date-fns/format";
import ptBrLocale from "date-fns/locale/pt-BR";

const listarRelatorioAuditoriaApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/relatorio/auditoria",
    isResourceService: true,
    method: "post",
    body: values,
    responseType: "blob",
  });
};

const listarRelatorioFiltrosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/relatorio/filtros",
    isResourceService: true,
    method: "get",
  });
};

const listarRelatorioFiltros = basicFlow({
  actionGenerator: actions.listarRelatorioFiltros,
  api: listarRelatorioFiltrosApi,
});

function* listarRelatorioFiltrosRouteWatcher() {
  yield routeWatcher(routes.ADMINISTRACAO_RELATORIO_AUDITORIA, function* () {
    yield put(actions.listarRelatorioFiltros.request());
  });
}

const listarRelatorioAuditoria = basicFlow({
  actionGenerator: actions.listarRelatorioAuditoria,
  api: listarRelatorioAuditoriaApi,
  postSuccess: ({ response, original }) => {
    downloadFile(response, original);
  },
  postFailure: function* ({ error }) {
    error.message = `Proposta não localizada.`;
    yield genericErrorHandler({ error });
  },
});
const downloadFile = (response) => {
  const blob = new Blob([response.data], {
    type: "application/xlsx",
  });
  const fileName = `relatorio_auditoria_documentos_${format(
    new Date(),
    "ddMMyyyykkmm",
    ptBrLocale
  )}.xlsx`;
  saveAs(blob, fileName);
};

export const sagas = [
  listarRelatorioAuditoria.watcher(),
  listarRelatorioFiltrosRouteWatcher(),
  listarRelatorioFiltros.watcher(),
];
