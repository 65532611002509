import React from "react";
import WysiwygTextField from "./WysiwygTextField";
import { Controller } from "react-hook-form";
import { getValueFromObject } from "../../utils/basic";
import translations from "../../i18n/pt_br";
import { FormHelperText } from "@material-ui/core";

const uploadImage = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        resolve(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject("Arquivo não encontrado");
    }
  });
};

const uploadImageCallBack = (file) => {
  return new Promise((resolve, reject) => {
    uploadImage(file)
      .then((link) => {
        resolve({ data: { link } });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ControlledWysiwygTextfield = ({
  formProps,
  name,
  margin,
  disabled,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;
  const isError = getValueFromObject(errors, name) !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      margin={margin}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <>
            <WysiwygTextField
              {...otherProps}
              editorState={value}
              margin={margin}
              onEditorStateChange={(e) => {
                onChange(e);
                !!otherProps.onChange && otherProps.onChange(e);
              }}
              onBlur={() => {
                onBlur();
                !!otherProps.onBlur && otherProps.onBlur();
              }}
              wrapperClassName="wysiwyg-wrapper"
              editorClassName="wysiwyg-editor"
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "link",
                  "emoji",
                  "image",
                  "history",
                ],
                fontFamily: {
                  options: [
                    "Arial",
                    "Calibri",
                    "Georgia",
                    "Impact",
                    "Tahoma",
                    "Times New Roman",
                    "Verdana",
                  ],
                },
                image: {
                  urlEnabled: false,
                  uploadEnabled: true,
                  uploadCallback: uploadImageCallBack,
                },
                colorPicker: {
                  popupClassName: "wysiwyg-color-picker",
                },
                link: {
                  popupClassName: "wysiwyg-link",
                },
              }}
              localization={{ locale: "pt_br", translations }}
              readOnly={disabled}
            />
            {isError && (
              <FormHelperText
                error={Boolean(isError)}
                variant="outlined"
                classes={{
                  error: "label-error",
                }}
              >
                {getValueFromObject(errors, name)?.message}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export default ControlledWysiwygTextfield;
