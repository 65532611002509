import { Header } from "./pages";
import React from "react";
import { getZendeskToken } from "../utils/localStorage";

const BasePage = ({ children, isLoggedIn }) => {
  if (isLoggedIn) {
    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: function (callback) {
              callback(getZendeskToken());
            },
          },
        },
      },
    };
  }

  return (
    <>
      {isLoggedIn && <Header />}
      <div className="container-xl body">{children}</div>
    </>
  );
};
export default BasePage;
