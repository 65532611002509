import LocalidadesPage from "./LocalidadesPage";
import { useDispatch, useSelector } from "react-redux";
import { getLocalidades } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/localidades.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const LocalidadePageConnected = () => {
  const dispatch = useDispatch();
  const localidades = useSelector(getLocalidades);
  const loading = localidades?.loading;
  const total = localidades?.total;
  const pagina = localidades?.page;

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(actions.listarLocalidades.request({ pagina: page }));
    },
    [dispatch]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarLocalidade({ localidade: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaLocalidade());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirLocalidade.request({ id: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "nome",
        value: values.search,
      };
      dispatch(
        actions.buscarLocalidade.request({ search: request, pagina: 1 })
      );
    },
    [dispatch]
  );

  return (
    <LocalidadesPage
      localidades={localidades?.list}
      localidade={localidades?.localidade}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default LocalidadePageConnected;
