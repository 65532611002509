import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";

const Etapa = ({ etapa, index, handleEdit, handleOpenExclusaoEtapa }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 user-table ">
        <span>{etapa.codigo}</span>
      </div>
      <div className="col-9 col-md-6 my-2 my-lg-0 user-table">
        <span>{etapa.nome}</span>
      </div>
      <div className="col-3 col-md-3 my-2 my-lg-0 user-table">
        <span>{etapa.ordem}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(etapa)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() => handleOpenExclusaoEtapa(etapa)}
        >
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Etapa;
