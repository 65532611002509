import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_MATRIZ: "@@administracao/LISTAR_MATRIZ",
  ATUALIZAR_MATRIZ: "@@administracao/ATUALIZAR_MATRIZ",
  NOVA_CONDICAO_CHECKLIST: "@@administracao/NOVA_CONDICAO_CHECKLIST",
  EDITAR_CONDICAO_CHECKLIST: "@@administracao/EDITAR_CONDICAO_CHECKLIST",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_CONDICAO_CHECKLIST: "@@administracao/INCLUIR_CONDICAO_CHECKLIST",
  SEARCH_MATRIZ: "@@administracao/SEARCH_MATRIZ",
  EXCLUIR_CONDICAO: "@@administracao/EXCLUIR_CONDICAO",
  ALTERAR_FILTRO: "@@administracao/ALTERAR_FILTRO",
};

export const actions = {
  listarMatriz: createAsyncAction(types.LISTAR_MATRIZ),
  atualizarMatriz: createAsyncAction(types.ATUALIZAR_MATRIZ),
  novaCondicaoChecklist: () => createSyncAction(types.NOVA_CONDICAO_CHECKLIST),
  editarCondicaoChecklist: (values) =>
    createSyncAction(types.EDITAR_CONDICAO_CHECKLIST, values),
  voltarListaMatriz: () => createSyncAction(types.VOLTAR_LISTA),
  incluirCondicaoChecklist: createAsyncAction(types.INCLUIR_CONDICAO_CHECKLIST),
  buscarMatriz: createAsyncAction(types.SEARCH_MATRIZ),
  excluirCondicaoChecklist: createAsyncAction(types.EXCLUIR_CONDICAO),
  alterarFiltro: (valores) =>
    createSyncAction(types.ALTERAR_FILTRO, { valores }),
};
