import FormParticipante from "./FormParticipante";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import validators from "../../../../utils/validators";
import { uf } from "../../../../utils/parametros";
import {
  getLoadingInclusaoParticipante,
  getLocationPayload,
  getParticipante,
  getPropostaLoading,
  getDetalheProposta,
  getCorban,
} from "../../../../selectors/propostas.selectors";
import {
  actions as propostaActions,
  actions,
} from "../../../../reducers/propostas.actions";
import { getPerfil } from "../../../../selectors/usuario.selectors";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../reducers/rotas.actions";
import {
  getProdutosConvenio,
  getEmpresasCorrespondentes,
} from "../../../../selectors/dominios.selectors";
import { getCurrentPathname } from "../../../../selectors/rotas.selectors";

const FormParticipanteConnected = () => {
  const dispatch = useDispatch();
  const participante = useSelector(getParticipante);
  const perfil = useSelector(getPerfil);
  const loadingDetalhe = useSelector(getPropostaLoading);

  const isAtualizacao = !!useSelector(getLocationPayload).idParticipante;
  const produtos = useSelector(getProdutosConvenio);
  const empresasCorrespondentes = useSelector(getEmpresasCorrespondentes);
  const detalheProposta = useSelector(getDetalheProposta);
  const pathname = useSelector(getCurrentPathname);
  const corban_data = useSelector(getCorban);
  const form = useForm();

  // eslint-disable-next-line
  const initialValues =
    !!participante?.dados?.idConsulta && pathname !== "/nova-corban"
      ? {
          ...participante?.dados,
          uf: participante?.dados?.uf ?? "",
          matriz: { id: participante?.dados?.matriz },
        }
      : !participante?.dados?.idConsulta && pathname === "/nova-corban"
      ? {
          ...participante?.dados,
          ...corban_data?.PessoaEndereco,
          ...corban_data?.PessoaContato,
          ...corban_data?.PessoaDadoEmpresa,
          uf: participante?.dados?.uf ?? "",
          codigoPessoa: corban_data?.DadosPessoa?.codigoPessoa,
          nomeContato: corban_data?.PessoaContato?.nome,
          telefone: `(${corban_data?.PessoaContato?.areaCelular}) ${corban_data?.PessoaContato?.telefoneCelular}`,
          tipoCorrespondente: corban_data?.PessoaDadoEmpresa?.tipoEmpresa,
          telefoneMaster:
            corban_data?.PessoaDadoEmpresa?.telefoneMaster?.length > 0
              ? `(${corban_data?.PessoaDadoEmpresa?.areaTelefoneMaster}) ${corban_data?.PessoaDadoEmpresa?.telefoneMaster}`
              : null,
          telefonePLD:
            corban_data?.PessoaDadoEmpresa?.telefonePLD?.length > 0
              ? `(${corban_data?.PessoaDadoEmpresa?.areaTelefonePLD}) ${corban_data?.PessoaDadoEmpresa?.telefonePLD}`
              : null,
        }
      : {
          cpf: "",
          nome: "",
          tipoPessoa: "",
          tipoParticipante: "",
          cep: "",
          logradouro: "",
          complemento: "",
          numero: "",
          bairro: "",
          municipio: "",
          uf: "",
          pais: "",
          paisOutro: "",
          email: "",
          telefone: "",
          localidade: "",
          nomeContato: "",
          nomeFantasia: "",
          dataConstituicao: "",
          tipoCorrespondente: "",
          tipoConstituicao: "",
          valorFaturamento: "",
          matriz: "",
          codigoFuncao: "",
          cnae: "",
          site: "",
          situacaoCnpj: "",
          nomeGerente: "",
        };

  const rules = {
    cpf: validators.cpfCnpj({
      required: true,
      participantes: initialValues?.participantes,
      validarRepetido: false,
    }),
    nome: validators.string({ required: true }),
    nomeContato: validators.string({ required: true }),
    email: validators.email({ required: true }),
    telefone: validators.phone({ required: true }),
    dataConstituicao: validators.date({ required: false, valid: true }),
    tipoCorrespondente: validators.string({ required: true }),
    emailMaster: validators.email({ required: false }),
    emailPLD: validators.email({ required: false }),
    cpfMaster: validators.cpfCnpj({ required: false }),
    cpfPLD: validators.cpfCnpj({ required: false }),
    telefoneMaster: validators.phone({ required: false }),
    telefonePLD: validators.phone({ required: false }),
    nomeGerente: validators.string({ required: false }),
    matriz: validators.object({
      custom: {
        substabelecido: (value) => {
          const tipo = form.getValues("tipoCorrespondente");
          if (tipo === "S") {
            if (value !== undefined && value !== null && !!value.id) {
              return true;
            }
            return "Campo obrigatório";
          }
          return true;
        },
      },
    }),
    valorFaturamento: validators.number({ required: true }),
  };

  const disabled = perfil !== "BANCO";

  // eslint-disable-next-line
  const formProps = {
    ...form,
    rules,
    initialValues,
  };

  useEffect(() => {
    if (initialValues.reset_form) {
      formProps.reset(initialValues);
      dispatch(actions.limparFormulario());
    }
    console.log(initialValues);
    // eslint-disable-next-line
  }, [initialValues.reset_form]);

  const loadingInclusao = useSelector(getLoadingInclusaoParticipante);

  const handleSubmit = useCallback(
    (values, tipoPessoaSocio, tipoSocio) => {
      const request = {
        produto: 2,
        id_pessoa: formProps.initialValues.idPessoa ?? "",
        id_consulta: formProps.initialValues.idConsulta ?? "",
        DadosPessoa: {
          produtoConvenio: values.produtoConvenio
            ? values.produtoConvenio
            : null,
          tipoParticipante: "C",
          tipoPessoa: "J",
          nome: values.nome.toUpperCase(),
          cpf_cnpj: values.cpf
            ?.replaceAll(".", "")
            .replace("-", "")
            .replace("/", ""),
          codigo: formProps.initialValues.codigoPessoa ?? "",
          colaborador: values.colaborador ? "S" : "N",
        },
        PessoaEndereco:
          !values.cep && !values.pais
            ? null
            : {
                pessoa: formProps.initialValues.idPessoa ?? "",
                tipoEndereco: "R",
                cep: values.cep?.replace("-", ""),
                logradouro: values.logradouro
                  ? values.logradouro.toUpperCase()
                  : null,
                numero: values.numero ?? "",
                complemento: values.complemento
                  ? values.complemento.toUpperCase()
                  : null,
                bairro: values.bairro ? values.bairro.toUpperCase() : null,
                municipio: values.municipio
                  ? values.municipio?.toUpperCase()
                  : null,
                uf: values.uf,
                pais: !values.pais
                  ? null
                  : values.pais === "BRASIL"
                  ? "BRASIL"
                  : values.paisOutro.toUpperCase(),
              },
        PessoaContato: {
          pessoa: formProps.initialValues.idPessoa ?? "",
          nome: values.nomeContato,
          email: values.email ?? "",
          areaCelular: values.telefone?.charAt(1) + values.telefone?.charAt(2),
          telefoneCelular: values.telefone?.slice(5).replace("-", ""),
        },
        PessoaDadoEmpresa: {
          pessoa: formProps.initialValues.idPessoa ?? "",
          nomeFantasia: values.nomeFantasia ?? null,
          dataConstituicao: values.dataConstituicao ?? null,
          tipoEmpresa: values.tipoCorrespondente ?? null,
          tipoConstituicao: values.tipoConstituicao ?? null,
          valorFaturamento: values.valorFaturamento ?? null,
          matriz: values.matriz?.id ?? null,
          codigoFuncao: values.codigoFuncao ?? null,
          cnae: values.cnae ?? null,
          site: values.site ?? null,
          situacaoCnpj: values.situacaoCnpj ?? null,
          banco: values.banco ?? null,
          nomeBanco: values.nomeBanco ? values.nomeBanco.toUpperCase() : null,
          agenciaBancaria: values.agenciaBancaria ?? null,
          conta: values.conta ?? null,
          digito: values.digito ? values.digito.toUpperCase() : null,
          nomeMaster: values.nomeMaster
            ? values.nomeMaster.toUpperCase()
            : null,
          cpfMaster: values.cpfMaster ?? null,
          emailMaster: values.emailMaster ?? null,
          areaTelefoneMaster:
            values.telefoneMaster?.length > 0
              ? values.telefoneMaster?.charAt(1) +
                values.telefoneMaster?.charAt(2)
              : null,
          telefoneMaster:
            values.telefoneMaster?.length > 0
              ? values.telefoneMaster?.slice(5).replace("-", "")
              : null,
          certificadoMaster: values.certificadoMaster ?? null,
          loginMaster: values.loginMaster ?? null,
          nomePLD: values.nomePLD ? values.nomePLD.toUpperCase() : null,
          cpfPLD: values.cpfPLD ?? null,
          emailPLD: values.emailPLD ?? null,
          areaTelefonePLD:
            values.telefonePLD?.length > 0
              ? values.telefonePLD?.charAt(1) + values.telefonePLD?.charAt(2)
              : null,
          telefonePLD:
            values.telefonePLD?.length > 0
              ? values.telefonePLD?.slice(5).replace("-", "")
              : null,
          certificadoPLD: values.certificadoPLD ?? null,
          nomeGerente: values.nomeGerente.toUpperCase() ?? null,
        },
        tipoPessoaSocio: tipoPessoaSocio,
        tipoSocio: tipoSocio,
      };

      if (!formProps.initialValues.idConsulta) {
        dispatch(actions.incluirCorbanSocio(request));
        dispatch(rotasActions.redirectTo(routes.NOVO_SOCIO_CORBAN));
      } else {
        dispatch(actions.atualizarParticipante.request(request));
        if (initialValues?.nomeGerente !== values.nomeGerente) {
          if (!initialValues?.nomeGerente && !values.nomeGerente) {
            return;
          } else {
            const text = `Troca de Gerente \nGerente anterior: ${
              initialValues?.nomeGerente
            } \nGerente atual: ${values.nomeGerente?.toUpperCase()}`;
            dispatch(
              actions.atualizarObservacao.request({
                idProposta: detalheProposta?.id,
                observacao: text,
                hideToast: true,
              })
            );
          }
        }
      }
    },

    // eslint-disable-next-line
    [dispatch, formProps.initialValues.idConsulta]
  );

  const handleGoBack = useCallback(() => {
    if (!formProps.initialValues.idConsulta) {
      dispatch(rotasActions.redirectTo(routes.PROPOSTAS));
    } else {
      dispatch(
        rotasActions.redirectTo(routes.PROPOSTA, {
          id: formProps.initialValues.idConsulta,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, formProps, detalheProposta]);

  const successCallback = useCallback(
    ({ data }, value) => {
      formProps.setValue("logradouro", data?.logradouro || "");
      formProps.setValue("bairro", data?.bairro || "");
      formProps.setValue("uf", data?.uf || "");
      formProps.setValue("municipio", data?.localidade || "");
      formProps.setValue("localidade", data?.ibge || "");
    },
    [formProps]
  );

  const consultarCep = useCallback(
    (value) => {
      if (!!value && value.length === 9) {
        dispatch(
          propostaActions.consultarCepCorreios.request({
            value,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );

  const validarCpfCpnjRepetido = useCallback(
    (value) => {
      // initialValues.participantes.comprador.forEach((c) => {
      //   if (c.cpfCnpj === value) return true;
      // });
      // initialValues.participantes.vendedor.forEach((c) => {
      //   if (c.cpfCnpj === value) return true;
      // });
      // return false;
    },
    // eslint-disable-next-line
    [dispatch]
  );

  return (
    <FormParticipante
      loading={
        participante?.loading === undefined
          ? empresasCorrespondentes.loading
          : participante?.loading
      }
      formProps={formProps}
      uf={uf.sort()}
      participante={participante?.dados}
      consultarCep={consultarCep}
      handleSubmit={handleSubmit}
      loadingInclusao={loadingInclusao}
      disabled={disabled}
      loadingDetalhe={loadingDetalhe}
      validarCpfCnpjRepetido={validarCpfCpnjRepetido}
      isAtualizacao={isAtualizacao}
      handleGoBack={handleGoBack}
      produtos={produtos}
      empresasCorrespondentes={empresasCorrespondentes}
    />
  );
};

export default FormParticipanteConnected;
