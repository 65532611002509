import { actions } from "./relatorioBI.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  relatorio: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarRelatorios.REQUEST:
    case actions.listarRelatorios.SUCCESS:
    case actions.listarRelatorios.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarRelatorios.REQUEST,
        error:
          action.type === actions.listarRelatorios.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarRelatorios.SUCCESS
            ? action.payload.response.data
            : [],
      };
    case actions.mostrarRelatorio.REQUEST:
    case actions.mostrarRelatorio.SUCCESS:
    case actions.mostrarRelatorio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.mostrarRelatorio.REQUEST,
        error:
          action.type === actions.mostrarRelatorio.FAILURE
            ? action.payload.error
            : null,
        relatorio:
          action.type === actions.mostrarRelatorio.SUCCESS
            ? action.payload.response.data
            : null,
      };
    case actions.atualizarRelatorio.REQUEST:
    case actions.atualizarRelatorio.SUCCESS:
    case actions.atualizarRelatorio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarRelatorio.REQUEST,
      };
    case actions.criarRelatorio.REQUEST:
    case actions.criarRelatorio.SUCCESS:
    case actions.criarRelatorio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.criarRelatorio.REQUEST,
        relatorio:
          action.type === actions.criarRelatorio.FAILURE
            ? action.payload.original.relatorio
            : null,
      };
    case actions.excluirRelatorio.REQUEST:
    case actions.excluirRelatorio.SUCCESS:
    case actions.excluirRelatorio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirRelatorio.REQUEST,
        relatorio: null,
      };
    default:
      return state;
  }
};

export default reducer;
