import RelatorioAuditoria from "./RelatorioAuditoriaPage";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import validators from "../../utils/validators";
import { getRelatorioAuditoria } from "../../selectors/administracao.selectors";

const RelatorioAuditoriaConnected = () => {
  const relatorioAuditoria = useSelector(getRelatorioAuditoria);

  const rules = {
    propostas: validators.string({ required: false }),
    usuarios: validators.number({ required: false }),
    dataFinal: validators.date({
      required: false,
      custom: {
        requiredIfOther: (value) => {
          const inicial = formProps.getValues("dataInicial");

          if (inicial && !value) {
            return "Campo obrigatório";
          }
          return true;
        },
      },
    }),
    dataInicial: validators.date({
      required: false,
    }),
  };
  const initialValues = {
    propostas: "",
    usuarios: null,
    dataFinal: "",
    dataInicial: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  return (
    <RelatorioAuditoria
      listaAuditoria={relatorioAuditoria}
      loadingFile={relatorioAuditoria.loadingFile}
      loading={relatorioAuditoria.loading}
      formProps={formProps}
      usuarios={relatorioAuditoria.usuarios}
    />
  );
};

export default RelatorioAuditoriaConnected;
