import React, { useState } from "react";
import { Search } from "@material-ui/icons";
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Popper,
} from "@material-ui/core";

// const TextField = ({ helperText, ...otherProps }) => (
//   <InputBase
//     type="text"
//     fullWidth
//     {...otherProps}
//     classes={{
//       root: "lateral-panel-input-container",
//       input: "lateral-panel-input-text",
//     }}
//   />
// );

const View = ({
  open,
  anchor,
  handleClickAway,
  arrow,
  handleArrowRef,
  handleSearchSubmit,
}) => {
  const [value, setValue] = useState("");

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit(value);
      handleClickAway();
    }
  };

  const handleSearchClick = (value) => {
    handleSearchSubmit(value);
    handleClickAway();
  };
  return (
    <Popper
      disablePortal
      className={"popup"}
      open={open}
      anchorEl={anchor}
      placement={"bottom-end"}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrow,
        },
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <span className={"arrow"} ref={handleArrowRef} />
          <Paper className="search-popup-container">
            <InputBase
              placeholder={
                "Consulta, CPF/CNPJ (com zeros e sem pontos) ou Nome"
              }
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => handleEnterKey(e)}
              style={{ width: "100%", padding: 10 }}
              autoFocus={true}
              endAdornment={
                <InputAdornment variant="filled" position="end">
                  <IconButton
                    onClick={() => handleSearchClick(value)}
                    classes={{
                      root: "lateral-panel-input-icon",
                      label: "color-brand",
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Paper>
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

export default View;
