import { actions } from "./dashboard.actions";

const initialState = {
  "producao-dia": {
    loading: false,
    data: "",
  },
  "producao-mes": {
    loading: false,
    data: "",
  },
  "projecao-mes": {
    loading: false,
    data: "",
  },
  "consultas-valor": {
    loading: false,
    data: "",
    page: 1,
  },
  "consultas-por-data": {
    loading: false,
    data: "",
    page: 1,
    visible: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.producaoDia.REQUEST:
    case actions.producaoDia.SUCCESS:
    case actions.producaoDia.FAILURE:
      return {
        ...state,
        "producao-dia": {
          ...state["producao-dia"],
          dataInicio:
            action.type === actions.producaoDia.REQUEST
              ? action.payload?.dataInicio
              : state["producao-dia"].dataInicio,
          dataFim:
            action.type === actions.producaoDia.REQUEST
              ? action.payload?.dataFim
              : state["producao-dia"].dataFim,
          loading: action.type === actions.producaoDia.REQUEST,
          data:
            action.type === actions.producaoDia.SUCCESS
              ? action.payload.response.data
              : "",
        },
        "consultas-por-data": {
          ...state["consultas-por-data"],
          visible: false,
        },
      };
    case actions.producaoMes.REQUEST:
    case actions.producaoMes.SUCCESS:
    case actions.producaoMes.FAILURE:
      return {
        ...state,
        "producao-mes": {
          ...state["producao-mes"],
          loading: action.type === actions.producaoMes.REQUEST,
          dataInicio:
            action.type === actions.producaoMes.REQUEST
              ? action.payload?.dataInicio
              : state["producao-mes"].dataInicio,
          dataFim:
            action.type === actions.producaoMes.REQUEST
              ? action.payload?.dataFim
              : state["producao-mes"].dataFim,
          data:
            action.type === actions.producaoMes.SUCCESS
              ? action.payload.response.data
              : "",
        },
        "consultas-por-data": {
          ...state["consultas-por-data"],
          visible: false,
        },
      };
    case actions.projecaoMes.REQUEST:
    case actions.projecaoMes.SUCCESS:
    case actions.projecaoMes.FAILURE:
      return {
        ...state,
        "projecao-mes": {
          ...state["projecao-mes"],
          loading: action.type === actions.projecaoMes.REQUEST,
          data:
            action.type === actions.projecaoMes.SUCCESS
              ? action.payload.response.data
              : "",
        },
      };
    case actions.consultasAcimaValor.REQUEST:
    case actions.consultasAcimaValor.SUCCESS:
    case actions.consultasAcimaValor.FAILURE:
      return {
        ...state,
        "consultas-valor": {
          ...state["consultas-valor"],
          loading: action.type === actions.consultasAcimaValor.REQUEST,
          page:
            action.type === actions.consultasAcimaValor.SUCCESS
              ? action.payload.original
              : state["consultas-valor"].page,
          total:
            action.type === actions.consultasAcimaValor.SUCCESS
              ? action.payload.response.data.total
              : state["consultas-valor"].total,
          data:
            action.type === actions.consultasAcimaValor.SUCCESS
              ? action.payload.response.data.consultas
              : "",
        },
      };
    case actions.consultasPorData.REQUEST:
    case actions.consultasPorData.SUCCESS:
    case actions.consultasPorData.FAILURE:
      return {
        ...state,
        "consultas-por-data": {
          ...state["consultas-por-data"],
          loading: action.type === actions.consultasPorData.REQUEST,
          visible: true,
          page:
            action.type === actions.consultasPorData.SUCCESS
              ? action.payload.original
              : state["consultas-por-data"].page,
          total:
            action.type === actions.consultasPorData.SUCCESS
              ? action.payload.response.data.total
              : state["consultas-por-data"].total,
          data:
            action.type === actions.consultasPorData.SUCCESS
              ? action.payload.response.data.consultas
              : "",
        },
      };
    default:
      return state;
  }
};

export default reducer;
