import React from "react";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  Menu,
  Toolbar,
} from "@material-ui/core";
import { AccountCircle, Menu as MenuIcon } from "@material-ui/icons";
import Logo from "assets/ib-logo.png";
import DrawerMenu from "./DrawerMenu";
import NestedMenuItem from "material-ui-nested-menu-item";
import { MenuItem } from "../../components";
import { Search } from "@material-ui/icons";
import SearchPopupConnected from "./search/SearchPopup.connected";

const SubMenus = React.forwardRef(
  ({ menus, handleClose, parentMenuOpen }, ref) => {
    return menus.map((item) => {
      if (!!item.menus) {
        return (
          <NestedMenuItem
            ref={ref}
            className={"submenu-item"}
            key={item.id}
            label={item.label}
            parentMenuOpen={parentMenuOpen}
            onClick={handleClose}
          >
            <SubMenus
              menus={item.menus}
              parentMenuOpen={parentMenuOpen}
              handleClose={handleClose}
            />
          </NestedMenuItem>
        );
      } else {
        const onClick = !!item.action
          ? () => item.action(item.route)
          : handleClose;
        return (
          <MenuItem key={item.id} onClick={onClick}>
            {item.label}
          </MenuItem>
        );
      }
    });
  }
);

const Menus = ({ menu, anchorEl, handleClose }) => {
  if (!menu.menus) return null;

  return (
    <Menu
      open={!!anchorEl[menu.id]}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorEl[menu.id]}
    >
      <SubMenus
        menus={menu.menus}
        handleClose={handleClose}
        parentMenuOpen={!!anchorEl[menu.id]}
      />
    </Menu>
  );
};

const Header = ({
  menus,
  username,
  handleClickLogout,
  handleClickOpenSearch,
  showSearch,
}) => {
  const [anchorEl, setAnchorEl] = React.useState({});
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleMenu = ({ event, id }) => {
    setAnchorEl({
      [id]: {
        top: event.clientY,
        left: event.clientX,
      },
    });
  };

  const handleClose = (id) => {
    setAnchorEl({
      [id]: null,
    });
  };

  return (
    <AppBar position="sticky" className="header">
      <Toolbar className="container-xl flex-row justify-content-between">
        {/*<Logo className={"logo-header"} />*/}
        <img src={Logo} alt="logo" className={"logo-header img-error"} />
        <div className="d-none d-md-block">

          {menus.map((menu) => {
            let onClick = null;
            if (!!menu.action) {
              onClick = () => menu.action(menu.route);
            } else if (!!menu.menus) {
              onClick = (event) => handleMenu({ event, id: menu.id });
            }

            return (
              <span key={menu.id}>
                <Button onClick={onClick}>
                  <span className={"menu-item"}>{menu.label}</span>
                </Button>
                <Menus
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menu={menu}
                />
              </span>
            );
          })}
          {showSearch && (
            <>
              <span
                style={{
                  marginLeft: "16px",
                }}
              >
                <Search
                  className={"menu-item menu-icon menu-icon-font clickable"}
                  onClick={handleClickOpenSearch}
                />
              </span>
              <SearchPopupConnected />
            </>
          )}
          <Button
            aria-haspopup="true"
            onClick={(event) => handleMenu({ event, id: "user" })}
            color="inherit"
          >
            <AccountCircle className={"ml-2 mr-2"} />
            <span className="user-text mr-2">{username}</span>
          </Button>
          <Menu
            open={!!anchorEl["user"]}
            onClose={() => handleClose("user")}
            anchorReference="anchorPosition"
            anchorPosition={anchorEl["user"]}
          >
            {/*<MenuItem onClick={handleClose}>Perfil</MenuItem>*/}
            {/*<Divider />*/}
            <MenuItem onClick={handleClickLogout}>Sair</MenuItem>
          </Menu>
        </div>
        <div className={"d-block d-md-none"}>
          {showSearch && (
            <>
              <span
                style={{
                  marginLeft: "16px",
                }}
              >
                <Search
                  className={"menu-item menu-icon menu-icon-font clickable"}
                  onClick={handleClickOpenSearch}
                />
              </span>
              <SearchPopupConnected />
            </>
          )}


          <IconButton onClick={() => setDrawerOpen(true)}>
            <MenuIcon className="menu-icon-font" />
          </IconButton>
          <Drawer
            anchor={"left"}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <div className="drawer-small">
              <div className={"drawer-header"}>{username}</div>
              <Divider />
              <List component="div">
                {menus.map((menu) => {
                  return <DrawerMenu level={0} key={menu.id} menu={menu} />;
                })}
                {/*<DrawerMenu*/}
                {/*  menu={{*/}
                {/*    id: "menu",*/}
                {/*    label: "Perfil",*/}
                {/*    action: () => {},*/}
                {/*  }}*/}
                {/*/>*/}
                <DrawerMenu
                  menu={{
                    id: "logout",
                    label: "Sair",
                    action: handleClickLogout,
                  }}
                />
              </List>
            </div>
          </Drawer>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
