import { ContentState, convertToRaw, EditorState } from "draft-js";
import d2h from "draftjs-to-html";
import h2d from "html-to-draftjs";

export const htmlToDraft = (value) => {
  const html = h2d(value);
  const contentState = ContentState.createFromBlockArray(html.contentBlocks);
  return EditorState.createWithContent(contentState);
};

export const draftToHtml = (state) => {
  return d2h(convertToRaw(state.getCurrentContent()));
};
