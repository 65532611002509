import { actions, types } from "./organizacao.actions";

const initialState = {
  lista: [],
  total: 0,
  pagina: 1,
  search: null,
  organizacao: null,
  gerencias: [],
  diretorias: [],
  cenins: [],
  centralizadoras: [],
  matriz_parceiros: [],
  loading: false,
  saving: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarOrganizacoes.REQUEST:
    case actions.listarOrganizacoes.SUCCESS:
    case actions.listarOrganizacoes.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarOrganizacoes.REQUEST,
        error:
          action.type === actions.listarOrganizacoes.FAILURE
            ? action.payload.error
            : null,
        lista:
          action.type === actions.listarOrganizacoes.SUCCESS
            ? action.payload.response.data.organizacoes
            : [],
        total:
          action.type === actions.listarOrganizacoes.SUCCESS
            ? action.payload.response.data.total
            : 0,
        pagina:
          action.type === actions.listarOrganizacoes.SUCCESS
            ? action.payload.response.data.pagina
            : 0,
        search:
          action.type === actions.listarOrganizacoes.SUCCESS
            ? action.payload.response.data.search
            : null,
      };
    case actions.organizacaoDominios.REQUEST:
    case actions.organizacaoDominios.SUCCESS:
    case actions.organizacaoDominios.FAILURE:
      return {
        ...state,
        error:
          action.type === actions.organizacaoDominios.FAILURE
            ? action.payload.error
            : null,
        gerencias:
          action.type === actions.organizacaoDominios.SUCCESS
            ? action.payload.response.data.gerencias
            : [],
        diretorias:
          action.type === actions.organizacaoDominios.SUCCESS
            ? action.payload.response.data.diretorias
            : [],
        centralizadoras:
          action.type === actions.organizacaoDominios.SUCCESS
            ? action.payload.response.data.centralizadoras
            : [],
        cenins:
          action.type === actions.organizacaoDominios.SUCCESS
            ? action.payload.response.data.cenins
            : [],
        matriz_parceiros:
          action.type === actions.organizacaoDominios.SUCCESS
            ? action.payload.response.data.matriz_parceiros
            : [],
      };
    case types.NOVA_ORGANIZACAO:
      return {
        ...state,
        organizacao: {
          bairro: null,
          cenim: null,
          centralizadora: null,
          cep: null,
          codigo: null,
          complemento: null,
          consultor_comercial: null,
          cpf_cnpj: null,
          ddd_celular: null,
          email: null,
          isMatriz: null,
          logradouro: null,
          matriz: null,
          municipio: null,
          nome: null,
          nome_fantasia: null,
          nr_celular: null,
          numero_logradouro: null,
          organizacaoMatriz: null,
          organizacao_agencia: null,
          responsavel: null,
          tipo: null,
          uf: null,
        },
      };
    case types.VOLTAR_LISTA_ORGANIZACAO:
      return {
        ...state,
        organizacao: null,
      };
    case types.EDITAR_ORGANIZACAO:
      return {
        ...state,
        organizacao: { ...action.payload, not_loading: true },
      };
    case actions.salvarOrganizacao.REQUEST:
    case actions.salvarOrganizacao.SUCCESS:
    case actions.salvarOrganizacao.FAILURE:
      return {
        ...state,
        saving: action.type === actions.salvarOrganizacao.REQUEST,
        organizacao:
          action.type === actions.salvarOrganizacao.SUCCESS
            ? null
            : state.organizacao,
        error:
          action.type === actions.listarOrganizacoes.FAILURE
            ? action.payload.error
            : null,
      };
    case actions.removerOrganizacao.REQUEST:
    case actions.removerOrganizacao.SUCCESS:
    case actions.removerOrganizacao.FAILURE:
      return {
        ...state,
        loading: action.type === actions.removerOrganizacao.REQUEST,
        error:
          action.type === actions.removerOrganizacao.FAILURE
            ? action.payload.error
            : null,
      };

    default:
      return state;
  }
};

export default reducer;
