import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentos,
  getDocumento,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/documentos.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormDocumento from "./FormDocumento";
import { getPerfis } from "../../../selectors/dominios.selectors";

const DocumentosPageConnected = () => {
  const dispatch = useDispatch();
  const documentos = useSelector(getDocumentos);
  const documento = useSelector(getDocumento);
  const { data: perfis } = useSelector(getPerfis);

  const initialValues = {
    ...documento,
    perfis: documento?.perfil
      ?.map((dp) => {
        return perfis.filter((perfil) => perfil.id === dp.perfil)[0];
      })
      .filter((p) => !!p),
    analise: documento?.analise
      ?.map((dp) => {
        return perfis.filter((perfil) => perfil.id === dp.perfil)[0];
      })
      .filter((p) => !!p),
    interno: documento.interno === "S",
    permiteDispensa: documento.permiteDispensa === "S",
    dataValidade: documento.dataValidade === "S",
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    origem: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        codigo: values.codigo,
        nome: values.nome.toUpperCase(),
        tipo: "P",
        origem: values.origem,
        orientacao: values.orientacao,
        interno: values.interno ? "S" : "N",
        permiteDispensa: values.permiteDispensa ? "S" : "N",
        dataValidade: values.dataValidade ? "S" : "N",
        perfis: values.perfis,
        analise: values.analise,
      };
      if (!!i) {
        dispatch(actions.atualizarDocumento.request({ documento: request }));
      } else {
        dispatch(actions.incluirDocumento.request({ documento: request }));
      }
    },
    // eslint-disable-next-line
    [dispatch, documentos]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaDocumento());
  }, [dispatch]);

  return (
    <FormDocumento
      documento={documento}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
      perfis={perfis}
    />
  );
};

export default DocumentosPageConnected;
