import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import {
  ControlledTextfield,
  ControlledSelect,
} from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";
import { CloseSharp } from "@material-ui/icons";
import { TIPOS_DESTINATARIOS } from "../../../constants/administracao/templateEmails.constants";

const DestinatarioDialog = ({
  handleSubmit,
  data,
  formProps,
  handleClose,
  open,
}) => {
  const [showOther, setShowOther] = useState(data.tipo === "OU");

  useEffect(() => {
    setShowOther(data.tipo === "OU");
  }, [data, setShowOther]);

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="sm"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Destinatários</div>
        <form
          className="d-flex flex-column"
          onSubmit={formProps.handleSubmit(handleSubmit)}
        >
          <div className="row">
            <div className="col-12">
              <ControlledSelect
                name={"tipo"}
                margin={"none"}
                formProps={formProps}
                onChange={(v) => setShowOther(v.target.value === "OU")}
                label={"Destinatário"}
              >
                {Object.keys(TIPOS_DESTINATARIOS).map((key) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={key}
                    value={key}
                  >
                    {TIPOS_DESTINATARIOS[key]}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            {showOther && (
              <div className="col-12">
                <ControlledTextfield
                  name={"outrosDestinatarios"}
                  margin={"none"}
                  formProps={formProps}
                  label={"Destinatário"}
                  uppercase={false}
                />
              </div>
            )}
          </div>
          <div className={"row justify-content-center mb-3 mt-2"}>
            <div className="col-6 mt-3">
              <Button label={"CONFIRMAR"} type={"submit"} />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DestinatarioDialog;
