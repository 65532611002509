import React from "react";
import {
  ControlledTextfield,
  IntegerTextField,
} from "../../../../components/inputs";

const CardDadosBancarios = ({ props, disabled }) => {
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Dados Bancários</span>
        <div className="row">
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"banco"}
              formProps={props}
              label={"Banco"}
              margin={"none"}
              disabled={disabled}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 3, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"nomeBanco"}
              formProps={props}
              label={"Nome Banco"}
              margin={"none"}
              disabled={disabled}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"agenciaBancaria"}
              formProps={props}
              label={"Agência"}
              margin={"none"}
              disabled={disabled}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 4, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"conta"}
              formProps={props}
              label={"Conta"}
              margin={"none"}
              disabled={disabled}
              InputProps={{ inputComponent: IntegerTextField }}
              inputProps={{ maxLength: 9, thousandSeparator: "" }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"digito"}
              formProps={props}
              label={"Dígito"}
              margin={"none"}
              disabled={disabled}
              inputProps={{ maxLength: 1 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDadosBancarios;
