import React from "react";
import BasePage from "../BasePage";
import Filtros from "./Filtros";

const listaAuditoriaErroIntegracaoPage = ({
  formProps,
  handleFilterClick,
  usuarios,
  loading,
  loadingFile,
}) => {
  return (
    <BasePage isLoggedIn={true}>
      <h2>Auditoria Documentos</h2>
      <div className={"row spacer"}>
        <div className={"col-12"}>
          <Filtros
            usuarios={usuarios}
            formProps={formProps}
            handleFilterClick={handleFilterClick}
            loading={loading}
            loadingFile={loadingFile}
          />
        </div>
      </div>
    </BasePage>
  );
};

export default listaAuditoriaErroIntegracaoPage;
