import React, { useState } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  IntegerTextField,
  ControlledSwitch,
  ControlledAutocomplete,
} from "../../../components/inputs";
import { MenuItem } from "../../../components";

const FormAtividade = ({
  props,
  disabled,
  atividade,
  etapa,
  perfis,
  tipoCondicaoCriacao,
  tipoResponsavel,
  templates,
}) => {
  const [isProducao, setIsProducao] = useState(atividade.isProducao);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-3">
          <ControlledTextfield
            name={"codigo"}
            margin={"none"}
            disabled={disabled}
            formProps={props}
            label={"Código"}
            InputProps={{
              inputComponent: IntegerTextField,
            }}
          />
        </div>
        <div className="col-12 col-md-9">
          <ControlledTextfield
            name={"nome"}
            formProps={props}
            uppercase={false}
            disabled={disabled}
            label={"Atividade"}
            margin={"none"}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div className="col-12 col-md-6">
          <ControlledSelect
            name={"Etapa.id"}
            formProps={props}
            label={"Etapa"}
            margin={"none"}
          >
            {etapa.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item.id}
                value={item.id}
              >
                {item.nome.toUpperCase()}
              </MenuItem>
            ))}
          </ControlledSelect>
        </div>
        <div className="col-12 col-md-6">
          <ControlledSelect
            name={"condicaoCriacao"}
            formProps={props}
            label={"Condição Criação"}
            margin={"none"}
          >
            {tipoCondicaoCriacao.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item.id}
                value={item.id}
              >
                {item.descricao}
              </MenuItem>
            ))}
          </ControlledSelect>
        </div>
        <div className="col-12 col-md-6">
          <ControlledSelect
            name={"responsavel"}
            formProps={props}
            label={"Responsável"}
            margin={"none"}
          >
            {tipoResponsavel.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item.id}
                value={item.id}
              >
                {item.nome}
              </MenuItem>
            ))}
          </ControlledSelect>
        </div>
        <div className="col-12 col-md-3">
          <ControlledTextfield
            name={"ordem"}
            margin={"none"}
            disabled={disabled}
            formProps={props}
            label={"Ordem"}
            InputProps={{
              inputComponent: IntegerTextField,
            }}
          />
        </div>
        <div className="col-12 col-md-3">
          <ControlledTextfield
            name={"sla"}
            margin={"none"}
            disabled={disabled}
            formProps={props}
            label={"SLA (dias)"}
            InputProps={{
              inputComponent: IntegerTextField,
            }}
          />
        </div>
        <div className="col-12 col-md-3">
          <ControlledSwitch
            name={"isProducao"}
            formProps={props}
            onChange={() => setIsProducao(!isProducao)}
            label={"É Produção?"}
            margin={"none"}
          />
        </div>
        <div className="col-12 col-md-3">
          <ControlledSwitch
            name="isComentarioObrigatorio"
            formProps={props}
            label="Comentário Obrigatório?"
            margin="none"
          />
        </div>
        <div className="col-12 col-md-12">
          <ControlledAutocomplete
            name={"perfis"}
            domain={perfis}
            autocompleteProps={{
              multiple: true,
            }}
            labelField={"nome"}
            formProps={props}
            label={"Perfil de Acesso"}
            margin={"none"}
          />
        </div>
        <div className="col-12">
          <ControlledAutocomplete
            name={"templates"}
            formProps={props}
            label={"Templates de Email"}
            labelField={"nome"}
            margin={"none"}
            domain={templates}
            autocompleteProps={{
              multiple: true,
            }}
          />
        </div>
        <div className="col-12">
          <ControlledTextfield
            name={"orientacao"}
            formProps={props}
            uppercase={false}
            disabled={disabled}
            label={"Orientação"}
            margin={"none"}
            inputProps={{ maxLength: 4000 }}
            rowsMax={16}
            rows={12}
            multiline={true}
          />
        </div>
      </div>
    </>
  );
};

export default FormAtividade;
