import FormSocio from "./FormSocio";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import validators from "../../../../utils/validators";
import {
  getLoadingInclusaoParticipante,
  getLocationPayload,
  getParticipante,
  getPropostaLoading,
  getDetalheProposta,
} from "../../../../selectors/propostas.selectors";
import { actions } from "../../../../reducers/propostas.actions";
import { getPerfil } from "../../../../selectors/usuario.selectors";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../reducers/rotas.actions";

const FormParticipanteConnected = () => {
  const dispatch = useDispatch();
  const participante = useSelector(getParticipante);
  const perfil = useSelector(getPerfil);
  const loadingDetalhe = useSelector(getPropostaLoading);

  const isAtualizacao = !!useSelector(getLocationPayload).idParticipante;
  const detalheProposta = useSelector(getDetalheProposta);

  const tipoPessoaParam = useSelector(getLocationPayload).tipoPessoa;
  const tipoParticipanteParam = useSelector(getLocationPayload)
    .tipoParticipante;
  const idConsulta = useSelector(getLocationPayload).id;

  const initialValues = {
    ...participante?.dados,
  };

  const rules = {
    cpf: validators.cpfCnpj({
      required: true,
      participantes: initialValues?.participantes,
      validarRepetido: false,
    }),
    nome: validators.string({ required: true }),
    nomeContato: validators.string({ required: true }),
    email: validators.email({ required: true }),
    telefone: validators.phone({ required: true }),
    dataConstituicao: validators.date({ required: false, valid: true }),
    tipoCorrespondente: validators.string({ required: true }),
    nomeGerente: validators.string({ required: false }),
  };

  const disabled = perfil !== "BANCO";

  // eslint-disable-next-line
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    if (initialValues.reset_form) {
      formProps.reset(initialValues);
      dispatch(actions.limparFormulario());
    }
    // eslint-disable-next-line
  }, [initialValues.reset_form]);

  const loadingInclusao = useSelector(getLoadingInclusaoParticipante);

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        produto: 2,
        id_pessoa: formProps.initialValues.idPessoa ?? "",
        id_consulta: formProps.initialValues.idConsulta ?? idConsulta,
        DadosPessoa: {
          tipoParticipante: i.tipoParticipante,
          tipoPessoa: i.tipoPessoa,
          nome: values.nome.toUpperCase(),
          cpf_cnpj: values.cpf
            ?.replaceAll(".", "")
            .replace("-", "")
            .replace("/", ""),
          codigo: formProps.initialValues.codigoPessoa ?? "",
          colaborador: values.colaborador ? "S" : "N",
          dataConsulta:
            values.dataConsulta !== "" && values.dataConsulta !== undefined
              ? values.dataConsulta
              : new Date().toISOString().slice(0, 10),
        },
        PessoaContato: {
          pessoa: formProps.initialValues.idPessoa ?? "",
          nome: values.nomeContato,
          email: values.email ?? "",
          areaCelular: values.telefone?.charAt(1) + values.telefone?.charAt(2),
          telefoneCelular: values.telefone?.slice(5).replace("-", ""),
        },
      };
      if (!formProps.initialValues.idConsulta && !idConsulta) {
        dispatch(
          actions.atualizarParticipante.request({
            ...detalheProposta.corban,
            socio: request,
          })
        );
      } else {
        dispatch(actions.atualizarParticipante.request(request));
      }
    },
    // eslint-disable-next-line
    [dispatch, detalheProposta, formProps.initialValues.idConsulta]
  );

  const handleGoBack = useCallback(() => {
    if (!formProps.initialValues.idConsulta && !idConsulta) {
      dispatch(actions.voltarInclusaoCorban());
      dispatch(rotasActions.redirectTo(routes.NOVA_CORBAN));
    } else {
      dispatch(
        rotasActions.redirectTo(routes.PROPOSTA, {
          id: formProps.initialValues.idConsulta ?? idConsulta,
        })
      );
    }

    // eslint-disable-next-line
  }, [dispatch, formProps, detalheProposta]);

  const validarCpfCpnjRepetido = useCallback(
    (value) => {
      // initialValues.participantes.comprador.forEach((c) => {
      //   if (c.cpfCnpj === value) return true;
      // });
      // initialValues.participantes.vendedor.forEach((c) => {
      //   if (c.cpfCnpj === value) return true;
      // });
      // return false;
    },
    // eslint-disable-next-line
    [dispatch]
  );

  return (
    <FormSocio
      loading={participante?.loading}
      formProps={formProps}
      participante={participante?.dados}
      handleSubmit={handleSubmit}
      loadingInclusao={loadingInclusao}
      disabled={disabled}
      loadingDetalhe={loadingDetalhe}
      validarCpfCnpjRepetido={validarCpfCpnjRepetido}
      isAtualizacao={isAtualizacao}
      handleGoBack={handleGoBack}
      tipoPessoa={
        !!detalheProposta?.corban
          ? detalheProposta?.corban?.tipoPessoaSocio
          : isAtualizacao
          ? participante?.dados?.tipoPessoa
          : tipoPessoaParam
      }
      tipoSocio={
        !!detalheProposta?.corban
          ? detalheProposta?.corban?.tipoSocio
          : isAtualizacao
          ? participante?.dados?.tipoParticipante
          : tipoParticipanteParam
      }
    />
  );
};

export default FormParticipanteConnected;
