import basicFlow from "sagas/asyncHandler";
import { actions } from "reducers/administracao/cargaConsultas.actions";
import { authenticatedRequest } from "utils/api";

const processarApi = (form) => {
  return authenticatedRequest({
    url: "/administracao/carga-consultas",
    method: "post",
    body: form,
  });
};
const processar = basicFlow({
  actionGenerator: actions.processar,
  transform: (payload) => {
    const form = new FormData();

    payload.files.forEach((file) => {
      form.append("file", file, file.name);
    });

    return form;
  },
  api: processarApi,
});

export const sagas = [processar.watcher()];
