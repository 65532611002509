import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/organizacao.actions";
import { useForm } from "react-hook-form";
import { uf } from "../../../utils/parametros";
import formatters from "../../../utils/formatters";
import {
  getOrganizacao,
  getDominioOrganizacoes,
  getOrganizacoes,
} from "../../../selectors/administracao.selectors";
import validators from "../../../utils/validators";
import FormOrganizacao from "./FormOrganizacao";
import { actions as propostaActions } from "../../../reducers/propostas.actions";
import { removeNonDigitsFromString } from "../../../utils/basic";

const FormOrganizacaoConnected = ({ tipoOrganizacao }) => {
  const dispatch = useDispatch();
  const organizacao = useSelector(getOrganizacao);
  const dominios = useSelector(getDominioOrganizacoes);
  const { saving } = useSelector(getOrganizacoes);
  const initialValues = {
    ...organizacao,
    cpf_cnpj:
      organizacao?.cpf_cnpj === null
        ? null
        : organizacao?.cpf_cnpj?.length <= 11
        ? formatters.strings.cpf(organizacao?.cpf_cnpj)
        : formatters.strings.cnpj(organizacao?.cpf_cnpj),
    telefone:
      organizacao.ddd_celular === null
        ? null
        : `(${organizacao.ddd_celular}) ${organizacao.nr_celular}`,
    isMatriz: organizacao.isMatriz === "S",
    uf: "",
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    tipo: validators.string({ required: true }),
    email: validators.email({ required: false }),
    cpf_cnpj: validators.cpfCnpj({
      required: false,
    }),
    telefone: validators.phone({ required: false }),
  };
  // eslint-disable-next-line
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };
  const successCallback = useCallback(
    ({ data }, value) => {
      console.log(data);
      formProps.setValue("logradouro", data?.logradouro || "");
      formProps.setValue("bairro", data?.bairro || "");
      formProps.setValue("uf", data?.uf || "");
      formProps.setValue("municipio", data?.localidade || "");
    },
    [formProps]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaOrganizacao());
  }, [dispatch]);

  const handleZipCodeBlur = useCallback(
    (value) => {
      if (!!value && value.length === 9) {
        dispatch(
          propostaActions.consultarCepCorreios.request({
            value,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.salvarOrganizacao.request(
          !organizacao?.id
            ? {
                ...values,
                nome: values?.nome?.toUpperCase() ?? "",
                logradouro: values?.logradouro?.toUpperCase() ?? "",
                nome_fantasia: values?.nome_fantasia?.toUpperCase() ?? "",
                numero_logradouro:
                  values?.numero_logradouro?.toUpperCase() ?? "",
                complemento: values?.complemento?.toUpperCase() ?? "",
                bairro: values?.bairro?.toUpperCase() ?? "",
                municipio: values?.municipio?.toUpperCase() ?? "",
                responsavel: values?.responsavel?.toUpperCase() ?? "",
                consultor_comercial:
                  values?.consultor_comercial?.toUpperCase() ?? "",
                cpf_cnpj:
                  values?.cpf_cnpj === ""
                    ? null
                    : removeNonDigitsFromString(values?.cpf_cnpj),
                ddd_celular:
                  values.telefone === ""
                    ? null
                    : values.telefone?.charAt(1) + values.telefone?.charAt(2),
                nr_celular:
                  values.telefone === ""
                    ? null
                    : values.telefone?.slice(5).replace("-", ""),
                cep:
                  values?.cep === ""
                    ? null
                    : removeNonDigitsFromString(values?.cep),
              }
            : {
                ...values,
                nome: values?.nome?.toUpperCase() ?? "",
                logradouro: values?.logradouro?.toUpperCase() ?? "",
                nome_fantasia: values?.nome_fantasia?.toUpperCase() ?? "",
                numero_logradouro:
                  values?.numero_logradouro?.toUpperCase() ?? "",
                complemento: values?.complemento?.toUpperCase() ?? "",
                bairro: values?.bairro?.toUpperCase() ?? "",
                municipio: values?.municipio?.toUpperCase() ?? "",
                responsavel: values?.responsavel?.toUpperCase() ?? "",
                consultor_comercial:
                  values?.consultor_comercial?.toUpperCase() ?? "",
                cpf_cnpj:
                  values?.cpf_cnpj === ""
                    ? null
                    : removeNonDigitsFromString(values?.cpf_cnpj),
                ddd_celular:
                  values.telefone === ""
                    ? null
                    : values.telefone?.charAt(1) + values.telefone?.charAt(2),
                nr_celular:
                  values.telefone === ""
                    ? null
                    : values.telefone?.slice(5).replace("-", ""),
                cep:
                  values?.cep === ""
                    ? null
                    : removeNonDigitsFromString(values?.cep),
                id: organizacao.id,
              }
        )
      );
    },
    [dispatch, organizacao]
  );

  return (
    <FormOrganizacao
      organizacao={organizacao}
      formProps={formProps}
      voltarParaLista={voltarParaLista}
      tipoOrganizacao={tipoOrganizacao}
      dominios={dominios}
      handleZipCodeBlur={handleZipCodeBlur}
      uf={uf}
      handleSubmit={handleSubmit}
      saving={saving}
    />
  );
};

export default FormOrganizacaoConnected;
