import { useCallback } from "react";
import ExportConsultasPage from "./ExportConsultasPage";
import { useDispatch, useSelector } from "react-redux";
import { getExportConsultas } from "../../../selectors/administracao.selectors";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import { actions } from "../../../reducers/administracao/exportConsultas.actions";

const ExportConsultasPageConnected = () => {
  const dispatch = useDispatch();
  const exportConsultas = useSelector(getExportConsultas);
  const rules = {
    status: validators.array({ required: false }),
    dataInicio: validators.date({ valid: true, required: false }),
    dataFim: validators.date({
      valid: true,
      required: false,
    }),
  };
  const initialValues = {
    dataInicio: "",
    dataFim: "",
    status: [],
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      dispatch(actions.processar.request(values));
    },
    [dispatch]
  );
  const handleCleanFile = useCallback(() => {
    dispatch(actions.cleanFile);
  }, [dispatch]);

  return (
    <ExportConsultasPage
      formProps={formProps}
      submitting={exportConsultas.downloading}
      showResults={exportConsultas.show_results}
      resultado={exportConsultas.resultado}
      handleSubmit={handleSubmit}
      handleCleanFile={handleCleanFile}
    />
  );
};

export default ExportConsultasPageConnected;
