import BasePage from "../../BasePage";
import Loader from "../../../components/loaders";
import React, { useCallback, useState } from "react";
import { Add } from "@material-ui/icons";
import Relatorio from "./Relatorio";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import FormRelatorio from "./FormRelatorio";
import { actions } from "../../../reducers/administracao/relatorioBI.actions";
import { useDispatch } from "react-redux";

const RelatorioBIPage = ({ loading, relatorios, handleDelete, relatorio }) => {
  const [dadosExclusaoRelatorio, setDadosExclusaoRelatorio] = useState({
    open: false,
  });

  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const handleCloseExclusaoRelatorio = useCallback(() => {
    setDadosExclusaoRelatorio({
      open: false,
    });
    // eslint-disable-next-line
  }, []);

  const handleOpenExclusaoRelatorio = useCallback((values) => {
    setDadosExclusaoRelatorio({
      open: true,
      relatorio: values,
    });
    // eslint-disable-next-line
  }, []);

  const handleNew = () => {
    setIsNew(true);
  };

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.mostrarRelatorio.request(values.id));
      setIsEdit(true);
    },
    [setIsEdit, dispatch]
  );

  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row spacer">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={80} />
          </div>
        </div>
      )}
      {!loading && !isEdit && !isNew && (
        <div className="row spacer">
          <div className="col-12">
            <div className="card" style={{ minHeight: 100 }}>
              <div className="card-body d-flex flex-column">
                <div className={"row d-flex align-items-center"}>
                  <div className={"col mt-0"}>
                    <div className="card-title color-brand">Relatórios BI</div>
                  </div>
                  <div className="d-none d-md-block mb-4"></div>
                </div>
                <div className={"row spacer"}>
                  <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 color-brand">
                    <span>CÓDIGO</span>
                  </div>
                  <div className="col-3 col-md-3 mt-sm-3 mt-lg-0 color-brand">
                    <span>RELATÓRIO</span>
                  </div>
                  <div className="col-3 col-md-3 mt-sm-3 mt-lg-0 color-brand">
                    <span>ID WORKSPACE</span>
                  </div>
                  <div className="col-3 col-md-3 mt-sm-3 mt-lg-0 color-brand">
                    <span>ID RELATÓRIO</span>
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-12">
                    {relatorios.map((d, index) => (
                      <Relatorio
                        key={d.id}
                        relatorio={d}
                        index={index}
                        handleEdit={handleEdit}
                        handleOpenExclusaoRelatorio={
                          handleOpenExclusaoRelatorio
                        }
                      />
                    ))}
                  </div>
                </div>
                <div className="row spacer">
                  <div className="col-12 offset-md-9 col-md-3">
                    <div className="d-none d-md-block">
                      <div className="botao-adicionar mr-3" onClick={handleNew}>
                        <Add className="botao-adicionar-icon mr-1" />
                        <span className="botao-adicionar-text">ADICIONAR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && isEdit && (
        <div>
          <FormRelatorio
            setIsNew={setIsNew}
            setIsEdit={setIsEdit}
            isEdit={true}
          />
        </div>
      )}
      {!loading && isNew && (
        <div>
          <FormRelatorio
            setIsNew={setIsNew}
            setIsEdit={setIsEdit}
            isEdit={false}
          />
        </div>
      )}

      <ConfirmarExclusaoDialog
        open={dadosExclusaoRelatorio.open}
        handleClose={handleCloseExclusaoRelatorio}
        excluirRelatorio={handleDelete}
        relatorio={dadosExclusaoRelatorio.relatorio}
      />
    </BasePage>
  );
};

export default RelatorioBIPage;
