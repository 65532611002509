import Loader from "../../components/loaders";
import React from "react";
import Consulta from "./ConsultaAcimaValor";
import Pagination from "@material-ui/lab/Pagination/Pagination";

const ConsultasAcimaValorPage = ({
  consultas,
  handlePagination,
  goToCockpit,
}) => {
  return (
    <>
      <div className="card" style={{ minHeight: 100 }}>
        <div className="card-body d-flex flex-column">
          {!!consultas.loading && (
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Loader type={"dots"} color={"brand"} size={80} />
              </div>
            </div>
          )}
          {!consultas.loading && (
            <>
              <div className={"row d-flex align-items-center"}>
                <div className={"col mt-0"}>
                  <div className="card-title-dashboard color-brand">
                    Consultas Acima de R$ 500K
                  </div>
                </div>
              </div>
              <div className={"row spacer"}>
                <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 title-table-dashboard">
                  <span>CONSULTA</span>
                </div>
                <div className="col-5 col-md-5 my-2 my-lg-0 title-table-dashboard">
                  <span>COMPRADOR</span>
                </div>
                <div className="col-3 col-md-3  my-2 my-lg-0 title-table-dashboard">
                  <span>VALOR FINANCIAMENTO</span>
                </div>
                <div className="col-2 col-md-2  my-2 my-lg-0 title-table-dashboard">
                  <span>SOLICITADA HÁ</span>
                </div>
              </div>
              <div className={"row"}>
                <div className="col-12">
                  {consultas.data.map((d, index) => (
                    <Consulta
                      key={d.id}
                      consulta={d}
                      index={index}
                      goToCockpit={goToCockpit}
                    />
                  ))}
                </div>
              </div>
              {consultas.total > 5 && (
                <>
                  <div className="row spacer">
                    <div className="col-12">
                      <Pagination
                        count={Math.ceil(consultas.total / 5)}
                        page={consultas.page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePagination}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConsultasAcimaValorPage;
