import React from "react";
import Loader from "../../../components/loaders";
import { ControlledTextfield, Textfield } from "../../../components/inputs";
import { Button } from "../../../components";
import formatters from "../../../utils/formatters";

const CardObservacao = ({
  loading,
  formProps,
  handleSubmit,
  disabled,
  submitting,
  perfil,
  data,
}) => (
  <>
    <div className="card" style={{ minHeight: 300 }}>
      <div className="card-body d-flex flex-column">
        {!!loading && (
          <div className="flex d-flex justify-content-center align-items-center">
            <Loader type={"dots"} size={80} />
          </div>
        )}
        {!loading && (
          <div className="col-12 mt-0">
            <form onSubmit={formProps.handleSubmit(handleSubmit)}>
              <div className="row mb-3">
                <div className="col-12">
                  <ControlledTextfield
                    formProps={formProps}
                    multiline={true}
                    disabled={disabled}
                    rowsMax={8}
                    rows={6}
                    uppercase={false}
                    name={"observacao"}
                    margin={"none"}
                    label={"Nova Observação"}
                    inputProps={{ maxLength: 2000 }}
                  />
                </div>
              </div>

              {!disabled && (
                <div className={"row justify-content-center mb-3"}>
                  <div className="col-6 mt-3">
                    <Button
                      loading={submitting}
                      label={"ATUALIZAR"}
                      type={"submit"}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
    {!!data && data?.length > 0 && (
      <div className="card spacer">
        <div className="card-body">
          <span className="card-title-checklist">Observações</span>
          {data
            .sort((a, b) => {
              if (a.dh_observacao !== null && b.dh_observacao !== null) {
                return new Date(a.dh_observacao) - new Date(b.dh_observacao);
              } else {
                if (a.dh_observacao !== null) {
                  return 1;
                }
                if (b.dh_observacao !== null) {
                  return -1;
                }
                return 0;
              }
            })
            .reverse()
            .map((observacao, index) => {
              return (
                <div className="row mb-0 mt-0" key={index}>
                  <div className="col-12 mt-0">
                    <Textfield
                      label={`Data: ${
                        observacao?.dh_observacao
                          ? formatters.dates.withHours(
                              new Date(observacao.dh_observacao)
                            )
                          : `Sem Data`
                      } Usuário: ${
                        observacao?.Usuario?.nome
                          ? observacao.Usuario.nome
                          : `CARGA`
                      }`}
                      value={observacao.ds_observacao}
                      margin="none"
                      uppercase={false}
                      multiline={true}
                      rowsMax={8}
                      inputProps={{
                        style: {
                          padding: "5px 12px",
                        },
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    )}
  </>
);

export default CardObservacao;
