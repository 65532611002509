import { Button } from "components";
import {
  ControlledSelect,
  ControlledTextfield,
  DateTextField,
} from "../../../components/inputs";
import React from "react";
import { MenuItem } from "../../../components";

const ExportConsultasForm = ({
  formProps,
  handleSubmit,
  submitting,
  resultado,
  handleCleanFile,
}) => {
  const situacao = [
    { key: "A", desc: "Em andamento" },
    { key: "C", desc: "Cancelada" },
    { key: "E", desc: "Contrato Emitido" },
    { key: "P", desc: "Paralisada" },
  ];

  const download = (fileBase64, momento_geracao) => {
    if (fileBase64 != null) {
      const outFile = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileBase64}`;
      const element = document.createElement("a");
      element.href = outFile;
      element.download = `exportacao_consultas_${momento_geracao}.xlsx`;
      document.body.appendChild(element);
      element.click();
    }
  };
  if (resultado?.file) {
    download(resultado.file, resultado.momento_geracao);
    handleCleanFile();
  }
  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleSubmit)}
    >
      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6 mt-3">
          <ControlledTextfield
            formProps={formProps}
            label={"Data Início"}
            name={"dataInicio"}
            margin={"none"}
            InputProps={{
              inputComponent: DateTextField,
            }}
          />
        </div>
        <div className="col-xs-12 col-md-6 mt-3">
          <ControlledTextfield
            formProps={formProps}
            label={"Data Fim"}
            name={"dataFim"}
            margin={"none"}
            InputProps={{
              inputComponent: DateTextField,
            }}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 mt-0">
          <ControlledSelect
            name={"status"}
            formProps={formProps}
            multiple={true}
            label={"Status"}
            margin={"none"}
            //onChange={(value) => console.log(value)}
          >
            {situacao.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item.key}
                value={item.key}
              >
                {item.desc}
              </MenuItem>
            ))}
          </ControlledSelect>
        </div>
      </div>
      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6 mt-3">
          <Button loading={submitting} label={"DOWNLOAD"} type={"submit"} />
        </div>
      </div>
    </form>
  );
};

export default ExportConsultasForm;
