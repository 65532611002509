import React, { useState, useCallback } from "react";
import Loader from "../../../../components/loaders";
import Documento from "./Documento";
import Filtros from "./Filtros";
import { Add } from "@material-ui/icons";
import AdicionarDocumentoDialog from "./AdicionarDocumentoDialog.connected";
import ConfirmarAnaliseDialog from "./ConfirmarAnaliseDialog.connected";
import VisualizarMotivoDialog from "./VisualizarMotivoDialog.connected";
import DetalhesDocumentoDialog from "./DetalhesDocumentoDialog.connected";
import IndexacaoDocumentosDialog from "./IndexacaoDocumentosDialog.connected";

const ListaDocumentos = ({
  loading,
  filtro,
  proposta,
  checklist,
  handleFilterClick,
  handleOpenImagens,
  uploading,
  handleUpload,
  perfil,
  produto,
  idPerfil,
}) => {
  const [dadosAdicionarDocumento, setDadosAdicionarDocumento] = useState({
    open: false,
  });
  const [dadosConfirmarAnalise, setDadosConfirmarAnalise] = useState({
    open: false,
  });
  const [dadosVisualizarComentarios, setDadosVisualizarComentarios] = useState({
    open: false,
  });
  const [dadosDetalheDocumento, setDadosDetalheDocumento] = useState({
    open: false,
  });
  const [dadosIndexacaoDocumentos, setDadosIndexacaoDocumentos] = useState({
    open: false,
  });

  const handleCloseConfirmarAnalise = useCallback(() => {
    setDadosConfirmarAnalise({
      open: false,
    });
  }, []);
  const handleOpenConfirmarAnalise = useCallback(
    (values) => {
      setDadosConfirmarAnalise({
        open: true,
        proposta,
        ...values,
      });
    },
    [proposta]
  );

  const handleCloseVisualizarComentarios = useCallback(() => {
    setDadosVisualizarComentarios({
      open: false,
    });
  }, []);
  const handleOpenVisualizarComentarios = useCallback(
    (values, isSubstituto) => {
      setDadosVisualizarComentarios({
        open: true,
        ...values,
        isSubstituto,
      });
    },
    []
  );

  const handleCloseAdicionarDocumento = useCallback(() => {
    setDadosAdicionarDocumento({
      open: false,
    });
  }, []);
  const handleOpenAdicionarDocumento = useCallback(
    (id, tipo) => {
      setDadosAdicionarDocumento({
        open: true,
        id,
        tipo,
        proposta,
      });
    },
    [proposta]
  );

  const handleCloseDetalhesDocumento = useCallback(() => {
    setDadosDetalheDocumento({
      open: false,
    });
  }, []);
  const handleOpenDetalhesDocumento = useCallback((values) => {
    setDadosDetalheDocumento({
      open: true,
      ...values,
    });
  }, []);

  const handleCloseIndexacaoDocumentos = useCallback((values) => {
    setDadosIndexacaoDocumentos({
      open: false,
    });
  }, []);

  // eslint-disable-next-line
  const handleOpenIndexacaoDocumentos = useCallback((values) => {
    setDadosIndexacaoDocumentos({
      open: true,
      ...values,
    });
  });

  if (!!loading) {
    return (
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loader type={"dots"} color={"brand"} size={50} />
        </div>
      </div>
    );
  }
  return (
    <>
      <Filtros filtro={filtro} handleFilterClick={handleFilterClick} />
      {checklist.length === 0 && (
        <div className="flex d-flex flex-column justify-content-center align-items-center">
          <span className="d-block colored-card-text mt-4 color-secondary text-center">
            Nenhum documento encontrado com o filtro selecionado.
            <br />
            Selecione outro filtro.
          </span>
        </div>
      )}
      {checklist.map((p) => (
        <React.Fragment key={p.id + p.tipo_participante}>
          {p.documentos.length > 0 && (
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ minHeight: 100 }}>
                  <div className="card-body d-flex flex-column">
                    <div className={"row d-flex align-items-center"}>
                      <div className={"col mt-0"}>
                        <div className="card-title-checklist">
                          {!!p.nome ? p.nome : "IMÓVEL"}
                        </div>
                        <div className="card-subtitle-checklist">
                          {p["tipo_participante"] === "C" && produto === "1"
                            ? "Convênio"
                            : p["tipo_participante"] === "C" && produto === "2"
                            ? "Corban"
                            : p["tipo_participante"] === "V"
                            ? "Vendedor"
                            : p["tipo_participante"] === "S"
                            ? "Sócio"
                            : p["tipo_participante"] === "R"
                            ? "Representante Legal"
                            : "Imóvel"}
                        </div>
                      </div>
                      {["BPO", "AGENCIA", "PARCEIRO", "BANCO"].indexOf(perfil) >
                        -1 && (
                        <div className="d-none d-md-block">
                          <div
                            className="botao-adicionar mr-3"
                            onClick={() =>
                              handleOpenAdicionarDocumento(
                                p.id,
                                p.tipo_participante
                              )
                            }
                          >
                            <Add className="botao-adicionar-icon mr-1" />
                            <span className="botao-adicionar-text">
                              ADICIONAR DOCUMENTO
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={"row"}>
                      <div className="col-12">
                        {p.documentos.map((d, index) => (
                          <Documento
                            key={d.id}
                            doc={d}
                            index={index}
                            handleOpenImagens={handleOpenImagens}
                            uploading={uploading.indexOf(d.id) > -1}
                            onChange={handleUpload}
                            perfil={perfil}
                            idPerfil={idPerfil}
                            handleOpenDetalhesDocumento={
                              handleOpenDetalhesDocumento
                            }
                            handleVisualizarMotivo={
                              handleOpenVisualizarComentarios
                            }
                            handleAtuarAnaliseDocumento={
                              handleOpenConfirmarAnalise
                            }
                            handleOpenIndexacaoDocumentos={
                              handleOpenIndexacaoDocumentos
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {p.documentos_conjuge?.length > 0 && (
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ minHeight: 100 }}>
                  <div className="card-body d-flex flex-column">
                    <div className={"row d-flex align-items-center"}>
                      <div className={"col mt-0"}>
                        <div className="card-title-checklist">
                          {p.nomeConjuge}
                        </div>
                        <div className="card-subtitle-checklist">
                          {"Cônjuge"}
                        </div>
                      </div>
                      {["BPO", "AGENCIA", "PARCEIRO"].indexOf(perfil) > -1 && (
                        <div className="d-none d-md-block">
                          <div
                            className="botao-adicionar mr-3"
                            onClick={() =>
                              handleOpenAdicionarDocumento(
                                p.id,
                                p.tipo_participante === "C" ? "CC" : "CV"
                              )
                            }
                          >
                            <Add className="botao-adicionar-icon mr-1" />
                            <span className="botao-adicionar-text">
                              ADICIONAR DOCUMENTO
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={"row"}>
                      <div className="col-12">
                        {p.documentos_conjuge.map((d, index) => (
                          <Documento
                            key={d.id}
                            doc={d}
                            index={index}
                            handleOpenImagens={handleOpenImagens}
                            uploading={uploading.indexOf(d.id) > -1}
                            onChange={handleUpload}
                            perfil={perfil}
                            handleOpenDetalhesDocumento={
                              handleOpenDetalhesDocumento
                            }
                            handleVisualizarMotivo={
                              handleOpenVisualizarComentarios
                            }
                            handleAtuarAnaliseDocumento={
                              handleOpenConfirmarAnalise
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
      <AdicionarDocumentoDialog
        dados={dadosAdicionarDocumento}
        handleClose={handleCloseAdicionarDocumento}
      />
      <ConfirmarAnaliseDialog
        dados={dadosConfirmarAnalise}
        handleClose={handleCloseConfirmarAnalise}
      />
      <VisualizarMotivoDialog
        dados={dadosVisualizarComentarios}
        perfil={perfil}
        handleClose={handleCloseVisualizarComentarios}
      />
      <DetalhesDocumentoDialog
        dados={dadosDetalheDocumento}
        handleClose={handleCloseDetalhesDocumento}
      />
      <IndexacaoDocumentosDialog
        dados={dadosIndexacaoDocumentos}
        handleClose={handleCloseIndexacaoDocumentos}
        handleUpload={handleUpload}
        uploading={uploading}
      />
    </>
  );
};

export default ListaDocumentos;
