import { combineReducers } from "redux";
import { reducer as location } from "../routes";
import { default as autenticacao } from "./autenticacao.reducer";
import { default as propostas } from "./propostas.reducer";
import { default as usuario } from "./usuario.reducer";
import { default as administracao } from "./administracao";
import { default as artefatos } from "./artefatos.reducer";
import { default as imovel } from "./imovel.reducer";
import { default as dominios } from "./dominios.reducer";
import { default as dashboard } from "./dashboard.reducer";
import { default as emails } from "./emails/emails.reducer";

export default combineReducers({
  location,
  autenticacao,
  propostas,
  artefatos,
  imovel,
  usuario,
  administracao,
  dominios,
  dashboard,
  emails,
});
