import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_USUARIOS: "@@administracao/LISTAR_USUARIOS",
  LISTAR_PERFIS: "@@administracao/LISTAR_PERFIS",
  ATUALIZAR_USUARIO: "@@administracao/ATUALIZAR_USUARIO",
  GET_ORGANIZACAO: "@@administracao/GET_ORGANIZACAO",
  NOVO_USUARIO: "@@administracao/NOVO_USUARIO",
  EDITAR_USUARIO: "@@administracao/EDITAR_USUARIO",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_USUARIO: "@@administracao/INCLUIR_USUARIO",
  SEARCH_USUARIO: "@@administracao/SEARCH_USUARIO",
  REENVIAR_EMAIL: "@@administracao/REENVIAR_EMAIL",
};

export const actions = {
  listarUsuarios: createAsyncAction(types.LISTAR_USUARIOS),
  listarPerfis: createAsyncAction(types.LISTAR_PERFIS),
  atualizarUsuario: createAsyncAction(types.ATUALIZAR_USUARIO),
  getOrganizacao: createAsyncAction(types.GET_ORGANIZACAO),
  novoUsuario: () => createSyncAction(types.NOVO_USUARIO),
  editarUsuario: (values) => createSyncAction(types.EDITAR_USUARIO, values),
  voltarListaUsuario: () => createSyncAction(types.VOLTAR_LISTA),
  incluirUsuario: createAsyncAction(types.INCLUIR_USUARIO),
  buscarUsuario: createAsyncAction(types.SEARCH_USUARIO),
  reenviarEmail: createAsyncAction(types.REENVIAR_EMAIL),
};
