import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const DatePicker = ({
  InputProps,
  variant = "filled",
  uppercase = true,
  margin,
  error,
  InputLabelProps,
  FormHelperTextProps,
  search = false,
  ...other
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
    <KeyboardDatePicker
      InputLabelProps={{
        classes: {
          root: "label-input",
        },
        shrink: true,
        ...InputLabelProps,
      }}
      error={error}
      variant={variant}
      InputProps={{
        classes: {
          root: "base-textfield",
          filled: "textfield-regular",
          input: uppercase ? "text-uppercase" : "",
          error: "textfield-undeline-error",
          underline: "textfield-undeline",
          disabled: "textfield-datepicker-disabled",
        },
        ...InputProps,
      }}
      FormHelperTextProps={{
        classes: {
          error: "label-error",
        },
        ...FormHelperTextProps,
      }}
      fullWidth
      margin={margin}
      {...other}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;
