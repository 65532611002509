import React from "react";
import Textfield from "./Textfield";
import { Controller } from "react-hook-form";
import { getValueFromObject } from "../../utils/basic";

const ControlledTextfield = ({
  formProps,
  name,
  margin,
  fake = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;
  const isError = getValueFromObject(errors, name) !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name) ?? ""}
      margin={margin}
      render={({ field: { onChange, onBlur, value } }) => {
        if (!!fake) {
          return null;
        }
        return (
          <Textfield
            {...otherProps}
            value={value}
            margin={margin}
            error={isError}
            helperText={getValueFromObject(errors, name)?.message}
            onChange={(v) => {
              onChange(v);
              !!otherProps.onChange && otherProps.onChange(v);
            }}
            onBlur={() => {
              onBlur();
              !!otherProps.onBlur && otherProps.onBlur(value);
            }}
          />
        );
      }}
    />
  );
};

export default ControlledTextfield;
