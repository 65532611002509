import { ControlledAutocomplete } from "../../../../components/inputs";
import React from "react";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

export default function DestinatariosTemplate(props) {
  const autocompleteProps = {
    ...props.autocompleteProps,
    clearOnBlur: true,
    filterOptions: (options, params) => {
      const filtered = filter(options, params);

      if (params.inputValue !== "") {
        filtered.push({
          [props.idField]: params.inputValue,
          manual: true,
        });
      }

      return filtered;
    },
  };

  return (
    <ControlledAutocomplete
      {...props}
      uppercase={false}
      domain={[]}
      getOptionLabel={(option) => {
        if (!!option.manual) {
          return option[props.idField];
        }
        if (!!option[props.labelField]) {
          return option[props.labelField];
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      autocompleteProps={autocompleteProps}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    />
  );
}
