import React, { useCallback } from "react";
import {
  ControlledTextfield,
  IntegerTextField,
  ControlledSwitch,
} from "../../../components/inputs";
import { Button } from "../../../components";

const FormEtapa = ({
  props,
  disabled,
  handleSubmit,
  etapa,
  voltarParaLista,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, etapa.id);
    },
    [handleSubmit, etapa]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Etapa</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-2">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Código"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"nome"}
                formProps={props}
                uppercase={false}
                disabled={disabled}
                label={"Etapa"}
                margin={"none"}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <div className="col-12 col-md-2">
              <ControlledTextfield
                name={"ordem"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Ordem"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-2">
              <ControlledSwitch
                name={"apresentaTimeline"}
                formProps={props}
                label={"Timeline?"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={etapa.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={etapa.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormEtapa;
