import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { connectRoutes, NOT_FOUND } from "redux-first-router";
import restoreScroll from "redux-first-router-restore-scroll";
import { getCurrentRoute } from "./selectors/rotas.selectors";
import {
  getPerfil,
  isUsuarioLogado,
  getUsuario,
} from "./selectors/usuario.selectors";
import {
  types as routes,
  actions as routeActions,
} from "./reducers/rotas.actions";
import * as pages from "./business/pages";
import { toast } from "react-toastify";

const routePages = {
  [routes.LOGIN]: {
    component: pages.LoginPage,
  },
  [routes.POLITICA_PRIVACIDADE]: {
    component: pages.PrivacyPolicyPage,
  },
  [routes.ESQUECI_SENHA]: {
    component: pages.EsqueciSenhaPage,
  },
  [routes.TROCAR_SENHA]: {
    component: pages.TrocarSenhaPage,
  },
  [routes.PROPOSTAS]: {
    component: pages.PropostasPage,
  },
  [routes.CARGA_CONSULTAS]: {
    component: pages.Administracao.CargaConsultasPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.PROPOSTA]: {
    component: pages.PropostaPage,
  },
  [routes.ARTEFATOS_DOCUMENTO]: {
    component: pages.ArtefatosDocumentoPage,
  },
  [routes.PARTICIPANTE]: {
    component: pages.FormParticipante,
  },
  [routes.IMOVEL]: {
    component: pages.ImovelPage,
  },
  [routes.NOVO_PARTICIPANTE]: {
    component: pages.FormParticipante,
    restricted: {
      perfil: ["BANCO"],
      produto: [1],
    },
  },
  [routes.INTERVENIENTE_QUITANTE]: {
    component: pages.FormInterveniente,
  },
  [routes.ADMINISTRACAO_USUARIOS]: {
    component: pages.Administracao.UsuariosPage,
    restricted: {
      perfil: ["ADMINISTRADOR", "BPO", "BANCO"],
    },
  },
  [routes.ADMINISTRACAO_DOCUMENTOS]: {
    component: pages.Administracao.DocumentosPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_ATIVIDADES]: {
    component: pages.Administracao.AtividadesPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_MCHECKLIST]: {
    component: pages.Administracao.MatrizPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_ETAPAS]: {
    component: pages.Administracao.EtapasPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_PRODUTOS]: {
    component: pages.Administracao.ProdutosPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_PERFIS]: {
    component: pages.Administracao.PerfisPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_PRODUTOSCONVENIO]: {
    component: pages.Administracao.ProdutosConvenioPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.NOT_FOUND]: {
    component: pages.NotFoundPage,
  },
  [NOT_FOUND]: {
    component: pages.NotFoundPage,
  },
  [routes.EXPORT_CONSULTAS]: {
    component: pages.Administracao.ExportConsultasPage,
    restricted: {
      perfil: ["ADMINISTRADOR", "BANCO GESTAO"],
    },
  },
  [routes.ADMINISTRACAO_ORGANIZACAO]: {
    component: pages.Administracao.OrganizacaoPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.EXPORT_SLA]: {
    component: pages.Administracao.ExportSlaPage,
    restricted: {
      perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO"],
    },
  },
  [routes.DASHBOARD]: {
    component: pages.DashboardPage,
    restricted: {
      perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
    },
  },
  [routes.EXPORT_SLA]: {
    component: pages.Administracao.ExportSlaPage,
    restricted: {
      perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
    },
  },
  [routes.ADMINISTRACAO_LOCALIDADES]: {
    component: pages.Administracao.LocalidadesPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_MOTIVO_CANCELAMENTO]: {
    component: pages.Administracao.MotivoCancelamentoPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACA0_TIPOS_DE_CONVENIOS]: {
    component: pages.Administracao.TiposDeConveniosPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ADMINISTRACAO_RELATORIO_BI]: {
    component: pages.Administracao.RelatorioBIPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ESCOLHA_PRODUTO]: {
    component: pages.EscolhaProduto,
    restricted: {
      perfil: ["BANCO"],
      produto: [1, 2],
    },
  },
  [routes.NOVA_CORBAN]: {
    component: pages.CorbanPage,
    restricted: {
      perfil: ["BANCO"],
      produto: [2],
    },
  },
  [routes.PARTICIPANTE_CORBAN]: {
    component: pages.CorbanPage,
    restricted: {
      perfil: ["BANCO", "ADMINISTRADOR", "CLIENTE"],
      produto: [2],
    },
  },
  [routes.INCLUIR_SOCIO_CORBAN]: {
    component: pages.CorbanSocioPage,
    restricted: {
      perfil: ["BANCO"],
      produto: [2],
    },
  },
  [routes.NOVO_SOCIO_CORBAN]: {
    component: pages.CorbanSocioPage,
    restricted: {
      perfil: ["BANCO"],
      produto: [2],
    },
  },
  [routes.PARTICIPANTE_SOCIO_CORBAN]: {
    component: pages.CorbanSocioPage,
    restricted: {
      perfil: ["BANCO", "ADMINISTRADOR", "CLIENTE"],
      produto: [2],
    },
  },
  [routes.ADMINISTRACAO_TEMPLATES]: {
    component: pages.Administracao.TemplateEmailPage,
    restricted: {
      perfil: ["ADMINISTRADOR"],
    },
  },
  [routes.ENVIO_EMAIL]: {
    component: pages.EnvioEmailPage,
    restricted: {
      perfil: ["BPO", "BANCO"],
    },
  },
  [routes.ADMINISTRACAO_RELATORIO_AUDITORIA]: {
    component: pages.Administracao.RelatorioAuditoriaPage,
    perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
  },
};

export const routePaths = {
  [routes.LOGIN]: "/",
  [routes.POLITICA_PRIVACIDADE]: "/privacidade",
  [routes.NOT_FOUND]: "/acesso-negado",
  [routes.ESQUECI_SENHA]: "/esqueci-senha",
  [routes.TROCAR_SENHA]: "/trocar-senha/:token",
  [routes.PROPOSTAS]: "/propostas",
  [routes.ARTEFATOS_DOCUMENTO]: "/artefatos-documento/:id",
  [routes.CARGA_CONSULTAS]: "/administracao/carga-consultas",
  [routes.PROPOSTA]: "/proposta/:id",
  [routes.PARTICIPANTE]: "/proposta/:id/participante/:idParticipante",
  [routes.IMOVEL]: "/proposta/:proposta/imovel/:id",
  [routes.NOVO_PARTICIPANTE]: "/novo-convenio",
  [routes.INTERVENIENTE_QUITANTE]: "/proposta/:id/interveniente",
  [routes.ADMINISTRACAO_USUARIOS]: "/administracao/usuarios",
  [routes.ADMINISTRACAO_ORGANIZACAO]: "/administracao/organizacao",
  [routes.EXPORT_CONSULTAS]: "/administracao/exportar-consultas",
  [routes.ADMINISTRACAO_DOCUMENTOS]: "/administracao/documentos",
  [routes.ADMINISTRACAO_ATIVIDADES]: "/administracao/produto/:id/atividades",
  [routes.ADMINISTRACAO_ETAPAS]: "/administracao/produto/:id/etapas",
  [routes.ADMINISTRACAO_MCHECKLIST]: "/administracao/matriz-checklist",
  [routes.ADMINISTRACAO_PRODUTOS]: "/administracao/produtos",
  [routes.ADMINISTRACAO_RELATORIO_BI]: "/administracao/relatorios-bi",
  [routes.ADMINISTRACA0_TIPOS_DE_CONVENIOS]:
    "/administracao/tipos-de-convenios",
  [routes.ADMINISTRACAO_PERFIS]: "/administracao/perfis",
  [routes.EXPORT_SLA]: "/administracao/exportar-sla",
  [routes.DASHBOARD]: "/dashboard",
  [routes.EXPORT_SLA]: "/administracao/exportar-sla",
  [routes.ADMINISTRACAO_LOCALIDADES]: "/administracao/localidades",
  [routes.ADMINISTRACAO_MOTIVO_CANCELAMENTO]:
    "/administracao/motivo-cancelamento",
  [routes.ADMINISTRACAO_PRODUTOSCONVENIO]: "/administracao/produtos-convenio",
  [routes.ESCOLHA_PRODUTO]: "/novo-cadastro/produto",
  [routes.NOVA_CORBAN]: "/nova-corban",
  [routes.NOVO_SOCIO_CORBAN]: "/nova-corban/socio",
  [routes.PARTICIPANTE_CORBAN]: "/proposta/:id/corban/:idParticipante",
  [routes.INCLUIR_SOCIO_CORBAN]:
    "/proposta/:id/novo-participante/:tipoParticipante/:tipoPessoa",
  [routes.PARTICIPANTE_SOCIO_CORBAN]:
    "/proposta/:id/socio-corban/:idParticipante",
  [routes.ADMINISTRACAO_TEMPLATES]: "/administracao/templates",
  [routes.ENVIO_EMAIL]: "/proposta/:proposta/envio-email/:atividade",
  [routes.INCLUIR_SOCIO_CORBAN]:
    "/proposta/:id/novo-participante/:tipoParticipante/:tipoPessoa",
  [routes.PARTICIPANTE_SOCIO_CORBAN]:
    "/proposta/:id/socio-corban/:idParticipante",
  [routes.ADMINISTRACAO_RELATORIO_AUDITORIA]:
    "/administracao/relatorio/auditoria",
};

const { reducer, middleware, enhancer } = connectRoutes(routePaths, {
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  notFoundPath: "/erro",
  onBeforeChange: (dispatch, getState, { action }) => {
    const route = routePages[action.type] ?? routePages[NOT_FOUND];
    if (route.restricted !== undefined) {
      const state = getState();
      if (!isUsuarioLogado(state)) {
        dispatch(routeActions.rejectTo(routes.LOGIN));
      } else {
        const perfil = getPerfil(state);
        const usuario = getUsuario(state);
        if (!canAccessRestrictedRoute(route, perfil, usuario)) {
          toast.error("Acesso Negado");
          dispatch(routeActions.rejectTo(routes.PROPOSTAS));
        }
      }
    }
  },
});

export { reducer, middleware, enhancer };

const Container = () => {
  const routeCode = useSelector(getCurrentRoute);
  const route = routePages[routeCode] ?? routePages[NOT_FOUND];
  const Component = route.component;

  return <Component />;
};

const canAccessRestrictedRoute = (route, perfil, usuario) => {
  if (!perfil) {
    return false;
  }
  if (route.restricted === true) {
    return true;
  }
  if (!!route.produto) {
    let produtoValido = false;
    if (Array.isArray(route.produto)) {
      for (const a of route.produto) {
        for (const b of usuario.produtos) {
          if (b.codigo === a) produtoValido = true;
        }
      }
    } else {
      return false;
    }
    const perfilValido = validarPerfil(route, perfil);

    return perfilValido && produtoValido;
  }

  return validarPerfil(route, perfil);
};

const validarPerfil = (route, perfil) => {
  if (Array.isArray(route.restricted.perfil)) {
    if (
      route.restricted.perfil.length === 0 ||
      route.restricted.perfil.indexOf(perfil) > -1
    ) {
      return true;
    }
  }
  return false;
};

export default Container;
