import React from "react";
import { MenuItem } from "../../../../components";
import { ControlledSelect } from "../../../../components/inputs";
import {
  responsavelAtividade,
  situacaoAtividade,
} from "../../../../utils/parametros";
import Loader from "../../../../components/loaders";

const Filtros = ({ filtro, handleFilterClick, formProps, dominioEtapa }) => {
  return (
    <form>
      <div className="card">
        <div className={"card-body"}>
          <div className={"row"}>
            <div className="col-12 card-subtitle-checklist mt-0">
              <span className="">Filtros:</span>
            </div>
          </div>
          {!!filtro.loading && (
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Loader type={"dots"} color={"brand"} size={50} />
              </div>
            </div>
          )}
          {!filtro.loading && (
            <>
              <div className="row">
                <div className="col-4 mt-0">
                  <ControlledSelect
                    name={"etapa"}
                    formProps={formProps}
                    multiple={true}
                    label={"Etapa"}
                    margin={"none"}
                    onChange={(value) => handleFilterClick(value, "etapa")}
                  >
                    {dominioEtapa.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-4 mt-0">
                  <ControlledSelect
                    name={"responsavel"}
                    formProps={formProps}
                    label={"Responsável"}
                    margin={"none"}
                    multiple={true}
                    onChange={(value) =>
                      handleFilterClick(value, "responsavel")
                    }
                  >
                    {responsavelAtividade.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-4 mt-0">
                  <ControlledSelect
                    name={"situacao"}
                    formProps={formProps}
                    label={"Situação"}
                    margin={"none"}
                    multiple={true}
                    onChange={(value) => handleFilterClick(value, "situacao")}
                  >
                    {situacaoAtividade.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default Filtros;
