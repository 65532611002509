import React, { useCallback, useState } from "react";
import CardEndereco from "../../CardEndereco";
import { Button } from "../../../../../components";
import CardDadosPessoaJuridica from "./CardDadosPessoaJuridica";
import ConfirmarDialog from "../../ConfirmarDialog";
import CardInformacoesAdicionais from "./CardInformacoesAdicionais";

const FormPessoaJuridica = ({
  loading,
  handleSubmit,
  formProps,
  uf,
  consultarCep,
  loadingInclusao,
  disabled,
  handleGoBack,
  isAtualizacao,
  produtos,
  tipoConvenio,
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, formProps.initialValues);
      handleCloseDialog();
    },
    // eslint-disable-next-line
    [handleSubmit, handleCloseDialog]
  );
  if (Object.keys(formProps.formState.errors).length > 0 && open) {
    setOpen(false);
  }

  return (
    <div>
      <form
        className="d-flex flex-column"
        onSubmit={formProps.handleSubmit(handleClickNext)}
      >
        <CardDadosPessoaJuridica
          props={formProps}
          disabled={disabled}
          produtos={produtos}
          tipoConvenio={tipoConvenio}
        />
        <CardEndereco
          props={formProps}
          title={"Endereço"}
          uf={uf}
          consultarCep={consultarCep}
          disabled={disabled}
        />
        <CardInformacoesAdicionais props={formProps} disabled={disabled} />
        {/*<CardDadosContato props={formProps} disabled={disabled} />*/}
        <div className="row spacer justify-content-center">
          <div className="col-12 col-md-4 col-5">
            <Button
              disabled={loading}
              label="VOLTAR"
              loading={loadingInclusao}
              onClick={() => handleGoBack()}
            />
          </div>
          {!disabled && (
            <div className="col-12 col-md-4 col-5">
              <Button
                disabled={loading}
                label={isAtualizacao ? "ATUALIZAR" : "CONFIRMAR"}
                loading={loadingInclusao}
                onClick={() => {
                  setOpen(true);
                  formProps.clearErrors();
                }}
              />
            </div>
          )}
        </div>
        <ConfirmarDialog open={open} handleClose={handleCloseDialog} />
      </form>
    </div>
  );
};

export default FormPessoaJuridica;
