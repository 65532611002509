import React, { useCallback } from "react";
import TrocarAnalistaDialog from "./TrocarAnalistaDialog";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getAnalistas } from "../../../selectors/dominios.selectors";
import validators from "../../../utils/validators";
import { actions as domainActions } from "../../../reducers/dominios.actions";
import { actions } from "../../../reducers/propostas.actions";
import { isChangingAnalyst } from "../../../selectors/propostas.selectors";

const TrocarAnalistaDialogConnected = ({ handleClose, dados }) => {
  const dispatch = useDispatch();
  const { loading, data: dominioAnalistas } = useSelector(getAnalistas);
  const submitting = useSelector(isChangingAnalyst);
  const { analista } = dados;

  const rules = {
    analista: validators.string({ required: false }),
  };
  const initialValues = {
    analista: analista ?? "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleOpen = useCallback(() => {
    dispatch(domainActions.analistas.request());
  }, [dispatch]);

  const handleSubmit = useCallback(
    ({ analista }) => {
      dispatch(
        actions.atualizarAnalista.request({
          consulta: dados.consulta,
          analista,
          handleClose,
        })
      );
    },
    [dispatch, dados, handleClose]
  );
  return (
    <TrocarAnalistaDialog
      handleSubmit={handleSubmit}
      handleOpen={handleOpen}
      open={dados.open}
      loading={loading}
      formProps={formProps}
      dominioAnalistas={dominioAnalistas}
      handleClose={handleClose}
      submitting={submitting}
    />
  );
};

export default TrocarAnalistaDialogConnected;
