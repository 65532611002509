import { actions, types } from "./motivoCancelamento.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  motivo_cancelamento: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarMotivosCancelamento.REQUEST:
    case actions.listarMotivosCancelamento.SUCCESS:
    case actions.listarMotivosCancelamento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarMotivosCancelamento.REQUEST,
        error:
          action.type === actions.listarMotivosCancelamento.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarMotivosCancelamento.SUCCESS
            ? action.payload.response.data.motivos_cancelamento
            : [],
        total:
          action.type === actions.listarMotivosCancelamento.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarMotivosCancelamento.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarMotivoCancelamento.REQUEST:
    case actions.atualizarMotivoCancelamento.SUCCESS:
    case actions.atualizarMotivoCancelamento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarMotivoCancelamento.REQUEST,
        localidade:
          action.type === actions.atualizarMotivoCancelamento.FAILURE
            ? action.payload.original.motivo_cancelamento
            : null,
        list:
          action.type === actions.atualizarMotivoCancelamento.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.motivo_cancelamento.id) {
                  return {
                    ...u,
                    descricao:
                      action.payload.values.motivo_cancelamento.descricao ??
                      u.descricao,
                  };
                } else {
                  return u;
                }
              })
            : state.list,
        motivo_cancelamento: null,
      };
    case types.NOVO_MOTIVO_CANCELAMENTO:
      return {
        ...state,
        motivo_cancelamento: {
          descricao: "",
          codigo: "",
        },
      };
    case types.EDITAR_MOTIVO_CANCELAMENTO:
      return {
        ...state,
        motivo_cancelamento: {
          ...action.payload.motivo_cancelamento,
        },
      };
    case types.VOLTAR_LISTA_MOTIVO_CANCELAMENTO:
      return {
        ...state,
        motivo_cancelamento: null,
      };
    case actions.incluirMotivoCancelamento.REQUEST:
    case actions.incluirMotivoCancelamento.SUCCESS:
    case actions.incluirMotivoCancelamento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirMotivoCancelamento.REQUEST,
        motivo_cancelamento:
          action.type === actions.incluirMotivoCancelamento.FAILURE
            ? action.payload.original.motivo_cancelamento
            : null,
        list:
          action.type === actions.incluirMotivoCancelamento.SUCCESS
            ? action.payload.response.data.motivos_cancelamento
            : null,
        total:
          action.type === actions.incluirMotivoCancelamento.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirMotivoCancelamento.REQUEST
            ? action.payload.pagina
            : state.pages,
      };
    case actions.buscarMotivoCancelamento.REQUEST:
    case actions.buscarMotivoCancelamento.SUCCESS:
    case actions.buscarMotivoCancelamento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarMotivoCancelamento.REQUEST,
        error:
          action.type === actions.buscarMotivoCancelamento.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarMotivoCancelamento.SUCCESS
            ? action.payload.response.data.motivos_cancelamento
            : [],
        total:
          action.type === actions.buscarMotivoCancelamento.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarMotivoCancelamento.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarMotivoCancelamento.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirMotivoCancelamento.REQUEST:
    case actions.excluirMotivoCancelamento.SUCCESS:
    case actions.excluirMotivoCancelamento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirMotivoCancelamento.REQUEST,
        motivo_cancelamento: null,
        list:
          action.type === actions.excluirMotivoCancelamento.SUCCESS
            ? state.list.filter((u) => u.id !== action.payload.original.id)
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
