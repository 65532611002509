import CookieBanner from "./CookieBanner";
import { useCallback } from "react";
import { actions as routeActions } from "./reducers/rotas.actions";
import { useDispatch } from "react-redux";

const CookieBannerConnected = () => {
  const dispatch = useDispatch();

  const handlePolicyClick = useCallback(() => {
    dispatch(
      routeActions.openNewWindowWith(
        "https://www.gpnet.com.br/politica-de-privacidade"
      )
    );
  }, [dispatch]);

  return <CookieBanner handlePolicyClick={handlePolicyClick} />;
};

export default CookieBannerConnected;
