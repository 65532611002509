import BasePage from "../BasePage";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../components/loaders";
import Pagination from "@material-ui/lab/Pagination";
import DetalheProposta from "./DetalheProposta.connected";
import { VisibleWithoutProfile, MenuItem } from "../../components";

import { Switch, Select } from "../../components/inputs";

// estilo responsavel pela responsividade e adquacao para mostrar o campo comprador sem quebra muito
import _PropostasPage from "../../styles/_PropostasPage.scss";

const PropostasPage = ({
  propostas,
  loading,
  total,
  handlePagination,
  pagina,
  usuario,
  consultarProposta,
  expanded,
  pesquisa,
  filtroValores,
  handleSubmit,
  selecionarFiltroSimples,
  filtrarPropostas,
  minhasAtividadesChecked,
  produtos,
  valorFiltroProdutos,
}) => {
  return (
    <BasePage isLoggedIn={true}>
      <h2>Bem Vindo, {usuario}</h2>
      <h6 className="mb-3">Aqui você consulta os Cadastros</h6>

      <VisibleWithoutProfile profiles={["CLIENTE"]}>
        <div
          className="card"
          style={{ marginBottom: "10px", minHeight: "75px" }}
        >
          <div className="row" style={{ padding: "10px", marginTop: "0px" }}>
            <div className="col-12 col-md-3 col-lg-3 col-xs-2 align-self-center">
              <Switch
                margin={"none"}
                label="MINHAS ATIVIDADES"
                marginTop={false}
                className="align-middle"
                value={minhasAtividadesChecked}
                style={{ marginTop: 0 }}
                onClick={(e) => {
                  selecionarFiltroSimples("minhas_atividades");
                  filtrarPropostas(
                    {
                      minhas_atividades: e.target.checked,
                      produto: [...valorFiltroProdutos],
                    },
                    ""
                  );
                }}
              />
            </div>
            {produtos.length > 1 && (
              <div className="col-12 col-md-9 col-lg-9 col-xs-10 mt-0">
                <Select
                  margin={"none"}
                  label="PRODUTO"
                  marginTop={false}
                  className="align-middle"
                  multiple={true}
                  value={valorFiltroProdutos}
                  style={{ marginTop: 0 }}
                  onChange={(e) => {
                    selecionarFiltroSimples("produto");
                    filtrarPropostas(
                      {
                        produto: e.target.value,
                        minhas_atividades: minhasAtividadesChecked,
                      },
                      ""
                    );
                  }}
                >
                  {produtos.map((item) => (
                    <MenuItem
                      className="select-submenu-item"
                      value={item.id}
                      key={item.id}
                    >
                      {item.nome}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </div>
        </div>
      </VisibleWithoutProfile>

      {!!loading && (
        <Accordion key="loader01" className="card">
          <AccordionSummary
            classes={{ content: "d-flex justify-content-center" }}
            aria-controls={"propostaLoader"}
            id={"propostaLoader"}
          >
            <Loader type={"dots"} color={"brand"} size={50} />
          </AccordionSummary>
        </Accordion>
      )}
      {!loading &&
        !!propostas &&
        propostas.map((proposta) => {
          const codigoProduto = produtos?.find(
            (item) => item?.id === proposta?.produto
          )?.codigo;

          return (
            <Accordion
              key={proposta.id}
              className="card"
              onChange={consultarProposta}
              expanded={String(expanded) === String(proposta.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"proposta" + proposta.id}
                id={proposta.id}
              >
                <div className={_PropostasPage}>
                  <b className={_PropostasPage}>Código: </b>
                  {proposta.codigo}
                  <span>
                    <b className="none _PropostasPage">&nbsp;-&nbsp;</b>
                    <b>{codigoProduto === "2" ? "Corban" : "Convênio"}:</b>{" "}
                    {proposta.cliente[0]["nm_pessoa"]}
                  </span>
                </div>
                {proposta.sla !== null && (
                  <VisibleWithoutProfile profiles={["CLIENTE"]}>
                    <div className="d-flex justify-content-end flex-grow-1">
                      <div className={`sla-tag ${slaStyle(proposta.sla)}`}>
                        <span className="accordion-sm sla-tag-label">
                          {slaLabel(proposta.sla)}
                        </span>
                      </div>
                    </div>
                  </VisibleWithoutProfile>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <DetalheProposta />
              </AccordionDetails>
            </Accordion>
          );
        })}
      {!loading && (!propostas || propostas?.length === 0) && (
        <div className="card">
          <div
            style={{
              display: "flex",
            }}
            className={"MuiAccordionSummary-content"}
          >
            <div
              className="col-10"
              style={{
                alignSelf: "center",
                justifySelf: "center",
                padding: "10px",
                margin: 0,
              }}
            >
              Não existem propostas disponíveis para consulta
            </div>
          </div>
        </div>
      )}
      {!loading && total > 10 && (
        <>
          <div className="row">
            <div className="col-12">
              <Pagination
                count={Math.ceil(total / 10)}
                page={pagina}
                variant="outlined"
                shape="rounded"
                onChange={(event, pagina) =>
                  handlePagination(event, pagina, pesquisa, filtroValores)
                }
              />
            </div>
          </div>
        </>
      )}
    </BasePage>
  );
};

const slaLabel = (sla) => {
  if (sla > 0) {
    return "Em atraso";
  } else if (sla < 0) {
    return "Em dia";
  } else {
    return "Hoje";
  }
};

const slaStyle = (sla) => {
  if (sla > 0) {
    return "sla-red";
  } else if (sla < 0) {
    return "sla-green";
  } else {
    return "sla-orange";
  }
};

export default PropostasPage;
