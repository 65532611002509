import { useDispatch, useSelector } from "react-redux";
import { getRelatorios } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/relatorioBI.actions";
import RelatorioBIPage from "./RelatorioBIPage";

const RelatorioBIPageConnected = () => {
  const dispatch = useDispatch();
  const relatorios = useSelector(getRelatorios);
  const loading = relatorios?.loading;

  console.log(relatorios);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirRelatorio.request(i));
    },
    [dispatch]
  );

  return (
    <RelatorioBIPage
      relatorios={relatorios.list}
      loading={loading}
      handleDelete={handleDelete}
    />
  );
};

export default RelatorioBIPageConnected;
