import React, { useCallback, useState } from "react";
import CardEndereco from "../../CardEndereco";
import { Button } from "../../../../../components";
import CardDadosPessoaFisica from "./CardDadosPessoaFisica";
import CardDadosContato from "./CardDadosContato";
import ComfirmarDialog from "../../ConfirmarDialog";

const FormPessoaFisica = ({
  loading,
  handleSubmit,
  formProps,
  estadoCivil,
  uf,
  consultarCep,
  loadingInclusao,
  regimeComunhao,
  disabled,
  isPf,
  validarCpfCnpjRepetido,
  isAtualizacao,
  handleGoBack,
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, formProps.initialValues);
      handleCloseDialog();
    },
    // eslint-disable-next-line
    [handleSubmit, handleCloseDialog]
  );
  if (Object.keys(formProps.errors).length > 0 && open) {
    setOpen(false);
  }

  return (
    <div>
      <form
        className="d-flex flex-column"
        onSubmit={formProps.handleSubmit(handleClickNext)}
      >
        <CardDadosPessoaFisica
          props={formProps}
          estadoCivil={estadoCivil}
          regimeComunhao={regimeComunhao}
          disabled={disabled}
          isPf={isPf}
          validarCpfCnpjRepetido={validarCpfCnpjRepetido}
          isAtualizacao={isAtualizacao}
        />
        <CardEndereco
          props={formProps}
          title={"Endereço Residencial"}
          uf={uf}
          consultarCep={consultarCep}
          disabled={disabled}
        />
        <CardDadosContato props={formProps} disabled={disabled} isPf={isPf} />
        <div className="row spacer justify-content-center">
          <div className="col-12 col-md-4 col-lg-5">
            <Button
              disabled={loading}
              label="VOLTAR"
              loading={loadingInclusao}
              onClick={() => handleGoBack()}
            />
          </div>
          {!disabled && (
            <div className="col-12 col-md-4 col-lg-5">
              <Button
                disabled={loading}
                label={isAtualizacao ? "ATUALIZAR" : "CONFIRMAR"}
                loading={loadingInclusao}
                onClick={() => {
                  setOpen(true);
                  formProps.clearErrors();
                }}
              />
            </div>
          )}
        </div>
        <ComfirmarDialog handleClose={handleCloseDialog} open={open} />
      </form>
    </div>
  );
};

export default FormPessoaFisica;
