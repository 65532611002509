import React from "react";
import { Flex } from "../commons";

export default function Card({ className, children, ...otherProps }) {
  const classes = ["card", className];

  return (
    <div className={classes.join(" ")} {...otherProps}>
      <Flex direction={"column"} className={"card-body"}>
        {children}
      </Flex>
    </div>
  );
}
