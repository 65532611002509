import React from "react";
import NumberFormat from "react-number-format";

const CurrencyTextField = ({
  inputRef,
  onChange,
  fixedDecimalScale = true,
  ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      if (values.value === "") {
        onChange(values.value);
      } else {
        onChange(values.floatValue);
      }
    }}
    thousandSeparator={"."}
    decimalSeparator={","}
    decimalScale={2}
    fixedDecimalScale={fixedDecimalScale}
    prefix="R$ "
  />
);

export default CurrencyTextField;
