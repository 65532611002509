import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/documentos.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { actions as dominioActions } from "../../reducers/dominios.actions";

const listarDocumentosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/documentos",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarDocumentoApi = ({ documento }) => {
  return authenticatedRequest({
    url: "/administracao/documento",
    isResourceService: true,
    method: "post",
    body: { documento: documento },
  });
};

const excluirDocumentoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/documento/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarDocumentosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/documento/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarDocumentos = basicFlow({
  actionGenerator: actions.listarDocumentos,
  api: listarDocumentosApi,
});

const atualizarDocumento = basicFlow({
  actionGenerator: actions.atualizarDocumento,
  api: atualizarDocumentoApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirDocumento = basicFlow({
  actionGenerator: actions.incluirDocumento,
  api: atualizarDocumentoApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirDocumento = basicFlow({
  actionGenerator: actions.excluirDocumento,
  api: excluirDocumentoApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarDocumento = basicFlow({
  actionGenerator: actions.buscarDocumento,
  api: buscarDocumentosApi,
});

function* listaDocumentosRouteWatcher() {
  const pagina = 1;

  yield routeWatcher(routes.ADMINISTRACAO_DOCUMENTOS, function* () {
    yield put(dominioActions.perfis.request());
    yield put(
      actions.listarDocumentos.request({
        pagina,
      })
    );
  });
}

export const sagas = [
  listarDocumentos.watcher(),
  listaDocumentosRouteWatcher(),
  atualizarDocumento.watcher(),
  incluirDocumento.watcher(),
  buscarDocumento.watcher(),
  excluirDocumento.watcher(),
];
