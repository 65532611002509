import { useCallback } from "react";
import ExportSlaPage from "./ExportSlaPage";
import { useDispatch, useSelector } from "react-redux";
import { getExportSla } from "../../../selectors/administracao.selectors";
import { getAtividades } from "../../../selectors/dominios.selectors";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import { actions } from "../../../reducers/administracao/exportSla.actions";

const ExportSlaPageConnected = () => {
  const dispatch = useDispatch();
  const exportSla = useSelector(getExportSla);
  const atividades = useSelector(getAtividades);

  const rules = {
    responsavel: validators.array({ required: false }),
    status: validators.array({ required: false }),
    dataInicio: validators.date({ valid: true, required: false }),
    dataFim: validators.date({
      valid: true,
      required: false,
    }),
  };
  const initialValues = {
    dataInicio: "",
    dataFim: "",
    responsavel: [],
    atividade: [],
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      dispatch(actions.processar.request(values));
    },
    [dispatch]
  );
  const handleCleanFile = useCallback(() => {
    dispatch(actions.cleanFile);
  }, [dispatch]);

  return (
    <ExportSlaPage
      formProps={formProps}
      submitting={exportSla.downloading}
      showResults={exportSla.show_results}
      resultado={exportSla.resultado}
      handleSubmit={handleSubmit}
      handleCleanFile={handleCleanFile}
      atividades={atividades}
    />
  );
};

export default ExportSlaPageConnected;
