import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/matrizChecklist.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put, select } from "redux-saga/effects";
import { actions as domainActions } from "../../reducers/dominios.actions";
import { getMatrizChecklist } from "../../selectors/administracao.selectors";
import { toast } from "react-toastify";

const listarMatrizApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/matriz-checklist",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarMatriz = basicFlow({
  actionGenerator: actions.listarMatriz,
  api: listarMatrizApi,
  transform: (values) => {
    for (const propName in values.filter) {
      if (
        values.filter[propName] === null ||
        values.filter[propName] === undefined ||
        values.filter[propName] === ""
      ) {
        delete values.filter[propName];
      }
    }
    return values;
  },
});

const incluirCondicaoChecklistApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/condicao-checklist",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const incluirCondicaoChecklist = basicFlow({
  actionGenerator: actions.incluirCondicaoChecklist,
  api: incluirCondicaoChecklistApi,
  postSuccess: function() {
    toast.success("Inclusão realizada com sucesso");
  },
});

const alterarCondicaoChecklist = basicFlow({
  actionGenerator: actions.atualizarMatriz,
  api: incluirCondicaoChecklistApi,
  postSuccess: function() {
    toast.success("Atualização realizada com sucesso");
  },
});

const excluirCondicaoChecklistApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/condicao-checklist/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const excluirCondicaoChecklist = basicFlow({
  actionGenerator: actions.excluirCondicaoChecklist,
  api: excluirCondicaoChecklistApi,
  postSuccess: function() {
    toast.success("Exclusão realizada com sucesso");
  },
});

function* listaMatrizRouteWatcher() {
  const pagina = 1;
  const matriz = yield select(getMatrizChecklist);
  yield routeWatcher(routes.ADMINISTRACAO_MCHECKLIST, function*() {
    yield put(
      actions.listarMatriz.request({
        pagina,
        "registros-pagina": matriz["registros-pagina"],
      })
    );
    yield put(domainActions.documentos.request());
    yield put(domainActions.produtos.request());
    yield put(domainActions.localidades.request());
    yield put(domainActions.cartorio.request());
  });
}

export const sagas = [
  listarMatriz.watcher(),
  listaMatrizRouteWatcher(),
  incluirCondicaoChecklist.watcher(),
  excluirCondicaoChecklist.watcher(),
  alterarCondicaoChecklist.watcher(),
];
