import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_PRODUTOS: "@@administracao/LISTAR_PRODUTOS",
  ATUALIZAR_PRODUTO: "@@administracao/ATUALIZAR_PRODUTO",
  NOVA_PRODUTO: "@@administracao/NOVA_PRODUTO",
  EDITAR_PRODUTO: "@@administracao/EDITAR_PRODUTO",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_PRODUTO: "@@administracao/INCLUIR_PRODUTO",
  SEARCH_PRODUTO: "@@administracao/SEARCH_PRODUTO",
  EXCLUIR_PRODUTO: "@@administracao/EXCLUIR_PRODUTO",
};

export const actions = {
  listarProdutos: createAsyncAction(types.LISTAR_PRODUTOS),
  atualizarProduto: createAsyncAction(types.ATUALIZAR_PRODUTO),
  novaProduto: () => createSyncAction(types.NOVA_PRODUTO),
  editarProduto: (values) => createSyncAction(types.EDITAR_PRODUTO, values),
  voltarListaProduto: () => createSyncAction(types.VOLTAR_LISTA),
  incluirProduto: createAsyncAction(types.INCLUIR_PRODUTO),
  buscarProduto: createAsyncAction(types.SEARCH_PRODUTO),
  excluirProduto: createAsyncAction(types.EXCLUIR_PRODUTO),
};
