import PropostaPage from "./PropostaPage";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useState, useMemo } from "react";
import {
  getDetalheProposta,
  getMenuSelecionado,
  getPropostaLoading,
  getPropostaError,
  getInterveniente,
  getWorkflow,
} from "../../../selectors/propostas.selectors";
import { actions } from "../../../reducers/propostas.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import {
  Assignment,
  People,
  Description,
  DashboardOutlined,
} from "@material-ui/icons";
import { getPerfil, getUsuario } from "../../../selectors/usuario.selectors";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";

const PropostaPageConnected = () => {
  const dispatch = useDispatch();
  const detalheProposta = useSelector(getDetalheProposta);
  const menuSelecionado = useSelector(getMenuSelecionado);
  const error = useSelector(getPropostaError);
  const loading = useSelector(getPropostaLoading);
  const perfil = useSelector(getPerfil);
  const { produtos } = useSelector(getUsuario);
  const interveniente = useSelector(getInterveniente);
  const submitting = detalheProposta?.submittingObservacao;
  const workflow = useSelector(getWorkflow);

  const codigoProduto =
    produtos.find((item) => item.id === detalheProposta?.Produto?.id)?.codigo ??
    "";

  const [alterarSituacao, setAlterarSituacao] = useState({
    open: false,
  });

  const handleCloseAlterarSituacao = useCallback(() => {
    setAlterarSituacao({
      open: false,
    });
  }, []);

  const [showMotivoCancelamento, setShowMotivoCancelamento] = useState(
    alterarSituacao.situacao === "C"
  );

  const handleOpenAlterarSituacao = useCallback(
    (value) => {
      if (value.situacao) {
        setShowMotivoCancelamento(value.situacao === "C");
      }
      setAlterarSituacao({
        situacao: value.situacao,
        consultaID: detalheProposta?.id,
        motivoCancelamento: detalheProposta?.motivoCancelamento,
        open: true,
      });
    },
    // eslint-disable-next-line
    [detalheProposta, dispatch]
  );

  const [alterarPrioridade, setAlterarPrioridade] = useState({
    open: false,
  });

  const handleCloseAlterarPrioridade = useCallback(() => {
    setAlterarPrioridade({
      open: false,
    });
  }, []);
  const handleOpenAlterarPrioridade = useCallback(
    (value) => {
      setAlterarPrioridade({
        prioridade: value.prioridade,
        consultaID: detalheProposta?.id,
        open: true,
      });
    },
    [detalheProposta]
  );
  const rules = useMemo(() => {
    return {
      observacao: validators.string({ required: true }),
    };
  }, []);
  const initialValues = useMemo(() => {
    return {
      observacao: "",
    };
  }, []);
  const form = useForm();

  const formProps = useMemo(() => {
    return {
      ...form,
      rules,
      initialValues,
    };
  }, [form, rules, initialValues]);

  const handleLimparCampoObservacao = useCallback(() => {
    formProps.setValue("observacao", "");
  }, [formProps]);

  const disabled = perfil !== "BPO" && perfil !== "BANCO";

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.atualizarObservacao.request({
          idProposta: detalheProposta?.id,
          callback: handleLimparCampoObservacao,
          ...values,
        })
      );
    },
    [dispatch, detalheProposta, handleLimparCampoObservacao]
  );

  const itens = [
    {
      key: 0,
      label: codigoProduto === "2" ? "Corban" : "Convênio",
      first: true,
      icon: <Assignment className={"d-md-none"} />,
    },
    {
      key: 1,
      label: "Cadastro",
      icon: <People className={"d-md-none"} />,
    },
    {
      key: 3,
      label: "DOCUMENTOS",
      icon: <Description className={"d-md-none"} />,
    },
    {
      key: 4,
      label: "ATIVIDADES",
      icon: <DashboardOutlined className={"d-md-none"} />,
      perfis: [
        "BPO",
        "ADMINISTRADOR",
        "BANCO GESTAO",
        "AGENCIA",
        "PARCEIRO",
        "BANCO",
      ],
    },
    {
      key: 5,
      label: "HISTÓRICO",
      icon: <DashboardOutlined className={"d-md-none"} />,
      perfis: ["BPO", "ADMINISTRADOR", "BANCO"],
    },
  ];

  const selecionarOpcaoMenu = useCallback(
    (e, value, proposta) => {
      dispatch(actions.selecionarOpcaoMenu(value));
      if (value === 3) {
        dispatch(actions.consultarChecklist.request({ consultaId: proposta }));
      } else if (value === 5) {
        dispatch(actions.historicoProposta.request(proposta));
        dispatch(actions.historicoEmails.request(proposta));
      }
    },
    [dispatch]
  );

  const abrirDetalhePessoa = useCallback(
    (id, idParticipante, tipoParticipante) => {
      if (Number.parseInt(detalheProposta.Produto.codigo) === 1) {
        if (!!idParticipante) {
          dispatch(
            routeActions.redirectTo(routes.PARTICIPANTE, { id, idParticipante })
          );
        } else {
          dispatch(
            routeActions.redirectTo(routes.INTERVENIENTE_QUITANTE, { id })
          );
        }
      } else {
        if (tipoParticipante !== "C") {
          dispatch(
            routeActions.redirectTo(routes.PARTICIPANTE_SOCIO_CORBAN, {
              id,
              idParticipante,
            })
          );
        } else {
          dispatch(
            routeActions.redirectTo(routes.PARTICIPANTE_CORBAN, {
              id,
              idParticipante,
            })
          );
        }
      }
    },
    [dispatch, detalheProposta]
  );

  const excluirParticipante = useCallback(
    (idProposta, idPessoa) => {
      if (!!idPessoa) {
        dispatch(
          actions.excluirParticipante.request({
            idProposta: idProposta,
            idPessoa: idPessoa,
          })
        );
      } else {
        dispatch(
          actions.atualizarIq.request({
            idProposta: idProposta,
            iq: null,
          })
        );
      }
    },
    [dispatch]
  );

  const novoCadastroPf = useCallback(
    (id, participacao, tipoPessoa) => {
      dispatch(
        actions.abrirNovoParticipante({
          id: id,
          participacao: participacao,
          tipoPessoa: tipoPessoa,
        })
      );
      dispatch(actions.incluirCorbanSocio());
      dispatch(
        routeActions.redirectTo(routes.INCLUIR_SOCIO_CORBAN, {
          id,
          tipoParticipante: participacao,
          tipoPessoa,
        })
      );
    },
    [dispatch]
  );

  const handleOpcaoCanceladoSelected = useCallback(
    (value) => {
      setShowMotivoCancelamento(value === "C");
    },
    [setShowMotivoCancelamento]
  );

  return (
    <PropostaPage
      detalheProposta={detalheProposta}
      itens={itens}
      error={error}
      menuSelecionado={menuSelecionado}
      loading={loading}
      selecionarOpcaoMenu={selecionarOpcaoMenu}
      abrirDetalhePessoa={abrirDetalhePessoa}
      novoCadastroPf={novoCadastroPf}
      excluirParticipante={excluirParticipante}
      disabled={disabled}
      outros={interveniente}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      workflow={workflow}
      perfil={perfil}
      alterarSituacao={alterarSituacao}
      handleCloseAlterarSituacao={handleCloseAlterarSituacao}
      handleOpenAlterarSituacao={handleOpenAlterarSituacao}
      alterarPrioridade={alterarPrioridade}
      handleCloseAlterarPrioridade={handleCloseAlterarPrioridade}
      handleOpenAlterarPrioridade={handleOpenAlterarPrioridade}
      showMotivoCancelamento={showMotivoCancelamento}
      handleOpcaoCanceladoSelected={handleOpcaoCanceladoSelected}
      codigoProduto={codigoProduto}
    />
  );
};

export default PropostaPageConnected;
