import { types as authTypes } from "./autenticacao.actions";
import { actions } from "./dominios.actions";

const initialState = {
  documentos: {
    loading: false,
    data: [],
  },
  analistas: {
    loading: false,
    data: [],
  },
  etapas: {
    loading: false,
    data: [],
  },
  atividades: {
    loading: false,
    data: [],
  },
  produtosConvenio: {
    loading: false,
    data: [],
  },
  produtos: {
    loading: false,
    data: [],
  },
  localidades: {
    loading: false,
    data: [],
  },
  cartorios: {
    loading: false,
    data: [],
  },
  motivosCancelamento: {
    loading: false,
    data: [],
  },
  tipo_convenio: {
    loading: false,
    data: [],
  },
  perfis: {
    loading: false,
    data: [],
  },
  atividades_excepcionais: {
    loading: false,
    data: [],
  },
  empresasCorrespondentes: {
    loading: false,
    data: [],
  },
  templates: {
    loading: false,
    data: [],
  },
};

const partial = (state, path, action, actionGenerator) => {
  return {
    ...state,
    [path]: {
      ...state[path],
      loading: action.type === actionGenerator.REQUEST,
      data:
        action.type === actionGenerator.SUCCESS
          ? action.payload.response.data
          : [],
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case actions.documentos.REQUEST:
    case actions.documentos.SUCCESS:
    case actions.documentos.FAILURE:
      return {
        ...state,
        documentos: {
          ...state.documentos,
          loading: action.type === actions.documentos.REQUEST,
          data:
            action.type === actions.documentos.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.analistas.REQUEST:
    case actions.analistas.SUCCESS:
    case actions.analistas.FAILURE:
      return {
        ...state,
        analistas: {
          ...state.analistas,
          loading: action.type === actions.analistas.REQUEST,
          data:
            action.type === actions.analistas.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.etapas.REQUEST:
    case actions.etapas.SUCCESS:
    case actions.etapas.FAILURE:
      return {
        ...state,
        etapas: {
          ...state.etapas,
          loading: action.type === actions.etapas.REQUEST,
          data:
            action.type === actions.etapas.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.atividades.REQUEST:
    case actions.atividades.SUCCESS:
    case actions.atividades.FAILURE:
      return {
        ...state,
        atividades: {
          ...state.atividades,
          loading: action.type === actions.atividades.REQUEST,
          data:
            action.type === actions.atividades.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.produtosConvenio.REQUEST:
    case actions.produtosConvenio.SUCCESS:
    case actions.produtosConvenio.FAILURE:
      return {
        ...state,
        produtosConvenio: {
          ...state.produtosConvenio,
          loading: action.type === actions.produtosConvenio.REQUEST,
          data:
            action.type === actions.produtosConvenio.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.produtos.REQUEST:
    case actions.produtos.SUCCESS:
    case actions.produtos.FAILURE:
      return {
        ...state,
        produtos: {
          ...state.produtos,
          loading: action.type === actions.produtos.REQUEST,
          data:
            action.type === actions.produtos.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.localidades.REQUEST:
    case actions.localidades.SUCCESS:
    case actions.localidades.FAILURE:
      return {
        ...state,
        localidades: {
          ...state.localidades,
          loading: action.type === actions.localidades.REQUEST,
          data:
            action.type === actions.localidades.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.cartorio.REQUEST:
    case actions.cartorio.SUCCESS:
    case actions.cartorio.FAILURE:
      return {
        ...state,
        cartorios: {
          ...state.cartorios,
          loading: action.type === actions.cartorio.REQUEST,
          data:
            action.type === actions.cartorio.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.motivos_cancelamento.REQUEST:
    case actions.motivos_cancelamento.SUCCESS:
    case actions.motivos_cancelamento.FAILURE:
      return {
        ...state,
        motivosCancelamento: {
          ...state.motivosCancelamento,
          loading: action.type === actions.motivos_cancelamento.REQUEST,
          data:
            action.type === actions.motivos_cancelamento.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.tipo_convenio.REQUEST:
    case actions.tipo_convenio.SUCCESS:
    case actions.tipo_convenio.FAILURE:
      return {
        ...state,
        tipo_convenio: {
          ...state.tipo_convenio,
          loading: action.type === actions.tipo_convenio.REQUEST,
          data:
            action.type === actions.tipo_convenio.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.perfis.REQUEST:
    case actions.perfis.SUCCESS:
    case actions.perfis.FAILURE:
      return {
        ...state,
        perfis: {
          ...state.perfis,
          loading: action.type === actions.perfis.REQUEST,
          data:
            action.type === actions.perfis.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.atividadesExcepcionais.REQUEST:
    case actions.atividadesExcepcionais.SUCCESS:
    case actions.atividadesExcepcionais.FAILURE:
      return {
        ...state,
        atividades_excepcionais: {
          data:
            action.type === actions.atividadesExcepcionais.SUCCESS
              ? action.payload.response.data
              : [],
          loading: action.type === actions.atividadesExcepcionais.REQUEST,
        },
      };
    case actions.empresasCorrespondentes.REQUEST:
    case actions.empresasCorrespondentes.SUCCESS:
    case actions.empresasCorrespondentes.FAILURE:
      return {
        ...state,
        empresasCorrespondentes: {
          data:
            action.type === actions.empresasCorrespondentes.SUCCESS
              ? action.payload.response.data
              : [],
          loading: action.type === actions.empresasCorrespondentes.REQUEST,
        },
      };
    case actions.templates.REQUEST:
    case actions.templates.SUCCESS:
    case actions.templates.FAILURE:
      return partial(state, "templates", action, actions.templates);
    default:
      return state;
  }
};

export default reducer;
