import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import formatters from "../../../../utils/formatters";
import { responsavelAtividade } from "../../../../utils/parametros";

const DetalhesAtividadeDialog = ({ handleClose, detalhes, open }) => {
  console.log(detalhes);
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      {!!detalhes.Atividade && (
        <DialogContent>
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
          <div className={"page-title"}>Detalhes da Atividade</div>
          <div className={"mb-3"}>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <span className="label">Nome</span>
                <div>
                  <span className="data-label">{detalhes.Atividade?.nome}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <span className="label">Responsável</span>
                <div>
                  <span className="data-label">
                    {responsavelAtividade
                      .filter(
                        (s) => s.id === detalhes.Atividade?.responsavel
                      )[0]
                      .nome.toUpperCase()}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Forma Criação</span>
                <div>
                  <span className="data-label">
                    {detalhes.condicaoCriacao === "C"
                      ? "AUTOMÁTICA (NÃO INICIAL)"
                      : detalhes.condicaoCriacao === "A"
                        ? "AUTOMÁTICA (INICIAL)"
                        : detalhes.condicaoCriacao === "M"
                          ? "MANUAL"
                          : detalhes.condicaoCriacao === "E"
                            ? "EXCEPCIONAL"
                            : "FLUXO"}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <span className="label">Data/hora Criação</span>
                <div>
                  <span className="data-label">
                    {formatters.dates.withHours(new Date(detalhes.dataCriacao))}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Criada por</span>
                <div>
                  <span className="data-label">
                    {detalhes.UsuarioCriacao?.nome}
                  </span>
                </div>
              </div>
            </div>
            {!!detalhes.dataStatusPendenteExecucao && (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <span className="label">
                    Data/hora{" "}
                    {detalhes.Atividade.AtividadeDecisaos.find(
                      (item) => item.padrao === "P"
                    )?.descricao ?? "Pendente"}
                  </span>
                  <div>
                    <span className="data-label">
                      {formatters.dates.withHours(
                        new Date(detalhes.dataStatusPendenteExecucao)
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <span className="label">Alterado por</span>
                  <div>
                    <span className="data-label">
                      {detalhes.UsuarioPendenteExecucao?.nome}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {!!detalhes.dataStatusAndamento && (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <span className="label">
                    Data/hora{" "}
                    {detalhes.Atividade.AtividadeDecisaos.find(
                      (item) => item.padrao === "E"
                    )?.descricao ?? "Em andamento"}
                  </span>
                  <div>
                    <span className="data-label">
                      {formatters.dates.withHours(
                        new Date(detalhes.dataStatusAndamento)
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <span className="label">Alterado por</span>
                  <div>
                    <span className="data-label">
                      {detalhes.UsuarioAndamento?.nome}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {!!detalhes.dataStatusAguardandoRetorno && (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <span className="label">
                    Data/hora{" "}
                    {detalhes.Atividade.AtividadeDecisaos.find(
                      (item) => item.padrao === "A"
                    )?.descricao ?? "Aguardando Retorno"}
                  </span>
                  <div>
                    <span className="data-label">
                      {formatters.dates.withHours(
                        new Date(detalhes.dataStatusAguardandoRetorno)
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <span className="label">Alterado por</span>
                  <div>
                    <span className="data-label">
                      {detalhes.UsuarioAguardandoRetorno?.nome}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {!!detalhes.dataStatusConclusao && (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <span className="label">Data/hora {detalhes.decisao}</span>
                  <div>
                    <span className="data-label">
                      {formatters.dates.withHours(
                        new Date(detalhes.dataStatusConclusao)
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <span className="label">Alterado por</span>
                  <div>
                    <span className="data-label">
                      {detalhes.UsuarioConclusao?.nome}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {
              !!detalhes.comentario && (
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <span className="label">Comentário</span>
                    <div className="comentario-scroll">
                      <span className="data-label">{detalhes.comentario}</span>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DetalhesAtividadeDialog;
