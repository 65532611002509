import { useDispatch, useSelector } from "react-redux";
import {
  getAtividades,
  getAtividade,
} from "../../../selectors/administracao.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../../reducers/administracao/atividades.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormAtividadePage from "./FormAtividadePage";
import {
  getEtapas,
  getPerfis,
  getDocumentos,
  getAtividades as getAtividadesDomain,
  getTemplates,
} from "../../../selectors/dominios.selectors";
import { useEffect } from "react";

const AtividadesPageConnected = () => {
  const dispatch = useDispatch();
  const atividades = useSelector(getAtividades);
  const atividade = useSelector(getAtividade);
  const { data: etapas } = useSelector(getEtapas);
  const { data: perfis } = useSelector(getPerfis);
  const { data: documentos } = useSelector(getDocumentos);
  const { data: atividadesDomain } = useSelector(getAtividadesDomain);
  const { data: templates } = useSelector(getTemplates);
  const [regras, setRegras] = useState([]);

  useEffect(() => {
    setRegras(atividade.regras ?? []);
    console.log(atividade);
  }, [atividade]);

  const initialValues = {
    templates: [],
    perfis: atividade?.Perfils ?? [],
    ...atividade,
    isProducao: atividade.isProducao === "S",
    isComentarioObrigatorio: atividade.isComentarioObrigatorio === "S",
    regras: {
      regras:
        atividade?.regras?.length ?? [] > 0
          ? atividade?.regras.map((a) => ({
              idRegra: a.id,
            }))
          : [],
    },
    decisao: {
      descricao: "",
      padrao: "",
      atividades: [],
      documento: {
        documento: "",
        tipoParticipante: "",
        tipoPreReq: "",
      },
    },
    regra: {
      atividades: [],
    },
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    "Etapa.id": validators.string({ required: true }),
    condicaoCriacao: validators.string({ required: true }),
    responsavel: validators.string({ required: true }),
    decisao: {
      descricao: validators.string({ required: true }),
      padrao: validators.string({ required: true }),
    },
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleDeleteDecisao = useCallback(
    (i) => {
      dispatch(actions.deleteDecisao.request({ decisao: i }));
    },
    [dispatch]
  );

  const handleDeleteRegra = useCallback(
    (i) => {
      dispatch(actions.deleteRegra.request({ regra: i }));
    },
    [dispatch]
  );

  const handleSubmit = (values, i) => {
    const request = {
      id: i,
      codigo: values.codigo,
      nome: values.nome,
      "id-etapa": values.Etapa.id,
      etapa: etapas.filter((u) => u.id === values.Etapa.id)[0],
      condicaoCriacao: values.condicaoCriacao,
      responsavel: values.responsavel,
      ordem: values.ordem,
      sla: values.sla,
      isProducao: values.isProducao ? "S" : "N",
      isComentarioObrigatorio: values.isComentarioObrigatorio ? "S" : "N",
      orientacao:
        values?.orientacao?.trim()?.length > 0
          ? values.orientacao.trim()
          : null,
      produto: atividades.produto,
      perfis: values.perfis,
      decisoes: values.decisoes,
      regras: [...regras],
      templates: values.templates,
    };
    if (!!i) {
      dispatch(actions.atualizarAtividade.request({ atividade: request }));
    } else {
      dispatch(actions.incluirAtividade.request({ atividade: request }));
    }
  };

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaAtividade());
  }, [dispatch]);

  const tipoCondicaoCriacao = [
    { id: "A", descricao: "AUTOMÁTICA (INICIAL)" },
    { id: "C", descricao: "AUTOMÁTICA (NÃO INICIAL)" },
    { id: "M", descricao: "MANUAL" },
    { id: "W", descricao: "WORKFLOW" },
  ];

  const openAccordion = useCallback(
    (e, expanded) => {
      if (!!expanded) {
        dispatch(actions.openAccordion(e.currentTarget.attributes.id.value));
      } else {
        dispatch(actions.openAccordion(""));
      }
    },
    [dispatch]
  );

  return (
    <FormAtividadePage
      etapa={etapas}
      perfis={perfis}
      atividade={atividade}
      props={formProps}
      handleSubmit={handleSubmit}
      tipoCondicaoCriacao={tipoCondicaoCriacao}
      voltarParaLista={voltarParaLista}
      openAccordion={openAccordion}
      accordion={atividades.accordion}
      documentos={documentos}
      atividades={atividadesDomain}
      handleDeleteDecisao={handleDeleteDecisao}
      templates={templates}
      handleDeleteRegra={handleDeleteRegra}
      setRegras={setRegras}
      regras={regras}
    />
  );
};

export default AtividadesPageConnected;
