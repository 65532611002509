import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/produtos.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { actions as dominiosAction } from "../../reducers/dominios.actions";

const listarProdutosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/produtos",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarProdutoApi = ({ produto }) => {
  return authenticatedRequest({
    url: "/administracao/produto",
    isResourceService: true,
    method: "post",
    body: { produto: produto },
  });
};

const excluirProdutoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/produto/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarProdutosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/produto/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarProdutos = basicFlow({
  actionGenerator: actions.listarProdutos,
  api: listarProdutosApi,
});

const atualizarProduto = basicFlow({
  actionGenerator: actions.atualizarProduto,
  api: atualizarProdutoApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirProduto = basicFlow({
  actionGenerator: actions.incluirProduto,
  api: atualizarProdutoApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirProduto = basicFlow({
  actionGenerator: actions.excluirProduto,
  api: excluirProdutoApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarProduto = basicFlow({
  actionGenerator: actions.buscarProduto,
  api: buscarProdutosApi,
});

function* listaProdutosRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_PRODUTOS, function* () {
    yield put(
      actions.listarProdutos.request({
        pagina,
      })
    );
    yield put(dominiosAction.produtosConvenio.request());
  });
}

export const sagas = [
  listarProdutos.watcher(),
  listaProdutosRouteWatcher(),
  atualizarProduto.watcher(),
  incluirProduto.watcher(),
  buscarProduto.watcher(),
  excluirProduto.watcher(),
];
