import React, { useState } from "react";
import { MenuItem } from "../../../../../components";
import CardDadosConjuge from "./CardDadosConjuge";
import {
  ControlledSelect,
  ControlledSwitch,
  ControlledTextfield,
  CpfCnpjTextField,
  DateTextField,
} from "../../../../../components/inputs";

const CardDadosPessoaFisica = ({
  props,
  estadoCivil,
  regimeComunhao,
  disabled,
  validarCpfCnpjRepetido,
  isAtualizacao,
}) => {
  const [hasCompanheira, setHasCompanheira] = useState(
    estadoCivil.filter((e) => e.id === props.initialValues?.estadoCivil)[0]
      ?.conjuge
  );
  const [showUniaoEstavel, setShowUniaoEstavel] = useState(
    estadoCivil.filter((e) => e.id === props.initialValues?.estadoCivil)[0]
      ?.uniaoEstavel
  );
  const [hasUniaoEstavel, setHasUniaoEstavel] = useState(
    props.initialValues?.uniaoEstavel ?? false
  );

  const [hasRegime, setHasRegime] = useState(
    props.initialValues?.estadoCivil === "CASADO"
  );

  const handleChangeEstadoCivil = (e) => {
    setHasCompanheira(
      e.target.value &&
        estadoCivil.some((i) => i.id === e.target.value && i["conjuge"])
    );
    setShowUniaoEstavel(
      e.target.value &&
        estadoCivil.some((i) => i.id === e.target.value && i["uniaoEstavel"])
    );
    setHasRegime(e.target.value === "CASADO");
  };

  const handleUniaoEstavel = (e) => {
    setHasUniaoEstavel(e.target.checked);
  };

  return (
    <>
      <div className="card spacer">
        <div className="card-body">
          <span className="card-title color-brand">Dados Pessoais</span>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledTextfield
                formProps={props}
                label={"CPF do Participante"}
                type={"cpf"}
                name={"cpf"}
                margin={"none"}
                disabled={disabled || isAtualizacao}
                onBlur={(value) => validarCpfCnpjRepetido(value)}
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-9">
              <ControlledTextfield
                formProps={props}
                label={"Nome do Participante"}
                name={"nome"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledTextfield
                formProps={props}
                label={"Data de Nascimento"}
                name={"dataNascimento"}
                margin={"none"}
                disabled={disabled}
                InputProps={{
                  inputComponent: DateTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-7">
              <ControlledTextfield
                formProps={props}
                label={"Nome da Mãe"}
                name={"nomeMae"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            {props.initialValues.tipoParticipante === "C" && (
              <div className="col-12 col-md-6 col-lg-2">
                <ControlledSwitch
                  name={"colaborador"}
                  formProps={props}
                  label={"É Colaborador?"}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
            )}
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledSelect
                name={"estadoCivil"}
                formProps={props}
                label={"Estado Civil"}
                disabled={disabled}
                margin={"none"}
                onChange={handleChangeEstadoCivil}
              >
                {estadoCivil.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            {hasRegime && (
              <>
                <div className="col-12 col-md-6 col-lg-4">
                  <ControlledSelect
                    name={"regimeCasamento"}
                    formProps={props}
                    label={"Regime de Casamento"}
                    margin={"none"}
                    disabled={disabled}
                  >
                    {regimeComunhao?.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.descricao}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <ControlledTextfield
                    formProps={props}
                    label={"Data do Casamento"}
                    name={"dataCasamento"}
                    margin={"none"}
                    disabled={disabled}
                    InputProps={{
                      inputComponent: DateTextField,
                    }}
                  />
                </div>
              </>
            )}
            {showUniaoEstavel && (
              <div className="col-12 col-md-6 col-lg-3">
                <ControlledSwitch
                  name={"uniaoEstavel"}
                  formProps={props}
                  onChange={handleUniaoEstavel}
                  label={"União estável?"}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
            )}
            {props.initialValues.tipoParticipante === "C" && (
              <div className="col-12 col-md-6 col-lg-2">
                <ControlledSwitch
                  name={"fgts"}
                  formProps={props}
                  label={"Utilizará FGTS?"}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {(hasCompanheira || hasUniaoEstavel) && (
        <CardDadosConjuge props={props} disabled={disabled} />
      )}
    </>
  );
};

export default CardDadosPessoaFisica;
