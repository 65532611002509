import React from "react";
import {
  ControlledTextfield,
  CpfCnpjTextField,
} from "../../../../components/inputs";

const CardDadosPessoaFisica = ({
  props,
  disabled,
  validarCpfCnpjRepetido,
  isAtualizacao,
}) => {


  return (
    <>
      <div className="card spacer">
        <div className="card-body">
          <span className="card-title color-brand">Dados Pessoais</span>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledTextfield
                formProps={props}
                label={"CPF do Participante"}
                type={"cpf"}
                name={"cpf"}
                margin={"none"}
                disabled={disabled || isAtualizacao}
                onBlur={(value) => validarCpfCnpjRepetido(value)}
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-9">
              <ControlledTextfield
                formProps={props}
                label={"Nome do Participante"}
                name={"nome"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDadosPessoaFisica;
