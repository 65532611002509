import { ControlledTextfield } from "../../components/inputs";
import { Button } from "../../components";
import { InputAdornment } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import Reaptcha from "reaptcha";
import React, { useCallback, useState } from "react";

const EsqueciSenhaForm = ({
  internal,
  loading,
  formProps,
  handleSubmit,
  handleClickBack,
}) => {
  const [token, setToken] = useState(null);
  const formSubmitHandler = useCallback(
    (values) => {
      if (!!token) {
        handleSubmit(values);
      }
    },
    [handleSubmit, token]
  );
  const verifyCallback = useCallback((t) => {
    setToken(t ?? null);
  }, []);

  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(formSubmitHandler)}
    >
      <ControlledTextfield
        label="Usuário"
        name={"user"}
        formProps={formProps}
        margin={"none"}
        uppercase={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={"ml-2 mr-3"}>
              <Person className="color-gold" />
            </InputAdornment>
          ),
        }}
      />
      <div className="mini-separator mb-3 mt-3" />
      <div className="row justify-content-center mb-3">
        <Reaptcha
          onVerify={verifyCallback}
          onExpire={verifyCallback}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        />
      </div>
      <div className="row justify-content-center">
        {!internal && (
          <div className="col-xs-12 col-md-6 mb-2">
            <Button
              className="button-alternative"
              label={"VOLTAR"}
              onClick={handleClickBack}
            />
          </div>
        )}
        <div className="col-xs-12 col-md-6 mb-2">
          <Button loading={loading} label={"ENVIAR"} type={"submit"} />
        </div>
      </div>
    </form>
  );
};

export default EsqueciSenhaForm;
