import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  CARREGAR_DOCUMENTOS: "@@dominios/DOCUMENTOS",
  CARREGAR_ETAPAS: "@@dominios/ETAPAS",
  CARREGAR_ATIVIDADES: "@@dominios/ATIVIDADES",
  CARREGAR_ANALISTAS: "@@dominios/ANALISTAS",
  CARREGAR_PRODUTOS_CONVENIO: "@@dominios/PRODUTOS_CONVENIO",
  CARREGAR_LOCALIDADES: "@@dominios/LOCALIDADE",
  CARREGAR_CARTORIOS: "@@dominios/CARTORIO",
  CARREGAR_MOTIVOS_CANCELAMENTO: "@@dominios/CARREGAR_MOTIVOS_CANCELAMENTO",
  CARREGAR_TIPOS_CONVENIOS: "@@dominios/CARREGAR_TIPOS",
  CARREGAR_PERFIS: "@@dominios/CARREGAR_PERFIS",
  CARREGAR_ATIVIDADES_EXCEPCIONAIS:
    "@@dominios/CARREGAR_ATIVIDADES_EXCEPCIONAIS",
  CARREGAR_PRODUTOS: "@@dominios/PRODUTOS",
  CARREGAR_EMPRESAS_CORRESPONDENTES: "@@dominios/EMPRESAS_CORRESPONDENTES",

  CARREGAR_TEMPLATES: "@@dominios/TEMPLATES",
};

export const actions = {
  documentos: createAsyncAction(types.CARREGAR_DOCUMENTOS),
  etapas: createAsyncAction(types.CARREGAR_ETAPAS),
  atividades: createAsyncAction(types.CARREGAR_ATIVIDADES),
  analistas: createAsyncAction(types.CARREGAR_ANALISTAS),
  produtosConvenio: createAsyncAction(types.CARREGAR_PRODUTOS_CONVENIO),
  localidades: createAsyncAction(types.CARREGAR_LOCALIDADES),
  cartorio: createAsyncAction(types.CARREGAR_CARTORIOS),
  motivos_cancelamento: createAsyncAction(types.CARREGAR_MOTIVOS_CANCELAMENTO),
  tipo_convenio: createAsyncAction(types.CARREGAR_TIPOS_CONVENIOS),
  perfis: createAsyncAction(types.CARREGAR_PERFIS),
  atividadesExcepcionais: createAsyncAction(
    types.CARREGAR_ATIVIDADES_EXCEPCIONAIS
  ),
  produtos: createAsyncAction(types.CARREGAR_PRODUTOS),
  empresasCorrespondentes: createAsyncAction(types.CARREGAR_EMPRESAS_CORRESPONDENTES),
  templates: createAsyncAction(types.CARREGAR_TEMPLATES),
};
