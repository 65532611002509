import React, { useCallback } from "react";
import { ControlledSelect } from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";
import { condicoesMatrizChecklist, uf } from "../../../utils/parametros";

const FormCondicao = ({
  props,
  handleSubmit,
  condicao,
  qualificacao,
  tipoPessoa,
  voltarParaLista,
  dominioDocumentos,
  dominioProdutos,
  dominioLocalidades,
  dominioCartorios,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, condicao.id);
    },
    [handleSubmit, condicao]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">
          {condicao.Documento?.nome ?? "Nova Condição"}
        </span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"Documento.id"}
                formProps={props}
                label={"Documento"}
                margin={"none"}
              >
                {dominioDocumentos.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"qualificacao"}
                formProps={props}
                label={"Qualificação"}
                margin={"none"}
              >
                {qualificacao.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"Produto.id"}
                formProps={props}
                label={"Produto"}
                margin={"none"}
              >
                {dominioProdutos.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"participante"}
                formProps={props}
                label={"Tipo Pessoa"}
                margin={"none"}
              >
                {tipoPessoa.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"Localidade.id"}
                formProps={props}
                label={"Localidade"}
                margin={"none"}
              >
                <MenuItem className={"select-submenu-item"} value={""}>
                  <em>(Nenhum)</em>
                </MenuItem>
                {dominioLocalidades.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"condicao"}
                formProps={props}
                label={"Condição"}
                margin={"none"}
              >
                {condicoesMatrizChecklist.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.codigo}
                    value={item.codigo}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"uf"}
                formProps={props}
                label={"UF"}
                margin={"none"}
              >
                <MenuItem className={"select-submenu-item"} value={""}>
                  <em>(Nenhum)</em>
                </MenuItem>
                {uf.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.codigo}
                    value={item.codigo}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"Organizacao.id"}
                formProps={props}
                label={"Cartório"}
                margin={"none"}
              >
                <MenuItem className={"select-submenu-item"} value={""}>
                  <em>(Nenhum)</em>
                </MenuItem>
                {dominioCartorios.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={condicao.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={condicao.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormCondicao;
