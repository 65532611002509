import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";

const Atividade = ({
  atividade,
  index,
  handleEdit,
  handleOpenExclusaoAtividade,
}) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 user-table ">
        <span>{atividade.codigo}</span>
      </div>
      <div className="col-5 col-md-6 my-2 my-lg-0 user-table">
        <span>{atividade.nome}</span>
      </div>
      <div className="col-4 col-md-3 my-2 my-lg-0 user-table">
        <span>{atividade?.Etapa?.nome?.toUpperCase()}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(atividade)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() => handleOpenExclusaoAtividade(atividade)}
        >
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Atividade;
