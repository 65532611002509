import React from "react";
import IndexacaoDocumentosDialog from "./IndexacaoDocumentosDialog";

const IndexacaoDocumentosDialogConnected = ({
  handleClose,
  dados,
  handleUpload,
  uploading,
}) => {
  return (
    <IndexacaoDocumentosDialog
      dados={dados}
      open={dados.open}
      handleClose={handleClose}
      handleUpload={handleUpload}
      uploading={uploading}
    />
  );
};

export default IndexacaoDocumentosDialogConnected;
