import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  BUSCAR_TEMPLATES_ATIVIDADE: "@@emails/BUSCAR_TEMPLATES_ATIVIDADE",
  PROCESSAR_TEMPLATE: "@@emails/PROCESSAR_TEMPLATE",
  PROCESSAR_DESTINATARIO: "@@emails/PROCESSAR_DESTINATARIO",
  ENVIAR_EMAIL: "@@emails/ENVIAR_EMAIL",
  EMAIL_PARAMS: "@@emails/EMAIL_PARAMS",
};

export const actions = {
  buscarTemplatesAtividade: createAsyncAction(types.BUSCAR_TEMPLATES_ATIVIDADE),
  processarTemplate: createAsyncAction(types.PROCESSAR_TEMPLATE),
  processarDestinatario: createAsyncAction(types.PROCESSAR_DESTINATARIO),
  enviarEmail: createAsyncAction(types.ENVIAR_EMAIL),
  emailParams: (values) => createSyncAction(types.EMAIL_PARAMS, values),
};
