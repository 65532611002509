import React from "react";
import NumberFormat from "react-number-format";
import { removeNonDigitsFromString } from "../../utils/basic";

const formatDate = (value) => {
  const stripped = removeNonDigitsFromString(value);

  let finalValue = stripped.slice(0, 2);
  if (stripped.length > 2) {
    finalValue += "/" + stripped.slice(2, 4);
  }
  if (stripped.length > 4) {
    finalValue += "/" + stripped.slice(4, 8);
  }
  return finalValue;
};

const DateTextField = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange(values.formattedValue);
    }}
    format={formatDate}
  />
);

export default DateTextField;
