import React from "react";
import BasePage from "../../../BasePage";
import { CardTitle, PageTitle } from "../../../../components/texts";
import { Column, Row } from "../../../../components/commons";
import { Card } from "../../../../components/cards";
import {
  ControlledTextfield,
  ControlledWysiwygTextField,
} from "../../../../components/inputs";
import TemplatesAtividade from "./TemplatesAtividade";
import DestinatariosTemplate from "./DestinatariosTemplate";
import { Button } from "../../../../components";
import Loader from "../../../../components/loaders";

const EnvioEmailPage = ({
  loading,
  formProps,
  handleSubmit,
  handleChangeTemplate,
  disableSubmitOnEnter,
  handleGoBack,
  submitting,
  title = "Enviar E-mail",
  historico = false,
  hasValue,
  canRespond = false,
  handleRespond = () => {},
}) => {
  if (!!loading) {
    return (
      <BasePage isLoggedIn={true}>
        <PageTitle>{title}</PageTitle>
        <Row className={"spacer"}>
          <Column base={12}>
            <Card>
              <div className="flex d-flex justify-content-center align-items-center">
                <Loader type={"dots"} size={80} />
              </div>
            </Card>
          </Column>
        </Row>
      </BasePage>
    );
  }

  if (historico) {
    return (
      <>
        <div>
          <div className="col-12 p-0">
            <div>
              <div className="card-body d-flex flex-column">
                <div className="row d-flex align-items-center">
                  <div className="mt-0 ml-4">
                    <div className="card-title-checklist">{title}</div>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <Card style={{ border: "none", width: "100%" }}>
                      {emailBody(formProps, hasValue, disableSubmitOnEnter)}
                    </Card>
                  </div>
                  <Row className="spacer">
                    <Column
                      base={12}
                      md={4}
                      className={canRespond ? "offset-md-2" : "offset-md-4"}
                    >
                      <Button label="VOLTAR" onClick={handleGoBack} />
                    </Column>
                    {canRespond && hasValue && (
                      <Column base={12} md={4}>
                        <Button label="RESPONDER" onClick={handleRespond} />
                      </Column>
                    )}
                    {canRespond && !hasValue && (
                      <Column base={12} md={4}>
                        <Button
                          label="CONFIRMAR"
                          loading={submitting}
                          type="submit"
                        />
                      </Column>
                    )}
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <BasePage isLoggedIn={true}>
        <PageTitle>{title}</PageTitle>
        <Row className={"spacer"}>
          <Column base={12}>
            <form onSubmit={handleSubmit}>
              {selecaoTemplate(formProps, handleChangeTemplate)}
              {cardEmail(formProps, hasValue, disableSubmitOnEnter)}
              <Row className="spacer">
                <Column base={12} md={4} className="offset-md-2">
                  <Button label="VOLTAR" onClick={handleGoBack} />
                </Column>
                <Column base={12} md={4}>
                  <Button
                    label="CONFIRMAR"
                    loading={submitting}
                    type={"submit"}
                  />
                </Column>
              </Row>
            </form>
          </Column>
        </Row>
      </BasePage>
    </>
  );
};

const selecaoTemplate = (formProps, handleChangeTemplate) => {
  return (
    <Card>
      <CardTitle className={"color-brand mb-0"}>
        Selecione um Template
      </CardTitle>
      <Row>
        <Column base={6} className={"mt-0"}>
          <TemplatesAtividade
            label={"Template"}
            onChange={handleChangeTemplate}
            formProps={formProps}
            name={"template"}
          />
        </Column>
      </Row>
    </Card>
  );
};

const cardEmail = (formProps, hasValue, disableSubmitOnEnter) => {
  return (
    <Card className={"spacer"}>
      {emailBody(formProps, !hasValue, disableSubmitOnEnter)}
    </Card>
  );
};

const emailBody = (formProps, disabled, disableSubmitOnEnter) => {
  return (
    <>
      <Row>
        <Column base={12} className={"mt-0"}>
          <ControlledTextfield
            label={"Assunto"}
            formProps={formProps}
            name={"assunto"}
            disabled={disabled}
            onKeyDown={disableSubmitOnEnter}
            uppercase={false}
            margin={"none"}
          />
        </Column>
      </Row>
      <Row>
        <Column base={12}>
          <DestinatariosTemplate
            label={"Destinatários"}
            formProps={formProps}
            idField={"value"}
            disabled={disabled}
            onKeyDown={disableSubmitOnEnter}
            labelField={"label"}
            autocompleteProps={{
              multiple: true,
              freeSolo: true,
              disabled: disabled,
            }}
            name={"destinatarios"}
          />
        </Column>
      </Row>
      <Row>
        <Column base={12} className={"spacer"}>
          <ControlledWysiwygTextField
            formProps={formProps}
            margin={"none"}
            name={"conteudo"}
            toolbarHidden={disabled}
            readOnly={disabled}
            label={""}
            disabled={disabled}
          />
        </Column>
      </Row>
    </>
  );
};

export default EnvioEmailPage;
