import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/organizacao.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarOrganizacoesApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/organizacoes",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const getOrganizacoesDominioApi = () => {
  return authenticatedRequest({
    url: "/administracao/dominio_organizacoes",
    isResourceService: true,
    method: "get",
  });
};

const incluirOrganizacaoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/salvar_organizacao",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const removerOrganizacaoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/remover_organizacao",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarOrganizacoes = basicFlow({
  actionGenerator: actions.listarOrganizacoes,
  api: listarOrganizacoesApi,
});

const getOrganizacoesDominio = basicFlow({
  actionGenerator: actions.organizacaoDominios,
  api: getOrganizacoesDominioApi,
});

const incluirOrganizacao = basicFlow({
  actionGenerator: actions.salvarOrganizacao,
  api: incluirOrganizacaoApi,
  postSuccess: function* ({ original }) {
    toast.success("Informações salvas com sucesso.");
    yield put(routeActions.redirectTo(routes.ADMINISTRACAO_ORGANIZACAO));
  },
});

const removerOrganizacao = basicFlow({
  actionGenerator: actions.removerOrganizacao,
  api: removerOrganizacaoApi,
  postSuccess: function* ({ original }) {
    toast.success("Organização removida com sucesso.");
    yield put(routeActions.redirectTo(routes.ADMINISTRACAO_ORGANIZACAO));
  },
});

function* listaOrganizacoesRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_ORGANIZACAO, function* () {
    yield put(
      actions.listarOrganizacoes.request({
        pagina,
      })
    );
    yield put(actions.organizacaoDominios.request());
  });
}

export const sagas = [
  listarOrganizacoes.watcher(),
  listaOrganizacoesRouteWatcher(),
  getOrganizacoesDominio.watcher(),
  incluirOrganizacao.watcher(),
  removerOrganizacao.watcher(),
];
