import React, { useCallback, useState } from "react";
import {
  ControlledAutocomplete,
  ControlledSelect,
  ControlledTextfield,
} from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";
import { situacaoAtividade } from "../../../utils/parametros";
import CardDecisao from "./CardDecisao";
import CardDocumentosPreReq from "./CardDocumentosPreReq";

const FormDecisaoWorkflow = ({
  props,
  disabled,
  documentos,
  atividades,
  decisoes,
  setDecisoes,
  docs,
  setDocs,
  handleDeleteDecisao,
}) => {
  const [novaDecisao, setNovaDecisao] = useState(false);

  const [editarDecisao, setEditDecisao] = useState({ isEdicao: false, id: "" });

  const limparCampos = useCallback(() => {
    props.setValue("decisao.descricao", "");
    props.setValue("decisao.padrao", "");
    props.setValue("decisao.atividades", []);
    props.setValue("decisao.documento.documento", "");
    props.setValue("decisao.documento.tipoParticipante", "");
    props.setValue("decisao.documento.tipoPreReq", "");
  }, [props]);

  const adicionarDecisao = useCallback((value) => {
    limparCampos();
    setDocs([]);
    setNovaDecisao(value);
    setEditDecisao({
      ...editarDecisao,
      isEdicao: false,
      id: "",
    });
    delete props.initialValues.decisao.id;
    // eslint-disable-next-line
  }, []);

  const addDecisao = useCallback(() => {
    let errors = false;

    props.clearErrors();

    if (props.getValues("decisao.descricao") === "") {
      props.setError("decisao.descricao", { message: "Campo Obrigatório" });
      errors = true;
    }
    if (props.getValues("decisao.padrao") === "") {
      props.setError("decisao.padrao", { message: "Campo Obrigatório" });
      errors = true;
    }

    decisoes.map((b, index) => {
      if (
        b.padrao === props.getValues("decisao.padrao") &&
        b.descricao.toUpperCase() ===
          props.getValues("decisao.descricao").toUpperCase() &&
        ((editarDecisao.isEdicao && index !== editarDecisao.id) ||
          !editarDecisao.isEdicao)
      ) {
        props.setError("decisao.descricao", {
          message: "Descrição já utilizada em outra decisão da mesma atividade",
        });
        errors = true;
      }
      return null;
    });

    if (!errors) {
      if (!editarDecisao.isEdicao) {
        setDecisoes((prevDecisoes) => [
          ...prevDecisoes,
          {
            descricao: props.getValues("decisao.descricao"),
            padrao: props.getValues("decisao.padrao"),
            atividades: props.getValues("decisao.atividades"),
            documentos: docs,
          },
        ]);
      } else {
        setDecisoes((prevDecisoes) =>
          prevDecisoes.map((t, index) => {
            if (index === editarDecisao.id) {
              return {
                ...t,
                descricao: props.getValues("decisao.descricao"),
                padrao: props.getValues("decisao.padrao"),
                atividades: props.getValues("decisao.atividades"),
                documentos: docs,
              };
            }
            return t;
          })
        );
      }

      limparCampos();
      setDocs([]);
      delete props.initialValues.decisao.id;
      setEditDecisao({
        ...editarDecisao,
        isEdicao: false,
        id: "",
      });
      setNovaDecisao(false);
    }
    // eslint-disable-next-line
  }, [props, docs]);

  const removeDecisao = useCallback(
    (decisao) => {
      if (decisao.id) {
        handleDeleteDecisao(decisao);
      }
      setDecisoes((prevDecisoes) => [
        ...prevDecisoes.filter((i) => i.descricao !== decisao.descricao),
      ]);
    },
    // eslint-disable-next-line
    [props, handleDeleteDecisao]
  );

  const editDecisao = useCallback(
    (d) => {
      setEditDecisao({
        ...editarDecisao,
        isEdicao: true,
        id: d.idDecisao,
      });
      setNovaDecisao(false);
      setDocs(d.documentos);
      props.initialValues.decisao.atividades = d.atividades;
      props.initialValues.decisao.descricao = d.descricao;
      props.initialValues.decisao.padrao = d.padrao;
      props.initialValues.decisao.id = Number(d.id);
      props.setValue("decisao.descricao", d.descricao);
      props.setValue("decisao.padrao", d.padrao);
      props.setValue("decisao.atividades", d.atividades);
      props.setValue("decisao.documento.documento", "");
      props.setValue("decisao.documento.tipoParticipante", "");
      props.setValue("decisao.documento.tipoPreReq", "");
    },
    // eslint-disable-next-line
    [props]
  );

  return (
    <>
      <div className={"row"}>
        {decisoes.map((d, index) => (
          <div className={"col-6"}>
            <CardDecisao
              decisao={{ ...d, idDecisao: index }}
              handleExclusaoDecisao={removeDecisao}
              key={index}
              editDecisao={editDecisao}
            />
          </div>
        ))}
      </div>
      {(novaDecisao || editarDecisao.isEdicao) && (
        <>
          <div className="row">
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"decisao.descricao"}
                margin={"none"}
                disabled={disabled}
                uppercase={false}
                formProps={props}
                label={"Descrição"}
                InputProps={{
                  maxLength: 30,
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"decisao.padrao"}
                formProps={props}
                label={"Padrão"}
                margin={"none"}
                id={"padrao"}
              >
                <MenuItem className={"select-submenu-item"} value={""}>
                  <em>(Nenhum)</em>
                </MenuItem>
                {situacaoAtividade.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome.toUpperCase()}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-12">
              <ControlledAutocomplete
                name={"decisao.atividades"}
                domain={atividades.filter(
                  (i) => i.id !== props.initialValues.id
                )}
                autocompleteProps={{
                  multiple: true,
                }}
                labelField={"nome"}
                formProps={props}
                label={"Atividades pré-requisito abertura"}
                margin={"none"}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="col-12">
              <CardDocumentosPreReq
                documentos={documentos}
                props={props}
                docs={docs}
                setDocs={setDocs}
              />
            </div>
          </div>
        </>
      )}
      {novaDecisao && (
        <div className="row spacer">
          <div className="offset-md-9 col-md-3 col-12">
            <Button label={"INCLUIR DECISÃO"} onClick={() => addDecisao()} />
          </div>
        </div>
      )}
      {editarDecisao.isEdicao && (
        <div className="row spacer">
          <div className="offset-md-6 col-md-3 col-12">
            <Button
              label="NOVA DECISÃO"
              onClick={() => adicionarDecisao(true)}
            />
          </div>
          <div className="col-md-3 col-12">
            <Button label={"SALVAR DECISÃO"} onClick={() => addDecisao()} />
          </div>
        </div>
      )}
      {!novaDecisao && !editarDecisao.isEdicao && (
        <div className="row spacer">
          <div className="offset-md-9 col-md-3 col-12">
            <Button
              label="NOVA DECISÃO"
              onClick={() => adicionarDecisao(true)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FormDecisaoWorkflow;
