export const estadoCivil = [
  { id: "SOLTEIRO", descricao: "SOLTEIRO", uniaoEstavel: true, conjuge: false },
  { id: "CASADO", descricao: "CASADO", uniaoEstavel: false, conjuge: true },
  {
    id: "DESQUITADO",
    descricao: "DESQUITADO",
    uniaoEstavel: true,
    conjuge: false,
  },
  {
    id: "DIVORCIADO",
    descricao: "DIVORCIADO",
    uniaoEstavel: true,
    conjuge: false,
  },
  { id: "VIUVO", descricao: "VIUVO", uniaoEstavel: true, conjuge: false },
  {
    id: "SEPARADO JUDICIALMENTE",
    descricao: "SEPARADO JUDICIALMENTE",
    uniaoEstavel: true,
    conjuge: false,
  },
  {
    id: "CASADO NO EXTERIOR",
    descricao: "CASADO NO EXTERIOR",
    uniaoEstavel: false,
    conjuge: true,
  },
];

export const regimeComunhao = [
  { id: "COMUNHÃO PARCIAL DE BENS", descricao: "COMUNHÃO PARCIAL DE BENS" },
  { id: "COMUNHÃO UNIVERSAL DE BENS", descricao: "COMUNHÃO UNIVERSAL DE BENS" },
  { id: "SEPARAÇÃO DE BENS", descricao: "SEPARAÇÃO DE BENS" },
  { id: "AQUESTO", descricao: "AQUESTO" },
  {
    id: "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI",
    descricao: "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI",
  },
];

const unidadesFederativa = [
  {
    codigo: "RO",
    nome: "RONDÔNIA",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  { codigo: "AC", nome: "ACRE", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  {
    codigo: "AM",
    nome: "AMAZONAS",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    codigo: "RR",
    nome: "RORAIMA",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  { codigo: "PA", nome: "PARÁ", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  { codigo: "AP", nome: "AMAPÁ", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  {
    codigo: "TO",
    nome: "TOCANTINS",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    codigo: "MA",
    nome: "MARANHÃO",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PI",
    nome: "PIAUÍ",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "CE",
    nome: "CEARÁ",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "RN",
    nome: "RIO GRANDE DO NORTE",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PB",
    nome: "PARAÍBA",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PE",
    nome: "PERNAMBUCO",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "AL",
    nome: "ALAGOAS",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "SE",
    nome: "SERGIPE",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "BA",
    nome: "BAHIA",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "MG",
    nome: "MINAS GERAIS",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "ES",
    nome: "ESPÍRITO SANTO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "RJ",
    nome: "RIO DE JANEIRO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "SP",
    nome: "SÃO PAULO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  { codigo: "PR", nome: "PARANÁ", regiao: { id: 4, sigla: "S", nome: "Sul" } },
  {
    codigo: "SC",
    nome: "SANTA CATARINA",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    codigo: "RS",
    nome: "RIO GRANDE DO SUL",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    codigo: "MS",
    nome: "MATO GROSSO DO SUL",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "MT",
    nome: "MATO GROSSO",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "GO",
    nome: "GOIÁS",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "DF",
    nome: "DISTRITO FEDERAL",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
];

export const uf = unidadesFederativa.sort((a, b) => {
  if (a.nome > b.nome) return 1;
  else return -1;
});

export const responsavelAtividade = [
  {
    id: "M",
    nome: "Comercial",
  },
  {
    id: "C",
    nome: "Convênios",
  },
  {
    id: "O",
    nome: "Jurídico Operacional",
  },
  {
    id: "D",
    nome: "Jurídico Proteção de Dados",
  },
  { id: "B", nome: "Backoffice" },
  { id: "P", nome: "Produtos" },
  { id: "E", nome: "Credcesta" },
  { id: "S", nome: "Suporte" },
  { id: "J", nome: "Projetos TI" },
  { id: "R", nome: "Crédito PJ" },
  { id: "T", nome: "TI" },
  { id: "I", nome: "Cadastro Institucional" },
  { id: "L", nome: "Compliance" },
];

export const situacaoAtividade = [
  {
    id: "A",
    nome: "Aguardando Retorno",
  },
  {
    id: "C",
    nome: "Concluída com sucesso",
  },
  {
    id: "S",
    nome: "Concluída sem sucesso",
  },
  {
    id: "E",
    nome: "Em Andamento",
  },
  { id: "P", nome: "Pendente" },
];

export const origemDocumento = [{ id: "P", descricao: "PARTICIPANTE" }];

export const qualificacao = [
  {
    id: "CO",
    nome: "CONVÊNIO/CORBAN",
  },
  {
    id: "SC",
    nome: "SÓCIO",
  },
  {
    id: "RL",
    nome: "REPRESENTANTE LEGAL",
  },
];

export const tipoPessoa = [
  {
    id: "F",
    nome: "FÍSICA",
  },
  {
    id: "J",
    nome: "JURÍDICA",
  },
  {
    id: "N",
    nome: "AMBAS",
  },
];

export const condicoesMatrizChecklist = [
  {
    codigo: "PAD1",
    descricao: "Padrão I",
  },
];

export const divisao_administrativa = [
  {
    codigo: "E",
    descricao: "ESTADO",
  },
  {
    codigo: "M",
    descricao: "MUNICÍPIO",
  },
  {
    codigo: "F",
    descricao: "FEDERAL",
  },
  {
    codigo: "O",
    descricao: "FORÇAS ARMADAS",
  },
  {
    codigo: "U",
    descricao: "UNIÃO",
  },
  {
    codigo: "G",
    descricao: "GOVERNO",
  },
  {
    codigo: "A",
    descricao: "AUTARQUIA",
  },
  {
    codigo: "P",
    descricao: "PREVIDÊNCIA",
  },
];

export const area_responsavel_kit = [
  {
    codigo: "C",
    descricao: "CONVÊNIOS",
  },
  {
    codigo: "M",
    descricao: "COMERCIAL",
  },
];

export const meio_envio = [
  {
    codigo: "E",
    descricao: "E-MAIL",
  },
  {
    codigo: "C",
    descricao: "CONVENCIONAL (EM MÃOS)",
  },
];

export const padraoEmail = [
  {
    codigo: "MN",
    descricao: "MANUAL",
  },
  {
    codigo: "DA",
    descricao: "DOCUMENTOS A VENCER",
  },
  {
    codigo: "DV",
    descricao: "DOCUMENTOS VENCIDOS",
  },
  {
    codigo: "AB",
    descricao: "ABERTURA DA ATIVIDADE",
  },
  {
    codigo: "AA",
    descricao: "ATIVIDADE COM SLA A VENCER",
  },
  {
    codigo: "AV",
    descricao: "ATIVIDADE COM SLA VENCIDO",
  },
];

export const tipoConstituicao = [
  {
    codigo: "LT",
    descricao: "LTDA",
  },
  {
    codigo: "SA",
    descricao: "SA",
  },
  {
    codigo: "SS",
    descricao: "SS",
  },
  {
    codigo: "ME",
    descricao: "ME",
  },
  {
    codigo: "EP",
    descricao: "EPP",
  },
  {
    codigo: "OU",
    descricao: "OUTROS",
  },
];

export const situacaoCNPJ = [
  {
    tipo: "R",
    descricao: "Regular",
  },
  {
    tipo: "P",
    descricao: "Pendente",
  },
  {
    tipo: "I",
    descricao: "Inapto",
  },
];
