import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/usuarios.actions";
import { actions as domainsActions } from "../../reducers/dominios.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/usuarios",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarPerfisApi = () => {
  return authenticatedRequest({
    url: "/administracao/perfis",
    isResourceService: true,
    method: "get",
  });
};

const atualizarUsuarioApi = ({ usuario }) => {
  return authenticatedRequest({
    url: "/administracao/usuario",
    isResourceService: true,
    method: "post",
    body: { usuario: usuario },
  });
};

const getOrganizacaoApi = ({ values }) => {
  return authenticatedRequest({
    url: "/administracao/organizacao/" + values,
    isResourceService: true,
    method: "get",
  });
};

const buscarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/usuario/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};
const reenviarEmailApi = ({ usuario }) => {
  return authenticatedRequest({
    url: "/administracao/reenviar-email",
    isResourceService: true,
    method: "post",
    body: { usuario: usuario },
  });
};

const listarUsuarios = basicFlow({
  actionGenerator: actions.listarUsuarios,
  api: listarUsuariosApi,
});

const listarPerfis = basicFlow({
  actionGenerator: actions.listarPerfis,
  api: listarPerfisApi,
});

const atualizarUsuario = basicFlow({
  actionGenerator: actions.atualizarUsuario,
  api: atualizarUsuarioApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirUsuario = basicFlow({
  actionGenerator: actions.incluirUsuario,
  api: atualizarUsuarioApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const getOrganizacao = basicFlow({
  actionGenerator: actions.getOrganizacao,
  api: getOrganizacaoApi,
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
});

const buscarUsuario = basicFlow({
  actionGenerator: actions.buscarUsuario,
  api: buscarUsuariosApi,
});

const reenviarEmail = basicFlow({
  actionGenerator: actions.reenviarEmail,
  api: reenviarEmailApi,
  postSuccess: function () {
    toast.info("E-mail enviado com sucesso");
  },
});

function* listaUsuariosRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_USUARIOS, function* () {
    yield put(
      actions.listarUsuarios.request({
        pagina,
      })
    );
    yield put(actions.listarPerfis.request());
    yield put(domainsActions.produtos.request());
  });
}

export const sagas = [
  listarUsuarios.watcher(),
  listaUsuariosRouteWatcher(),
  listarPerfis.watcher(),
  atualizarUsuario.watcher(),
  getOrganizacao.watcher(),
  incluirUsuario.watcher(),
  buscarUsuario.watcher(),
  reenviarEmail.watcher(),
];
