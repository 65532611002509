import React from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

const DrawerMenu = ({ menu, level }) => {
  const [open, setOpen] = React.useState(false);
  const onClick = React.useCallback(() => {
    if (!!menu.menus) {
      setOpen(!open);
    } else {
      menu.action(menu.route);
    }
  }, [menu, open, setOpen]);

  return (
    <>
      <ListItem button onClick={onClick}>
        <ListItemText
          primary={menu.label}
          classes={{ primary: `drawer-item drawer-item-${level}` }}
        />
        {!!menu.menus ? !open ? <ExpandMore /> : <ExpandLess /> : null}
      </ListItem>
      {!!menu.menus && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {menu.menus.map((subMenu) => {
              return (
                <DrawerMenu key={subMenu.id} menu={subMenu} level={level + 1} />
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default DrawerMenu;
