import React, { Fragment } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";

const DocumentosDialog = ({ handleClose, detalhes, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      {!!detalhes && (
        <DialogContent>
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
          <div className={"page-title"}>
            Documentos pendentes para Encerramento
          </div>
          {detalhes.map((p) => (
            <Fragment key={p.id}>
              <div className={"page-subtitle mt-2 font-weight-bold"}>
                {p.participante}
              </div>
              <div className={"mb-3"}>
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    {p.documentos.map((item) => {
                      return (
                        <div className="mb-2" key={item.id}>
                          <span className="data-label">{`${
                            item.Documento.nome
                          } (${
                            item.situacaoDocumento === "A"
                              ? "Analisado"
                              : "Indexado"
                          })`}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DocumentosDialog;
