import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  CARREGAR: "@@imovel/CARREGAR",
  SALVAR: "@@imovel/SALVAR",
};

export const actions = {
  carregarImovel: createAsyncAction(types.CARREGAR),
  gravarImovel: createAsyncAction(types.SALVAR),
};
