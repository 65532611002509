import React from "react";
import Loader from "../../../../components/loaders";
import CardParticipante from "./CardParticipante";
import { Button } from "../../../../components";

const ParticipantesPage = ({
  proposta,
  buyers,
  socios,
  abrirDetalhePessoa,
  excluirParticipante,
  disabled,
  loading,
  perfil,
  detalheProposta,
  novoCadastroPf,
}) => (
  <>
    {!!loading && (
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loader type={"dots"} color={"brand"} size={50} />
        </div>
      </div>
    )}
    {!loading && (
      <div className="row">
        {!!buyers &&
          buyers.map((buyer) => (
            <div
              key={buyer.nome}
              className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3"
            >
              <CardParticipante
                proposta={proposta}
                pessoa={buyer}
                participacao={
                  Number.parseInt(detalheProposta?.Produto?.codigo) === 2
                    ? "Corban"
                    : "Convenio"
                }
                abrirDetalhePessoa={abrirDetalhePessoa}
                excluirParticipante={excluirParticipante}
                disabled={disabled}
                perfil={perfil}
                produto={Number.parseInt(detalheProposta?.Produto?.codigo)}
              />
            </div>
          ))}
        {!!socios &&
          socios.map((buyer) => (
            <div
              key={buyer.nome}
              className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3"
            >
              <CardParticipante
                proposta={proposta}
                pessoa={buyer}
                participacao={
                  buyer.tipoParticipante === "S"
                    ? "SÓCIO"
                    : "REPRESENTANTE LEGAL"
                }
                perfil={perfil}
                abrirDetalhePessoa={abrirDetalhePessoa}
                excluirParticipante={excluirParticipante}
                disabled={disabled}
              />
            </div>
          ))}
      </div>
    )}
    {!loading &&
      !disabled &&
      Number.parseInt(detalheProposta?.Produto?.codigo) === 2 && (
        <>
          <div className="row spacer">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-4">
              <Button
                //disabled={submitting}
                type={"submit"}
                className={"botao-participante"}
                onClick={() => novoCadastroPf(proposta, "S", "F")}
                label={
                  false ? (
                    <Loader type={"dots"} color={"white"} size={30} />
                  ) : (
                    "ADICIONAR SÓCIO PF"
                  )
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-4">
              <Button
                //disabled={submitting}
                type={"submit"}
                className={"botao-participante"}
                onClick={() => novoCadastroPf(proposta, "S", "J")}
                label={
                  false ? (
                    <Loader type={"dots"} color={"white"} size={30} />
                  ) : (
                    "ADICIONAR SÓCIO PJ"
                  )
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-4">
              <Button
                //disabled={submitting}
                type={"submit"}
                className={"botao-participante"}
                onClick={() => novoCadastroPf(proposta, "R", "F")}
                label={
                  false ? (
                    <Loader type={"dots"} color={"white"} size={30} />
                  ) : (
                    "ADICIONAR REPRESENTANTE PF"
                  )
                }
              />
            </div>
          </div>
        </>
      )}
  </>
);

export default ParticipantesPage;
