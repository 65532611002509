import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_ETAPAS: "@@administracao/LISTAR_ETAPAS",
  ATUALIZAR_ETAPA: "@@administracao/ATUALIZAR_ETAPA",
  NOVA_ETAPA: "@@administracao/NOVA_ETAPA",
  EDITAR_ETAPA: "@@administracao/EDITAR_ETAPA",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_ETAPA: "@@administracao/INCLUIR_ETAPA",
  SEARCH_ETAPA: "@@administracao/SEARCH_ETAPA",
  EXCLUIR_ETAPA: "@@administracao/EXCLUIR_ETAPA",
};

export const actions = {
  listarEtapas: createAsyncAction(types.LISTAR_ETAPAS),
  atualizarEtapa: createAsyncAction(types.ATUALIZAR_ETAPA),
  novaEtapa: () => createSyncAction(types.NOVA_ETAPA),
  editarEtapa: (values) => createSyncAction(types.EDITAR_ETAPA, values),
  voltarListaEtapa: () => createSyncAction(types.VOLTAR_LISTA),
  incluirEtapa: createAsyncAction(types.INCLUIR_ETAPA),
  buscarEtapa: createAsyncAction(types.SEARCH_ETAPA),
  excluirEtapa: createAsyncAction(types.EXCLUIR_ETAPA),
};
