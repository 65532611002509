import React from "react";
import {
  ControlledTextfield,
  ControlledSelect,
  CpfCnpjTextField,
  DateTextField,
  IntegerTextField,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";
import {
  divisao_administrativa,
  area_responsavel_kit,
} from "../../../../../utils/parametros";

const CardDadosPessoaJuridica = ({
  props,
  disabled,
  produtos,
  tipoConvenio,
}) => {
  return (
    <>
      <div className="card spacer">
        <div className="card-body">
          <span className="card-title color-brand">Dados do Orgão</span>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledTextfield
                formProps={props}
                label={"CNPJ"}
                type={"cnpj"}
                name={"cpf"}
                margin={"none"}
                disabled={disabled}
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-8">
              <ControlledTextfield
                formProps={props}
                label={"Nome do Convênio"}
                name={"nome"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledSelect
                formProps={props}
                label={"Produto"}
                name={"produtoConvenio"}
                margin={"none"}
                disabled={disabled}
                loading={produtos.loading}
              >
                {produtos.data.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledSelect
                formProps={props}
                label={"Divisão Administrativa"}
                name={"tipoDivisaoAdministrativa"}
                margin={"none"}
                disabled={disabled}
              >
                {divisao_administrativa.map((item) => (
                  <MenuItem
                    className="select-submenu-item"
                    key={item.codigo}
                    value={item.codigo}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledSelect
                formProps={props}
                label={"Tipo de Convênio"}
                name={"idTipoConvenio"}
                margin={"none"}
                disabled={disabled}
                loading={tipoConvenio.loading}
              >
                {tipoConvenio.data.map((item) => (
                  <MenuItem
                    className="select-submenu-item"
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <ControlledSelect
                formProps={props}
                label={"Área Responsável pelo Kit"}
                name={"tipoAreaKitCredenciamento"}
                margin={"none"}
                disabled={disabled}
              >
                {area_responsavel_kit.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.codigo}
                    value={item.codigo}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledTextfield
                formProps={props}
                label={"Quantidade de Funcionários"}
                name={"quantidadeFuncionarios"}
                margin={"none"}
                disabled={disabled}
                InputProps={{ inputComponent: IntegerTextField }}
                inputProps={{ maxLength: 11 }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledTextfield
                formProps={props}
                label={"Data de Início"}
                name={"dataConsulta"}
                margin={"none"}
                disabled={disabled}
                InputProps={{ inputComponent: DateTextField }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDadosPessoaJuridica;
