import { useDispatch, useSelector } from "react-redux";
import {
  getUsuario,
} from "../../../selectors/usuario.selectors";
import { useCallback } from "react";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import EscolhaProduto from "./EscolhaProduto";
import { actions as rotasActions, types as routes } from "../../../reducers/rotas.actions";

const EscolhaProdutoConnected = () => {
  const dispatch = useDispatch();
  const usuario = useSelector(getUsuario);

  const initialValues = {
    produto: "",
  };

  const rules = {
    produto: validators.string({ required: true }),
  };
  // eslint-disable-next-line
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleGoBack = useCallback(() => {
    dispatch(rotasActions.redirectTo(routes.PROPOSTAS));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (values) => {
      if (values.produto === 1) {
        dispatch(rotasActions.redirectTo(routes.NOVO_PARTICIPANTE));
      } else {
        dispatch(rotasActions.redirectTo(routes.NOVA_CORBAN));
      }
    },
    // eslint-disable-next-line
    [dispatch]
  );

  return (
    <EscolhaProduto
      usuario={usuario}
      formProps={formProps}
      handleSubmit={handleSubmit}
      handleGoBack={handleGoBack}
    />
  );
};

export default EscolhaProdutoConnected;
