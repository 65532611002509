import { useCallback, useState } from "react";
import ImovelPage from "./ImovelPage";
import { useSelector, useDispatch } from "react-redux";
import { getImovel } from "../../../../../selectors/imovel.selectors";
import { getPerfil } from "../../../../../selectors/usuario.selectors";
import { actions } from "../../../../../reducers/imovel.actions";
import { actions as propostaActions } from "../../../../../reducers/propostas.actions";
import { uf } from "../../../../../utils/parametros";
import { useForm } from "react-hook-form";
import validators from "../../../../../utils/validators";
import { getCartorios } from "../../../../../selectors/dominios.selectors";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../../reducers/rotas.actions";

const ImovelPageConnected = () => {
  const dispatch = useDispatch();
  const { loading, error, submitting, data } = useSelector(getImovel);
  const perfil = useSelector(getPerfil);
  const { data: dominioCartorios } = useSelector(getCartorios);

  const rules = {
    cep: validators.string({ required: true }),
    logradouro: validators.string({ required: true }),
    numero: validators.string({ required: true }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),
  };
  const initialValues = {
    cep: data?.cep || "",
    logradouro: data?.logradouro || "",
    numero: data?.numero || "",
    complemento: data?.complemento || "",
    bairro: data?.bairro || "",
    municipio: data?.municipio || "",
    uf: data?.uf || "",
    valorAvaliacao: data?.valorAvaliacao || "",
    foreiro: data?.foreiro === "S",
    condominio: data?.condominio === "S",
    cartorio: data?.cartorio || "",
  };
  // eslint-disable-next-line
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const [dominioCartoriosFiltrado, setDominioCartoriosFiltrado] = useState([]);
  const [didMount, setDidMount] = useState(false);

  const filtrarCartorios = useCallback(
    (values) => {
      setDominioCartoriosFiltrado(
        // eslint-disable-next-line
        dominioCartorios.filter((cartorio) => {
          if (
            cartorio.uf === values.uf?.toUpperCase() &&
            cartorio.municipio === values.municipio?.toUpperCase()
          )
            return cartorio;
        })
      );
    },
    // eslint-disable-next-line
    [dispatch, dominioCartorios, setDominioCartoriosFiltrado]
  );

  const successCallback = useCallback(
    ({ data }, value) => {
      formProps.setValue("logradouro", data?.logradouro || "");
      formProps.setValue("bairro", data?.bairro || "");
      formProps.setValue("uf", data?.uf || "");
      formProps.setValue("municipio", data?.localidade || "");
      filtrarCartorios({ uf: data?.uf, municipio: data?.localidade });
    },
    // eslint-disable-next-line
    [formProps, filtrarCartorios]
  );
  const handleZipCodeBlur = useCallback(
    (value) => {
      if (!!value && value.length === 9) {
        dispatch(
          propostaActions.consultarCepCorreios.request({
            value,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.gravarImovel.request({
          ...values,
          cartorio: values?.cartorio === "" ? null : values?.cartorio,
        })
      );
    },
    [dispatch]
  );

  const disabled = perfil !== "BPO" && perfil !== "BANCO";
  if (!didMount) {
    if (dominioCartorios?.length > 0 && data !== null) {
      setTimeout(() => {
        filtrarCartorios({
          uf: data?.uf?.toUpperCase(),
          municipio: data?.municipio?.toUpperCase(),
        });
      }, 50);
      setDidMount(true);
    }
  }

  const handleGoBack = useCallback(() => {
    dispatch(
      rotasActions.redirectTo(routes.PROPOSTA, {
        id: data.id_consulta,
      })
    );
  }, [dispatch, data]);

  return (
    <ImovelPage
      loading={loading}
      error={error}
      dominioUfs={uf}
      handleZipCodeBlur={handleZipCodeBlur}
      formProps={formProps}
      submitting={submitting}
      disabled={disabled}
      handleSubmit={handleSubmit}
      dominioCartorios={dominioCartoriosFiltrado}
      filtrarCartorios={filtrarCartorios}
      handleGoBack={handleGoBack}
    />
  );
};

export default ImovelPageConnected;
