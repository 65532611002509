import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";

const Produto = ({ produto, index, handleEdit, handleOpenExclusaoProduto }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 user-table ">
        <span>{produto.codigo}</span>
      </div>
      <div className="col-12 col-md-9 my-2 my-lg-0 user-table">
        <span>{produto.nome}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(produto)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() => handleOpenExclusaoProduto(produto)}
        >
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Produto;
