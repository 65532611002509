import { useDispatch, useSelector } from "react-redux";
import {
  getTiposDeConvenios,
  getTipoDeConvenio,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/tiposDeConvenios.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormTipoDeConvenio from "./FormTipoDeConvenio";

const TiposDeConveniosPageConnected = () => {
  const dispatch = useDispatch();
  const tiposDeConvenios = useSelector(getTiposDeConvenios);
  const tipoDeConvenio = useSelector(getTipoDeConvenio);
  const initialValues = {
    ...tipoDeConvenio,
  };

  const rules = {
    tipoConvenio: validators.string({ required: true }),
    nome: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        tipoConvenio: values.tipoConvenio.toUpperCase(),
        nome: values.nome.toUpperCase(),
      };
      if (!!i) {
        dispatch(
          actions.atualizarTipoDeConvenio.request({ tipoDeConvenio: request })
        );
      } else {
        dispatch(
          actions.incluirTipoDeConvenio.request({ tipoDeConvenio: request })
        );
      }
    },
    // eslint-disable-next-line
    [dispatch, tiposDeConvenios]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaTipoDeConvenio());
  }, [dispatch]);
  return (
    <FormTipoDeConvenio
      tipoDeConvenio={tipoDeConvenio}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default TiposDeConveniosPageConnected;
