import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_MOTIVOS_CANCELAMENTO: "@@administracao/LISTAR_MOTIVOS_CANCELAMENTO",
  ATUALIZAR_MOTIVO_CANCELAMENTO:
    "@@administracao/ATUALIZAR_MOTIVO_CANCELAMENTO",
  NOVO_MOTIVO_CANCELAMENTO: "@@administracao/NOVO_MOTIVO_CANCELAMENTO",
  EDITAR_MOTIVO_CANCELAMENTO: "@@administrador/EDITAR_MOTIVO_CANCELAMENTO",
  VOLTAR_LISTA_MOTIVO_CANCELAMENTO:
    "@@administrador/VOLTAR_LISTA_MOTIVO_CANCELAMENTO",
  INCLUIR_MOTIVO_CANCELAMENTO: "@@administrador/INCLUIR_MOTIVO_CANCELAMENTO",
  SEARCH_MOTIVO_CANCELAMENTO: "@@administrador/SEARCH_MOTIVO_CANCELAMENTO",
  EXCLUIR_MOTIVO_CANCELAMENTO: "@@administrador/EXLUIR_MOTIVO_CANCELAMENTO",
};

export const actions = {
  listarMotivosCancelamento: createAsyncAction(
    types.LISTAR_MOTIVOS_CANCELAMENTO
  ),
  atualizarMotivoCancelamento: createAsyncAction(
    types.ATUALIZAR_MOTIVO_CANCELAMENTO
  ),
  novoMotivoCancelamento: () =>
    createSyncAction(types.NOVO_MOTIVO_CANCELAMENTO),
  editarMotivoCancelamento: (values) =>
    createSyncAction(types.EDITAR_MOTIVO_CANCELAMENTO, values),
  voltarListaMotivoCancelamento: () =>
    createSyncAction(types.VOLTAR_LISTA_MOTIVO_CANCELAMENTO),
  incluirMotivoCancelamento: createAsyncAction(
    types.INCLUIR_MOTIVO_CANCELAMENTO
  ),
  buscarMotivoCancelamento: createAsyncAction(types.SEARCH_MOTIVO_CANCELAMENTO),
  excluirMotivoCancelamento: createAsyncAction(
    types.EXCLUIR_MOTIVO_CANCELAMENTO
  ),
};
