import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { ListAltOutlined } from "@material-ui/icons";
import { responsavelAtividade } from "../../../../utils/parametros";
import DetalhesProximaAtividadeDialogConnected from "./DetalhesProximaAtividadeDialog.connected";

const ProximaAtividade = ({ proximaAtividade, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDetalhesPoximaAtividade = (event) => {
    setAnchorEl(event);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        className={
          index % 2 === 0
            ? "row mt-0 d-flex align-items-center medium-row tr-even"
            : "row mt-0 d-flex align-items-center medium-row tr-odd"
        }
      >
        <div className="col-sm-12 col-lg-4 mt-sm-2 mt-lg-0">
          <div style={{ display: "flex" }}>
            <div className="resumir-texto">
              <Tooltip title={proximaAtividade.nome}>
                <span>{proximaAtividade.nome}</span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 resumir-texto">
          <Tooltip
            title={
              responsavelAtividade.filter(
                (s) => s.id === proximaAtividade.responsavel
              )[0].nome
            }
          >
            <span>
              {
                responsavelAtividade.filter(
                  (s) => s.id === proximaAtividade.responsavel
                )[0].nome
              }
            </span>
          </Tooltip>
        </div>
        <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
          <span>
            {!!proximaAtividade.dataSla
              ? new Date(proximaAtividade.dataSla).toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })
              : ""}{" "}
          </span>
        </div>
        <div className="col my-2 my-lg-0 resumir-texto">
          <Tooltip title={proximaAtividade?.Etapa?.nome ?? ""}>
            <span>{proximaAtividade?.Etapa?.nome ?? ""}</span>
          </Tooltip>
        </div>

        <div className="col-sm-12 col-lg-auto my-2 my-lg-0">
          <Tooltip title="Detalhes da Atividade">
            <IconButton
              className="p-2"
              onClick={() =>
                handleOpenDetalhesPoximaAtividade(
                  proximaAtividade.atividadesFinal
                )
              }
            >
              <ListAltOutlined className={"color-secondary icon-indexado"} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <DetalhesProximaAtividadeDialogConnected
        handleClose={handleClose}
        open={open}
        detalhes={anchorEl}
        atividade={proximaAtividade.nome}
      />
    </>
  );
};

export default ProximaAtividade;
