import React, { useCallback } from "react";
import { Button, MenuItem } from "../../../components";
import { ControlledSelect } from "../../../components/inputs";
import { tipoPessoa, qualificacao } from "../../../utils/parametros";
import Loader from "../../../components/loaders";

const Filtros = ({
  filtro,
  handleFilterClick,
  formProps,
  produtos,
  documentos,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleFilterClick(values);
    },
    [handleFilterClick]
  );

  const handleResetForm = () => {
    formProps.setValue("documento", "");
    formProps.setValue("qualificacao", "");
    formProps.setValue("participante", "");
    formProps.setValue("produto", "");
  };
  return (
    <form onSubmit={formProps.handleSubmit(handleClickNext)}>
      <div className="card">
        <div className={"card-body"}>
          <div className={"row"}>
            <div className="col-12 card-subtitle-checklist mt-0">
              <span className="">Filtros:</span>
            </div>
          </div>
          {!!filtro?.loading && (
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Loader type={"dots"} color={"brand"} size={50} />
              </div>
            </div>
          )}
          {!filtro?.loading && (
            <>
              <div className="row">
                <div className="col-3 mt-0">
                  <ControlledSelect
                    name={"documento"}
                    formProps={formProps}
                    multiple={false}
                    label={"Documento"}
                    margin={"none"}
                  >
                    {documentos.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-3 mt-0">
                  <ControlledSelect
                    name={"qualificacao"}
                    formProps={formProps}
                    multiple={false}
                    label={"Qualificação"}
                    margin={"none"}
                  >
                    {qualificacao.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-3 mt-0">
                  <ControlledSelect
                    name={"participante"}
                    formProps={formProps}
                    label={"Tipo de Pessoa"}
                    margin={"none"}
                    multiple={false}
                  >
                    {tipoPessoa.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-3 mt-0">
                  <ControlledSelect
                    name={"produto"}
                    formProps={formProps}
                    label={"Produto"}
                    margin={"none"}
                    multiple={false}
                  >
                    {produtos.data.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
              </div>
              <div className="row spacer">
                <div className="offset-md-6 col-md-3 col-12">
                  <Button label="LIMPAR" onClick={handleResetForm} />
                </div>
                <div className="col-md-3 col-12">
                  <Button label="FILTRAR" type={"submit"} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default Filtros;
