import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";

const Template = ({ data, index, handleEdit, handleOpenExclusao }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-4 mt-lg-0 user-table ">
        <span>{data.nome}</span>
      </div>
      <div className="col-7 mt-lg-0 user-table ">
        <span>{data.assunto}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(data)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton className="p-2" onClick={() => handleOpenExclusao(data)}>
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Template;
