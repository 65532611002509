import React, { useCallback } from "react";
import {
  ControlledTextfield,
  IntegerTextField,
} from "../../../components/inputs";
import { Button } from "../../../components";

import { useDispatch, useSelector } from "react-redux";
import { getRelatorio } from "../../../selectors/administracao.selectors";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import { actions } from "../../../reducers/administracao/relatorioBI.actions";

const FormRelatorio = ({ disabled, setIsNew, isNew, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  const relatorio = useSelector(getRelatorio);

  let initialValues = isEdit
    ? {
        ...relatorio,
      }
    : {
        codigo: null,
        relatorio: null,
        nome: null,
        idWorkspace: null,
        idRelatorio: null,
      };
  const rules = {
    codigo: validators.string({ required: true }),
    relatorio: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    idWorkspace: validators.string({ required: false }),
    idRelatorio: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const voltarParaLista = () => {
    setIsNew(false);
    setIsEdit(false);
  };

  const handleSubmit = useCallback(
    (values, relatorioId) => {
      if (isEdit) {
        values.id = relatorioId;
        dispatch(actions.atualizarRelatorio.request(values));
        setIsNew(false);
        setIsEdit(false);
      } else {
        dispatch(actions.criarRelatorio.request(values));
        setIsNew(false);
        setIsEdit(false);
      }
    },

    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, relatorio?.id);
    },
    // eslint-disable-next-line
    [handleSubmit, relatorio]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Relatório</span>
        <form onSubmit={formProps.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={formProps}
                label={"Código"}
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"nome"}
                uppercase={true}
                formProps={formProps}
                disabled={disabled}
                label={"Relatório"}
                margin={"none"}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <div className="col-12 col-md-12">
              <ControlledTextfield
                name={"idWorkspace"}
                uppercase={true}
                formProps={formProps}
                disabled={disabled}
                label={"Id Workspace"}
                margin={"none"}
                inputProps={{ maxLength: 2000 }}
              />
            </div>
            <div className="col-12 col-md-12">
              <ControlledTextfield
                name={"idRelatorio"}
                formProps={formProps}
                uppercase={true}
                disabled={disabled}
                label={"Id Relatório"}
                margin={"none"}
                inputProps={{ maxLength: 2000 }}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={relatorio?.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={relatorio?.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormRelatorio;
