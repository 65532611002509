import TemplateEmailPage from "./TemplateEmailPage";
import { useDispatch, useSelector } from "react-redux";
import { getTemplates } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/templatesEmail.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const TemplateEmailPageConnected = () => {
  const dispatch = useDispatch();
  const data = useSelector(getTemplates);
  const loading = data?.loading;
  const total = data?.total;
  const pagina = data?.page;

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(actions.listarTemplates.request({ pagina: page }));
    },
    [dispatch]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarTemplate({ template: values }));
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirTemplate.request({ id: i }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novoTemplate());
  }, [dispatch]);

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "codigo",
        value: values.search,
      };
      dispatch(actions.buscarTemplate.request({ search: request, pagina: 1 }));
    },
    [dispatch]
  );

  return (
    <TemplateEmailPage
      templates={data.list}
      template={data.template}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default TemplateEmailPageConnected;
