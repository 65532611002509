import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  PRODUCAO_DIA: "@@dashboard/PRODUCAO_DIA",
  PRODUCAO_MES: "@@dashboard/PRODUCAO_MES",
  PROJECAO_MES: "@@dashboard/PROJECAO_MES",
  CONSULTAS_ACIMA_VALOR: "@@dashboard/CONSULTAS_ACIMA_VALOR",
  CONSULTAS_POR_DATA: "@@dashboard/CONSULTAS_POR_DATA",
};

export const actions = {
  producaoDia: createAsyncAction(types.PRODUCAO_DIA),
  producaoMes: createAsyncAction(types.PRODUCAO_MES),
  projecaoMes: createAsyncAction(types.PROJECAO_MES),
  consultasAcimaValor: createAsyncAction(types.CONSULTAS_ACIMA_VALOR),
  consultasPorData: createAsyncAction(types.CONSULTAS_POR_DATA),
};
