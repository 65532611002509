import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { Textfield } from "../../../../components/inputs";
import {
  default as BotaoUploadDocumento,
  getAcceptedTypes,
} from "./BotaoUploadDocumento";
import { CloseSharp } from "@material-ui/icons";

const IndexacaoDocumentoDialog = ({
  open,
  handleClose,
  handleSubmit,
  formProps,
  uploading,
  handleUpload,
  dados,
}) => {
  const [motivo, setMotivo] = useState();
  useEffect(() => {
    setMotivo(dados.motivoIndexacao);
  }, [dados]);
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: "w100" }}
      maxWidth="sm"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className="page-title">Indexação de Documentos</div>
        <div className="row">
          <div className="col-12">
            <Textfield
              multiline={true}
              uppercase={false}
              rowsMax={8}
              rows={4}
              name="motivo"
              margin="none"
              label="Justificativa para Indexação (opcional)"
              inputProps={{ maxLength: 500 }}
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
            />
          </div>
        </div>
        <div className="row justify-content-center mb-3 mt-2">
          <div className="col-6 mt-3">
            <BotaoUploadDocumento
              name={dados.id}
              uploading={uploading}
              classes={{
                formControl: "justify-content-center",
              }}
              accept={getAcceptedTypes(dados.tipo)}
              fullWidth={true}
              onChange={handleUpload}
              motivo={motivo}
              handleClose={handleClose}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default IndexacaoDocumentoDialog;
