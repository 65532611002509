import DashboardPage from "./DashboardPage";
import {
  getProducaoDia,
  getProducaoMes,
  getProjecaoMes,
  getConsultasValor,
  getConsultasPorData,
} from "../../selectors/dashboard.selectors";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { actions } from "../../reducers/dashboard.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as propostasActions } from "../../reducers/propostas.actions";

const DashboardPageConnected = () => {
  const dispatch = useDispatch();
  const producaoDia = useSelector(getProducaoDia);
  const producaoMes = useSelector(getProducaoMes);
  const projecao = useSelector(getProjecaoMes);
  const consultas = useSelector(getConsultasValor);
  const consultasPorData = useSelector(getConsultasPorData);

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(actions.consultasAcimaValor.request(page));
    },
    [dispatch]
  );

  const goToCockpit = useCallback(
    (id) => {
      dispatch(propostasActions.limparProposta());
      dispatch(routeActions.redirectTo(routes.PROPOSTA, { id }));
    },
    [dispatch]
  );

  const getProducao = useCallback(
    (dataInicio, dataFim) => {
      const data = { dataInicio, dataFim };
      if (dataInicio !== dataFim) {
        dispatch(actions.producaoMes.request(data));
      } else {
        dispatch(actions.producaoDia.request(data));
      }
    },
    [dispatch]
  );

  const getListaConsultasMes = useCallback(() => {
    const dataInicio = producaoMes.dataInicio
      ? producaoMes.dataInicio
      : new Date();
    const dataFim = producaoMes.dataFim ? producaoMes.dataFim : new Date();
    dataInicio.setDate(1);
    dispatch(
      actions.consultasPorData.request({ dataInicio, dataFim, pagina: 1 })
    );
  }, [dispatch, producaoMes]);

  const getListaConsultasDia = useCallback(() => {
    const dataInicio = producaoDia.dataInicio
      ? producaoDia.dataInicio
      : new Date();
    const dataFim = producaoDia.dataFim ? producaoDia.dataFim : new Date();
    dispatch(
      actions.consultasPorData.request({ dataInicio, dataFim, pagina: 1 })
    );
  }, [dispatch, producaoDia]);

  return (
    <DashboardPage
      producaoDia={producaoDia}
      producaoMes={producaoMes}
      projecao={projecao}
      consultas={consultas}
      handlePagination={handlePagination}
      goToCockpit={goToCockpit}
      getProducao={getProducao}
      getListaConsultasMes={getListaConsultasMes}
      getListaConsultasDia={getListaConsultasDia}
      consultasPorData={consultasPorData}
    />
  );
};

export default DashboardPageConnected;
