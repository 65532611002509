import { useDispatch, useSelector } from "react-redux";
import { getMotivosCancelamento } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/motivoCancelamento.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormMotivoCancelamento from "./FormMotivoCancelamento";

const FormMotivoCancelamentoConnected = () => {
  const dispatch = useDispatch();
  const motivosCancelamento = useSelector(getMotivosCancelamento);

  const initialValues = {
    ...motivosCancelamento["motivo_cancelamento"],
  };

  const rules = {
    codigo: validators.string({ required: true }),
    descricao: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        descricao: values.descricao.toUpperCase(),
        codigo: values.codigo,
      };
      if (!!i) {
        dispatch(
          actions.atualizarMotivoCancelamento.request({
            motivo_cancelamento: request,
          })
        );
      } else {
        dispatch(
          actions.incluirMotivoCancelamento.request({
            motivo_cancelamento: request,
          })
        );
      }
    },
    [dispatch]
  );

  const voltar = useCallback(() => {
    dispatch(actions.voltarListaMotivoCancelamento());
  }, [dispatch]);

  return (
    <FormMotivoCancelamento
      motivoCancelamento={motivosCancelamento["motivo_cancelamento"]}
      props={formProps}
      handleSubmit={handleSubmit}
      voltar={voltar}
      loading={motivosCancelamento.loading}
      disabled={false}
    />
  );
};

export default FormMotivoCancelamentoConnected;
