import { authenticatedRequest } from "../utils/api";
import basicFlow from "./asyncHandler";
import { actions } from "../reducers/imovel.actions";
import { actions as propostaActions } from "../reducers/propostas.actions";
import { routeWatcher } from "./rotas.saga";
import {
  types as routes,
  actions as routeActions,
} from "../reducers/rotas.actions";
import { put, select } from "redux-saga/effects";
import { getImovel } from "../selectors/imovel.selectors";
import { toast } from "react-toastify";
import { removeNonDigitsFromString } from "../utils/basic";
import { getPayload } from "../selectors/rotas.selectors";
import { actions as domainActions } from "../reducers/dominios.actions";

const carregarImovelApi = ({ id }) => {
  return authenticatedRequest({
    url: `/cadastro/imovel/${id}`,
    isResourceService: true,
    method: "get",
  });
};
const carregarImovel = basicFlow({
  actionGenerator: actions.carregarImovel,
  transform: function* () {
    const { id } = yield select(getImovel);
    return {
      id,
    };
  },
  api: carregarImovelApi,
  postFailure: function* () {
    const proposta = yield select(getPayload);
    yield put(
      routeActions.redirectTo(routes.PROPOSTA, { id: proposta.proposta })
    );
    toast.error("Imóvel não encontrado");
  },
});

const gravarImovelApi = (values) => {
  return authenticatedRequest({
    url: `/cadastro/imovel`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};
const gravarImovel = basicFlow({
  actionGenerator: actions.gravarImovel,
  transform: function* (payload) {
    const { proposta, id } = yield select(getImovel);

    return {
      id_imovel: id,
      id_consulta: proposta,
      dados: {
        ...payload,
        cep: removeNonDigitsFromString(payload.cep),
        foreiro: !!payload.foreiro ? "S" : "N",
        condominio: !!payload.condominio ? "S" : "N",
        localidade: null,
      },
    };
  },
  api: gravarImovelApi,
  postSuccess: function* () {
    toast.success("Informações salvas com sucesso.");
    const { proposta } = yield select(getImovel);
    yield put(propostaActions.consultarProposta.request(proposta));
    yield put(routeActions.redirectTo(routes.PROPOSTA, { id: proposta }));
  },
});

function* imovelRouteWatcher() {
  yield routeWatcher(routes.IMOVEL, function* () {
    yield put(actions.carregarImovel.request());
    yield put(domainActions.cartorio.request());
  });
}

export const sagas = [
  carregarImovel.watcher(),
  gravarImovel.watcher(),
  imovelRouteWatcher(),
];
