import React from "react";
import { CloseSharp, DeleteForeverSharp } from "@material-ui/icons";
import { CardWithDeleteButton } from "../../../components";
import Chip from "@material-ui/core/Chip";

const Cardregra = ({ regra, handleExclusaoRegra, editRegra }) => {
  return (
    <CardWithDeleteButton
      style={{ minHeight: 150 }}
      canDelete={true}
      onClick={() => editRegra(regra)}
      className={"clickable"}
      renderDefault={() => (
        <>
          <div className="d-flex flex-row card-decisoes-title justify-content-between"></div>
          <div
            className={
              "atividades-label-title participantes-label-title-comprador mt-n4"
            }
          >
            Regra {regra.idRegra}
          </div>
          <div className="card-body d-flex flex-column">
            {!!regra?.atividades && regra?.atividades.length > 0 && (
              <div className="row">
                <div className="col-12 mt-1">
                  <div className="participantes-label participantes-label-medium">
                    Atividades
                  </div>
                </div>
                <div className="col-12 mt-1">
                  {regra?.atividades.map((at) => {
                    return (
                      <Chip
                        //icon={icon}
                        label={at.nome}
                        //onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                        className={"mt-1 ml-1"}
                        variant="outlined"
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      renderSecondary={({ handleClick }) => (
        <div className="card-body bg-red visible-1s pb-0">
          <CloseSharp
            className="position-absolute clickable close-icon"
            onClick={handleClick}
          />
          <div className="row align-items-center justify-content-center">
            <DeleteForeverSharp className="large-delete-icon" />
            <span className="color-white text-center">
              Confirma a exclusão da regra{" "}
              <span className="delete-buyer-name">{regra.descricao}</span>?
            </span>
          </div>
          <div className="row align-items-center justify-content-center">
            <div
              className="clickable confirm-delete-button spacer"
              onClick={(e) => {
                e.stopPropagation();
                handleExclusaoRegra(regra);
              }}
            >
              SIM, PODE EXCLUIR
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default Cardregra;
