import React from "react";
import ProximaAtividade from "./ProximaAtividade";
import { Add } from "@material-ui/icons";

const ProximasAtividades = ({
  proximasAtividades,
  handleOpenAdicionarEtapaExcepcional,
  excepcional,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="card" style={{ minHeight: 100 }}>
          <div className="card-body d-flex flex-column">
            <div className={"row d-flex align-items-center"}>
              <div className={"col mt-0"}>
                <div className="card-title-checklist">Próximas Atividades</div>
              </div>
              {excepcional === "S" && (
                <div>
                  <div
                    className="botao-adicionar mr-3"
                    onClick={handleOpenAdicionarEtapaExcepcional}
                  >
                    <Add className="botao-adicionar-icon mr-1" />
                    <span className="botao-adicionar-text">
                      ABRIR ATIVIDADE
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className={"row"}>
              <div className="col-12">
                <div
                  className={
                    "row mt-0 d-flex align-items-center medium-row texto-dispensar tr-odd"
                  }
                >
                  <div className="col-sm-12 col-lg-4 mt-sm-2 mt-lg-0">
                    <span>Atividade</span>
                  </div>
                  <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                    <span>Resp.</span>
                  </div>
                  <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                    <span>Previsão Início</span>
                  </div>
                  <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                    <span>Etapa</span>
                  </div>
                </div>
                {proximasAtividades &&
                  proximasAtividades
                    .sort((a, b) => {
                      if (a.nome > b.nome) {
                        return 1;
                      }
                      if (a.nome < b.nome) {
                        return -1;
                      }
                      return 0;
                    })
                    .sort((a, b) => {
                      if (a.Etapa.ordem > b.Etapa.ordem) {
                        return 1;
                      }
                      if (a.Etapa.ordem < b.Etapa.ordem) {
                        return -1;
                      }
                      return 0;
                    })
                    .reduce((unique, o) => {
                      if (
                        !unique.some(
                          (obj) => obj.nome === o.nome && obj.id === o.id
                        )
                      ) {
                        unique.push(o);
                      }
                      return unique;
                    }, [])

                    .map((d, index) => (
                      <ProximaAtividade
                        key={d.id}
                        proximaAtividade={d}
                        index={index}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProximasAtividades;
