import React from "react";
import DetalhesAtividadeDialog from "./DetalhesAtividadeDialog";

const DetalhesAtividadeDialogConnected = ({
  handleClose,
  dados: { open, ...otherData },
}) => {
  return (
    <DetalhesAtividadeDialog
      open={open}
      handleClose={handleClose}
      detalhes={otherData}
    />
  );
};

export default DetalhesAtividadeDialogConnected;
