import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  LISTAR_ARTEFATOS: "@@artefatos/LISTAR",
  REMOVER_ARTEFATO: "@@artefatos/REMOVER",
  SELECIONAR_ARTEFATO: "@@artefatos/SELECIONAR",
  DOWNLOAD_ARTEFATO: "@@artefatos/DOWNLOAD",
  UPLOAD_ARTEFATO: "@@artefatos/UPLOAD",
};

export const actions = {
  listarArtefatos: createAsyncAction(types.LISTAR_ARTEFATOS),
  removerArtefato: createAsyncAction(types.REMOVER_ARTEFATO),
  download: createAsyncAction(types.DOWNLOAD_ARTEFATO),
  upload: createAsyncAction(types.UPLOAD_ARTEFATO),
  selecionarArtefato: (artefato) =>
    createSyncAction(types.SELECIONAR_ARTEFATO, { artefato }),
};
