import { authenticatedRequest } from "../utils/api";
import basicFlow from "./asyncHandler";
import { actions } from "../reducers/dashboard.actions";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { put } from "redux-saga/effects";

const producaoApi = (values) => {
  return authenticatedRequest({
    url: `/dashboard/producao`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const producaoDia = basicFlow({
  actionGenerator: actions.producaoDia,
  api: producaoApi,
  transform: function (data) {
    let dataInicio = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/Sao_paulo" })
    );
    if (dataInicio.getDay() === 0)
      dataInicio.setUTCDate(dataInicio.getUTCDate() - 2);
    if (dataInicio.getDay() === 6)
      dataInicio.setUTCDate(dataInicio.getUTCDate() - 1);
    let dataFim = new Date(dataInicio);
    if (!!data?.dataInicio) {
      return { dataInicio: data.dataInicio, dataFim: data.dataFim };
    }
    return { dataInicio: dataInicio, dataFim: dataFim };
  },
});

const producaoMes = basicFlow({
  actionGenerator: actions.producaoMes,
  api: producaoApi,
  transform: function (data) {
    let dataInicio = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    let dataFim = new Date();
    if (!!data?.dataInicio) {
      return { dataInicio: data.dataInicio, dataFim: data.dataFim };
    }
    return { dataInicio: dataInicio, dataFim: dataFim };
  },
});

const projecaoApi = () => {
  return authenticatedRequest({
    url: `/dashboard/projecao`,
    isResourceService: true,
    method: "get",
  });
};

const projecao = basicFlow({
  actionGenerator: actions.projecaoMes,
  api: projecaoApi,
});

const consultasAcimaValorApi = (value) => {
  return authenticatedRequest({
    url: `/consulta/consultar-valor`,
    isResourceService: true,
    method: "post",
    body: { valor: 500000, pagina: value },
  });
};

const consultasAcimaValor = basicFlow({
  actionGenerator: actions.consultasAcimaValor,
  api: consultasAcimaValorApi,
});

const consultasPorDataApi = (value) => {
  return authenticatedRequest({
    url: `/dashboard/listar-consultas-data`,
    isResourceService: true,
    method: "post",
    body: value,
  });
};

const consultasPorData = basicFlow({
  actionGenerator: actions.consultasPorData,
  api: consultasPorDataApi,
});

function* dashboardRouteWatcher() {
  yield routeWatcher(routes.DASHBOARD, function* () {
    yield put(actions.producaoDia.request());
    yield put(actions.producaoMes.request());
    yield put(actions.projecaoMes.request());
    yield put(actions.consultasAcimaValor.request(1));
  });
}

export const sagas = [
  producaoDia.watcher(),
  dashboardRouteWatcher(),
  producaoMes.watcher(),
  projecao.watcher(),
  consultasAcimaValor.watcher(),
  consultasPorData.watcher(),
];
