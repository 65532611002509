import React, { useCallback } from "react";
import TrocarSituacaoConsulta from "./TrocarSituacaoConsulta";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import { actions } from "../../../reducers/propostas.actions";
import { getProposta } from "../../../selectors/propostas.selectors";
import { getMotivosCancelamento } from "../../../selectors/dominios.selectors";

const TrocarSituacaoConsultaConnected = ({
  alterarSituacao,
  handleCloseAlterarSituacao,
  handleOpenAlterarSituacao,
  handleOpcaoCanceladoSelected,
  showMotivoCancelamento,
}) => {
  const dispatch = useDispatch();
  const situacaoProposta = useSelector(getProposta)?.situacaoProposta;
  const motivosCancelamento = useSelector(getMotivosCancelamento);
  const dominioData = [
    { key: "R", desc: "Em Prospecção" },
    { key: "A", desc: "Em Andamento" },
    { key: "E", desc: "Ativo" },
    { key: "C", desc: "Cancelado" },
    { key: "P", desc: "Suspenso" },
  ];
  const rules = {
    situacao: validators.string({ required: false }),
    consultaID: validators.number({ required: true }),
    motivoCancelamento: validators.number({ required: true }),
  };
  const initialValues = {
    situacao: alterarSituacao.situacao,
    consultaID: alterarSituacao.consultaID,
    motivoCancelamento: alterarSituacao.motivoCancelamento,
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    ({ situacao, motivoCancelamento }) => {
      dispatch(
        actions.atualizarSituacaoConsulta.request({
          consultaID: alterarSituacao.consultaID,
          situacao,
          motivoCancelamento: motivoCancelamento ? motivoCancelamento : null,
          handleCloseAlterarSituacao,
        })
      );
    },
    [dispatch, alterarSituacao, handleCloseAlterarSituacao]
  );

  return (
    <TrocarSituacaoConsulta
      handleSubmit={handleSubmit}
      handleOpen={handleOpenAlterarSituacao}
      open={alterarSituacao.open}
      formProps={formProps}
      dominioData={dominioData}
      handleClose={handleCloseAlterarSituacao}
      motivoCancelameto={motivosCancelamento}
      submitting={situacaoProposta?.loading}
      showMotivoCancelamento={showMotivoCancelamento}
      handleOpcaoCanceladoSelected={handleOpcaoCanceladoSelected}
    />
  );
};

export default TrocarSituacaoConsultaConnected;
