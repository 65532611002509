import BasePage from "../../BasePage";
import Loader from "../../../components/loaders";
import React, { useCallback, useState } from "react";
import { Add } from "@material-ui/icons";
import CondicaoChecklist from "./CondicaoChecklist";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import FormCondicao from "./FormCondicao.connected";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import Filtros from "./Filtros";

const MatrizChecklistPage = ({
  loading,
  matriz,
  condicao,
  total,
  pagina,
  handlePagination,
  handleNew,
  handleEdit,
  excluirCondicao,
  formProps,
  registrosPagina,
  filtro,
  handleFilterClick,
  produtos,
  documentos,
}) => {
  const [dadosExclusaoDocumento, setDadosExclusaoDocumento] = useState({
    open: false,
  });

  const handleCloseExclusaoDocumento = useCallback(() => {
    setDadosExclusaoDocumento({
      open: false,
    });
  }, []);

  const handleOpenExclusaoDocumento = useCallback((values) => {
    setDadosExclusaoDocumento({
      open: true,
      condicao: values,
    });
  }, []);

  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row spacer">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={80} />
          </div>
        </div>
      )}
      {!loading && !condicao && (
        <Filtros
          filtro={filtro}
          handleFilterClick={handleFilterClick}
          formProps={formProps}
          produtos={produtos}
          documentos={documentos}
        />
      )}
      {!loading && !condicao && matriz.length === 0 && (
        <div className="flex d-flex flex-column justify-content-center align-items-center">
          <span className="d-block colored-card-text mt-4 color-secondary text-center">
            Nenhuma condição encontrada com o filtro selecionado.
            <br />
            Selecione outro filtro.
          </span>
        </div>
      )}
      {!loading && !condicao && matriz.length > 0 && (
        <div className="row spacer">
          <div className="col-12">
            <div className="card" style={{ minHeight: 100 }}>
              <div className="card-body d-flex flex-column">
                <div className={"row d-flex align-items-center"}>
                  <div className={"col mt-0"}>
                    <div className="card-title color-brand">
                      Matriz Geração Checklist
                    </div>
                  </div>
                </div>
                <div className={"row spacer cabecalho-matriz"}>
                  <div className="col-4 col-md-4 mt-sm-2 mt-lg-0 color-brand">
                    <span>DOCUMENTO</span>
                  </div>
                  <div className="col-2 col-md-2 my-2 my-lg-0 color-brand">
                    <span>QUALIFICAÇÃO</span>
                  </div>
                  <div className="col-1 col-md-1  my-2 my-lg-0 color-brand">
                    <span>PESSOA</span>
                  </div>
                  <div className="col-2 col-md-2 my-2 my-lg-0 color-brand">
                    <span>LOCALIDADE</span>
                  </div>
                  <div className="col-3 col-md-3 my-2 my-lg-0 color-brand">
                    <span>PRODUTO</span>
                  </div>
                  <div className="col-4 col-md-4 my-2 my-lg-0 color-brand">
                    <span>CONDIÇÃO</span>
                  </div>
                  <div className="col-2 col-md-2 my-2 my-lg-0 color-brand">
                    <span>UF</span>
                  </div>
                  <div className="col-2 col-md-2 my-2 my-lg-0 color-brand">
                    <span>CARTÓRIO</span>
                  </div>
                </div>
                <div className={"row text-lowercase"}>
                  <div className="col-12">
                    {matriz.map((d, index) => (
                      <CondicaoChecklist
                        key={d.id}
                        condicao={d}
                        index={index}
                        handleEdit={handleEdit}
                        handleOpenExclusaoDocumento={
                          handleOpenExclusaoDocumento
                        }
                      />
                    ))}
                  </div>
                </div>
                <div className="row spacer">
                  <div className="col-12 offset-md-9 col-md-3">
                    <div className="d-none d-md-block">
                      <div className="botao-adicionar mr-3" onClick={handleNew}>
                        <Add className="botao-adicionar-icon mr-1" />
                        <span className="botao-adicionar-text">
                          ADICIONAR CONDIÇÃO
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && !condicao && total > registrosPagina && (
        <>
          <div className="row spacer">
            <div className="col-12">
              <Pagination
                count={Math.ceil(total / registrosPagina)}
                page={pagina}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </div>
          </div>
        </>
      )}
      {!loading && !!condicao && (
        <div>
          <FormCondicao />
        </div>
      )}
      <ConfirmarExclusaoDialog
        open={dadosExclusaoDocumento.open}
        handleClose={handleCloseExclusaoDocumento}
        excluirCondicao={excluirCondicao}
        condicao={dadosExclusaoDocumento.condicao}
      />
    </BasePage>
  );
};

export default MatrizChecklistPage;
