import MotivoCancelamentoPage from "./MotivoCancelamentoPage";
import { useDispatch, useSelector } from "react-redux";
import { getMotivosCancelamento } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/motivoCancelamento.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const MotivoCancelamentoPageConnected = () => {
  const dispatch = useDispatch();
  const motivosCancelamento = useSelector(getMotivosCancelamento);
  const loading = motivosCancelamento?.loading;
  const total = motivosCancelamento?.total;
  const pagina = motivosCancelamento?.page;

  const handlePaginantion = useCallback(
    (e, page) => {
      dispatch(actions.listarMotivosCancelamento.request({ pagina: page }));
    },
    [dispatch]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(
        actions.editarMotivoCancelamento({ motivo_cancelamento: values })
      );
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novoMotivoCancelamento());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirMotivoCancelamento.request({ id: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "descricao",
        value: values.search,
      };
      dispatch(
        actions.buscarMotivoCancelamento.request({ search: request, pagina: 1 })
      );
    },
    [dispatch]
  );

  return (
    <MotivoCancelamentoPage
      motivosCancelamento={motivosCancelamento?.list}
      motivoCancelamento={motivosCancelamento["motivo_cancelamento"]}
      loading={loading}
      total={total}
      handlePagination={handlePaginantion}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default MotivoCancelamentoPageConnected;
