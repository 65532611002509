import { useDispatch, useSelector } from "react-redux";
import {
  getEtapas,
  getEtapa,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/etapas.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormEtapa from "./FormEtapa";

const EtapasPageConnected = () => {
  const dispatch = useDispatch();
  const etapas = useSelector(getEtapas);
  const etapa = useSelector(getEtapa);

  const initialValues = {
    ...etapa,
    apresentaTimeline: etapa.apresentaTimeline === "S",
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        codigo: values.codigo,
        nome: values.nome,
        ordem: values.ordem,
        apresentaTimeline: values.apresentaTimeline ? "S" : "N",
        produto: etapas.produto,
      };
      if (!!i) {
        dispatch(actions.atualizarEtapa.request({ etapa: request }));
      } else {
        dispatch(actions.incluirEtapa.request({ etapa: request }));
      }
    },
    // eslint-disable-next-line
    [dispatch, etapas]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaEtapa());
  }, [dispatch]);

  return (
    <FormEtapa
      etapa={etapa}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default EtapasPageConnected;
