import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/templatesEmail.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarTemplates = basicFlow({
  actionGenerator: actions.listarTemplates,
  api: (values) => {
    return authenticatedRequest({
      url: "/administracao/templates",
      isResourceService: true,
      method: "post",
      body: values,
    });
  },
});

const atualizarTemplate = basicFlow({
  actionGenerator: actions.atualizarTemplate,
  api: (values) => {
    return authenticatedRequest({
      url: "/administracao/template",
      isResourceService: true,
      method: "post",
      body: values,
    });
  },
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirTemplate = basicFlow({
  actionGenerator: actions.incluirTemplate,
  api: (values) => {
    return authenticatedRequest({
      url: "/administracao/template",
      isResourceService: true,
      method: "post",
      body: values,
    });
  },
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirTemplate = basicFlow({
  actionGenerator: actions.excluirTemplate,
  api: (values) => {
    return authenticatedRequest({
      url: "/administracao/excluir-template",
      isResourceService: true,
      method: "post",
      body: values,
    });
  },
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarTemplate = basicFlow({
  actionGenerator: actions.buscarTemplate,
  api: (values) => {
    return authenticatedRequest({
      url: "/administracao/template/search",
      isResourceService: true,
      method: "post",
      body: values,
    });
  },
});

function* templatesRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_TEMPLATES, function* () {
    yield put(
      actions.listarTemplates.request({
        pagina,
      })
    );
  });
}

export const sagas = [
  listarTemplates.watcher(),
  templatesRouteWatcher(),
  atualizarTemplate.watcher(),
  incluirTemplate.watcher(),
  buscarTemplate.watcher(),
  excluirTemplate.watcher(),
];
