import BasePage from "./BasePage";
import { styled } from "@material-ui/core/styles";

const Title = styled("div")({
  fontFamily: "Montserrat, sans-serif",
  color: "#020550",
  fontSize: "2rem",
  fontWeight: 800,
  margin: "32px 0",
  textTransform: "uppercase",
});
const SubTitle = styled("div")({
  fontFamily: "Montserrat, sans-serif",
  color: "#004891",
  fontSize: "1.2rem",
  fontWeight: 800,
});
const Content = styled("div")({
  fontFamily: "Montserrat, sans-serif",
  color: "#3a3f53",
  fontSize: "1rem",
  margin: "0 0 32px 0",
  whiteSpace: "pre-wrap",
});

const PrivacyPolicyPage = () => {
  return (
    <BasePage>
      <Title>Política de Privacidade</Title>
      <SubTitle>Sua privacidade é algo importante para nós</SubTitle>
      <Content>
        Veja aqui o compromisso da G&P Projetos e Sistemas com a proteção de
        seus dados pessoais.
      </Content>
      <Content>
        {"Essa política tem como objetivo informar sobre como a G&P tem compromisso com a privacidade e tratamento dos dados pessoais coletados através de seus sites, sistemas e serviços prestados.\n" +
          "\n" +
          "1. OBJETIVO\n" +
          "A presente política tem como objetivo:\n" +
          "• Informar como a G&P protege e mantém a confidencialidade e integridade de dados pessoais que trata e controla;\n" +
          "• Informar os direitos aos titulares de dados e quais os meios de acesso para seus requerimentos.\n" +
          "\n" +
          "2. ABRANGÊNCIA\n" +
          "Esta Política de Privacidade é destinada a todos os usuários, titulares de dados, que acessam a G&P por qualquer meio sistêmico (site, softwares, sistemas e aplicações) ou físico (escritórios, stand de eventos e outros). Podendo o titular ter relação direta com a G&P (clientes, potenciais clientes, candidatos a vagas de emprego, visitante de nossos escritórios, de nossos Sites e Aplicativos) ou indireta, neste caso utilizando plataformas, softwares e aplicativos mantidos pela G&P para ou em nome de seus clientes.\n" +
          "\n" +
          "3. DEFINIÇÕES\n" +
          "• Browser: é um programa que permite a navegação pela internet. Como Google Chrome, Internet Explorer, Mozilla Firefox etc.;\n" +
          "• Cookies: são arquivos temporários que contém informações de navegação do usuário que está acessando a rede, website ou aplicações;\n" +
          "• Cloud: traduzido, nuvem. Refere-se à computação em nuvem, tecnologia que permite o uso remoto de recursos computacionais;\n" +
          "• Dados pessoais: qualquer informação relativa a uma pessoa singular identificada ou identificável, direta ou indiretamente, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrônica ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, econômica, cultural ou social;\n" +
          "• LGPD: Lei de Proteção Geral de Dados;\n" +
          "• Link: ligação entre um e outro documento. Pode ser usado para direcionar de uma para uma outra página de internet;\n" +
          "• Logs: é o registro de eventos que sejam relevantes para um sistema;\n" +
          "• IP: ou Internet Protocol (Protocolo de Internet), é a identificação única que cada computador deve utilizar para acesso a uma rede (interna ou externa);\n" +
          "• Segurança da Informação: relativo aos proteção de um conjunto de informações e ativos, preservando o valor que estes possuem para a pessoa e/ou organização;\n" +
          "• Usuário: quem utiliza os serviços, ativos ou informações;\n" +
          "• Widgets: é um tipo de botão ou atalho que permite acesso rápido a um aplicativo ou endereço web.\n" +
          "\n" +
          "4. COLETA DE DADOS\n" +
          "A privacidade e confidencialidade de informações são de extrema importância para a G&P. Coletamos dados pessoais dos nossos funcionários, potenciais funcionários, clientes, potenciais clientes, fornecedores, contatos comerciais, acionistas e usuários do site.\n" +
          "Também são tratados dados originados de nossas operações de negócios, dados estes coletados através ou em nome de clientes que contratam os serviços da G&P. A coleta neste caso é necessária para o funcionamento adequado de tais operações de negócio.\n" +
          "Ao informar seus dados, a G&P entende que o usuário em questão:\n" +
          "• Não irá praticar falsidade ideológica ou qualquer informação que seja propositalmente falsa;\n" +
          "• Cometer fraude;\n" +
          "• Transmitir ou propagar arquivos que contenham códigos maliciosos, potencialmente destrutivos.\n" +
          "Vamos detalhar como suas informações podem ser coletadas e como são tratadas pela G&P nos subtópicos a seguir.\n" +
          "\n" +
          "4.1. Consentimento para coleta e utilização dos dados\n" +
          "Para todos os meios de coleta de dados, a G&P solicita o consentimento prévio do titular de acordo com a legislação aplicável. Os meios de coleta de consentimento podem ser:\n" +
          "• Sistêmicos: Nos casos em que lhe solicitamos para clicar “Eu aceito/I accept”, “Eu concordo/I agree” ou em botões, checkbox/funcionalidades de caráter similar em relação a um termo de privacidade;\n" +
          "• Documental: Nos casos em que lhe solicitamos o consentimento mediante assinatura em termos, fichas e formulários impressos – ou digital.\n" +
          "• Mensagens eletrônicas: Nos casos de utilização de e-mail com a devida autorização e consentimento para utilização dos dados pessoais.\n" +
          "• Fale conosco, pesquisas de satisfação, boletins e outros formulários eletrônicos: disponíveis em nosso website e aplicações.\n" +
          "Independente do modo de coleta, é necessário o consentimento, e saiba que ao fazê-lo, consideraremos que nos foi dada permissão para processar os dados pessoais de acordo com o consentimento fornecido e legislação em vigor.\n" +
          "Não utilizaremos seus dados pessoais para quaisquer fins não compatíveis com aqueles informados a você, a menos que exigido ou autorizado por lei.\n" +
          "\n" +
          "4.2. Fornecimento de dados\n" +
          "A G&P usa seus dados pessoais apenas quando necessário, conforme consentimento e para fins específicos.\n" +
          "Abaixo apresentamos uma visão geral sobre o fornecimento de seus dados pessoais e base legal para cada um destes fins.\n" +
          "\n" +
          "4.2.1. Dados para recrutamento\n" +
          "Para fins de análise de perfil profissional visando o recrutamento e seleção de candidatos a vagas, a G&P coleta e armazena dados em consentimento com os candidatos.\n" +
          "A tabela abaixo demonstra os fins para o qual são utilizados os dados pessoais necessários:\n" +
          "– Avaliar a sua adequação para ocupar a vaga à qual você está se candidatando, assim como para outros cargos que poderão ser oferecidos.\n" +
          "– Facilitar a comunicação com você.\n" +
          "Justifica-se com base nos legítimos interesses da G&P de:\n" +
          "– Assegurar a contratação de candidatos adequados;\n" +
          "– Garantir a adequada comunicação com os candidatos.\n" +
          "Produzir análises de dados, incluindo avaliar conjuntos de candidatos para melhor entender quem está se oferecendo para cargos na G&P, bem como atrair e reter talentos.\n" +
          "Justifica-se com base nos legítimos interesses da G&P de assegurar a continuidade da melhora dos seus processos de recrutamento e assegurar a contratação de candidatos aderentes às posições de interesse da companhia e seus clientes.\n" +
          "Transferir seus dados para contato, formação acadêmica, dados profissionais, informações de inscrição e o currículo, todo o material fornecido por você em nosso sistema de recrutamento para o G&P Talent Connection – um site que mantemos para notificá-lo sobre novas vagas que possam ser do seu interesse.\n" +
          "Justifica-se com base nos legítimos interesses da G&P de assegurar a contratação de candidatos aderentes às posições de interesse da companhia e seus clientes.\n" +
          "Se você se cadastrar no nosso website Carreiras/Careers, nós repassaremos seus dados para uma base a fim de enviar-lhe futuramente comunicados sobre vagas e eventos na G&P. Você também poderá receber recomendações personalizadas para vagas enquanto navega em nosso website Carreiras/Careers.\n" +
          "Justifica-se com base nos legítimos interesses da G&P de assegurar a contratação de candidatos aderentes às posições de interesse da companhia e seus clientes.\n" +
          "Em alguns casos, as entrevistas online poderão ser gravadas para fins de revisão por parte de recrutadores adicionais e gerentes contratantes. Tal fato ocorre com consentimento do titular de dados e apoia-se legalmente no legítimo interesse da G&P em assegurar a contratação de candidatos adequados.\n" +
          "Ao se cadastrar no site para nossas oportunidades de carreira ou solicitar contato, o usuário deve informar seu e-mail e, no caso de candidatura, também o currículo, que pode ser encaminhado com outros dados que o usuário entender como sendo o ideal para o modelo, sendo os mais comuns seu nome, data de nascimento, telefone e endereço.\n" +
          "\n" +
          "4.2.2. Dados para contratação\n" +
          "Para fins de contratação e com base legal na legislação vigente, a G&P coleta e armazena dados pessoais.\n" +
          "A tabela abaixo demonstra os fins para o qual são utilizados os dados pessoais necessários:\n" +
          "Fins: Gerenciar nossa relação contratual e/ou empregatícia e seus requisitos legais.\n" +
          "Base legal: Necessário para cumprimento contratual e conformidade com obrigação legal.\n" +
          "Fins: Facilitar a comunicação com você (inclusive em casos de emergência e para fornecer a você informações solicitadas).\n" +
          "Base legal: Justifica-se com base em nossos interesses legítimos de assegurar comunicação e gestão de emergências adequadas na organização.\n" +
          "Fins: Desempenhar funções administrativas (pagamentos, reembolsos etc.).\n" +
          "Base legal: Justifica-se com base nos legítimos interesses da G&P de assegurar a contratação de candidatos adequados.\n" +
          "Fins: Administrar benefícios do funcionário.\n" +
          "Base legal: Justifica-se com base nos legítimos interesses da G&P de garantir que nossos funcionários recebam os benefícios adequados.\n" +
          "Fins: Produzir qualquer relatório legalmente exigido e responder a processo legal.\n" +
          "Base legal: No cumprimento de uma obrigação legal.\n" +
          "\n" +
          "4.2.3. Dados para operações de negócio\n" +
          "A tabela abaixo demonstra os fins para o qual são utilizados os dados pessoais necessários:\n" +
          "Operar e gerenciar operações de negócios da G&P ou de seus clientes.\n" +
          "Justifica-se com base em nossos interesses legítimos de assegurar o funcionamento adequado das nossas operações de negócios.\n" +
          "Os dados coletados e utilizados em operações de negócio poderão ser compartilhados com terceiros, clientes da G&P, contratantes destas operações ou empresas prestadoras de serviços necessários para a manutenção das atividades das operações de negócio.\n" +
          "Conforme legislação em vigor, o compartilhamento de dados acontecerá mediante ao consentimento do titular de dados.\n" +
          "Obs.: O não consentimento ou a retirada do consentimento poderá inviabilizar operações de negócio para o titular. O fornecimento dos dados se faz indispensável para o cumprimento de atividades, análises e cadastros necessários para as finalidades de diversas operações.\n" +
          "\n" +
          "4.2.4. Dados de widgets de mídias sociais e terceiros\n" +
          "O site da G&P possui botões que tem como funcionalidade direcionar o usuário para:\n" +
          "– Redes sociais como Twitter, Facebook, Youtube e Instagram.\n" +
          "– Sites das nossas redes parceiras, anunciantes e clientes.\n" +
          "Ao clicar nestes botões, você será direcionado para websites ou aplicações não pertencentes à G&P, que possuem sua própria política de privacidade e responsabilidades para com os titulares de dados e com seus sistemas de coletas de dados.\n" +
          "Temos o cuidado em utilizar apenas sites conhecidamente seguros para associar aos nossos canais de comunicação. No entanto a G&P não se responsabiliza por websites e programas destes terceiros, bem como a política de privacidade de dados da G&P não é aplicada a estes sites. Observe os termos de uso e de privacidade destes terceiros antes de usar ou fornecer informações em seus websites ou aplicações. (veja detalhes no item 9. PRIVACIDADE DOS DADOS).\n" +
          "A G&P executa escutas sociais, através da identificação e análise de conteúdos acessíveis publicamente que citem a G&P ou suas operações de negócio. O objetivo é obter a percepção de sentimento, intenção, disposição e tendências de mercado e as necessidades de nossos stakeholders e, dessa forma, melhorar nossos serviços. Não é objetivo de tais escutas identificar indivíduos.\n" +
          "Escuta social por meio de buscas por palavras-chave em conteúdos disponibilizados publicamente.\n" +
          "Justifica-se com base em nossos legítimos interesses em proteger nossos ativos e nossa marca nas mídias sociais.\n" +
          "\n" +
          "4.2.5. Dados e informações do browser\n" +
          "Ao acessar nosso website, nosso servidor coleta algumas informações registradas no seu navegador de internet. Os registros que normalmente são fornecidos pelo browser são: IP, data e hora da solicitação, idioma e a solicitação que foi realizada através do seu navegador. É possível que também sejam encaminhadas informações que possam identificar o seu navegador.\n" +
          "A coleta de dados ocorre mediante concordância com a mensagem de consentimento exibida no momento de acesso ao website.\n" +
          "Tal coleta de dados ocorre com os objetivos exibidos na tabela a seguir:\n" +
          "Melhorar a segurança e o funcionamento do nosso website.\n" +
          "Justifica-se com base em nossos legítimos interesses de manter a segurança adequada do website.\n" +
          "Monitorar os acessos do website, suas páginas, dados e formulários.\n" +
          "Justifica-se com base nos nossos interesses legítimos de aprimorar a experiência do usuário.\n" +
          "\n" +
          "4.2.6. Dados de cookies e logs de dados\n" +
          "Eventualmente, podemos usar cookies ou tecnologias de rastreamento e localização para uso administrativo. A coleta e análise dos movimentos e comportamento coletados na visita em nosso website, assim como os recursos que você acessa ou faz download, nos ajudam a desenvolver melhorias para nossos serviços e sistemas bem como disponibilizar conteúdo mais relevantes para os visitantes do nosso site, tornando a experiencia do usuário mais positiva.\n" +
          "Os cookies coletados não transferem seus dados pessoais nem a sistemas no seu computador. Outros dados como tráfego, dados de localização, weblogs e outros dados de comunicação, também podem estar inclusos nos cookies e log armazenados.\n" +
          "Tal coleta de dados ocorre com os objetivos exibidos na tabela abaixo:\n" +
          "Melhorar a qualidade, conteúdo e segurança de nosso website e e-mails.\n" +
          "Justifica-se com base em nossos legítimos interesses de manter a qualidade e segurança adequada do website.\n" +
          "\n" +
          "4.2.7. Comunicações e pesquisas de satisfação\n" +
          "As comunicações e pesquisas de satisfação são enviadas via e-mail, SMS, mensagens eletrônicas de aplicativos como WhatsApp e outros meios digitais. O objetivo é avaliarmos os serviços, obter feedback de clientes e público em geral, encaminhar notificações sobre produtos, serviços ou novidades, bem como melhorarmos continuamente. Portanto, os e-mails que recebemos podem ser armazenados para fins estatísticos e de controle, visando melhorias e comunicações posteriores.\n" +
          "Caso queira revogar o consentimento para que não façamos mais o envio de comunicados e e-mails de marketing, o mesmo poderá ser realizado por meio da opção de cancelamento presente no rodapé dos e-mails.\n" +
          "Tal coleta de dados ocorre com os objetivos exibidos na tabela a seguir:\n" +
          "Mensagens, comunicações e pesquisas digitais.\n" +
          "Justifica-se com base em nosso legítimo interesse em obter opiniões e informações para assegurar que os nossos serviços/produtos estão sendo prestados no mais alto nível.\n" +
          "\n" +
          "4.2.8. Website, blog e portais G&P\n" +
          "Nosso website possui formulários para cadastro que têm o como objetivo inscrever o usuário para obter serviços, receber propagandas, boletins de notícias e alertas, inscrever-se para conferência(s), solicitar ou fazer download de artigo ou documentos técnicos.\n" +
          "Os cadastros vêm ao encontro com a melhor experiência que desejamos proporcionar aos usuários de nossos canais de comunicação.\n" +
          "As páginas que coletam os dados pessoais exibem informações sobre o motivo pelo qual os dados pessoais são necessários e como eles serão usados. Sempre serão coletados o mínimo de dados necessários para a finalidade proposta. O fornecimento desses dados fica inteiramente a critério do usuário.\n" +
          "Tal coleta de dados ocorre com os objetivos exibidos na tabela abaixo:\n" +
          "Formulários e fichas web de cadastro.\n" +
          "Justifica-se com base em nosso legítimo interesse em melhorar a experiência do usuário através de disponibilização de conteúdo.\n" +
          "\n" +
          "4.2.9. Dados de clientes e potenciais clientes\n" +
          "A G&P mantém uma base de dados de Gestão de Relacionamento com o Cliente (CRM). Seu objetivo é registrar as ações comerciais e de marketing junto a clientes e potenciais clientes.\n" +
          "Nossas bases de dados de CRM incluem dados de funcionários dos nossos clientes e de empresas com quem temos uma relação de parceria ou de prospecção comercial.\n" +
          "Os dados pessoais mantidos envolvem: dados de contatos da companhia, informações publicamente disponíveis (ex. participação na diretoria, artigos publicados, comunicados de imprensa, postagens públicas em portais e mídia social), informações relacionadas ao negócio incluídas por profissionais da G&P com base nas interações pessoais realizadas.\n" +
          "Caso você deseje ser excluído das nossas bases comerciais de dados e CRM, entre em conosco através do e-mail ouvidoria@gpnet.com.br.\n" +
          "\n" +
          "4.2.10. Visitação on-site (matriz e filiais)\n" +
          "Caso venha visitar nossos escritórios e instalações, podemos gravar a sua imagem através de sistemas de monitoramento e gestão de acesso por razões de segurança. Serão coletadas também informações pessoais para providenciar as suas credenciais de acesso, de forma a manter um ambiente de trabalho seguro à segurança patrimonial e à integridade física.\n" +
          "Tal coleta de dados ocorre com os objetivos exibidos na tabela abaixo:\n" +
          "Captação de imagens\n" +
          "Justifica-se com base em nosso legítimo interesse em manter a segurança patrimonial, integridade física e controle de acesso.\n" +
          "Credenciais de acesso\n" +
          "Justifica-se com base em nosso legítimo interesse em manter a segurança patrimonial, integridade física e controle de acesso.\n" +
          "Com relação ao exposto acima relacionado a legítimos interesses para um determinado fim, acreditamos que nossos interesses legítimos não são sobrepostos pelos seus interesses, direitos ou liberdades, dada a transparência que fornecemos na atividade de tratamento, nossa abordagem de privacidade na concepção, nossas revisões de privacidade periódicas e seus direitos em relação à atividade de tratamento de dados.\n" +
          "\n" +
          "5. CONSENTIMENTO\n" +
          "Todos os dados são tratados mediante consentimento prévio, conforme legislação aplicável. O consentimento poderá ser fornecido por meio de documentos, formulários, websites, sistemas e aplicações.\n" +
          "Caso queira solicitar o cancelamento de tratamento de seus dados pessoais e revogar o consentimento hora fornecido, o mesmo poderá ser realizado a qualquer tempo através das próprias aplicações, ou através do e-mail ouvidoria@gpnet.com.br.\n" +
          "\n" +
          "6. CONSULTA E ALTERAÇÃO DOS DADOS PESSOAIS\n" +
          "A consulta ou alteração dos seus dados pessoais que são tratados em nosso ambiente poderá ser realizada a qualquer tempo através das próprias aplicações, ou através do e-mail ouvidoria@gpnet.com.br. Utilize este e-mail também em caso de dúvidas adicionais.\n" +
          "\n" +
          "7. ARMAZENAMENTO E PERÍODO DE RETENÇÃO DOS DADOS\n" +
          "Seus dados são armazenados pela G&P em seus servidores próprios ou em tecnologia gerenciada internamente, utilizando anonimização e tecnologias de proteção de dados que mantém a segurança e privacidade dos dados dos titulares Em casos de utilização de serviços em cloud para armazenamento dos dados, a G&P exige aos seus provedores de serviços que utilizem, minimamente, as mesmas regras, níveis e tecnologias de segurança utilizadas internamente para a proteção dos seus dados, com padrões rígidos de segurança e confidencialidade, além do cumprimento das diretrizes da legislação em vigor, em especial da LGPD.\n" +
          "Independentemente do local de armazenamento, a metodologia aplicada deverá estar em conformidade com as políticas da G&P e legislação vigente. Isso significa que os direitos do titular de dados independem do local de armazenamento, permanecendo os mesmos em qualquer situação.\n" +
          "A G&P deverá manter as suas informações pessoais apenas pelo tempo necessário ao cumprimento de obrigações legais, contratuais ou para os fins para os quais as informações são tratadas. Os registros de dados pessoais são deletados após um período razoável de acordo com os critérios:\n" +
          "• Período legal de retenção de dados;\n" +
          "• Ao final da relação que temos em andamento com você (relação comercial, contratual ou de divulgações em geral ao qual esteja cadastrado);\n" +
          "• Após o período necessário para atingir as finalidades pretendidas.\n" +
          "Caso deseje que a G&P não utilize mais os seus dados pessoais, entre em contato no canal de ouvidoria via portal ou através do e-mail ouvidoria@gpnet.com.br.\n" +
          "\n" +
          "8. FINALIDADE DE USO DOS DADOS\n" +
          "Todas as informações pessoais coletadas pela G&P são utilizadas para comunicação com os usuários e pessoas interessadas nos nossos serviços, de forma ética e legal, sempre com o devido consentimento e autorização declarada deles. Também são utilizados para campanhas de marketing, análise de dados e pesquisas a fim de promover melhorias, fornecer serviços com maior qualidade e tomadas de decisões que possam favorecer o crescimento e evolução na prestação dos nossos serviços. As informações coletadas/armazenadas pela G&P não serão repassadas para terceiros ou utilizadas para finalidades diferentes das aqui expostas, exceto em caso de determinação legal ou judicial.\n" +
          "Compartilharemos de dados pessoais com terceiros.\n" +
          "Poderemos transferir dados pessoais para prestadores de serviços, conselheiros profissionais, autoridades públicas e governamentais ou terceiros relacionados, visando a adequada manutenção e processamento de nossas operações corporativas ou comerciais:\n" +
          "• Compartilhar suas informações com provedores de serviços terceirizados que prestem serviços para nós, tais como cobranças, processos de pagamento, serviços ao cliente, envio de e-mails, propaganda e marketing, segurança e monitoramento de desempenho, serviços de reparos e manutenção, processamento e cumprimento de ordens e transações, verificação de informações cadastrais de consumidores, pesquisa, guarda de dados, auditoria e processamento de dados;\n" +
          "• Para proteger e defender direitos legais, segurança e a proteção da G&P, nossas afiliadas, usuários ou o público, inclusive a fim de proteger contra fraudes e atividades maliciosas; e\n" +
          "• Para outros propósitos de negócio descritos neste termo de privacidade ou para qualquer outro fim revelado a você no momento que coletamos a informação ou decorrente de seu consentimento expresso.\n" +
          "Prestadores de serviços corporativos ou terceiros podem estar localizados em outros países. Independentemente da localização da prestação do serviço tomaremos as precauções necessárias para assegurar que seus dados pessoais terão a proteção adequada conforme exigido pelas leis de privacidade de dados pertinentes e pelas políticas internas da G&P.\n" +
          "\n" +
          "9. PRIVACIDADE DOS DADOS\n" +
          "A G&P tem a responsabilidade em garantir a privacidade dos dados coletados e tratados por ela. Caso os usuários acessem ou divulguem informações em sites de terceiros, mesmo tendo acessado através de links disponíveis no nosso site, a G&P não será responsável pelos danos que possam ser causados aos usuários pelo mau uso de suas informações por esses sites ou outros.\n" +
          "Ao comentar, compartilhar informações ou interagir em mídias sociais, mesmo que em página sob o nome da G&P, o usuário aceita que suas informações poderão ser vistas pelos demais usuários da plataforma e, possivelmente, possa ser usada por outros usuários para comunicar-se. Caso isso ocorra, a G&P não será imputável de qualquer responsabilidade.\n" +
          "As ações dos usuários em redes sociais, mesmo em páginas que levem o nome da G&P não são de responsabilidade de nossa empresa. Mesmo havendo controle e monitoramento das nossas redes sociais, não somos responsáveis pelas informações prestadas pelos usuários da rede e nem pelas ações por eles realizadas.\n" +
          "\n" +
          "10. DIREITOS DOS USUÁRIOS\n" +
          "Os dados dos usuários serão, a qualquer momento, controlados por ele, podendo ser solicitado à G&P através de requisição:\n" +
          "• Acesso aos seus dados;\n" +
          "• Correção de dados que estejam incompletos, incorretos ou sem exatidão;\n" +
          "• Bloqueio ou eliminação dos dados;\n" +
          "• Revogar o uso dos dados, tendo esta solicitação efeito futuro à data de solicitação;\n" +
          "• Possibilidade de cancelamento, a qualquer momento, do nosso material informativo;\n" +
          "• Confirmação da existência de tratamento dos dados.\n" +
          "O usuário poderá, além dos direitos acima descritos, solicitar demais presentes na Lei Geral de Proteção de Dados (LGPD), número 13.709/2018.\n" +
          "Para requisição sobre os seus dados, o usuário poderá encaminhar e-mail para ouvidoria@gpnet.com.br ou através do nosso canal de Ouvidoria no site https://www.gpnet.com.br. A G&P se coloca no direito de confirmar a solicitação e identidade do solicitante por meios que ela julgar necessário. Após a verificação, a G&P irá informar o tempo para concluir a solicitação.\n" +
          "Em caso de solicitação de exclusão ou revogação do uso dos dados, a G&P deverá eliminar os dados do titular, retendo somente os dados necessários para cumprimento de obrigações legais e contratuais conforme legislação em vigor.\n" +
          "\n" +
          "11. CONTATO DO ENCARREGADO\n" +
          "O Encarregado de Dados é responsável pela inspeção de questões relacionadas a esta Política de Privacidade e ao Tratamento de Dados Pessoais pela G&P.\n" +
          "Caso tenha alguma dúvida sobre como consultar seus dados e exercer seus direitos de titular, nos contate através do e-mail abaixo.\n" +
          "O contato também pode ser realizado caso:\n" +
          "• Você tiver questões sobre como a G&P protege seus dados pessoais;\n" +
          "• Você desejar exercer seus direitos em relação aos seus dados pessoais (exemplo: “Quais são seus direitos em relação ao tratamento dos seus dados pessoais?” e “Seus direitos em relação a comunicações de marketing”);\n" +
          "• Você deseja fazer uma reclamação sobre o uso dos seus dados pela G&P.\n" +
          "Encarregado de Dados: Elaine Cristina Lima da Conceição\n" +
          "E-mail: dpo@gpnet.com.br\n" +
          "\n" +
          "12. ALTERAÇÕES NA POLÍTICA\n" +
          "A G&P se mantém no direito de alterar essa Política a qualquer momento, sob necessidade da empresa ou por força maior. No entanto, os direitos sob os dados dos usuários serão mantidos e em nenhum momento utilizados sem o seu consentimento declarado de forma explicita.\n" +
          "Em caso de alterações significativas, os usuários/visitantes serão comunicados através do nosso site. Nele serão mantidas as informações de data da alteração e versão da política vigente. As políticas anteriores serão armazenadas como histórico.\n\n"}
        <b>Última atualização 17 de novembro de 2022.</b>
      </Content>
    </BasePage>
  );
};

export default PrivacyPolicyPage;
