import { useCallback } from "react";
import { useForm } from "react-hook-form";
import TrocarSenhaForm from "./TrocarSenhaForm";
import validators from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/autenticacao.actions";
import { getTrocarSenhaLoading } from "../../selectors/autenticacao.selectors";
import PasswordValidator from "password-validator";

const TrocarSenhaFormConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getTrocarSenhaLoading);
  const form = useForm();

  // prettier-ignore
  const schema = new PasswordValidator()
    .is().min(8)
    .has().uppercase()
		.has().digits()
    .has().lowercase()
    .has().symbols();

  const rules = {
    password: validators.string({
      required: true,
      custom: {
        rules: (value) => {
          return (
            schema.validate(value) ||
            "A senha informada não obedece a todas as regras descritas abaixo"
          );
        },
      },
    }),
    password2: validators.string({
      required: true,
      custom: {
        same: (value) => {
          const { password } = form.getValues();
          return value === password || "As senhas devem ser iguais";
        },
      },
    }),
  };

  const initialValues = {
    password: "",
    password2: "",
  };

  const formProps = {
    ...form,
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      dispatch(actions.trocarSenha.request(values));
    },
    [dispatch]
  );

  const handlePasswordChange = useCallback(
    (evt, setList) => {
      setList({
        dirty: true,
        list: schema.validate(evt.target?.value, { list: true }),
      });
    },
    [schema]
  );

  return (
    <TrocarSenhaForm
      formProps={formProps}
      loading={loading}
      handleSubmit={handleSubmit}
      handlePasswordChange={handlePasswordChange}
    />
  );
};

export default TrocarSenhaFormConnected;
