import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const View = ({ menuSelecionado, itens, selecionarOpcaoMenu }) => {
  return (
    <ToggleButtonGroup
      value={menuSelecionado}
      exclusive
      onChange={(e, value) => {
        selecionarOpcaoMenu(e, value);
      }}
      style={{ flexWrap: "wrap" }}
    >
      {itens.map((item) => {
        return (
          <ToggleButton
            value={item.key}
            key={item.key}
            style={{ minWidth: "140px" }}
            classes={{
              root: "menu-proposta mb-0 mt-0",
              label: "menu-proposta-label d-flex flex-column",
              selected: "menu-proposta-selected",
            }}
          >
            {item.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default View;
