import { useDispatch, useSelector } from "react-redux";
import { getLocalidades } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/localidades.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormLocalidade from "./FormLocalidade";

const FormLocalidadeConnected = () => {
  const dispatch = useDispatch();
  const localidades = useSelector(getLocalidades);

  const initialValues = {
    ...localidades.localidade,
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        nome: values.nome.toUpperCase(),
        codigo: values.codigo,
      };
      if (!!i) {
        dispatch(actions.atualizarLocalidade.request({ localidade: request }));
      } else {
        dispatch(actions.incluirLocalidade.request({ localidade: request }));
      }
    },
    [dispatch]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaLocalidade());
  }, [dispatch]);

  return (
    <FormLocalidade
      localidade={localidades.localidade}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default FormLocalidadeConnected;
