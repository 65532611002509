import { MenuItem } from "../../../../components";
import { ControlledSelect } from "../../../../components/inputs";
import React from "react";
import {
  getEmailTemplate,
  getEmailTemplates,
} from "../../../../selectors/emails.selectors";
import { useSelector } from "react-redux";
import InputLoader from "../../../../components/loaders/InputLoader";

export default function TemplatesAtividade(props) {
  const { data: templates } = useSelector(getEmailTemplates);
  const { processing } = useSelector(getEmailTemplate);

  return (
    <>
      <ControlledSelect margin={"none"} {...props}>
        {templates.map((item) => (
          <MenuItem
            className={"select-submenu-item"}
            key={item.id}
            value={item.id}
          >
            {item.nome}
          </MenuItem>
        ))}
      </ControlledSelect>
      {!!processing && <InputLoader color={"dark-orange"} />}
    </>
  );
}
